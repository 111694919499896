import { defineStore } from 'pinia'
import io from 'socket.io-client'
import { getBaseUrl } from '@/utils/baseUrlProvider'
import { isUnifiedCommerce } from '@/constants'
import * as uuid from 'uuid'
import vuexStore from '@/store'
import { getToken } from '@baloise/vue-keycloak'

export const useSocketIOStore = defineStore('socketIO', {
  state: () => ({
    activeCorrelationIds: [],
    _socket: null
  }),
  // Prevent claring of the socket on global reset.
  globalReset() {
    this.activeCorrelationIds = []
  },
  getters: {
    socket: (state) => {
      if (!state._socket) {
        // No need to throw an error in that case. It doesnt break the application.
        // console.log('>>> Socket is not yet initialized.')
        return
      }
      return state._socket
    }
  },
  actions: {
    async initConnection(vm = {}) {
      if (!vuexStore.getters['Auth/isAuthenticated']) {
        vm?.$log?.debug('SocketIOManager | not authenticated')
        return
      }
      const clientAccount = vuexStore.getters['Auth/getUser']
      const token = vuexStore.getters['Auth/getAuthToken']

      let tokenUFC = null
      if (isUnifiedCommerce()) {
        tokenUFC = await getToken()
      }

      const baseUrl = await getBaseUrl()
      const baseSocketUrl = baseUrl
        .replace('https://', 'wss://')
        .replace('http://', 'ws://')

      if (window.io) {
        // for e2e testing purposes we're checking if io instance already exists, i.e. mocked in the e2e tests
        // otherwise the cypress tests will all fail due to cross origin error.
        this._socket = window.io
      } else {
        const socketOptions = {
          path: '/socket.io',
          forceNew: true,
          transports: ['websocket'],
          query: {
            clientAccount,
            caller: uuid.v4().substring(0, 30), // API is limiting the caller property length to 30 chars
            authorization: `Bearer ${isUnifiedCommerce() ? tokenUFC : token}`,
            ...(isUnifiedCommerce() && { whitelabel: 'unified-commerce' })
          }
        }
        this._socket = io(baseSocketUrl, socketOptions)
      }

      this.socket.on('connect', () => {
        vm?.$log?.debug('SocketIOManager | connection opened')
      })

      this.socket.on('validationError', (error) => {
        const err = 'SocketIOManager | validationError' + error
        vm?.$log?.error('SocketIOManager | validationError', err)
        vm?.$logException(err)
      })

      this.socket.on('error', (error) => {
        const err = 'SocketIOManager | error' + error
        vm?.$log?.error('SocketIOManager | error', err)
        // vm?.$logException(err) //comment out to prevent sentry spamming
        this.socket?.connect()
      })

      this.socket.on('serverError', (error) => {
        const err = 'SocketIOManager | serverError' + error
        vm?.$log?.error('SocketIOManager | serverError', err)
        vm?.$logException(err)
      })

      this.socket.on('disconnect', (reason) => {
        vm?.$log?.debug('SocketIOManager | socket disconnected:', reason)
      })

      this.socket.on('connect_error', function (error) {
        vm?.$log?.debug('SocketIOManager | connection error:', error)
      })
    },
    closeConnection(vm = {}) {
      vm.$log.debug('SocketIOManager | connection closed')
      this.socket?.close()
    },
    setActiveId(id) {
      this.activeCorrelationIds.push(id)
    },
    removeActiveId(id) {
      this.activeCorrelationIds = this.activeCorrelationIds.filter(
        (cid) => cid === id
      )
    },
    hasActiveId(id) {
      return this.activeCorrelationIds.includes(id)
    },
    getSocketDataParser() {
      return (data, items) => {
        // TODO: currently API socket.io returns double stringified response, when fixed on API side, should remove one of the parses.
        const parsedData = JSON.parse(JSON.parse(data))
        const id = parsedData.correlationId

        const isValidId = this.hasActiveId(id)
        const storedItem = isValidId && items?.[id]
        const originKey = storedItem?.originKey

        return {
          id,
          isValidId,
          parsedData,
          originKey,
          storedItem
        }
      }
    }
  }
})
