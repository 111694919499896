<template>
  <div
    :class="[
      'th-wrapper bg-white rounded relative',
      wrapperClass || 'card-shadow mb-8 xs:mb-6',
      {
        'th-wrapper-collapsable': collapsable,
        'th-wrapper-collapsed': collapsed,
        'th-wrapper-disabled': disabled
      }
    ]"
  >
    <!-- Header -->
    <div
      v-if="
        $slots.title ||
        title ||
        $slots.center ||
        $slots.actions ||
        $slots['header-additional'] ||
        collapsable
      "
      class="p-8 pb-0 rounded-t header-item th-wrapper-header"
      :class="headerClass"
    >
      <!-- Toggle -->
      <div
        v-if="collapsable"
        class="th-wrapper-header-toggle"
        @click="toggleCollapse"
      />

      <!-- Title -->
      <div
        v-if="$slots.title || title"
        class="th-wrapper-header-title header-item font-bold text-sm text-th-color-header"
        :class="{
          'flex-grow': collapsed,
          'text-base': isWhiteLabel,
          'w-full': fullWidthTitle
        }"
      >
        <svgicon
          v-if="navigateBackButton"
          :src="require('@/assets/icons/th-icon-arrow-left.svg')"
          :style="{
            height: '25px',
            width: '25px'
          }"
          class="cursor-pointer"
          @click="navigateBack"
        />
        <div
          v-if="collapsable"
          class="th-wrapper-header-toggle"
          @click="toggleCollapse"
        />
        <th-popover v-if="info" :text="info" placement="top" class="pr-3" />
        <slot name="title">
          <span>{{ title }}</span>
        </slot>
      </div>

      <!-- Center -->
      <div
        v-show="!collapsed"
        :class="{ 'xs:justify-start xs:w-full xs:mt-4': $slots.center }"
        class="th-wrapper-center header-item flex-grow justify-center"
      >
        <div
          v-if="collapsable"
          class="th-wrapper-header-toggle"
          @click="toggleCollapse"
        />
        <div v-if="disabled" class="th-disabled-overlay" />
        <slot class="z-10" name="center" />
      </div>

      <!-- Actions -->
      <div
        v-show="!collapsed"
        v-if="$slots.actions"
        class="th-wrapper-actions header-item xs:w-full xs:mt-2"
      >
        <div
          v-if="collapsable"
          class="th-wrapper-header-toggle"
          @click="toggleCollapse"
        />
        <div v-if="disabled" class="th-disabled-overlay" />
        <slot class="z-10" name="actions" />
      </div>

      <!-- Collapse -->
      <el-button
        v-if="collapsable"
        :title="
          collapsed
            ? $t('components.th_wrapper.expand')
            : $t('components.th_wrapper.collapse')
        "
        class="collapse-button text-xl p-1 text-th-blue active:text-th-secondary z-50 xs:absolute xs:right-0 xs:top-0 xs:mr-6 xs:mt-6"
        text
        @click="toggleCollapse"
      >
        <svgicon
          :src="require('@/assets/icons/th-icon-chevron-down.svg')"
          :style="{
            height: '25px',
            width: '25px'
          }"
          class="transition-all duration-300 fill-current"
          :class="{ 'transform -rotate-180': !collapsed }"
        />
      </el-button>

      <!-- Sub title -->
      <div
        v-show="!collapsed"
        v-if="$slots.subtitle || subtitle"
        class="th-wrapper-subtitle w-full text-sm mt-2 text-th-color-header"
        :class="{ italic: !isWhiteLabel, 'mt-8 color-subtitle': isWhiteLabel }"
      >
        <div v-if="disabled" class="th-disabled-overlay" />
        <slot name="subtitle">
          <span>{{ subtitle }}</span>
        </slot>
      </div>

      <!-- Additional header -->
      <div
        v-show="!collapsed"
        v-if="$slots['header-additional']"
        class="th-wrapper-additional-header w-full relative"
      >
        <div v-if="disabled" class="th-disabled-overlay" />
        <slot name="header-additional" />
      </div>
    </div>

    <!-- Body -->
    <div v-show="!collapsed" :class="[bodyClass || 'p-8']" class="relative">
      <!-- Disabled overlay -->
      <div
        v-if="disabled"
        class="th-disabled-overlay"
        :class="{ 'had-disabled-text': disabledText }"
      >
        <div v-if="disabledText">
          <el-icon class="mr-2"><Warning /></el-icon>
          <span>{{ disabledText }}</span>
        </div>
      </div>

      <div class="th-wrapper-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { isUnifiedCommerce } from '@/constants'
import backButton from '@/mixins/back-button'

export default {
  name: 'THWrapper',

  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    subtitle: {
      type: String,
      required: false,
      default: undefined
    },
    info: {
      type: String,
      required: false,
      default: null
    },
    wrapperClass: {
      type: String,
      default: null
    },
    headerClass: {
      type: String,
      default: null
    },
    bodyClass: {
      type: String,
      default: null
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    collapsedByDefault: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidthTitle: {
      type: Boolean,
      default: false
    },
    navigateBackButton: {
      type: Boolean,
      default: false
    },
    disabledText: {
      type: String,
      default: null
    }
  },

  setup(props) {
    const { goBack } = backButton(props.returnedPath || '/')

    return { goBack }
  },

  data() {
    return {
      collapsed: this.collapsable && this.collapsedByDefault
    }
  },

  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    toggleCollapse() {
      if (!this.collapsable) return
      this.collapsed = !this.collapsed
      this.$emit('collapsed-changed', this.collapsed)
    },
    navigateBack() {
      this.goBack()
    }
  }
}
</script>

<style scoped>
.header-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  letter-spacing: var(--letter-spacing-titles);
}

.th-wrapper {
  transition: filter 0.3s;
}

.th-wrapper-header-title,
.th-wrapper-center,
.th-wrapper-actions,
.th-wrapper-body,
.th-wrapper-header {
  position: relative;
}

.th-wrapper-header-title .th-wrapper-header-toggle,
.th-wrapper-center .th-wrapper-header-toggle,
.th-wrapper-actions .th-wrapper-header-toggle {
  z-index: -1 !important;
}

.th-wrapper-header-title,
.th-wrapper-subtitle,
.th-wrapper-center {
  z-index: 1;
}

.th-wrapper-header .th-wrapper-header-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
}

.th-wrapper-collapsable .th-wrapper-header {
  transition: background 0.3s ease-in-out;
  background: linear-gradient(
    0deg,
    #ffffff00 0%,
    #ffffff00 50%,
    #4a9eff0a 100%
  );
  background-position-y: 100%;
  background-repeat: no-repeat;
  background-size: 100% 200%;
}

.th-wrapper-collapsable .th-wrapper-header:hover {
  background-position-y: 0;
}

/* Collapsed */
.th-wrapper-collapsed .th-wrapper-header {
  padding: 1rem 2rem !important;
}

@media (max-width: 767px) {
  .th-wrapper-collapsed .collapse-button {
    margin-top: 0.5rem !important;
  }
}

/* Disabled */
.th-wrapper-disabled {
  cursor: not-allowed;
}

.th-wrapper-disabled .th-wrapper-body,
.th-wrapper-disabled .th-wrapper-actions,
.th-wrapper-disabled .th-wrapper-subtitle,
.th-wrapper-disabled .th-wrapper-additional-header,
.th-wrapper-disabled .th-wrapper-center {
  position: relative;
  filter: grayscale(1);
  user-select: none;
}

.th-disabled-overlay {
  position: absolute;
  cursor: not-allowed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--error-color);
  z-index: 40;
}

.had-disabled-text {
  background: rgba(255, 255, 255, 0.85);
}

/* Override elements ui */
.th-wrapper :deep(.el-input .el-input__inner[type='number']) {
  text-align: right;
}

.th-wrapper :deep(.leading-normal .el-form-item__content) {
  line-height: 1;
}

.th-wrapper :deep(.el-form-item__label) {
  line-height: 1.2;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  padding-right: 0;
  float: none;
  color: var(--label-text-color);
  display: block;
}

.th-wrapper :deep(.el-button) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th-wrapper
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:after,
.th-wrapper
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:after {
  content: '*';
  color: var(--error-color);
  margin-left: 2px;
}

.th-wrapper
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.th-wrapper
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:before {
  content: '';
  margin-right: 0;
}
</style>
