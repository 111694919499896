<template>
  <div
    v-if="element"
    class="w-full h-full"
    :class="{
      'cursor-pointer': !!element.onClick,
      'pointer-events-none': isDisabled
    }"
    v-on="element.onClick ? { click: element.onClick } : {}"
  >
    <!-- ↑↑↑ Source for conditional event handler: https://stackoverflow.com/a/57235465/4640352 -->
    <el-col>
      <component
        :is="element.component"
        :disabled="isDisabled"
        :can-click="!!element.onClick"
        :route-to-options="element.routeToOptions"
        @toggleOpen="$emit('toggleOpen')"
      >
        <template #content_upper_row>
          {{ formatDateTime(notification.operation.date) }}
        </template>
        <template #content_lower_row>
          <template v-if="isTypeOf(element.text, 'array')">
            <p v-for="(item, i) of element.text" :key="i">{{ item }}</p>
          </template>
          <template v-else-if="isTypeOf(element.text, 'object')">
            <p>{{ getObjectText(element.text) }}</p>
          </template>
          <template v-else>
            {{ element.text }}
          </template>
        </template>
      </component>
    </el-col>
  </div>
</template>

<script>
import isAfter from 'date-fns/isAfter'
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import typeOf from 'just-typeof'
import { saveAs } from 'file-saver'
import {
  ExportLoading,
  ExportFailed,
  ExportSuccess,
  ExportExpired,
  ExportInfo
} from '@/components/notifications/exports'
import * as Assets from '@/components/notifications/assets'
import { InfoBasic } from '@/components/notifications/info'
import { useMessagesStore } from '@/store/messages'
import { useExportsStore } from '@/store/exports'

const getHandler = ({ entity, path = '', handler = 'getAll', query }) => {
  const inst = th[entity]()
  const fullInst = get(inst, path) || inst
  const instEntityHandler =
    // SDK entities are tightly coupled with their context so in the case of a method we need to preserve the context by invoking the method with .call
    typeof fullInst === 'function' ? fullInst.call(inst) : fullInst
  return instEntityHandler?.[handler]?.(query)
}

export default {
  components: {
    ExportLoading,
    ExportFailed,
    ExportSuccess,
    ExportExpired,
    ExportInfo,
    InfoBasic
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  setup() {
    const messagesStore = useMessagesStore()
    return { messagesStore }
  },
  computed: {
    types() {
      const entityKey = this.notification.operation?.originKey ?? ''
      const exportsLoading = {
        component: ExportLoading,
        text: this.$t('notifications.exports.loading.text', {
          entity: this.$t(entityKey)
        })
      }
      return {
        exports: {
          loading: exportsLoading,
          progress: {
            component: ExportLoading,
            text: this.$t('notifications.exports.progress.text', {
              entity: this.$t(entityKey),
              progress: this.notification.operation?.progress ?? '0%'
            })
          },
          preparing: exportsLoading,
          error: {
            component: ExportFailed,
            text: this.$t('notifications.exports.error.text', {
              entity: this.$t(entityKey)
            }),
            onClick: this.refetchExport
          },
          success: {
            component: ExportSuccess,
            text: this.$t('notifications.exports.success.text', {
              entity: this.$t(entityKey)
            }),
            routeToOptions: this.routeToOptions,
            onClick: () => this.saveFile(this.notification.operation)
          },
          expired: {
            component: ExportExpired,
            text: this.$t('notifications.exports.expired.text', {
              entity: this.$t(entityKey)
            }),
            onClick: this.refetchExport
          },
          empty: {
            component: ExportInfo,
            text: this.$t('notifications.exports.empty.text', {
              entity: this.$t(entityKey)
            })
          }
        },
        info: {
          component: InfoBasic
        },
        purchaseOrder: {
          component: InfoBasic,
          text: this.$t('notifications.purchase_order.success.text', {
            entity: this.$t(entityKey)
          })
        },
        assets: {
          loading: {
            component: Assets.Loading
          },
          success: {
            component: Assets.Success
          },
          error: {
            component: Assets.Error
          }
        }
      }
    },
    element() {
      const { type, expiresAt, module, text } = this.notification.operation

      const getText = () => (text ? { text } : {})
      const moduleComponents = this.types?.[module]
      const isExpired = () =>
        moduleComponents?.expired && isAfter(new Date(), new Date(expiresAt))

      const getElement = () =>
        moduleComponents?.component
          ? moduleComponents
          : moduleComponents?.[isExpired() ? 'expired' : type]
      return {
        ...getElement(),
        ...getText() // being careful not to override the resolved element's text with undefined/null
      }
    },
    routeToOptions() {
      const { module } = this.notification.operation
      // Only show the link to export history for exports with a documentType
      const documentType = this.notification.operation?.action?.query
        ?.documentType

      if (module !== 'exports' || !documentType) return null
      return {
        to: {
          name: 'export-history'
        },
        text: this.$t('notifications.exports.success.link_to_export_history'),
        actionText: this.$t('common.interactions.buttons.download'),
        onClick: () => this.$emit('toggleOpen')
      }
    },
    isDisabled() {
      return this.notification.operation?.disabled ?? false
    }
  },
  methods: {
    formatDateTime(date) {
      if (!date) return
      return this.$date.formatDateTime(date)
    },
    getObjectText(textObj) {
      const { type } = this.notification.operation
      return textObj?.[type] ?? ''
    },
    isTypeOf(item, type) {
      return typeOf(item) === type
    },
    async saveFile({ url, filename }) {
      if (!url) return
      try {
        await saveAs(url, filename || undefined)
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.download.fail')
        })
      }
    },
    async refetchExport() {
      const { action = {}, originKey } = this.notification.operation

      const { entity, query, handler, path } = action

      if (!entity || !query || !originKey) {
        throw new Error(`Didn't get a entity/query/originKey`)
      }
      const _action = getHandler({ entity, query, path, handler })

      if (!_action) throw new Error(`Didn't get a handler`)

      try {
        const { data } = await _action

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey,
            action,
            date: new Date()
          }
        })

        const newNotification = this.$deepClone(this.notification)
        newNotification.operation.disabled = true

        this.messagesStore.updateMessage({
          id: newNotification.id,
          changedProp: newNotification
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t(originKey)
          })
        })
      }
    }
  }
}
</script>
