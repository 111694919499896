<template>
  <notification-base>
    <template #icon_start>
      <el-icon class="text-th-green font-bold text-2xl"><Check /></el-icon>
    </template>
    <template #content>
      <slot name="content">
        <div class="text-th-light-gray font-light text-sm tracking-wide pb-1">
          <slot name="content_upper_row" />
        </div>
        <div>
          <slot name="content_lower_row" />
        </div>
      </slot>
    </template>
  </notification-base>
</template>

<script>
import NotificationBase from '../base'

export default {
  components: {
    NotificationBase
  }
}
</script>
