<template>
  <el-form ref="form" :model="formData" :rules="rules" class="mt-4">
    <div class="mb-5">
      {{ $t('components.th_datatable.export.settings.lable') }}
    </div>
    <!-- Email -->
    <el-form-item
      prop="email"
      class="mr-4 mb-5 flex-shrink-0"
      :label="$t('components.th_datatable.export.settings.email')"
    >
      <el-input id="email" v-model="email" class="w-full" />
    </el-form-item>

    <!-- File Format -->
    <el-form-item
      prop="format"
      class="mr-4 mb-5 flex-shrink-0"
      :label="$t('components.th_datatable.export.settings.file_format.lable')"
    >
      <el-radio-group id="format" v-model="format" class="flex flex-col">
        <el-radio
          v-for="type in Object.values(FILE_TYPES)"
          :key="type"
          :label="type"
        >
          {{
            $t(`components.th_datatable.export.settings.file_format.${type}`)
          }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- Filter -->
    <el-form-item
      prop="filters"
      class="mr-4 mb-5 flex-shrink-0"
      :label="$t('components.th_datatable.export.settings.filter.lable')"
    >
      <el-radio-group
        id="filters"
        v-model="currentFilters"
        class="flex flex-col"
      >
        <el-radio :label="true">
          {{
            $t(
              'components.th_datatable.export.settings.filter.currently_active'
            )
          }}
        </el-radio>
        <el-radio :label="false">
          {{ $t('components.th_datatable.export.settings.filter.everything') }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- Column Selection -->
    <el-form-item
      v-if="!forceAllColumns"
      prop="columnSelection"
      class="mr-4 mb-5 flex-shrink-0"
      :label="
        $t('components.th_datatable.export.settings.column_selection.lable')
      "
    >
      <el-radio-group
        id="columnSelection"
        v-model="columnSelection"
        class="flex flex-col"
      >
        <el-radio
          v-for="column in Object.values(COLUMN_SELECTION)"
          :key="column"
          :label="column"
        >
          {{
            $t(
              `components.th_datatable.export.settings.column_selection.${column}`
            )
          }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- Recurrence -->
    <el-form-item class="mb-1">
      <el-switch
        v-model="isRecurring"
        :active-text="
          $t('components.th_datatable.export.settings.recurrence.lable')
        "
      />
    </el-form-item>

    <div class="flex">
      <!-- Recurrence Cycle -->
      <el-form-item
        prop="recurrenceCycle"
        class="mr-4 mb-3 flex-shrink-0"
        :label="
          $t('components.th_datatable.export.settings.recurrence_cycle.lable')
        "
      >
        <el-select
          v-model="recurrenceCycle"
          class="w-full"
          :disabled="!isRecurring"
        >
          <el-option
            v-for="option in Object.values(RECURRING_CYCLE)"
            :key="option"
            :label="
              t(`components.th_datatable.export.settings.interval.${option}`)
            "
            :value="option"
          />
        </el-select>
      </el-form-item>
      <!-- interval -->
      <el-form-item
        v-show="recurrenceCycle !== RECURRING_CYCLE.DAILY"
        prop="recurringOn"
        class="mr-4 mb-3 flex-shrink-0"
        :label="$t('components.th_datatable.export.settings.interval.lable')"
      >
        <el-select
          v-model="recurringOn"
          class="w-full"
          :disabled="disabledRecurringOn"
        >
          <el-option
            v-for="option in intervalOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
    </div>
  </el-form>
</template>

<script setup>
import {
  defineProps,
  ref,
  watch,
  defineEmits,
  defineExpose,
  computed
} from 'vue'
import { numberToTextFormat } from '@/utils/numbers/index.js'
import { useI18n } from 'vue-i18n'
import {
  DAYS_OF_WEEK,
  RECURRING_CYCLE,
  FILE_TYPES,
  COLUMN_SELECTION
} from '../helpers.js'

//i18n
const { t, locale } = useI18n()

const form = ref(null)

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  forceAllColumns: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const formData = computed(() => ({
  ...props.modelValue
}))

const email = computed({
  get() {
    return props.modelValue.email
  },
  set(value) {
    emitValue('email', value)
  }
})

const currentFilters = computed({
  get() {
    return props.modelValue.current_filters
  },
  set(value) {
    emitValue('current_filters', value)
  }
})

const format = computed({
  get() {
    return props.modelValue.format
  },
  set(value) {
    emitValue('format', value)
  }
})

const isRecurring = computed({
  get() {
    return !!props.modelValue.is_recurring
  },
  set(value) {
    emitValue('is_recurring', value)
  }
})

const recurrenceCycle = computed({
  get() {
    return props.modelValue.recurring_cycle
  },
  set(value) {
    emitValue('recurring_cycle', value)
  }
})

const recurringOn = computed({
  get() {
    return props.modelValue.recurring_on
  },
  set(value) {
    emitValue('recurring_on', value)
  }
})

const columnSelection = computed({
  get() {
    return props.modelValue.column_selection
  },
  set(value) {
    emitValue('column_selection', value)
  }
})

const intervalOptions = computed(() => {
  if (recurrenceCycle.value === RECURRING_CYCLE.WEEKLY) {
    return Object.entries(DAYS_OF_WEEK).map(([value, day]) => {
      return {
        label: t(`common.days.${day}`),
        value: +value
      }
    })
  }
  if (recurrenceCycle.value === RECURRING_CYCLE.MONTHLY) {
    //this will generate a list of days of the month (1st, 2nd, 3rd, etc.)

    return Array.from({ length: 28 }, (_, i) => {
      return {
        label: t('components.th_datatable.export.settings.day_of_the_month', [
          numberToTextFormat(i + 1, locale.value)
        ]),
        value: i + 1
      }
    })
  }
  return []
})

const disabledRecurringOn = computed(() => {
  return !isRecurring.value || !intervalOptions.value.length
})

const rules = computed(() => ({
  email: [
    {
      required: true,
      message: t('common.forms.rules.email_required'),
      trigger: 'blur'
    },
    {
      type: 'email',
      message: t('common.forms.rules.email_format_invalid'),
      trigger: ['blur', 'change']
    }
  ]
}))

//watch
watch(
  () => recurrenceCycle.value,
  () => (recurringOn.value = intervalOptions.value[0]?.value)
)

//methods
function emitValue(path, value) {
  emit('update:modelValue', {
    ...props.modelValue,
    [path]: value
  })
}

defineExpose({ form })
</script>
