<template>
  <el-popover
    popper-class="w-auto filter-popover"
    placement="bottom"
    trigger="click"
  >
    <div class="header-title mb-2">
      {{ $t('components.table_filter.header_title') }}
    </div>
    <div class="el-input__wrapper my-2 pl-2">
      <svgicon
        :src="require('@/assets/icons/th-icon-magnifying-glass.svg')"
        :style="{
          height: '24px',
          width: '24px',
          marginRight: '0.5em'
        }"
      />
      <input
        ref="input"
        v-model="searchValue"
        class="el-input__inner"
        :placeholder="$t('common.inputs.placeholders.search')"
        @keyup="searchFilters"
      />
    </div>
    <div
      v-for="(header, index) in searchedFilter"
      :key="`${index}-${header.label}`"
      data-testid="header-check"
      class="header-filter-option"
      @click="triggerChange(header)"
    >
      <el-checkbox
        v-model="header.enabled"
        :disabled="checkIfCheckboxDisabled(header)"
        @click.prevent
      />
      <span class="ml-3">
        {{ header.label }}
      </span>
    </div>
    <div class="restore-filters" @click="restoreDefaults()">
      {{ $t('components.table_filter.restore') }}
    </div>
    <template #reference>
      <el-button size="small" text icon="CirclePlus" />
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    initialValues: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headersReset: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      filter: [],
      initialFilter: [],
      searchValue: '',
      searchedFilter: null,
      filterInitialHeaders: null
    }
  },
  watch: {
    initialValues: {
      deep: true,
      handler(newInitialValues) {
        this.setFilterValue(newInitialValues)
        this.searchFilters()
      }
    }
  },
  created() {
    document.addEventListener('click', this.closeOnOutsideClick)
  },
  mounted() {
    this.setFilterValue(this.initialValues)
    this.initialFilter = this.filter
    this.searchedFilter = this.filter
    this.filterInitialHeaders = this.initialValues
    this.restoreIfDefaultsAreEmpty(this.filterInitialHeaders)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeOnOutsideClick)
  },
  methods: {
    toggleHeaderVisibility(scope) {
      this.showHeaderFilterList = !this.showHeaderFilterList
    },
    triggerChange(header) {
      const filterHeader = this.filter.find((h) => h.label === header.label)
      filterHeader.enabled = !filterHeader.enabled
      this.$emit(
        'update:modelValue',
        this.filter.filter((item) => item.enabled === true)
      )
    },
    closeOnOutsideClick(e) {
      if (this.$el.contains && !this.$el.contains(e.target)) {
        this.showHeaderFilterList = false
      }
    },
    searchFilters() {
      if (this.searchValue !== '') {
        const search = this.searchValue
        const matchingObjects = this.filter.filter((obj) => {
          if (obj.label && typeof obj.label === 'string') {
            return obj.label.toLowerCase().includes(search.toLowerCase())
          }
          return false
        })

        this.searchedFilter = matchingObjects
      } else {
        this.searchedFilter = this.filter
      }
    },
    restoreIfDefaultsAreEmpty(headerSettings) {
      const hasDefault = Object.values(headerSettings).some(
        (value) => value === true
      )
      if (!hasDefault) this.restoreDefaults()
    },
    restoreDefaults() {
      this.searchValue = ''
      const filteredHeaders = this.filter.map((item) => {
        const updatedItem = { ...item }
        // eslint-disable-next-line no-prototype-builtins
        if (this.initialValues.hasOwnProperty(item.field)) {
          updatedItem.enabled = this.filterInitialHeaders[item.field]
        }
        return updatedItem
      })

      this.searchedFilter = filteredHeaders
      const currentyActiveReset = filteredHeaders.filter(
        (item) => item.enabled === true
      )

      const returnDefaults =
        currentyActiveReset.length !== 0
          ? currentyActiveReset
          : this.headersReset
      this.$emit('update:modelValue', returnDefaults)
    },
    checkIfCheckboxDisabled(header) {
      const onlyOneHeaderVisible =
        Object.values(this.initialValues).filter((value) => value === true)
          .length === 1
      return header.enabled && onlyOneHeaderVisible
    },
    setFilterValue(prop) {
      this.filter = this.headers.map((item) => {
        return {
          ...item,
          enabled: prop[item.field] === undefined ? true : prop[item.field]
        }
      })
    }
  }
}
</script>

<style scoped>
.header-title {
  font-family: var(--font-face);
  font-size: var(--control-font-size);
  line-height: var(--control-line-height);
  font-weight: var(--control-font-weight);
  letter-spacing: var(--letter-spacing-titles);
  color: var(--el-table-text-color-control);
}

.header-filter-option {
  padding: 10px 15px;
  color: var(--checkbox-text-color);
  cursor: pointer;
}

.header-filter-option > span {
  position: relative;
  top: -2px;
}

.el-button {
  font-size: 18px;
  padding: 0px;
}

.restore-filters {
  position: sticky;
  bottom: -12px;
  left: 0;
  border-top: 1px solid var(--disabled-color);
  padding: 16px 8px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  letter-spacing: var(--letter-spacing-table);
  cursor: pointer;
  background: white;
  width: 100%;
  z-index: 1;
}
</style>
