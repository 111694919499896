import { defineStore } from 'pinia'
import getDefaultFeatures from './helpers/app-config/features-config'
import {
  deepMap,
  setFeatureFlagsOnConfig,
  setWhitelabelsFeatures
} from './helpers/app-config/index.js'
import compose from 'just-compose'
import { whitelabels } from '@/constants'
import { restrictedFeaturesPaths } from '@/utils/feature-flags'
import { getNavigation } from './helpers/app-config/navigation'
import safeGet from 'just-safe-get'
import vuexStore from '@/store'

export const useAppConfigStore = defineStore('appConfig', {
  state: () => {
    return {
      featureLock: true,
      isSidebarCollapsed: false,
      _features: getDefaultFeatures()
    }
  },
  getters: {
    /**
     * @returns Object containging current feature flags. All flags will be true when featureLock is false.
     */
    featureConfig(state) {
      // if we want to toggle features, e.g. for QA turn all leaves to true
      if (!state.featureLock) {
        return deepMap(state._features, () => true)
      }
      return state._features
    },
    /**
     * @returns Object containing the navigation tree.
     */
    navigation() {
      const featureConfig = this.featureConfig
      const dashboardsKey =
        'Config.clientAccountConfiguration.custom_dashboards.dashboards'
      const rootState = vuexStore.state
      const customDashboards = safeGet(rootState, dashboardsKey, null)
      return [...getNavigation({ featureConfig, customDashboards })]
    }
  },
  actions: {
    /**
     * Flips the state of featureLock.
     */
    toggleFeatures() {
      this.featureLock = !this.featureLock
    },
    /**
     * Flips the state of isSidebarCollapsed.
     */
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed
    },
    /**
     * @param {Object} features Feature flags configuration.
     * @param {string} whitelabel Name of current whitelabel.
     */
    setFeatureFlagsOnConfig(features, whitelabel) {
      const buildFeatures = compose(
        (config) => setFeatureFlagsOnConfig(config, features),
        setWhitelabelsFeatures(whitelabelsFeaturesConfig, whitelabel),
        (config) => setEnvDefaultFeatures(config, this.featureLock)
      )
      const defaults = getDefaultFeatures()
      const featureConfig = buildFeatures(defaults)

      this.$patch({ _features: featureConfig })
    }
  }
})

export const whitelabelsFeaturesConfig = {
  licenses: {
    path: 'resources.licenses',
    exclude: [whitelabels.LOCALSEARCH]
  },
  customers: {
    path: restrictedFeaturesPaths.crm[0],
    include: [whitelabels.LOCALSEARCH]
  }
}

const setEnvDefaultFeatures = (features, featureLock) => {
  if (
    process.env.VUE_APP_NO_DEV_FEATURES !== 'true' &&
    process.env.NODE_ENV !== 'production' &&
    !featureLock
  ) {
    return deepMap(features, () => true)
  }
  return features
}
