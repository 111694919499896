<template>
  <div v-show="active" class="th-tab">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ThTab',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
