<template>
  <notification-base>
    <template #icon_start>
      <div class="flex w-10 h-10 pr-2">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path" />
        </svg>
      </div>
    </template>
    <template #content>
      <slot name="content">
        <div class="text-th-light-gray font-light text-sm tracking-wide">
          <slot name="content_upper_row" />
        </div>
        <div class="text-th-primary">
          <slot name="content_lower_row" />
        </div>
      </slot>
    </template>
  </notification-base>
</template>

<script>
import NotificationBase from '../base'

export default {
  components: {
    NotificationBase
  }
}
</script>

<style scoped>
.circular {
  height: 42px;
  width: 42px;
  animation: loading-rotate 2s linear infinite;
}

.path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #409eff;
  stroke-linecap: round;
}
</style>
