import typeOf from 'just-typeof'
import pick from 'just-pick'
import isEmpty from 'just-is-empty'
import compose from 'just-compose'
import flush from 'just-flush'
import { getDateTimeFormat } from '../date'
import { isEmptyString } from '../strings'

/**
 * Check for object type and that it is empty
 * @param {Object} item
 * @returns {boolean}
 */
export function isEmptyObject(item) {
  return item && typeOf(item) === 'object' && !Object.keys(item).length
}

/**
 * Check if empty array or array with objects whose keys are all null
 * @param {Array} item
 * @returns {boolean}
 */
export function isEmptyArray(item) {
  if (Array.isArray(item)) {
    if (!item.length) return true

    const onlyEmptyObjects = item.every(
      (el) =>
        typeOf(el) === 'object' &&
        Object.values(el).every((key) => key === null)
    )

    if (onlyEmptyObjects) return true
  }
  return false
}

/**
 * Transforms an array of export map list of injectable data to an export map object
 * @param {object[]} exportMapList - should include at least name and label properties
 * @param {object} [clientConfig] - the client configuration object
 * @returns {object}
 */
export function exportMapFactory(exportMapList = [], clientConfig = {}) {
  return exportMapList.reduce(
    (obj, column) => {
      let dateFormat
      if (column.type === 'date') {
        dateFormat =
          typeof column.format === 'string' && column.format.length
            ? column.format
            : getDateTimeFormat()
      }

      const dateObj =
        column.type === 'date' ? { type: 'date', format: dateFormat } : {}
      return {
        fields: {
          ...obj.fields,
          [column.name]: {
            header: column.label,
            type: column.type,
            ...dateObj
          }
        }
      }
    },
    { fields: {} }
  )
}

/**
 * basic deep clone function that does not preserve non serializable values.
 * Works well with Vue and good for cloning Vue's data properties, shallow and deep.
 * @param {object} object
 */
export function deepClone(object) {
  const notAllowed = ['undefined', 'null']
  if (notAllowed.includes(typeOf(object))) return object
  let newObject
  try {
    newObject = JSON.parse(JSON.stringify(object))
  } catch (err) {
    newObject = object
    throw new Error('deepClone: failed to clone the provided value', err)
  }
  return newObject
}

/**
 * Takes a resources object of array resource values and filters out the deleted items from each resource array
 * @param {object} resources
 * @returns {object}
 */
export function filterDeletedResources(resources) {
  const filterDeleted = (item) => item.deleted !== true

  const filteredLists = {}
  Object.entries(resources).forEach(([resource, list]) => {
    filteredLists[resource] = list.filter(filterDeleted)
  })

  return filteredLists
}

/**
 * Picks only the form fields from the payload, thus make it an acceptable body to make a request with.
 * @param {object} payload
 * @param{object} form
 * @returns {object}
 */
export function makeHandleableBody(payload, form) {
  return pick(payload, Object.keys(form))
}

/**
 * takes an object and replaces all property values that are empty strings, objects or arrays with null
 * @param {object} obj
 * @returns {object}
 */
export function replaceEmptyPropsWithNull(obj) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const isEmpty =
      isEmptyObject(value) || isEmptyArray(value) || isEmptyString(value)
    const newValue = isEmpty ? null : value
    return { ...result, [key]: newValue }
  }, {})
}

/**
 * Get the value or the type label from a table item.
 * @param {string} [headerName='']
 * @param {object} [item={}]
 * @param {ItemType[]} [types=[]]
 * @returns {string} item label
 */
export function getItemLabel(headerName = '', item = {}, types = []) {
  if (headerName !== 'type') return item[headerName]
  const typeObj = types.find((t) => t.value === item[headerName])
  return typeObj ? typeObj.label : '–'
}

/**
 * @typedef ItemType
 * @property {string} value
 * @property {string} label
 */

/**
 * If passed item is empty (string, array, object, etc.) it will return null, otherwise returns the item
 * @param {any} item
 * @returns {any|null}
 */
export function replaceEmptyWithNull(item) {
  return isEmpty(item) ? null : item
}

/**
 * Deeply clean a passed object.
 * Remove undefined props, replaces all empty props will null and lastly if the passed object is empty it returns null
 * @param {object} [obj={}]
 * @returns {object|null}
 */
export function cleanObject(obj = {}) {
  return compose(
    flush,
    replaceEmptyPropsWithNull,
    replaceEmptyWithNull
  )(obj || {})
}
