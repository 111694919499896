<template>
  <div
    class="th-header flex flex-wrap items-center justify-end px-5"
    data-testid="nav-item-holder"
  >
    <h3 class="flex-grow" v-text="getPageName" />

    <actions />
    <location-context v-if="!isUnifiedCommerceClient" class="ml-5" />
    <el-dropdown
      class="ml-5"
      trigger="click"
      @visible-change="onDropdownVisibilityChange"
    >
      <!-- Account name -->
      <div class="flex items-center">
        <span v-text="accountName" />
        <el-icon class="el-icon--right">
          <ArrowUp v-if="isDropDownVisible" />
          <ArrowDown v-else />
        </el-icon>
      </div>
      <template #dropdown>
        <el-dropdown-menu class="th-dropdown-menu">
          <!-- Username -->
          <el-dropdown-item>
            <div class="user-label-container">
              <div v-text="userName" />
              <div class="userId" v-text="userId" />
            </div>
          </el-dropdown-item>
          <!-- Profile -->
          <el-dropdown-item v-if="isSubUserStaff">
            <router-link
              to="/user_profile"
              class="flex flex-row items-center justify-end"
            >
              {{ $t('nav.main.items.user_profile.title') }}
            </router-link>
          </el-dropdown-item>
          <!-- Cookie Preferences -->
          <el-dropdown-item v-if="isCookieScriptLoaded">
            <a
              href="#"
              class="flex flex-row items-center justify-end"
              @click.prevent="showCookiePreferences"
            >
              {{ $t('nav.main.items.cookie_preferences.title') }}
            </a>
          </el-dropdown-item>
          <!-- Exit -->
          <el-dropdown-item v-if="isPOS">
            <el-button text class="w-full justify-end" @click="exitDashboard">
              {{ $t('nav.main.items.exit_dashboard.title') }}
              <svgicon
                :src="require('@/assets/icons/th-icon-close.svg')"
                class="ml-2"
              />
            </el-button>
          </el-dropdown-item>
          <!-- Logout -->
          <el-dropdown-item v-else>
            <router-link to="/logout" class="w-full" @click="trackLogout">
              <el-button text class="w-full justify-end">
                {{ $t('nav.main.items.logout.title') }}
                <svgicon
                  :src="require('@/assets/icons/th-icon-logout.svg')"
                  class="ml-2"
                />
              </el-button>
            </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { useBranchesStore } from '@/store/branches'
import { getPageTranslationKey } from './header-helper'
import LocationContext from './location-context'
import Actions from './actions'
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'
import { useProductsStore } from '@/store/products'
import { useSocketIOStore } from '@/store/socket-io'
import { useAssetsStore } from '@/store/assets'
import { useExportsStore } from '@/store/exports'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    LocationContext,
    Actions
  },
  setup() {
    const branchesStore = useBranchesStore()
    const appConfigStore = useAppConfigStore()
    const productsStore = useProductsStore()

    const { navigation } = storeToRefs(appConfigStore)

    return { navigation, branchesStore, productsStore }
  },
  data() {
    return {
      userInfoMode: 'human-readable',
      isDropDownVisible: false,
      heartbeat: null,
      isPOS: false,
      isCookieScriptLoaded: false
    }
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.Auth.authenticated,
      userName: (state) => state.Auth.username || '-',
      accountName: (state) => state.Auth.accountName || '-',
      userId: (state) => state.Auth.user || '-',
      staff: (state) => state.Auth.staff,
      subUser: (state) => state.Auth.thUserId
    }),
    ...mapGetters({
      isAdmin: 'Auth/isAdmin'
    }),
    isSubUserStaff() {
      return !!this.staff && !!this.subUser
    },
    isUnifiedCommerceClient() {
      return isUnifiedCommerce()
    },
    getPageName() {
      const translationKey = getPageTranslationKey(
        this.navigation,
        this.$route.path
      )
      return this.$t(translationKey)
    }
  },
  async created() {
    await this.$store.dispatch('Auth/waitThInitializated')
    this.setPOSStatus()
    this.startHeartbeat()
    this.checkForCookieScript()
    this.productsStore.checkProductsCount()
    this.branchesStore.checkBranchesCount()
    await useSocketIOStore().initConnection(this)
    useExportsStore().init(this)
    useAssetsStore().init(this)
    this.$store.dispatch('Notifications/initNotifications')
    this.$store.dispatch('Config/setBranchCurrencies', { vm: this })
  },
  beforeUnmount() {
    this.stopHeartbeat()
    useSocketIOStore().closeConnection(this)
  },
  methods: {
    ...mapActions('Auth', ['logout']),
    onDropdownVisibilityChange(isVisible) {
      this.isDropDownVisible = isVisible
    },
    checkForCookieScript() {
      if (!window.CookieScript?.instance) {
        window.addEventListener('CookieScriptLoaded', () => {
          this.isCookieScriptLoaded = true
        })
      } else {
        this.isCookieScriptLoaded = true
      }
    },
    exitDashboard() {
      if (safeGet(window, 'webkit.messageHandlers.close.postMessage')) {
        this.logout()
        window.webkit.messageHandlers.close.postMessage('exit_dashboard')
        th.destroy()
      }
    },
    setPOSStatus() {
      try {
        const clientApp =
          JSON.parse(window.localStorage.getItem('clientApp')) || {}
        this.isPOS = !!safeGet(clientApp, 'embed')
      } catch (error) {
        this.$logException(error, { trackError: true })
      }
    },
    startHeartbeat() {
      // Will only start heartbeat if dashboard is in POS system
      if (!this.isPOS) return
      this.updateHeartbeat()
      this.heartbeat = setInterval(this.updateHeartbeat, 10000)
    },
    updateHeartbeat() {
      const timeStamp = new Date().getTime()
      // This post message eto POS to let it know the
      // to let it know the dashboard is still active
      if (safeGet(window, 'webkit.messageHandlers.heartbeat.postMessage')) {
        window.webkit.messageHandlers.heartbeat.postMessage(timeStamp)
      }
    },
    stopHeartbeat() {
      if (!this.isPOS) return
      clearInterval(this.heartbeat)
    },
    showCookiePreferences() {
      window.CookieScript?.instance?.show()
    },
    trackLogout() {
      this.$ampli.eventWithBaseProps('logout')
      setTimeout(() => {
        // If we do the reset immediately it will strip 'logout' event off the props
        this.$ampli.reset()
      }, 1000)
    }
  }
}
</script>

<style scoped>
.th-header {
  height: 60px;
  box-shadow: var(--header-box-shadow);
}

.user-label-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  line-height: normal;
  padding: 5px 0;
  cursor: default;
}

.user-label-container .userId {
  font-size: 0.7em;
  opacity: 0.5;
}

.el-dropdown {
  font-size: var(--control-font-size);
  font-weight: var(--control-font-weight);
  line-height: var(--control-line-height);
  cursor: pointer;
  margin: 5px 0px;
  color: inherit;
}
</style>
