import { createI18n } from 'vue-i18n'
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementDeLocale from 'element-plus/lib/locale/lang/de'

const elementMessages = {
  'en-US': elementEnLocale,
  'de-DE': elementDeLocale
}

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const elementLocaleMessages = elementMessages[locale] || {}
      messages[locale] = { ...locales(key), ...elementLocaleMessages }
    }
  })
  return messages
}

const messages = loadLocaleMessages()
const messageKeys = Object.keys(messages)
const initialLocale = window?.navigator?.language

let targetLocale
if (!initialLocale) {
  // no reported locale -> we do nothing, as this case is handled by `fallbackLocale`
} else if (messageKeys.includes(initialLocale)) {
  targetLocale = initialLocale
} else {
  // initial locale does not match any defined locale
  // could happen if browser language is e.g. 'de'
  // trying to find partial match, e.g. 'de' => 'de-DE'
  // first we get the base lang, so that 'de-AT' can fall back to 'de-DE' as well
  const baseLocale = initialLocale.split('-')[0]

  const partialMatches = messageKeys
    .sort()
    .filter((l) => l.startsWith(baseLocale))
  if (partialMatches.length) {
    targetLocale = partialMatches[0]
  }
}

const fallbackLocale = 'en-US'

export default createI18n({
  locale: targetLocale || fallbackLocale,
  legacy: false, // you must set `false`, to use Composition API
  globalInjection: true,
  fallbackLocale: {
    'en-US-gastro': ['en-US'],
    'de-DE-gastro': ['de-DE'],
    default: [fallbackLocale]
  },
  missingWarn: false,
  fallbackWarn: false,
  messages: messages
})
