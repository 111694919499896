/**
 * Asynchronously waiting for a dom element
 * Based on: https://github.com/azu/wait-for-element.js
 * @param {string} selector the css selector
 * @param {number} timeout the timeout in millisecond
 * @returns {Promise}
 */
export function waitForElement(selector, timeout) {
  const timeoutOption = timeout || 2000 // 2s
  const loopTime = 100
  const limitCount = timeoutOption / loopTime
  const limitCountOption = limitCount < 1 ? 1 : limitCount
  let tryCount = 0

  function tryCheck(resolve, reject) {
    if (tryCount < limitCountOption) {
      const element = document.querySelector(selector)
      if (element != null) {
        return resolve(element)
      }
      setTimeout(function () {
        tryCheck(resolve, reject)
      }, loopTime)
    } else {
      reject(new Error(`Not found element match the selector: ${selector}`))
    }
    tryCount++
  }

  return new Promise(function (resolve, reject) {
    tryCheck(resolve, reject)
  })
}

/**
 * Copy text to the system's clipboard
 * @param {string} text
 * @returns {Promise}
 */
export const copyToClipboard = (text) =>
  text && typeof text === 'string'
    ? navigator?.clipboard?.writeText(text)
    : Promise.reject(new TypeError('copyToClipboard requires a string'))
