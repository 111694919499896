import th from '@tillhub/javascript-sdk'
import pProps from 'p-props'
import pRetry from 'p-retry'
import mapValues from 'just-map-values'
import typeOf from 'just-typeof'

const specialCasesDict = {
  product_groups: 'productGroups',
  voucher_systems: 'voucherSystems',
  product_templates: 'productTemplates'
}

function getAllRequest(item, query = {}) {
  const _item = specialCasesDict[item] || item
  return th[_item]().getAll({
    query: { deleted: false, active: true, ...query }
  })
}

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$resourceFetch = async function resourceFetch(
      ...requiredResource
    ) {
      const resources = {}
      const wrapRequestHandler = async (handler, errorHandler, fallback) => {
        if (!errorHandler && !fallback) {
          return handler()
        }
        if (!errorHandler || !fallback) {
          throw new Error('errorHandler and fallback are only used as a pair')
        }
        try {
          return await handler()
        } catch (error) {
          errorHandler(error)
          return fallback()
        }
      }

      requiredResource.forEach(function (item) {
        if (typeOf(item) === 'string') {
          resources[item] = wrapRequestHandler(() => getAllRequest(item), null)
        } else if (typeOf(item) === 'object') {
          const {
            handler,
            resource,
            query,
            prop,
            errorHandler,
            fallback
          } = item
          if (!resource && !handler) {
            throw new Error('resource or handler is required')
          }
          if (query && handler) {
            throw new Error('query is useless when used with handler')
          }
          const propName = prop || resource
          if (!propName) {
            throw new Error(
              'use resource or prop to defined property name in the result'
            )
          }
          if (handler) {
            resources[propName] = wrapRequestHandler(
              handler,
              errorHandler,
              fallback
            )
          } else {
            resources[propName] = wrapRequestHandler(
              () => getAllRequest(resource, query),
              errorHandler,
              fallback
            )
          }
        } else {
          throw new TypeError('Resource definition has an invalid type')
        }
      })

      const run = async () => {
        const result = await pProps(resources)
        return result
      }

      const res = await pRetry(run, {
        retries: 2,
        minTimeout: 100,
        maxTimeout: 5000,
        randomize: true
      })
      return mapValues(res, (value, key) => value.data)
    }

    app.provide('resourceFetch', app.config.globalProperties.$resourceFetch)
  }
}
