<template>
  <div>
    <!-- Uploader -->
    <el-upload
      v-if="showUploadInfo"
      ref="uploader"
      class="th-image-upload"
      drag
      action=""
      :style="uploadSize"
      :limit="1"
      :auto-upload="false"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-error="onError"
      :on-change="onChange"
      :on-exceed="exceeded"
      :on-remove="handleRemove"
      :accept="acceptedFormats"
      :disabled="readonly"
    >
      <!-- Loading -->
      <loading v-if="loading" fill />

      <!-- Placeholder -->
      <div class="flex flex-col w-full text-5xl place-items-center">
        <!-- Icon -->
        <slot name="icon">
          <el-icon
            ><PictureRounded v-if="icon === 'PictureRounded'" /><Upload v-else
          /></el-icon>
        </slot>

        <!-- Title -->
        <label
          class="text-sm mt-2 block cursor-pointer overflow-ellipsis overflow-hidden"
        >
          <span v-if="!title">
            {{ $t('components.image_upload.drag_drop') }}
            {{ $t('components.image_upload.click_to_upload') }}
          </span>
          <span v-else>
            {{ title }}
          </span>
        </label>

        <!-- Info -->
        <p
          class="m-0 cursor-pointer overflow-ellipsis overflow-hidden text-xs"
          :class="{ italic: !isWhiteLabel }"
        >
          <span v-if="infoMsg">
            {{ infoMsg }}
          </span>
          <span v-else-if="!showLongSizeInfo">
            {{ $t('components.image_upload.size_message.short') }}
          </span>
          <span v-else>
            {{ $t('components.image_upload.size_message.long') }}
          </span>
        </p>
      </div>
    </el-upload>

    <!-- Video Preview -->
    <div
      v-if="allowVideo && !showUploadInfo && contentType === 'video'"
      class="video-wrap"
    >
      <vue3-video-player
        :src="contentUrl"
        class="vjs-custom-skin"
        v-bind="playerOptions"
      />
    </div>

    <!-- Image Preview-->
    <img
      v-if="!showUploadInfo && contentType === 'image'"
      :class="imageSize"
      :style="{ height: height, margin: 'auto' }"
      :src="getImageSrc(modelValue, imageKey)"
    />

    <!-- Actions -->
    <div class="absolute top-0 right-0 m-1 z-40">
      <!-- Delete button -->
      <el-button
        v-if="showDelete && !readonly"
        icon="Delete"
        class="el-button--text-icon"
        plain
        :title="$t('common.interactions.buttons.remove')"
        @click.stop="handleClear"
      />
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import Loading from '@/components/loading'
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'ThImageUpload',

  components: {
    Loading
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },

    imageKey: {
      type: String,
      required: true
    },

    // Apart from a image uploader, can also upload images
    allowVideo: {
      type: Boolean,
      default: false
    },

    // Add a custom info message
    infoMsg: {
      type: String,
      default: null
    },

    clearable: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    resource: {
      type: String,
      required: true,
      validator: function (value) {
        const allowedResources = [
          'products',
          'branches',
          'customers',
          'staff',
          'contentImage',
          'warehouses',
          'productGroups'
        ]
        return allowedResources.indexOf(value) !== -1
      }
    },

    fillType: {
      type: String,
      required: false,
      default: 'both',
      validator(value) {
        return ['width', 'height', 'both', 'contain'].includes(value)
      }
    },

    width: {
      type: String,
      required: false,
      default: '100%'
    },

    height: {
      type: String,
      required: false,
      default: '100%'
    },

    showLongSizeInfo: {
      type: Boolean,
      required: false,
      default: false
    },

    defaultImage: {
      type: String,
      required: false,
      default: null
    },

    fileSizeKB: {
      type: Number,
      default: 500
    },

    fileSizeKBVideo: {
      type: Number,
      default: 2000
    },

    /* TODO CHECK */
    icon: {
      type: String,
      required: false,
      default: 'Upload'
    },

    title: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      contentData: {}
    }
  },

  computed: {
    contentUrl() {
      return safeGet(this.modelValue, `${this.imageKey}`)
    },

    contentType() {
      return this.isVideo(this.contentUrl) ? 'video' : 'image'
    },

    showDelete() {
      return this.clearable && this.contentUrl
    },

    showUploadInfo() {
      return !this.contentUrl && !this.defaultImage
    },

    imageSize() {
      if (this.fillType === 'width') {
        return 'image-full-width'
      } else if (this.fillType === 'height') {
        return 'image-full-height'
      } else if (this.fillType === 'contain') {
        return 'image-contain'
      } else {
        return 'image-full-width image-full-height'
      }
    },

    uploadSize() {
      return { height: this.height, width: this.width, lineHeight: 'initial' }
    },

    // By default it works as just and image uploader, but also supports video uploading with preview
    acceptedFormats() {
      return this.allowVideo ? 'video/*,image/*' : 'image/*'
    },

    playerOptions() {
      return {
        autoplay: true,
        muted: false,
        controls: true,
        loop: true
      }
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    isVideo(fileName) {
      return this.allowVideo && /\.(mov|mp4)$/i.test(fileName)
    },

    async uploadFile(file) {
      this.loading = true
      const extension = this.isVideo(file.name) ? 'video' : 'image'
      try {
        const bodyFormData = new FormData()

        bodyFormData.append(extension, new Blob([file]), file.name)

        const prefix = uuid.v4()
        const ext = file.name.match(/\.[0-9a-z]+$/i)[0] || ''
        const subsystem =
          extension === 'video' ? 'contentVideos' : this.resource
        // Entities are videos and images
        const data = await th[extension + 's']().create(
          { subsystem, prefix, ext },
          bodyFormData
        )

        this.contentData = data

        if (this.contentData.data) {
          this.$emit('update:modelValue', this.contentData.data)
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('components.image_upload.error_message.create_fail')
        })
      } finally {
        this.loading = false
      }
    },

    beforeUpload() {
      return false
    },

    onError(err) {
      this.$message({
        type: 'error',
        message: err.message
      })
    },

    async onChange(file) {
      this.$refs.uploader.clearFiles()
      if (!this.validateFileSize(file)) {
        this.$message({
          type: 'error',
          message: this.$t('components.image_upload.error_message.exceeds_size')
        })
      } else {
        this.uploadFile(file.raw)
      }
      // to do: set the image when upload is successful
      // the following might now be available in some browsers
      try {
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = () => {
          this.image = reader.result
        }
        reader.onerror = function (err) {
          throw err
        }
      } catch (err) {
        this.onError(err)
      }
    },

    validateFileSize(file) {
      const fileLimit = this.isVideo(file.name)
        ? this.fileSizeKBVideo
        : this.fileSizeKB
      return file.size / 1024 <= fileLimit
    },

    exceeded() {
      this.$message({
        type: 'error',
        message: this.$t('components.image_upload.error_message.exceeds_limit')
      })

      return false
    },

    handleRemove() {
      this.image = ''
    },

    handleClear() {
      this.$emit('update:modelValue', null)
    },

    getImageSrc(value, imageKey) {
      const srcString = safeGet(value, `${imageKey}`)
      // NOTE: we add a query string at end so browser knows to update img tag
      return srcString ? `${srcString}?${Date.now()}` : this.defaultImage
    }
  }
}
</script>

<style scoped>
.image-full-height {
  height: 100%;
}

.image-full-width {
  min-width: 100%;
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.th-image-upload {
  height: 100%;
  width: 100%;
}

.th-image-upload :deep(.el-upload) {
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: block;
  color: var(--font-color-light);
}

.th-image-upload :deep(.el-upload:hover) {
  color: var(--font-color);
}

.th-image-upload :deep(.el-upload .el-upload-dragger) {
  padding: 1rem;
  height: 100%;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.th-image-upload :deep(.el-upload .el-upload-dragger) {
  border-color: var(--border-color-hover);
}

.th-image-upload :deep(.el-upload .el-upload-dragger.is-dragover) {
  border-color: var(--border-color-hover);
}

.video-wrap {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #c1c1c1;
}
</style>
