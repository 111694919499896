import typeOf from 'just-typeof'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Plugin that push new route to the router and prevents the router from showing a DuplicateNavigation error
 */
export default {
  install: (app) => {
    app.config.globalProperties.$safePush = function safePush(route) {
      this.$router.push(route, () => {})
    }

    app.config.globalProperties.$safePushOrReplace = function safePushOrReplace(
      route,
      { force } = {}
    ) {
      const onComplete = () => {}
      const onAbort = (err) => {
        if (
          err &&
          err.name === 'NavigationDuplicated' &&
          typeOf(route) === 'string' &&
          force === true
        ) {
          // we will hack re-rendering
          return this.$router.replace(`${route}&temp=${getRandomInt(0, 1000)}`)
        }
      }

      this.$router.push(route, onComplete, onAbort)
    }
  }
}
