/**
 * Adds the $logException function to all stores.
 * @param {import('pinia').PiniaPluginContext} context
 */
export const piniaPluginLogException = (context) => {
  const { $logException = null } = { ...context.app.config.globalProperties }

  context.store.$logException = $logException

  if (!$logException) {
    throw new Error(
      'logException plugin not found. Ensure the logException app plugin has been installed before using any pinia stores.'
    )
  } else if (typeof $logException === 'function') {
    return { $logException }
  }
}
