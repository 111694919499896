<template>
  <th-middle-page-wrapper v-if="showLogin">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent="handleSubmit"
    >
      <!-- Language -->
      <el-form-item prop="language" :label="$t('pages.auth.login.language')">
        <el-select id="language" v-model="language" class="w-full">
          <el-option
            v-for="lang in locales"
            :key="lang.value"
            :label="lang.label"
            :value="lang.value"
          />
        </el-select>
      </el-form-item>

      <!-- Organization -->
      <el-form-item prop="organisation">
        <th-input-title :info="$t('pages.auth.login.organisation.tooltip')">
          {{ $t('pages.auth.login.organisation.placeholder') }}
          <span class="font-bold"> ({{ $t('common.labels.optional') }}) </span>
        </th-input-title>
        <el-input
          id="organisation"
          v-model="form.organisation"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :placeholder="$t('pages.auth.login.organisation.placeholder')"
        />
      </el-form-item>

      <!-- Username -->
      <el-form-item
        prop="username"
        :label="$t('pages.auth.login.username.placeholder')"
      >
        <el-input
          id="username"
          v-model="form.username"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :placeholder="$t('pages.auth.login.username.placeholder')"
        />
      </el-form-item>

      <!-- Password -->
      <el-form-item
        prop="password"
        :label="$t('pages.auth.login.password.placeholder')"
      >
        <el-input
          id="password"
          v-model="form.password"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :placeholder="$t('pages.auth.login.password.placeholder')"
          type="password"
        />
      </el-form-item>

      <!-- Login -->
      <el-button
        type="primary"
        class="w-full mt-4 signin-button"
        native-type="submit"
        :loading="loading"
      >
        {{ $t('pages.auth.login.sign_in') }}
      </el-button>
    </el-form>

    <div class="flex justify-between items-center text-xs mt-4">
      <!-- Version -->
      <version class="text-th-gray-base" />

      <!-- Password forgotten -->
      <router-link to="password_forgotten" class="text-th-secondary">
        {{ $t('pages.auth.login.forgot_password_link') }}
      </router-link>
    </div>
  </th-middle-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import ThMiddlePageWrapper from '@components/middle-page-wrapper'
import Version from '@components/version'
import * as Sentry from '@sentry/vue'
import { waitForElement } from '@/utils/dom'
import { locales, gastroLocaleSuffix } from '@/constants'
import { isKeycloackEnabled, isUnifiedCommerce } from '@/constants/index'

export default {
  components: {
    Version,
    ThMiddlePageWrapper
  },

  async beforeRouteLeave(to, from, next) {
    try {
      const captcha = await waitForElement('.grecaptcha-badge')
      if (captcha) {
        captcha.style.opacity = 0
        captcha.style['pointer-events'] = 'none'
      }
    } catch (err) {
      this.$logException(err)
    }

    next()
  },

  data() {
    let organisation = null
    // in all cases where a organisation is defined on the query paramaters and parsable, let's we assume
    // the caller wants to have an org login method.
    // This should be done gracefully, as we do want to test for good browser support or implementation bugs
    // throwing in this component - otherwise this component would be a lock-out
    try {
      const get = (n) => {
        const half = window.location.search.split(n + '=')[1]
        return half !== undefined
          ? window.decodeURIComponent(half.split('&')[0])
          : null
      }

      organisation = get('organisation')
    } catch (err) {
      // no-op
    }
    return {
      loading: false,
      form: {
        username: '',
        password: '',
        organisation
      },
      locales,
      recaptchaToken: null
    }
  },

  computed: {
    language: {
      get() {
        return safeGet(
          this.$store.state.Config,
          'localConfiguration.settings.language',
          this.$i18n.locale || 'de-DE'
        )
      },
      set(newValue) {
        if (this.language === newValue) return
        this.$store.dispatch('Config/setLocalConfigurationValue', {
          path: 'settings.language',
          value: newValue || null
        })
        this.$rootMaybeChangeLocale(
          newValue || this.$store.getters['Config/getLocale']
        )
        this.$i18n.locale = newValue
      }
    },
    rules() {
      return {
        username: [
          {
            required: true,
            message: this.$t('pages.auth.login.username.validation.required'),
            trigger: 'blur'
          },
          {
            min: 5,
            message: this.$t('pages.auth.login.username.validation.length'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('pages.auth.login.password.validation.required'),
            trigger: 'blur'
          },
          {
            min: 5,
            message: this.$t('pages.auth.login.password.validation.length'),
            trigger: 'blur'
          }
        ]
      }
    },
    showLogin() {
      // Unified commerce whitelabel has a keycloak login
      return !isKeycloackEnabled() || !isUnifiedCommerce()
    }
  },

  async beforeCreate() {
    // Dont create the captcha for whitelabel unified commerce
    if (this.showLogin) {
      try {
        const captcha = await waitForElement('.grecaptcha-badge')
        if (captcha) {
          captcha.style.opacity = 1
          captcha.style['pointer-events'] = 'auto'
        }
      } catch (err) {
        // TODO CHECK
        // Vue.prototype.$logException(err)
        this.$logException(err)
      }
    }
  },

  mounted() {
    // Revert gastro language - example: de-DE-gastro -> de-DE
    this.language = this.language.replace(gastroLocaleSuffix, '')
  },

  methods: {
    async handleSubmit() {
      if (!(await this.validate())) return

      this.checkRecaptcha((err, token) => {
        if (err) return this.$logException(err)
        this.recaptchaToken = token
        this.login()
      })
    },
    async login() {
      this.loading = true
      const [err, { user } = {}] = await this.$store.dispatch(
        'Auth/getAuthToken',
        {
          username: this.form.username,
          password: this.form.password,
          organisation: this.form.organisation,
          recaptchaToken: this.recaptchaToken || undefined
        }
      )
      this.loading = false

      if (err) {
        if (err.message === 'users.secondary.errors.sub_user.inactive') {
          err.message = this.$t('pages.auth.login.error.disabled.message')
        }

        return this.$logException(err, {
          message: this.$t('pages.auth.login.error.message'),
          trackError: false
        })
      }

      try {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user })
        })
      } catch (err) {
        this.$logException(err)
      }

      this.$message({
        message: this.$t('pages.auth.login.success.message'),
        type: 'success'
      })

      this.$router.replace('/redirect')
      await this.$rootMakeMeCall()
      this.$ampli.eventWithBaseProps('login')
      this.$rootMaybeChangeLocale()
    },
    checkRecaptcha(cb) {
      if (!window.grecaptcha) {
        return cb(
          new TypeError(
            'recaptcha does not seem to be available in this browser'
          )
        )
      }

      window.grecaptcha.ready(async () => {
        let token
        try {
          token = await window.grecaptcha.execute(
            '6Lde7r4UAAAAAEUZuRuaD11-W590yc5t-YXOZIo5',
            { action: 'login' }
          )
        } catch (err) {
          this.$log.debug(
            `recaptcha failed to retrieve token: ${err.message || err}`
          )

          return cb(err)
        }

        return cb(null, token)
      })
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
