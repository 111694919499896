<template>
  <el-row class="flex items-center py-4 max-w-md text-th-primary relative">
    <el-col :span="2" :offset="2" class="flex items-center">
      <template v-if="$slots.icon_start">
        <div class="absolute th-top-18">
          <slot name="icon_start" />
        </div>
      </template>
    </el-col>
    <el-col :span="16">
      <slot name="content" />
    </el-col>
    <el-col :span="2" :offset="1">
      <template v-if="$slots.icon_end">
        <slot name="icon_end" />
      </template>
    </el-col>
  </el-row>
</template>

<style scoped>
.th-top-18 {
  top: 18px;
}
</style>
