import { isUnifiedCommerce, unifiedCommerceExcludedScopes } from '@/constants'

const ALL_PERMISSIONS = 'all'
const ADMIN = 'admin'
const OWNER = 'owner'
const SUPPORT = 'support'

/**
 *  Extract the category part from a scope
 * @param {String} scope - e.g. "staff:create"
 * @returns {String} - category name
 */
const getScopeCategoryName = (scope) => scope.split(':')[0]

/**
 *  Transforms an array of scopes to an array of unique scope category names
 * @param {String[]} arr - array of string in the scopes structure, e.g. "staff:create"
 * @returns {String[]}
 */
export const getUniqueCategories = (arr) => [
  ...new Set(arr.map(getScopeCategoryName))
]

/**
 *
 * @param {String[]} [guardScopes = []]
 * @param {String[]} [userScopes = []]
 * @returns {Boolean}
 */
export const shouldAllowNavItem = (guardScopes = [], userScopes = []) => {
  if (!guardScopes || !guardScopes.length) return true
  if (!Array.isArray(userScopes)) return false
  const uniqueCategories = getUniqueCategories(userScopes)
  return guardScopes.some((gScope) => uniqueCategories.includes(gScope))
}

/**
 * Check the permissions of a user against the allowed scopes
 * @param {Object} meta
 * @param {String[]} meta.scopes
 * @param {String} [userRole = '']
 * @param {String[]} [userScopes = []]
 * @returns {Boolean}
 */
export function checkPermissions({ scopes } = {}, userRole = '', userScopes) {
  const isGuarded = scopes?.length
  if (!isGuarded) return true
  if (!userScopes && !userRole) return false

  const _userScopes = userScopes || []
  const _scopes = scopes || []

  // unified commerce restrictions for scopes
  if (
    isUnifiedCommerce() &&
    scopePermutationsFromArr(_scopes).some((x) =>
      unifiedCommerceExcludedScopes.includes(x)
    )
  )
    return false

  return (
    // user is owner
    userRole === OWNER ||
    // user is admin
    _userScopes.includes(ADMIN) ||
    // user is support
    _userScopes.includes(SUPPORT) ||
    // user's scopes has all the available permissions
    _userScopes.includes(ALL_PERMISSIONS) ||
    // user's scopes include category names that are overriding a specific scopes
    getUniqueCategories(_scopes).some((x) => _userScopes.includes(x)) ||
    // the user has at least one of the requires scopes
    scopePermutationsFromArr(_scopes).some((x) => _userScopes.includes(x))
  )
}

/**
 * Transforms a navigation array by checking its children for the allowed permissions
 * @param {Object[]} navArr - array of trees
 * @param {String} userRole
 * @param {String[]} userScopes
 * @returns {Object[]}
 */
export function parseNavByPermissions(navArr, userRole, userScopes) {
  return navArr.reduce((acc, curr) => {
    if (
      curr.meta &&
      !shouldAllowNavItem(curr.meta.scopes, userScopes) &&
      !checkPermissions(curr.meta, userRole, userScopes)
    ) {
      return acc
    }
    const children = curr.children
      ? parseNavByPermissions(curr.children, userRole, userScopes)
      : undefined
    return acc.concat({ ...curr, children })
  }, [])
}

/**
 * Finds the missing required scopes
 * @param {String[]} requiredScopes
 * @param {String[]} userScopes
 * @returns {Object}
 */
export function missingScopes(requiredScopes, userScopes) {
  return requiredScopes
    .filter((s) => !userScopes.includes(s))
    .reduce((acc, scope) => {
      const [resource, action] = scope.split(':')
      return { resource, actions: (acc.actions || []).concat(action) }
    }, {})
}

/**
 * Generating all linear combinations from a scope structured string
 * e.g.
 * a provided input: 'an:example:scope'
 * will result with: ['an', 'an:example', 'an:example:scope']
 * @param {String} scope
 * @returns {String[]}
 */
export function genScopePermutations(scope) {
  if (typeof scope !== 'string') return []
  return scope.split(':').map((_, i, parts) => parts.slice(0, i + 1).join(':'))
}

/**
 * Get all the linear combinations for multiple scopes as a one dimension array
 * @param {String[]} scopes
 * @returns {String[]}
 */
export function scopePermutationsFromArr(scopes) {
  if (!Array.isArray(scopes)) return []
  return scopes.reduce(
    (acc, curr) => acc.concat(genScopePermutations(curr)),
    []
  )
}

export const mapNames = (name) =>
  ({
    vouchers: 'loyalty'
  }[name] ?? name)
