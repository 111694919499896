<template>
  <div class="relative">
    <el-tooltip
      :visible="!open && tooltipMessage && showTooltip"
      manual
      placement="bottom"
      effect="light"
    >
      <template #content>
        <div class="text-sm" v-text="tooltipMessage" />
      </template>
      <el-button
        class="py-0 focus:outline-none focus:shadow-outline text-xl text-white"
        text
        @click="toggleOpen(!open)"
      >
        <div :class="{ ringing: !open && unreadNotificationsExist }">
          <span
            v-if="unreadNotificationsExist"
            class="w-3 h-3 rounded-full bg-th-primary absolute top-0 right-0 z-10"
          />
          <svgicon
            :src="thIconBell"
            :style="{ height: '20px', width: '20px' }"
          />
        </div>
      </el-button>
    </el-tooltip>

    <th-drawer
      v-model="open"
      :title="$t('notifications.main.title')"
      destroy-on-close
      class="th-drawer"
      @update:modelValue="toggleOpen"
    >
      <template #default>
        <div class="flex flex-col h-full">
          <el-row class="py-6 text-2xl text-th-primary" type="flex">
            <el-col :span="2" :offset="2">
              <svgicon
                :src="thIconBell"
                :style="{ height: '20px', width: '20px' }"
              />
            </el-col>
            <el-col :span="14">
              <span class="font-bold">{{
                $t('notifications.main.title')
              }}</span>
            </el-col>
            <el-col :span="2" :offset="3">
              <el-button
                class="py-0 focus:outline-none focus:shadow-outline text-th-primary"
                text
                @click="toggleOpen(false)"
              >
                <el-icon class="text-2xl"><Close /></el-icon>
              </el-button>
            </el-col>
          </el-row>
          <el-row
            v-if="!notifications.length"
            class="pt-5 h-full w-full bg-auto bg-no-repeat"
            :style="`background-image: url(${notificationsEmpty});`"
          >
            <el-col
              :span="14"
              :offset="4"
              class="mt-32 text-2xl font-bold text-th-secondary"
            >
              <div>{{ $t('notifications.main.empty.text.welcome') }}</div>
              <div>
                {{ $t('notifications.main.empty.text.no_notifications') }}
              </div>
            </el-col>
          </el-row>
          <div v-else class="flex flex-col h-full overflow-y-auto">
            <el-row
              v-for="notification in sortedNotifications"
              :key="notification.id"
              class="border-t last:border-b border-th-lunar-gray"
            >
              <notification-item
                :notification="notification"
                @toggleOpen="toggleOpen(!open)"
              />
            </el-row>
          </div>
        </div>
      </template>
    </th-drawer>
  </div>
</template>

<script>
import isBefore from 'date-fns/isBefore'
import compare from 'just-compare'
import debounce from 'debounce'
import notificationsEmpty from '@/assets/illustrations/notifications_empty.svg'
import NotificationItem from './components/notification'
import thIconBell from '@/assets/icons/th-icon-bell.svg'
import { NOTIFICATIONS_TYPES } from '@/constants'
import { useMessagesStore } from '@/store/messages'
import { storeToRefs } from 'pinia'
const { SUCCESS, LOADING } = NOTIFICATIONS_TYPES

let triggerTooltipTimeout

const tooltipMessages = {
  assets: {
    [LOADING]: 'notifications.assets.loading.text'
  },
  exports: {
    [SUCCESS]: 'notifications.exports.success.text',
    [LOADING]: 'notifications.exports.loading.text'
  }
}

export default {
  components: {
    NotificationItem
  },
  setup() {
    const messagesStore = useMessagesStore()
    const { getGlobalMessages } = storeToRefs(messagesStore)
    return { messagesStore, notifications: getGlobalMessages }
  },
  data() {
    return {
      thIconBell,
      notificationsEmpty,
      open: false,
      showTooltip: false
    }
  },
  computed: {
    unreadNotificationsExist() {
      // NOTE: since in current implementation once the latest notification is read hence all notifications are read (all unread notifications are marked as read when opening the sidebar). Therefore we can just check the read status of the latest notification instead for filter for all unread ones.
      return this.sortedNotifications[0]?.read === false
    },
    sortedNotifications() {
      return [...this.notifications].sort((a, b) => {
        const dateA = a.operation.date
        const dateB = b.operation.date
        return isBefore(new Date(dateA), new Date(dateB)) ? 1 : -1
      })
    },
    tooltipMessage() {
      if (!this.unreadNotificationsExist) return
      const latestNotification = this.sortedNotifications[0]
      if (!latestNotification?.operation) return

      const {
        type,
        originKey,
        module,
        tooltipText,
        showTooltip = true
      } = latestNotification.operation

      if (!showTooltip || ![SUCCESS, LOADING].includes(type)) {
        return undefined
      }

      if (tooltipText) return tooltipText

      const isValidTooltipMessage =
        originKey && tooltipMessages?.[module]?.[type]

      if (isValidTooltipMessage) {
        return this.$t(tooltipMessages[module][type], {
          entity: this.$t(originKey)
        })
      }
      return undefined
    }
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue !== oldValue && this.unreadNotificationsExist) {
        this.setNotificationsReadStatus()
      }
    },
    sortedNotifications(newNotifications, oldNotifications) {
      if (compare(newNotifications, oldNotifications)) return
      const { type, showTooltip, tooltipDuration } =
        newNotifications[0]?.operation || {}
      if (![SUCCESS, LOADING].includes(type) && !showTooltip) return
      this.triggerTooltip(tooltipDuration)
    }
  },
  beforeUnmount() {
    this.showTooltip = false
  },
  methods: {
    toggleOpen(nextOpen) {
      if (this.open === nextOpen) return
      this.open = nextOpen
    },
    setNotificationsReadStatus() {
      this.notifications
        .filter((m) => m.read === false)
        .forEach((notification) => {
          this.messagesStore.updateMessage({
            id: notification.id,
            changedProp: {
              read: true
            }
          })
        })
    },
    /**
     * triggerTooltip method is debounced because we don't want the tooltip flicker with different messages.
     */
    triggerTooltip: debounce(function (tooltipDuration = 2500) {
      this.showTooltip = true
      clearTimeout(triggerTooltipTimeout)
      triggerTooltipTimeout = setTimeout(() => {
        this.showTooltip = false
      }, tooltipDuration)
    }, 250)
  }
}
</script>

<style scoped>
.ringing {
  animation: ring 10s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  2% {
    transform: rotate(-28deg);
  }
  3% {
    transform: rotate(34deg);
  }
  4% {
    transform: rotate(-32deg);
  }
  5% {
    transform: rotate(30deg);
  }
  6% {
    transform: rotate(-28deg);
  }
  7% {
    transform: rotate(26deg);
  }
  8% {
    transform: rotate(-24deg);
  }
  9% {
    transform: rotate(22deg);
  }
  10% {
    transform: rotate(-20deg);
  }
  11% {
    transform: rotate(18deg);
  }
  12% {
    transform: rotate(-16deg);
  }
  13% {
    transform: rotate(14deg);
  }
  14% {
    transform: rotate(-12deg);
  }
  15% {
    transform: rotate(10deg);
  }
  16% {
    transform: rotate(-8deg);
  }
  17% {
    transform: rotate(6deg);
  }
  18% {
    transform: rotate(-4deg);
  }
  19% {
    transform: rotate(2deg);
  }
  20% {
    transform: rotate(-1deg);
  }
  21% {
    transform: rotate(1deg);
  }
  22% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
