import store from '@/store'

export function isNullish(value) {
  return [null, undefined].includes(value)
}

export function getDefaultCurrency() {
  return store.getters['Config/getCurrentDefaultCurrency']
}

/**
 * Async interval that stops computation until predicate is truthy.
 * @param {Function} predicate
 * @param {Number} interval
 * @param {Number} timeout
 * @param {Boolean} shouldReject
 * @returns {Promise}
 */
export function waitForData(
  predicate = () => true,
  interval = 50,
  timeout = 2500,
  shouldReject = false
) {
  const start = Date.now()
  let intervalId = null
  return new Promise((resolve, reject) => {
    intervalId = setInterval(() => {
      if (predicate()) {
        clearInterval(intervalId)
        return resolve()
      }
      if (Date.now() - start >= timeout) {
        clearInterval(intervalId)
        if (shouldReject) reject()
      }
    }, interval)
  })
}

/**
 * Check if device is a tablet
 * @returns {boolean}
 */
export function isTablet() {
  const userAgent = navigator.userAgent.toLowerCase()
  return (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    ) ||
    // Test for iPadOs
    // https://newbedev.com/how-to-detect-ipad-pro-as-ipad-using-javascript
    (!!navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform))
  )
}

/**
 * Check if device is a mobile phone
 * @return {boolean}
 */
export function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase()
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    userAgent
  )
}

/**
 * Check if device is not mobile or tablet
 * @return {boolean}
 */
export function isDektop() {
  return !isTablet() && !isMobile()
}
