/**
 * Debounce function that tracks the amount of clicks triggered on an element.
 * After the wait period that provided is passed the provided callback function will be called with the amount of clicks exposed to it.
 * @param {Function} cb
 * @param {Number} wait
 */
export function debounceClicks(cb, wait) {
  let timeout
  let clicks = 0

  return function (...args) {
    const later = () => {
      timeout = null
      cb.call(this, clicks, args)
      clicks = 0
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    clicks++
  }
}
