<template>
  <div class="datatable-wrapper" :style="{ height, width }">
    <div v-if="showToolHeader" class="flex flex-col mx-6 mt-6 mb-0">
      <slot name="message" />
      <div
        class="table-actions w-max overflow-hidden flex items-center align-center"
      >
        <div v-if="showActions" class="actions h-full">
          <filter-button
            v-if="showFilter || showSearchFilter"
            class="h-full"
            :model-value="injectFilter"
            :filters="searchFiltersWithData"
            @filter="handleSearchFilterInput"
            @submit="handleSearchFilterSubmit"
            @update-filter="handleUpdateFilter"
          />
          <search-input
            v-if="showSearch || showSearchFilter"
            class="h-full"
            :model-value="injectFilter"
            :filters="searchFiltersWithData"
            @filter="handleSearchFilterInput"
            @submit="handleSearchFilterSubmit"
          />
          <slot name="actions" />

          <div v-if="$slots['actions-post']" class="actions-post-wrapper">
            <slot name="actions-post" />
          </div>
        </div>
        <div class="flex-grow" />
        <div v-if="showTools" class="tools">
          <div v-if="documentExport && exportDocumentType" class="mr-2">
            <document-export
              :filters="searchFiltersWithData"
              :document-type="exportDocumentType"
              :resource-options="getResourceOptions()"
              :filename-prefix="exportFilenamePrefix"
              :wrap-query-in-object="wrapQueryInObject"
              :header-name-to-export-column-map="headerNameToExportColumnMap"
              :force-all-columns="forceAllExportColumns"
              :handle-export="handleExport"
              :headers="filterHeaders"
              :button-type="buttons.length ? 'secondary' : 'primary'"
            />
          </div>
          <slot name="tools" />
          <datatable-buttons
            :buttons="buttons"
            :export-options="exportOptions"
            :resource-instance="getResourceInstance()"
            :resource="resource"
            :resource-options="getResourceOptions()"
            :route-base="routeBaseDefault"
            :do-route="doRoute"
            :headers="headers"
            :export-map-list="exportMapList"
            :operations="operations"
            :download-retry-options="downloadRetryOptions"
            :custom-create-path="customCreatePath"
          >
            <template #dropdown-items-start>
              <slot name="dropdown-items-start" />
            </template>
            <template #dropdown-items-end>
              <slot name="dropdown-items-end" />
            </template>
          </datatable-buttons>
        </div>
      </div>
      <div v-if="$slots['chips-viewer']">
        <slot name="chips-viewer" />
      </div>
      <chips-viewer
        v-else-if="showSearch || showFilter || showSearchFilter"
        class="w-max"
        text-field-name="q"
        :model-value="injectFilter"
        :filters="searchFiltersWithData"
        @submit="handleSearchFilterSubmit"
      />
    </div>
    <el-card
      v-show="!showNoData"
      class="content card-content m-6"
      :body-style="{ height: '100%', padding: '0px' }"
    >
      <el-container class="table-feature-holder">
        <el-main class="table-actual-wrapper">
          <el-table
            ref="datatable"
            v-loading="dataLoading"
            :border="tableBorders"
            :data="page"
            :class="{
              pointer: expandingRow || doRoute,
              'multiple-select-limit': !!multipleSelectLimit
            }"
            max-height="auto"
            height="100%"
            :element-loading-text="loadingText"
            :cell-class-name="applyCellClassName"
            :row-key="rowKey"
            :span-method="tableSpanMethod"
            :expand-row-keys="expandedRows"
            :row-class-name="getRowClassName"
            @expand-change="trackExpandedRows"
            @sort-change="sortChange"
            @row-click="
              (...eventArgs) => handleRowClickTypes('row-click', ...eventArgs)
            "
            @row-dblclick="
              (...eventArgs) =>
                handleRowClickTypes('row-dblclick', ...eventArgs)
            "
            @selection-change="handleSelectionChange"
            @select-all="handleSelectAll"
            @refresh-datatable="refresh"
          >
            <!-- row multiselect -->
            <el-table-column
              v-if="multipleSelect"
              type="selection"
              align="center"
              width="55"
              :selectable="selectable"
            />
            <!-- expending row -->
            <el-table-column v-if="!!expandingRow" type="expand">
              <template #header>
                <el-button
                  class="outline-none"
                  text
                  :icon="expandedRows.length ? 'ArrowDown' : 'ArrowRight'"
                  @click="handleExpandCollapseAllRows"
                />
              </template>
              <template #default="props">
                <slot
                  v-if="!isEmpty(props?.row) && _doExpandRow(props.row)"
                  name="expanding-row"
                  v-bind="props"
                />
              </template>
            </el-table-column>
            <!-- row data -->
            <el-table-column
              v-for="(header, index) in filterHeaders"
              :key="index"
              :sortable="sortable ? 'custom' : false"
              :formatter="
                extendedFormatter(header, header.formatter, header.field, index)
              "
              :fixed="header.fixed"
              :prop="header.field"
              :label="header.label"
              :align="header.align || 'left'"
              :show-overflow-tooltip="header.truncate"
              :width="header.width"
              :min-width="header.minWidth"
              :sort-orders="['ascending', 'descending']"
              :sort-type="header.sortType || 'string'"
            >
              <template v-if="header.customRowComponent" #default="scope">
                <component
                  :is="header.customRowComponent"
                  v-bind="header"
                  :scope="scope"
                  :formatter="
                    extendedFormatter(
                      header,
                      header.formatter,
                      header.field,
                      index
                    )
                  "
                  @refresh-requested="refresh"
                />
              </template>
            </el-table-column>

            <!-- row operations -->
            <el-table-column
              v-if="showOperations"
              :label="getLabel('table.headers.operations', 'Operations')"
              :width="operationsWidth"
              align="right"
            >
              <template #default="scope">
                <slot
                  v-if="scope.operations || $slots.operations"
                  name="operations"
                  v-bind="scope"
                />
                <div v-else>
                  <el-button
                    v-if="operations.update"
                    class="el-button--primary-icon"
                    icon="Edit"
                    size="small"
                    @click.stop="edit(scope.$index)"
                  />
                  <el-button
                    v-if="operations.delete"
                    class="el-button--text-icon"
                    icon="Delete"
                    size="small"
                    @click.stop="deleteRow(scope.$index)"
                  />
                  <el-button
                    v-if="!!operations.link"
                    text
                    :disabled="operations.link.disable(scope.row)"
                    @click.stop="operations.link.handleClick(scope.row)"
                  >
                    {{ operations.link.text }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <!-- row menu -->
            <el-table-column v-if="rowMenuOptions.length" prop="row-menu">
              <template #default="scope">
                <row-menu
                  :row="scope.row"
                  :options="rowMenuOptions"
                  :row-menu-direction-left="rowMenuDirectionLeft"
                  @row-menu-click="handleRowMenuClick"
                  @row-menu-opened="handleRowMenuOpen"
                  @row-menu-close="handleRowMenuClose"
                />
              </template>
            </el-table-column>

            <el-table-column
              v-if="headersFilterable"
              align="right"
              fixed="right"
              class-name="header-filter"
              width="40"
            >
              <template #header>
                <headers-filter
                  v-model="filterHeaders"
                  :headers="headers"
                  :headers-reset="headersMinActive"
                  :initial-values="initialHeaders"
                  @update:modelValue="updateHeadersConfig"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-main>

        <el-footer v-if="_showSummary" class="summary-actual-wrapper">
          <!-- if we got both a slot and and array show the array -->
          <slot v-if="!Array.isArray(_summary)" name="custom-summary" />

          <el-row v-else class="summary-row">
            <el-col
              :span="summaryLabel ? 4 : 0"
              class="summary-row-label-holder"
            >
              <h3 class="summary-label" v-text="summaryLabel" />
            </el-col>

            <el-col
              :span="summaryLabel ? 20 : 24"
              class="summary-row-summary-holder"
            >
              <el-table
                ref="summaryTable"
                class="summary-table"
                :data="_summary"
                max-height="auto"
              >
                <el-table-column
                  v-for="(header, index) in _summaryHeaders"
                  :key="index"
                  :formatter="
                    extendedFormatter(
                      header,
                      header.formatter,
                      header.field,
                      index
                    )
                  "
                  :fixed="header.fixed"
                  :prop="header.field"
                  :label="header.label"
                  :align="header.align || 'left'"
                  :show-overflow-tooltip="header.truncate"
                  :width="header.width"
                  :min-width="header.minWidth"
                >
                  <template #header>
                    <div :class="getSummaryHeadersClassName(header)">
                      <th-popover
                        v-if="header.tooltip"
                        :text="header.tooltip"
                        class="pr-2"
                      />
                      <span v-text="header.label" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-card>

    <el-card
      v-show="showNoData"
      class="content card-content m-6"
      :body-style="{ height: '100%', padding: '0px' }"
    >
      <slot name="no-data">
        <no-data />
      </slot>
    </el-card>

    <template v-if="hasAfterTable">
      <slot name="after-table" />
    </template>

    <div v-if="paging" class="footer">
      <ElConfigProvider :locale="elementLocale">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="localPageSize"
          v-loading="metaLoading"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </ElConfigProvider>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import pAll from 'p-all'
import typeOf from 'just-typeof'
import isEmpty from 'just-is-empty'
import pWhilst from 'p-whilst'
import safeGet from 'just-safe-get'
import filterObject from 'just-filter-object'
import omit from 'just-omit'
import qs from 'qs'
import mapValues from 'just-map-values'
import safeSet from 'just-safe-set'
import FilterButton from '@/components/filter-header/filter'
import SearchInput from '@/components/filter-header/search'
import ChipsViewer from '@/components/filter-header/chips-viewer'
import DatatableButtons from './buttons'
import NoData from './no-data'
import RowMenu from './row-menu'
import { makeAsyncCall } from './helpers/async'
import { compareRow } from './helpers/sort'
import { debounceClicks } from './helpers'
import HeadersFilter from './headers-filter.vue'
import DocumentExport from '@/components/document-export/index.vue'
import props from './props'
import enLocale from 'element-plus/lib/locale/lang/en'
import deLocale from 'element-plus/lib/locale/lang/de'
import esLocale from 'element-plus/lib/locale/lang/es'
import itLocale from 'element-plus/lib/locale/lang/it'
import czLocale from 'element-plus/lib/locale/lang/cs'

/**
 * Handle data with uniform UX
 *
 * @author Tillhub
 * @license UNLICENSED
 */
export default {
  name: 'ThDatatable',
  components: {
    NoData,
    HeadersFilter,
    DatatableButtons,
    RowMenu,
    ChipsViewer,
    FilterButton,
    SearchInput,
    DocumentExport
  },
  props,
  emits: [
    'size-changed',
    'current-page-changed',
    'will-handle-page-change',
    'will-handle-page',
    'handle-parsed-filter',
    'loading',
    'loaded',
    'page-handled',
    'handled-count',
    'delete-requested',
    'edit-requested',
    'loading-error',
    'row-click',
    'row-dblclick',
    'selection-change',
    'select-all',
    'headers-config',
    'search-filter',
    'search-filter-submit',
    'search-filter-submit-route'
  ],
  data() {
    return {
      localData: this.tableData || [],
      localSummary: null,
      isDataLoading: false,
      isMetaLoading: false,
      currentPage: 1,
      page: this.tableData || [],
      totalCount: 0,
      overallCount: undefined,
      selectedItems: [],
      next: null,
      show: this.message.length ? this.showMessage : false,
      defaultFallback: '--',
      expandedRows: [],
      filtersData: {},
      isEmpty,
      elementLocale: enLocale
    }
  },
  computed: {
    ...mapGetters({
      pageSize: 'Config/getPageSizes',
      tableBorders: 'Config/getTableBorders',
      tableColumnOrder: 'Config/getTableColumnOrder',
      lastUrlWithPageNumber: 'Config/getLastUrlWithPageNumber'
    }),
    localPageSize: {
      get() {
        return this.pageSize?.main || 20
      },
      set(value) {
        this.$store.dispatch('Config/setLocalConfigurationValue', {
          path: 'pageSizes.main',
          value
        })
      }
    },
    pageOffset() {
      return this.isOffsetPaginationSupported
        ? (this.currentPage - 1) * this.localPageSize
        : undefined
    },
    isOffsetPaginationSupported() {
      return ['customersV1'].includes(this.resource)
    },
    localResourceLimit() {
      //limit the results, if fetch offset supported, the limit is pageSize.
      //if filter is on (doMetaCheck is set to false) and the returned results are as set by the props.
      return this.isOffsetPaginationSupported && this.doMetaCheck
        ? this.localPageSize
        : this.resourceLimit
    },
    searchFiltersWithData() {
      // Inject filters data
      const copyOfFilters = [...this.searchFilters]
      copyOfFilters.forEach((f) => {
        f.originalData = this.filtersData[f.name]
      })
      return copyOfFilters
    },
    initialHeaders() {
      const initial = this.headersDefaultHideWithColumnSort.reduce(
        (acc, item) => {
          acc[item] = false
          return acc
        },
        {}
      )

      return {
        ...initial,
        ...this.headersConfig
      }
    },
    headersDefaultHideWithColumnSort() {
      let defaultHideOrder = this.headersDefaultHide
      if (this.columnsOrderEnabled && this.tableColumnOrder)
        defaultHideOrder =
          this.tableColumnOrder[this.tableIdentifier]?.hidden ||
          this.headersDefaultHide
      return defaultHideOrder
    },
    columnsOrderEnabled() {
      return this.tableIdentifier && this.sortColumnsEnabled
    },
    filterHeaders: {
      get() {
        let headers = this.headers.filter(
          (header) =>
            this.initialHeaders[header.field] === undefined ||
            this.initialHeaders[header.field]
        )
        if (this.columnsOrderEnabled) headers = this.sortColumns(headers)
        return headers
      },
      set(values) {
        return values
      }
    },
    dataLoading() {
      return this.isDataLoading && this.showLoading
    },
    metaLoading() {
      return this.isMetaLoading && this.showLoading
    },
    parsedFilter() {
      if (this.$route && this.$route.query) {
        const filter = qs.parse(this.$route.query) || {}
        this.$emit('handle-parsed-filter', filter.filter)
        return filter.filter
      }

      return null
    },
    hasFilters() {
      return !!this.parsedFilter
    },
    injectFilter() {
      if (!this.parsedFilter || !this.searchFilters) return {}
      const filters = Object.keys(this.parsedFilter).reduce((filters, key) => {
        filters[key] = {
          value: this.parsedFilter[key],
          name: key
        }

        const item = this.searchFilters.find((item) => item.name === key)
        if (item) {
          filters[key].label = item.label
        }

        return filters
      }, {})

      // NOTE: this is a patch, as feature, to mitigate quirky query param behavior. Essentially we do not want to
      // add tags to the search input, that have been defined in the search filter list
      if (typeOf(this.pruneSearchFilters) === 'boolean') {
        return filterObject(
          filters,
          (key, value) => !!this.searchFilters.find((item) => item.name === key)
        )
      }

      if (typeOf(this.pruneSearchFilters) === 'function') {
        return this.pruneSearchFilters(filters)
      }

      return filters
    },
    isSearch() {
      return this.hasFilters && !!this.parsedFilter.q && this.fuzzySearch
    },
    doMetaCheck() {
      // TODO: fix the meta check determination once it's impact is evaluated
      if (this.forceMetaCheck === true) return true

      return this.noMetaCheck !== true && this.hasFilters !== true
    },
    showNoData() {
      if (this.blockNoData === true) return false
      return !this.localData.length && !this.isDataLoading
    },
    _showSummary() {
      if (typeOf(this.showSummary) === 'boolean') return this.showSummary

      if (Array.isArray(this._summary)) return true
      if (this.$slots['custom-summary']) return true

      return false
    },
    _summaryHeaders() {
      return (this.summaryHeaders || this.filterHeaders).map((item) => ({
        ...item,
        sortable: false,
        sortType: undefined
      }))
    },
    _summary() {
      if (this.transformSummaryData)
        return this.transformSummaryData(this.summary || this.localSummary)
      return this.summary || this.localSummary
    },
    _showTotalCount() {
      return !!this.showTotalCount && Number.isFinite(this.overallCount)
    },
    hasAfterTable() {
      return this.$slots['after-table']
    },
    routeBaseDefault() {
      return this.routeBase || `/${this.resource || this.customResource}`
    }
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      this.elementLocale = this.checkLocale(newLocale)
    }
  },
  mounted() {
    this.elementLocale = this.checkLocale(this.$i18n?.locale)
    if (this.doFetch) {
      this.fetch()
    }
  },
  methods: {
    /**
     * Simultaneously fetch the total count and first page of a resource's data.
     * Fetching requires a uniform layout of the Tillhub SDK, hence the availability of a resource as an instance
     * class and that having a .meta and .getAll call that behavior exactly the same across pageable resources. That
     * is also that a second API page will be returned as next cursor from the SDK.
     *
     * This function propagates some usage mistake as exceptions. It also handles some unexpected errors based on it's surroundings, e.g.
     * throwing if no error listener is attached.
     */
    async fetch() {
      try {
        let resourceOptions = this.getResourceOptions()
        if (isEmpty(resourceOptions)) resourceOptions = undefined

        const actions = [this.fetchDataWrapper(resourceOptions)]

        if (this.doMetaCheck) {
          actions.push(this.fetchMetaWrapper(resourceOptions))
        }

        this.$emit('loading')
        let [
          { data, metaData: embeddedMetaData },
          { data: metaData } = {}
        ] = await makeAsyncCall(() => pAll(actions), this.retryOptions)

        // as implementation convenience we are allowing not to make any meta
        // call. This is especially useful for resources that are potentially very small in count, e.g. branches
        // and likely also have no advanced query-ability, e.g. querying for timestamp, type, owner etc.
        //
        // In those cases we overriding the metaData prop from above with the incoming data length.
        //
        // For some resources we are embedding metadata directly into the response in order not to require an external
        // metadata call. In those cases we want to likely always override the metadata object.
        if (embeddedMetaData) {
          metaData = embeddedMetaData
        } else if (this.doMetaCheck !== true && this.isSearch) {
          // TODO: meta for search should be handled from search data handler or only through local data
          metaData = {
            count: (data.starts_with || data.search).length
          }
        } else if (this.doMetaCheck !== true) {
          metaData = {
            count: data.length
          }
        }

        // handle meta will in any case handle display data such as the total count. That function, will have more logic
        // than meets the eye. E.g. in cases where we do not make a meta call and also are transforming the incoming data (see above)
        // the total count needs to be correct
        this.handleMeta(metaData)
        this.setSortByQuery()
        this.$emit('loaded', data)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isMetaLoading = false
        // Get the page number and apply it
        this.loadPageNumber()
      }
    },

    fetchDataWrapper(resourceOptions) {
      const inst = this.getResourceInstance()
      if (typeOf(inst[this.getHandlerKey]) !== 'function') {
        throw new TypeError(
          `th-datatable: component requires SDK .${this.getHandlerKey} function`
        )
      }

      let action
      if (this.isSearch) {
        const searchQuery =
          typeOf(this.extendSearch) === 'function'
            ? this.extendSearch(this.parsedFilter)
            : this.parsedFilter.q

        action = () => inst.search(searchQuery)
      } else if (this.customFetchId) {
        action = () =>
          inst[this.getHandlerKey](this.customFetchId, resourceOptions)
      } else {
        action = () => inst[this.getHandlerKey](resourceOptions)
      }

      return async () => {
        this.isDataLoading = true
        let { data, next, summary, metaData } = await action()

        //Handle next
        if (next && typeOf(next) === 'function') {
          this.next = next
        }

        //Handle summary
        if (Array.isArray(summary)) {
          this.localSummary = summary
        }

        //Handle data
        this.localData = await this.handleIncomingData(data)

        if (this.isOffsetPaginationSupported) {
          this.page = this.localData
        } else {
          this.handlePage()
        }

        this.isDataLoading = false
        return { data, metaData }
      }
    },

    fetchMetaWrapper(resourceOptions) {
      const instMeta =
        this.getMetaResourceInstance() || this.getResourceInstance()

      if (this.noMetaCheck !== true && typeOf(instMeta.meta) !== 'function') {
        throw new TypeError(
          'th-datatable: component requires SDK .meta function'
        )
      }

      let metaQuery = this.metaOptions || omit(resourceOptions.query, 'limit')
      if (metaQuery && Object.keys(metaQuery).length && !metaQuery.query) {
        metaQuery = { query: metaQuery }
      }

      return async () => {
        this.isMetaLoading = true
        let { data } = this.customFetchId
          ? await instMeta.meta(this.customFetchId, metaQuery)
          : await instMeta.meta(metaQuery)
        this.isMetaLoading = false

        return { data }
      }
    },

    getRowClassName({ row }) {
      return this.expandRowIf(row) ? '' : 'row-expand-cover'
    },

    getSummaryHeadersClassName(header) {
      return [
        'flex',
        'items-center',
        {
          'justify-end': header.align === 'right',
          'justify-center': header.align === 'center',
          'justify-start': header.align === 'left' || !header.align
        }
      ]
    },

    getResourceOptions() {
      const resourceOptions = {
        limit: this.localResourceLimit,
        offset: this.pageOffset,
        ...this.resourceQuery
      }

      // Adding sorting to the resources
      const { filter, order } = this.getSortFilter()
      if (filter) {
        resourceOptions['orderFields[]'] = this.getSignAscDesc(order) + filter
      }

      if (this.parsedFilter) {
        let query = {}

        Object.keys(this.parsedFilter).forEach((k) => {
          const filter = this.searchFilters.find((f) => f.name === k)

          if (
            filter &&
            filter.modifyFilter &&
            typeOf(filter.modifyFilter) === 'function'
          ) {
            query = {
              ...query,
              ...filter.modifyFilter(this.parsedFilter[k])
            }
          } else {
            query[k] = this.parsedFilter[k]
          }
        })

        // NOTE: this is intended to fix a frontend quirk, where the frontend might set filters that do not
        // make sense for the backend. This fix is being called below and is mainly to avoid bad UI. However,
        // for consistency we are chopping of superfluous params for the backend as well.
        if (typeOf(this.pruneSearchFilters) === 'boolean') {
          query = filterObject(
            query,
            (key, value) =>
              !!this.searchFilters.find((item) => {
                if (item.name === key) return true
                if (item.prop && item.prop === key) return true
                if (Array.isArray(item.prop) && item.prop.includes(key))
                  return true
                return false
              })
          )
        }

        if (typeOf(this.pruneSearchFilters) === 'function') {
          query = this.pruneSearchFilters(query)
        }

        safeSet(resourceOptions, 'query', {
          limit: this.localResourceLimit,
          ...resourceOptions.query,
          ...query
        })
      }

      return resourceOptions
    },

    async handleIncomingData(data) {
      const _data = this.isSearch ? this.handleSearchData(data) : data
      return this.transformFetchedData
        ? await this.transformFetchedData(_data)
        : _data
    },

    handleSearchData(data) {
      return data.starts_with || data.search
    },

    /**
     * handle an available next cursor. If a user jumps to any arbitrary page, we need to page until that data
     * becomes available
     * @param {Number} bound defines when to stop fetch recursively
     */
    async handleNext(bound) {
      if (!bound)
        throw new TypeError('th-datatable: no bound for paging call is defined')
      if (!this.next)
        throw new TypeError(
          'th-datatable: no next cursor is available for paging'
        )

      return pWhilst(
        () => {
          return this.localData.length <= bound && this.next
        },
        async () => {
          const { data, next } = await this.next()
          // just to make sure we gonna halt execution if there is no new cursor
          if (!next) {
            this.next = null
          } else {
            this.next = next
          }

          this.localData = [
            ...this.localData,
            ...(this.transformFetchedData
              ? await this.transformFetchedData(data)
              : data)
          ]
        }
      )
    },
    /**
     * let the caller handle deletion events
     */
    deleteRow(index) {
      this.$emit('delete-requested', this.page[index])
    },
    /**
     * the caller will be notified about edit requests, which usually
     * will have routing to an edit page as a consequence. Since this is
     * common we offer a convenience options to route based on the resource
     */
    edit(index) {
      if (!this.page[index]) return
      this.$emit('edit-requested', this.page[index], this.page[index].id)

      if (this.doRoute && this.page[index].id) {
        this.goToEdit(this.page[index].id)
      }
    },
    /**
     * handle go to edit
     */
    goToEdit(id) {
      if (this.customEditPath) {
        this.$router.push(`${this.customEditPath}/${id}`)
      } else {
        this.$router.push(`${this.routeBaseDefault}/${id}`)
      }
    },
    /**
     *
     * apply a user defined formatter. If none is defined we expect the passed formatter to be the field value.
     * TODO: review.
     */
    extendedFormatter(item, formatter, field, index) {
      if (formatter) {
        return (row, col) => {
          const value = formatter(row, col)
          if (value === null || value === undefined) {
            return item.fallback || this.defaultFallback
          } else {
            return value
          }
        }
      }
      return (row, col) => {
        if (item.fallback && !row[field] && !Number.isFinite(row[field])) {
          return item.fallback
        }
        return row[field]
      }
    },
    /**
     *
     * will be emitted from the pagination component. Any size change will cause the table to return to page 1
     * @param {Number} val page size that was defined in the page size options
     */
    handleSizeChange(val) {
      this.localPageSize = val
      this.handlePage()
      this.currentPage = 1
      // order of execution might be important here, as we allow outside pageSize changes
      this.$emit('size-changed', val)
    },
    /**
     *
     * any page control will trigger this event, e.g. a paging number or a goto input
     * @param val page index
     */
    handleCurrentChange(val) {
      this.$emit('will-handle-page-change', val)
      if (this.isOffsetPaginationSupported) {
        this.fetch()
      } else {
        this.handlePage()
      }
      this.applyPageNumberChange(val)
      this.$emit('current-page-changed', val)
    },
    applyPageNumberChange(pageNumber) {
      const query = this.$route.query || {}
      query.page = pageNumber
      const params = new URLSearchParams(query).toString()
      window.history.pushState({}, null, this.$route.path + '?' + params)
      this.$store.dispatch('Config/setLastUrlWithPageNumber', {
        path: this.$route.path,
        pageNumber
      })
    },
    loadPageNumber() {
      if (parseInt(this.$route?.query?.page)) {
        this.currentPage = parseInt(this.$route?.query?.page)
        this.applyPageNumberChange(this.currentPage)
      }
      this.handlePage()
    },
    /**
     *
     * Slice local data based on current page and the page size. This function also handles boundaries and behaviors
     * when one is overstepped.
     */
    async handlePage() {
      const notifyPayload = {
        currentPage: this.currentPage,
        pageSize: this.localPageSize
      }
      this.$emit('will-handle-page', notifyPayload)

      if (
        this.currentPage * this.localPageSize >= this.localData.length &&
        this.next
      ) {
        try {
          this.isDataLoading = true
          await this.handleNext(this.currentPage * this.localPageSize)
        } catch (err) {
          this.handleError(err)
        } finally {
          this.isDataLoading = false
        }
      }

      // allow transforming data, as we put it into the table
      let localTransform
      // NOTE: if this function affects the number of rows, pagination will be off
      // in that case rather use transformFetchedData()
      if (this.transformTableData) {
        localTransform = this.transformTableData
      } else {
        localTransform = function localTransform(data) {
          return data
        }
      }

      // slice from start of page
      this.page = localTransform(
        this.localData.slice(
          (this.currentPage - 1) * this.localPageSize,
          this.currentPage * this.localPageSize
        )
      )

      this.$emit('page-handled', notifyPayload)
    },
    /**
     *
     * Handle incoming metadata by setting total items etc.
     * @param {Object} metadata the result of a TH SDK .meta call
     */
    handleMeta(metadata) {
      // we assume that implementers transforming fetched data cannot have any knowledge about the pagination
      // logic. Hence we brace in here.
      // However we gained to possiblity to do so via a new flag. see TODO above. TODO: remove this logic if possible
      if (this.transformFetchedData && !this.transformFetchedMetaAllowed) {
        // local data has been set before. Note: that previously that was not the case
        this.totalCount = this.localData.length
        this.$emit('handled-count', this.totalCount)
        return
      }

      if (
        !metadata ||
        (metadata.count && !Number.isFinite(Number(metadata.count))) ||
        (metadata[0]?.count && !Number.isFinite(Number(metadata[0].count)))
      ) {
        throw new TypeError('th-datatable: metadata did not retrieve count')
      }

      // protect against the API returning a string for the count
      this.totalCount = !Array.isArray(metadata.count)
        ? Number(metadata.count)
        : Number(metadata[0].count)
      // mind the wording here. This is due to us badly naming .totalCount before
      this.overallCount = Number.isFinite(Number(metadata.total_count))
        ? Number(metadata.total_count)
        : undefined
      this.$emit('handled-count', this.totalCount)
    },

    clear() {
      this.currentPage = 1
      this.page = []
      this.totalCount = 0
      this.localData = []
      this.selectedItems = []
      this.next = null
    },

    /**
     * Refresh the data, triggering re-render if data is available
     *
     */
    refresh() {
      this.$nextTick(() => {
        this.clear()
        this.fetch()
      })
    },
    /**
     * Emit an error to the caller if a listener is set. Otherwise throw.
     *
     * @param {Error} err upstream error
     */
    handleError(err) {
      this.$logException(err, { trackError: false })
      this.$emit('loading-error', err)
    },
    /**
     * Simple row click handler. Routes if doRoute is set.
     */
    handleRowClick(row, column, event) {
      this.$emit('row-click', row, column, event)

      if (
        this.doRoute &&
        !this.expandingRow &&
        row.id &&
        !this.isRowMenu(column, event)
      ) {
        this.goToEdit(row.id)
      }
      if (this.expandingRow && !this.doRoute && this.expandRowIf(row)) {
        this.$refs.datatable.toggleRowExpansion(row)
      }
    },
    /**
     * Simple row double click handler. Routes if doRoute is set or routing on double click is specifically requested.
     *
     * Might not be fired when click triggers first and has an action that will choke the second click.
     * @param {Object} row EltableRowData
     * @param {Object} column EltableColumnData
     * @param {Object} event EltableColumnEvent
     */
    handleRowDblClick(row, column, event) {
      this.$emit('row-dblclick', row, column, event)

      // we allow overriding routing when the user specifically requests it. Otherwise determine via doRoute
      if (
        (this.doRouteDblClick === true || this.doRoute) &&
        row.id &&
        !this.isRowMenu(column, event)
      ) {
        this.goToEdit(row.id)
      }
    },
    /**
     * Row click handler for the types of row clicks can be triggered.
     * Based on the doSlowerRowDblClick prop it will fire a debounced click handler or the regular row click handlers (handleRowClick and handleRowDblClick).
     *
     * @param {Object} eventType ElTableRow event type names, e.g. row-click, row-dblclick
     * @param {Object} row EltableRowData
     * @param {Object} column EltableColumnData
     * @param {Object} event EltableColumnEvent
     */
    handleRowClickTypes(eventType, ...eventArgs) {
      if (eventType === 'row-click') {
        if (this.doSlowerRowDblClick)
          return this.debouncedHandleClick(...eventArgs)
        return this.handleRowClick(...eventArgs)
      }

      if (eventType === 'row-dblclick') {
        // in case that doSlowerRowDblClick is enabled we don't need to do anything for row-dblclick event, debouncedHandleClick will take care of double clicks.
        if (this.doSlowerRowDblClick) return
        return this.handleRowDblClick(...eventArgs)
      }
    },
    /**
     * Debouncer function that tracks the number of clicks in a specified timeframe and will fire the desired click handler based on the amount of clicks.
     */
    debouncedHandleClick: debounceClicks(function (clicks, eventArgs) {
      if (clicks === 1) this.handleRowClick(...eventArgs)
      else if (clicks === 2) this.handleRowDblClick(...eventArgs)
    }, 200),
    /**
     * Emit selection change to the implementor
     * @param {Object} val value of selected item
     */
    handleSelectionChange(val) {
      this.selectedItems = val
      this.$emit('selection-change', val)
    },
    /**
     * Emit selection to the implementor when user triggers select all checkbox
     * @param {Object} val value of selected items
     */
    handleSelectAll(val) {
      this.$emit('select-all', val)
    },
    changeTotalCount(count) {
      this.totalCount = this.totalCount + count
    },
    async updateHeadersConfig(activeHeaders) {
      const activeFields = activeHeaders.map((h) => h.field)
      const config = {}

      this.headers.forEach((header) => {
        config[header.field] = activeFields.includes(header.field)
      })

      // In case of having the order of the columns enabled
      // we need to store in the client configuration the name of the fields that are shown and hidden
      if (this.columnsOrderEnabled) {
        const shown = []
        const hidden = []
        Object.keys(config).forEach((key) => {
          config[key] ? shown.push(key) : hidden.push(key)
        })

        this.$store.dispatch('Config/setTableColumnOrder', {
          tableName: this.tableIdentifier,
          shownColumns: shown,
          hiddenColumns: hidden
        })
        await this.$store.dispatch('Config/saveClientAccountConfiguration')
      }

      this.$emit('headers-config', config)
    },
    handleSearchFilterInput(v) {
      this.$emit('search-filter', v)
    },
    handleSearchFilterSubmit(v) {
      this.$emit('search-filter-submit', v)

      if (this.doRouteFilters) {
        const route = this.$makeFilteredPath(
          this.routeBaseDefault,
          mapValues(v, (value) => {
            if (value === null || value === undefined || value.value === null)
              return undefined
            return value.value
          })
        )

        this.$nextTick(() => {
          this.$emit('search-filter-submit-route', route)
          this.$router.push(route)
        })
      }
    },
    toggleShow() {
      this.show = !this.show
    },
    getResourceInstance() {
      if (this.customResource) {
        return this.customResource
      } else {
        return th[this.resource]()
      }
    },
    getMetaResourceInstance() {
      return this.metaResource ? th[this.metaResource]() : null
    },
    sortChange(info) {
      if (!info?.prop) return
      const { filter, order } = this.getSortFilter()
      if (
        filter &&
        info.prop === filter &&
        info.order === order &&
        this.sortType !== 'remote'
      )
        return
      if (this.sortType === 'remote') {
        // remote
        const query = {
          ...this.$route.query
        }
        if (info.order) {
          // Set the order, with + for ASC and - for DESC
          query['orderFields[]'] = [this.getSignAscDesc(info.order) + info.prop]
        } else {
          // Clean filters if order is null
          delete query['orderFields[]']
        }
        this.$router.replace({
          query
        })
      } else {
        this.localData = [...this.localData].sort((a, b) => {
          const column =
            this.headers.find((col) => col.field === info.prop) || {}
          const sortType = column.sortType
          const fallback = column.fallback || this.defaultFallback
          return compareRow(a, b, info, sortType, fallback)
        })
        this.handlePage()
      }
    },
    getSortFilter() {
      let filter = null
      if (this.$route?.query) {
        filter = qs.parse(this.$route.query)
        filter = filter?.orderFields
      }
      if (!filter) return {}
      filter = Array.isArray(filter) ? filter[0] : filter
      return {
        filter: filter.substring(1),
        order: this.getAscDescFromSign(filter)
      }
    },
    getAscDescFromSign(filter) {
      return filter[0] === '+' ? 'ascending' : 'descending'
    },
    getSignAscDesc(order) {
      return order === 'ascending' ? '+' : '-'
    },
    setSortByQuery() {
      const { filter, order } = this.getSortFilter()
      if (filter) this.$refs.datatable.sort(filter, order)
    },
    isRowMenu(column, event) {
      const classNames = safeGet(event, ['target', 'className']) || ''

      return (
        (column && column.property === 'row-menu') ||
        classNames.includes('menu-text') ||
        classNames.includes('menu-item') ||
        classNames.includes('menu-container')
      )
    },

    _doExpandRow(row) {
      if (typeOf(this.expandingRow) === 'boolean') return this.expandingRow
      if (typeOf(this.expandingRow) === 'function')
        return this.expandingRow(row)
      return undefined
    },

    getLabel(path, fallback) {
      if (!this.labels) return undefined

      const l = safeGet(this.labels, path)

      if (l === undefined || l === null) return fallback || undefined

      return l
    },
    trackExpandedRows(touchedRow, expandedRows) {
      this.expandedRows = expandedRows.map((row) => {
        if (typeof this.rowKey === 'function') {
          return this.rowKey(row)
        }
        if (typeof this.rowKey === 'string') {
          return row[this.rowKey]
        }
        return row.id
      })
    },
    handleExpandCollapseAllRows() {
      const isExpanded = !!this.expandedRows.length
      this.expandedRows = !isExpanded
        ? this.page.map((row) => {
            if (this.expandRowIf && !this.expandRowIf(row)) return
            if (typeof this.rowKey === 'function') {
              return this.rowKey(row)
            }
            if (typeof this.rowKey === 'string') {
              return row[this.rowKey]
            }
            return row.id
          })
        : []
    },
    handleUpdateFilter(filter) {
      if (filter && !filter.originalData) return
      this.filtersData[filter.name] = filter.originalData
    },
    sortColumns(headers) {
      let columnsOrder = null
      if (this.tableColumnOrder)
        columnsOrder = this.tableColumnOrder[this.tableIdentifier]?.shown

      // If we don't have an order stored, we return the default headers
      if (!columnsOrder) return headers

      // Order the stored columns in the correct order
      const orderedHeaders = []
      columnsOrder.forEach((column) => {
        const col = headers.find(
          (headerColumn) => column === headerColumn.field
        )
        if (col) orderedHeaders.push(col)
      })
      return orderedHeaders.length ? orderedHeaders : headers
    },
    checkLocale(locale = '') {
      if (locale.includes('de')) {
        return deLocale
      } else if (locale.includes('es')) {
        return esLocale
      } else if (locale.includes('it')) {
        return itLocale
      } else if (locale.includes('cs')) {
        return czLocale
      } else {
        return enLocale
      }
    }
  }
}

export function extendedFormatter(item, formatter, field, index) {
  if (formatter) {
    return (row, col) => {
      const value = formatter(row, col)
      if (value === null || value === undefined) {
        return item.fallback || this.defaultFallback
      } else {
        return value
      }
    }
  }
  return (row, col) => {
    if (item.fallback && !row[field] && !Number.isFinite(row[field])) {
      return item.fallback
    }
    return row[field]
  }
}
</script>

<style scoped>
.datatable-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  color: var(--text-default-color);
}

.datatable-wrapper > .content {
  height: 100%;
}

.content {
  flex: 1;
  overflow: hidden;
}

.table-wrapper > * {
  margin-left: auto;
  margin-right: auto;
}

.tools {
  display: flex;
}

.footer {
  height: 60px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  box-shadow: var(--bottom-box-shadow);
}

.datatable-wrapper :deep(.el-button-group) {
  display: flex;
}

.datatable-wrapper :deep(.el-button-group .el-button),
.table-actions {
  height: var(--action-button-height);
  display: flex;
  align-items: center;
}

.el-table :deep(.el-table__expanded-cell) {
  background: #ffffff;
  border-left: 3px solid var(--primary-color);
}

.el-table :deep(.el-table__expanded-cell:hover) {
  background: #f5f7fa !important;
}

.el-table :deep(.el-table__row.expanded td:first-child) {
  border-left: 3px solid var(--primary-color);
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 0;
}

.el-table :deep(th .cell) {
  white-space: nowrap;
  color: var(--el-table-text-color-deep);
  letter-spacing: var(--letter-spacing-table);
}

.el-table :deep(td .cell) {
  font-size: var(--el-table-text-sm);
  color: var(--el-table-text-color-deep);
  letter-spacing: var(--letter-spacing-table);
  min-height: 22px;
}

.el-table__body-wrapper {
  overflow: auto;
}

.actions-post-wrapper {
  margin-left: 50px;
}

.no-data-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer :deep(.el-table__row) {
  cursor: pointer;
}

.table-actual-wrapper,
.summary-actual-wrapper {
  margin: 0;
  padding: 0;
  border: 0;
}

.summary-actual-wrapper {
  height: auto !important;
}

.summary-label {
  width: 100%;
  text-align: center;
}

.table-feature-holder {
  flex-direction: column;
}

.summary-row,
.summary-row .summary-table,
.summary-row .summary-table :deep(*) {
  background-color: var(--background-color) !important;
}

.summary-table :deep(th) {
  margin: 0;
  padding: 0;
  border-bottom: unset;
}

.page-location > * {
  margin-right: 0.3rem;
}

.datatable-wrapper :deep(.row-expand-cover .el-table__expand-icon) {
  visibility: hidden;
}

.datatable-wrapper :deep(.row-expand-cover) {
  cursor: default;
}

.datatable-search-filter :deep(.filter-container) {
  max-height: 60vh;
  overflow-y: scroll;
}

.multiple-select-limit :deep(thead .el-checkbox__inner) {
  display: none;
}

@media only screen and (max-width: 800px) {
  .datatable-search-filter :deep(.search-input-container) {
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .datatable-search-filter :deep(.search-input-container) {
    width: 100px;
  }

  .footer {
    overflow-x: scroll;
  }
}
</style>
