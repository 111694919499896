<template>
  <div id="app">
    <template v-if="authenticated">
      <el-container>
        <sidebar />
        <el-container class="main-wrapper">
          <el-header
            :class="{ 'z-10': isWhiteLabel }"
            :height="isWhiteLabel ? '60px' : '65px'"
          >
            <header-nav />
          </el-header>
          <el-main><router-view v-if="!isLoading" /></el-main>
        </el-container>
      </el-container>
    </template>
    <template v-else>
      <router-view />
    </template>
    <div id="th-modals" />
    <div v-if="showLoadingUnifiedCommerce" id="th-loading">
      <div id="th-spinner" />
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import HeaderNav from './components/nav/header.vue'
import Sidebar from './components/sidebar/index.vue'
import { isUnifiedCommerce } from '@/constants'
import { mapState, mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'
import { userPilotIdentify } from '@/user-pilot'
import {
  hidden,
  visibilityChange,
  languageISO,
  gastroLocaleSuffix
} from './constants'
import { useWhitelabelThemeStore } from './store/whitelabel-theme'
import { useMessagesStore } from './store/messages'

export default {
  name: 'App',
  components: {
    HeaderNav,
    Sidebar
  },
  data: () => {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      isGastro: 'Auth/isGastro',
      locale: 'Config/getLocale',
      user: 'Auth/getUser'
    }),
    ...mapState('Auth', ['authenticated']),
    isWhiteLabel() {
      return isUnifiedCommerce()
    },
    showLoadingUnifiedCommerce() {
      return this.isWhiteLabel && this.isLoading
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('Auth/waitThInitializated')
    this.$store.dispatch('Config/getClientAccountConfiguration')
    const messagesStore = useMessagesStore()
    messagesStore.clearLocalOperations()
    messagesStore.clearStaleMessages()
    const themeStore = useWhitelabelThemeStore()
    themeStore.fetchDNSConfig()
    await this.makeMeCall() // Fetch me data
    this.isLoading = false

    //Assign an identity for user pilot
    userPilotIdentify(this.user, {
      isGastro: this.isGastro,
      isGastroWithOutOrderPro:
        this.isGastro &&
        !safeGet(
          this.clientAccountConfiguration,
          'gastro.orders.remote_access.enabled',
          false
        ),
      locale: this.locale,
      datev: this.$isFeatureEnabled('datev'),
      cfd: this.$isFeatureEnabled('digital_receipts'),
      merchant: safeGet(
        this.clientAccountConfiguration,
        'tenant.company_name',
        ''
      )
    })
    this.maybeChangeLocale()

    document.addEventListener(
      visibilityChange,
      (v) => this.handleVisibilityChange(v),
      false
    )
  },
  mounted() {
    window.scrollTo(0, 1)
    this.adjustMetaTags()
  },
  methods: {
    maybeChangeLocale(locale = this.locale) {
      let newLocale = String(locale)
      const l = newLocale || this.$i18n.locale
      if (
        this.isGastro &&
        (l === languageISO.ENGLISH || l === languageISO.GERMAN)
      ) {
        newLocale = l + gastroLocaleSuffix
      }

      if (!newLocale) return // Skip no language
      if (this.$i18n.locale === newLocale) return // Skip same language

      const isSupported = this.$i18n.availableLocales.includes(newLocale)
      if (!isSupported) return

      this.$i18n.locale = newLocale
      this.$log.debug('Language:', this.$i18n.locale)
      Sentry.configureScope((scope) => {
        scope.setTag('page_locale', this.$i18n.locale)
      })
    },
    handleVisibilityChange(e) {
      if (document[hidden] === false) {
        this.makeMeCall()
      }
    },
    async makeMeCall() {
      return this.$store.dispatch('Auth/getMeData', { vm: this })
    },
    adjustMetaTags() {
      const name = this.isWhiteLabel ? 'Unzer Dashboard' : 'Tillhub Dashboard'
      const iconsLink = this.isWhiteLabel
        ? 'public'
        : 'https://storage.googleapis.com/tillhub-api-images/dashboard/logos'

      const iconSizes = [
        '57x57',
        '60x60',
        '72x72',
        '76x76',
        '114x114',
        '120x120',
        '144x144',
        '152x152',
        '180x180'
      ]

      const faviconSizes = ['16x16', '32x32', '96x96']
      const descriptionName = 'Management Dashboard for the ' + name + ' POS'
      const AndroidRequiredIcon = this.isWhiteLabel
        ? require('@/assets/icons/meta-icons/android-icon-192x192.png')
        : iconsLink + '/icons/android-icon-192x192.png'

      const url = this.isWhiteLabel
        ? 'https://dashboard.unzer.com'
        : 'https://dashboard.tillhub.com'

      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta name="description" content="' +
          name +
          ' - The management platform for your POS system." />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta property="og:title" content="' + name + '" />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta property="og:description" content="' + descriptionName + '" />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta property="og:url" content="' + url + '" />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta property="twitter:title" content="' + name + '" />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta property="twitter:description" content="' +
          descriptionName +
          '" />'
      )
      iconSizes.forEach((size) => {
        const requiredIcon = this.isWhiteLabel
          ? require('@/assets/icons/meta-icons/apple-icon-' + size + '.png')
          : iconsLink + '/icons/apple-icon-' + size + '.png'

        document.head.insertAdjacentHTML(
          'beforeEnd',
          '<link rel="apple-touch-icon" type="image/png" sizes="' +
            size +
            '" href="' +
            requiredIcon +
            '" />'
        )
      })
      faviconSizes.forEach((size) => {
        const requiredIcon = this.isWhiteLabel
          ? require('@/assets/icons/meta-icons/favicon-' + size + '.png')
          : iconsLink + '/icons/favicon-' + size + '.png'
        document.head.insertAdjacentHTML(
          'beforeEnd',
          '<link rel="icon" type="image/png" sizes="' +
            size +
            '" href="' +
            requiredIcon +
            '" />'
        )
      })
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta rel="icon" type="image/png" sizes="192x192" href="' +
          AndroidRequiredIcon +
          '" />'
      )
      document.head.insertAdjacentHTML(
        'beforeEnd',
        '<meta name="msapplication-TileImage" content="' +
          iconsLink +
          '/icons/ms-icon-144x144.png" />'
      )

      if (!this.isWhiteLabel) {
        const favicon = document.getElementById('favicon')
        favicon.href =
          'https://storage.googleapis.com/tillhub-api-images/dashboard/logos/icons/favicon.ico'
      }
    }
  }
}
</script>
