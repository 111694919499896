<template>
  <div>
    <el-button :type="buttonType" @click="openExportDocumentModal">
      <svgicon
        :src="require('@/assets/icons/th-icon-download.svg')"
        :style="{ height: '20px', width: '20px' }"
        class="mr-1 fill-current"
      />
      <span>{{ $t('common.components.th_datatable.export') }} </span>
    </el-button>
    <document-export-modal
      :filters="filters"
      :resource-options="resourceOptions"
      :filename-prefix="filenamePrefix"
      :document-type="documentType"
      :headers="headers"
      :wrap-query-in-object="wrapQueryInObject"
      :header-name-to-export-column-map="headerNameToExportColumnMap"
      :force-all-columns="forceAllColumns"
      :single-export-handler="handleExport"
    />
  </div>
</template>
<script setup>
import { defineProps, inject } from 'vue'
import DocumentExportModal from './components/document-export-modal.vue'

const props = defineProps({
  filters: {
    type: Array,
    default: () => []
  },
  resourceOptions: {
    type: Object,
    default: () => ({})
  },
  filenamePrefix: {
    type: String,
    default: ''
  },
  wrapQueryInObject: {
    type: Boolean,
    default: false
  },
  headers: {
    type: Array,
    default: () => []
  },
  headerNameToExportColumnMap: {
    type: Object,
    default: () => ({})
  },
  forceAllColumns: {
    type: Boolean,
    default: false
  },
  documentType: {
    type: String,
    required: true
  },
  handleExport: {
    type: Function,
    default: () => {}
  },
  buttonType: {
    type: String,
    default: 'primary'
  }
})

const thModal = inject('thModal')

function openExportDocumentModal() {
  thModal.show('documentExport')
}
</script>
