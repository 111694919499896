import safeGet from 'just-safe-get'

export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  return [
    {
      value: '/reports/financial_accounting/transactions',
      key: 'nav.main.items.financial_accounting.transactions',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.transactions'
      ),
      scopes: ['reports_financial_accounting:transactions:read']
    },
    {
      value: '/reports/financial_accounting/balances',
      key: 'nav.main.items.financial_accounting.balances',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.balances'
      ),
      scopes: ['reports_financial_accounting:balances:read']
    },
    {
      value: '/reports/financial_accounting/cash_book',
      key: 'nav.main.items.financial_accounting.cash_book',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.cash_book'
      ),
      scopes: ['reports_financial_accounting:cash_book:read']
    },
    {
      value: '/reports/financial_accounting/returns',
      key: 'nav.main.items.financial_accounting.returns',
      enabled: !!safeGet(featureConfig, 'reports.financial_accounting.returns'),
      scopes: ['reports_financial_accounting:returns:read']
    },
    {
      value: '/reports/financial_accounting/payment_options',
      key: 'nav.main.items.financial_accounting.payment_options',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.payment_options'
      ),
      scopes: ['reports_financial_accounting:payment_options:read']
    },
    {
      value: '/reports/financial_accounting/payments',
      key: 'nav.main.items.financial_accounting.payments',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.payments'
      ),
      scopes: ['reports_financial_accounting:payments:read']
    },
    {
      value: '/reports/financial_accounting/vat',
      key: 'nav.main.items.financial_accounting.vat',
      enabled: !!safeGet(featureConfig, 'reports.financial_accounting.vat'),
      scopes: ['reports_financial_accounting:vat:read']
    },
    {
      value: '/reports/financial_accounting/counting_protocols',
      key: 'nav.main.items.accounting.counting_protocols',
      enabled: !!safeGet(
        featureConfig,
        'reports.financial_accounting.counting_protocols'
      ),
      scopes: ['reports_financial_accounting:counting_protocols:read']
    }
  ]
}
