<template>
  <el-tabs
    v-model="activeTab"
    class="th-tabs-form-wrapper"
    :class="{
      'has-border': hasBorder,
      'content-overflow-visible': contentOverflowVisible
    }"
    :style="computedStyle"
  >
    <el-tab-pane
      v-for="option in tabs"
      :key="option.name"
      class="tab-pane"
      :label="option.label"
      :name="option.name"
    >
      <div class="tab-content" :style="tabContentComputedStyle">
        <slot :name="option.name" />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  name: 'ThTabsFormWrapper',
  props: {
    modelValue: {
      type: String,
      required: false,
      default: undefined
    },
    initialTab: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Array of objects that will be used to render the correct tabs content (which are passed as slots).
     * Every tab-content/slot related option object must have a "name" (the slot name) and "label" properties.
     */
    tabs: {
      type: Array,
      required: true
    },
    /**
     * Max height of the tab content in case of vertical content overflow.
     */
    tabContentMaxHeight: {
      type: String,
      required: false,
      default: undefined
    },
    tabContentHeight: {
      type: String,
      required: false,
      default: '100%'
    },
    height: {
      type: String,
      required: false,
      default: '100%'
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: true
    },
    contentOverflowVisible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    tabContentComputedStyle() {
      return {
        maxHeight: this.tabContentMaxHeight || undefined,
        height: this.tabContentHeight || undefined
      }
    },
    activeTab: {
      get() {
        return (
          this.modelValue ||
          this.initialTab ||
          safeGet(this.tabs, '0.name') ||
          undefined
        )
      },
      set(v) {
        return this.$emit('update:modelValue', v)
      }
    },
    computedStyle() {
      return {
        height: this.height || undefined
      }
    }
  }
}
</script>

<style scoped>
.tab-pane {
  height: 100%;
}

.th-tabs-form-wrapper :deep(.el-tabs__content) {
  /* 40px is the height of the tab bar. We want to make sure here that we scroll inside the tab pane */
  height: calc(100% - 40px);
}

.th-tabs-form-wrapper.content-overflow-visible :deep(.el-tabs__content) {
  overflow-y: auto;
}

.th-tabs-form-wrapper.has-border :deep(.el-tabs__content) {
  /* 40px is the height of the tab bar. We want to make sure here that we scroll inside the tab pane */

  box-sizing: border-box;
  border-right: 1px;
  border-left: 1px;
  border-bottom: 1px;
  border-top: 0;
  border-color: #dcdfe6;
  border-style: solid;
  border-bottom-right-radius: 5px 5px;
  border-bottom-left-radius: 5px 5px;
}

.th-tabs-form-wrapper :deep(.el-tabs__header) {
  /* 40px is the height of the tab bar. We want to make sure here that we scroll inside the tab pane */
  margin: 0;
}
</style>
