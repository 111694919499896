<template>
  <div class="th-currency-select">
    <el-select
      :id="id"
      :tabindex="tabIndex"
      :model-value="modelValue"
      :placeholder="placeholder"
      :clearable="clearable"
      :class="addClass"
      :disabled="readonly"
      @change="handleInput"
      @clear="handleClear"
    >
      <el-option
        v-for="item in availableCurrencies"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
import { currencies } from '@/constants'

export default {
  name: 'ThCurrencySelect',
  props: {
    modelValue: {
      type: String,
      default: null
    },
    allowedCurrencies: {
      type: Array,
      default: null
    },
    filteredCurrencies: {
      type: Array,
      default: null
    },
    tabIndex: {
      type: [Number, String],
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'Currency'
    },
    addClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    availableCurrencies() {
      return currencies.filter((item) => {
        if (this.filteredCurrencies && this.filteredCurrencies.includes(item))
          return false

        return !this.allowedCurrencies || this.allowedCurrencies.includes(item)
      })
    }
  },
  methods: {
    handleInput(val) {
      this.$emit('update:modelValue', val)
    },
    handleClear() {
      this.$emit('clear')
    }
  }
}
</script>

<style scoped></style>
