import get from 'just-safe-get'
import set from 'just-safe-set'
import { currencies } from '@/constants'

export function disableIdGeneratorsOnEmpty(config) {
  const idGenerators = {
    staff: {
      template: 'staff_id_template',
      generate: 'generate_staff_id'
    },
    products: {
      template: 'product_id_template',
      generate: 'generate_product_id'
    },
    customers: {
      template: 'customer_number_template',
      generate: 'generate_customer_number'
    }
  }

  for (const [key, { template, generate }] of Object.entries(idGenerators)) {
    const isTemplateTruthy = get(config, [key, template])
    if (!isTemplateTruthy) {
      set(config, [key, generate], false)
    }
  }
}

/**
 * This is a helper function that returns the available currency list
 * @param {Array} branchCurrencies list of branch currencies
 * @param {String} defaultCurrency fall back currency
 * @returns {Array}
 */

export function calculateAvailableCurrencies(
  branchCurrencies = [],
  defaultCurrency
) {
  const uniqueCurrencies = new Set([defaultCurrency, ...branchCurrencies])

  return currencies.filter((currency) => uniqueCurrencies.has(currency))
}
