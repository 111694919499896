/**
  AMPLI FILE (src/ampli/index.js) IS AUTOGENERATED.
  DO NOT MODIFY, DO THIS INSTEAD

  1. Install Ampli CLI: `npm install -g @amplitude/ampli`
  2. Go to the project folder
  3. Run `ampli pull`
 */

import { ampli } from '@/ampli'
import * as amplitude from '@amplitude/analytics-browser'
import i18n from '@/i18n'
import router from '@/router'
import safeGet from 'just-safe-get'
import { getNavigation as getFinancialAccountingNavigation } from '@/views/reports/financial-accounting/navigation'
import { getNavigation as getAccountingNavigation } from '@/views/accounting/navigation'
import { getNavigation as getReportsStatisticsNavigation } from '@/views/reports/statistics/navigation'
import { getNavigation as getLoyaltyVouchersNavigation } from '@/views/loyalty/vouchers/navigation'
import { getNavigation as getUtilitiesAuditsNavigation } from '@/views/utilities/audits/navigation'
import { getNavigation as getUtilitiesCmsNavigation } from '@/views/utilities/cms/navigation'
import { getNavigation as getSettingsDashboardNavigation } from '@/views/settings/sections/dashboard/components/navigation'
import { getNavigation as getSettingsPosNavigation } from '@/views/settings/sections/pos/components/navigation'
import { useAppConfigStore } from '@/store/app-config'

const t = i18n.global.t

const accountModeNames = {
  remote: 'Onboarding',
  local: 'Production',
  unset: 'Not set'
}

function getNavigationNameByRoute(navigation, path) {
  const navigationMatchedItem = navigation.findLast((item) =>
    new RegExp(`^${item.route}`).test(path)
  )
  return navigationMatchedItem
    ? navigationMatchedItem.key.map((item) => t(item, 'en')).join(' - ')
    : null
}

export class AmpliPlugin {
  constructor(cli) {
    this.cli = cli
    this.store = null
    this.postponedUser = null
    this.postponedOptOut = null
    this.postponedProperties = []

    const financialAccountingNavigation = getFinancialAccountingNavigation()
    const accountingNavigation = getAccountingNavigation()
    const reportsStatisticsNavigation = getReportsStatisticsNavigation()
    const loyaltyVouchersNavigation = getLoyaltyVouchersNavigation({
      useExternal: true
    }).map((item) => ({ value: item.path, key: item.key }))
    const utilitiesAuditsNavigation = getUtilitiesAuditsNavigation()
    const utilitiesCmsNavigation = getUtilitiesCmsNavigation()
    const settingsDashboardNavigation = getSettingsDashboardNavigation()
    const settingsPosNavigation = getSettingsPosNavigation()

    this.secondaryNavigation = [
      ...financialAccountingNavigation,
      ...accountingNavigation,
      ...reportsStatisticsNavigation,
      ...loyaltyVouchersNavigation,
      ...utilitiesAuditsNavigation,
      ...utilitiesCmsNavigation,
      ...settingsDashboardNavigation,
      ...settingsPosNavigation
    ].map((item) => ({ route: item.value, key: [item.key] }))
  }

  setStore(store) {
    this.store = store
  }

  setUser(user) {
    if (!this.cli.isLoaded) {
      this.postponedUser = user
      return
    }
    if (typeof user !== 'string') {
      throw new Error('setUser expecting user ID')
    }
    this.cli.client.setUserId(user)
  }

  setProperty(name, value) {
    if (!this.cli.isLoaded) {
      this.postponedProperties.push({ name, value })
      return
    }
    const identifyObj = new amplitude.Identify()
    identifyObj.set(name, value)
    this.cli.client.identify(identifyObj)
  }

  event(name, props = {}) {
    if (!this.cli[name]) {
      throw new Error(`No such event "${name}" (ampli)`)
    }
    this.ensurePostponedPropsInit()
    if (!this.cli.isLoaded) {
      this.cli[name](props)
    } else {
      this.debugEvent(name, props)
    }
  }

  eventWithBaseProps(name, props = {}) {
    if (!this.cli[name]) {
      throw new Error(`No such event "${name}" (ampli)`)
    }
    this.ensurePostponedPropsInit()
    const baseProps = this.getBaseEventProperties()
    const combinedProps = {
      ...baseProps,
      ...props
    }
    if (this.cli.isLoaded) {
      this.cli[name](combinedProps)
    } else {
      this.debugEvent(name, combinedProps)
    }
  }

  getBaseEventProperties() {
    const config = this.store.getters['Config/getClientAccountConfiguration']

    const accountMode =
      safeGet(config, 'settings.counting_numbers.preferred_initialization') ||
      'unset'

    const branchLabel = safeGet(
      this.store,
      'state.Config.locationContext.branches.0.label'
    )

    const appConfigStore = useAppConfigStore()
    const navigationFlattened = appConfigStore.navigation.reduce(
      (prev, curr) => {
        prev.push({
          key: [curr.key],
          route: curr.route
        })
        curr.children.forEach((item) => {
          if (item.key && item.route) {
            prev.push({
              key: [curr.key, item.key],
              route: `${curr.route}${item.route || ''}`
            })
          }
        })
        return prev
      },
      []
    )
    const navigationPrimary = getNavigationNameByRoute(
      navigationFlattened,
      router.currentRoute.value.path
    )

    const props = {
      account_mode: accountModeNames[accountMode],
      current_branch: branchLabel,
      current_navigation_primary: navigationPrimary || 'Unknown page'
    }

    const navigationSecondary = getNavigationNameByRoute(
      this.secondaryNavigation,
      router.currentRoute.value.path
    )

    if (navigationSecondary) {
      props.current_navigation_secondary = navigationSecondary
    }
    return props
  }

  ensurePostponedPropsInit() {
    if (!this.cli.isLoaded) {
      return
    }

    if (this.postponedUser) {
      this.cli.client.setUserId(this.postponedUser)
      this.postponedUser = null
    }

    if (this.postponedProperties.length) {
      this.postponedProperties.forEach(({ name, value }) => {
        const identifyObj = new amplitude.Identify()
        identifyObj.set(name, value)
        this.cli.client.identify(identifyObj)
      })
      this.postponedProperties = []
    }

    if (this.postponedOptOut !== null) {
      this.cli.client.setOptOut(this.postponedOptOut)
      this.postponedOptOut = null
    }
  }

  reset() {
    if (!this.cli.isLoaded) {
      return
    }
    this.cli.client.reset()
  }

  setOptOut(value) {
    if (!this.cli.isLoaded) {
      this.postponedOptOut = value
      return
    }
    this.cli.client.setOptOut(value)
  }

  debugEvent(name, props) {
    // eslint-disable-next-line
    console.info('[Amplitude event]', name, props)
  }
}

class AmpliPluginConsentReciever {
  init() {
    if (!window.CookieScript?.instance) {
      window.addEventListener('CookieScriptLoaded', () => {
        this.attemptLoadingAmpli()
      })
    } else {
      this.attemptLoadingAmpli()
    }
  }

  isAmpliAllowed() {
    if (!window.CookieScript?.instance) {
      return false
    }
    const state = window.CookieScript.instance.currentState()
    return state.categories?.includes('targeting')
  }

  attemptLoadingAmpli() {
    if (this.isAmpliAllowed()) {
      this.loadAmpli()
    } else {
      window.addEventListener('CookieScriptAccept', (e) => {
        if (this.isAmpliAllowed()) {
          this.loadAmpli()
        }
      })
    }
  }

  loadAmpli() {
    ampli.load({
      environment: 'default',
      client: {
        configuration: {
          trackingOptions: {
            ipAddress: false // this is important
          }
        }
      }
    })
  }
}

export const ampliPlugin = new AmpliPlugin(ampli)

export default {
  install: (app, { init } = { init: false }) => {
    ampliPlugin.setStore(app.config.globalProperties.$store)
    if (init) {
      const url =
        '//cdn.cookie-script.com/s/a895bc34a48888cee5d82a82ca1c48fe.js'
      const scriptNode = document.createElement('script')
      scriptNode.setAttribute('src', url)
      document.body.appendChild(scriptNode)
      new AmpliPluginConsentReciever().init()
    }
    app.config.globalProperties.$ampli = ampliPlugin
    app.provide('ampli', app.config.globalProperties.$ampli)
  }
}
