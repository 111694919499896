import { createRouter, createWebHistory } from 'vue-router'
import qs from 'qs'
import safeGet from 'just-safe-get'
import { ampliPlugin } from '@/plugins/ampli'

import store from './store'
import { checkPermissions, missingScopes } from './utils/permissions'
import { getChildNameByPermissions } from './utils/navigation'

import Login from './views/auth/login'
import Redirect from './components/redirect'

import { userPilotReload } from '@/user-pilot'
import { useAppConfigStore } from './store/app-config'
import { isKeycloackEnabled } from './constants/index'

const Defaulter = () =>
  import(/* webpackChunkName: "defaulter" */ './components/defaulter')

const CustomDashboardsWrap = () =>
  import(
    /* webpackChunkName: "custom-dashboards" */ './views/utilities/custom-dashboards/components/custom-content'
  )

function requireAuth(to, from, next) {
  if (!store.getters['Auth/isAuthenticated']) {
    // redirect to 401, which then redirects to login
    next('/401')
  } else {
    next()
  }
}

const noMatch = {
  path: '/:catchAll(.*)',
  component: () =>
    import(/* webpackChunkName: "errors_404" */ './views/errors/404')
}

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      redirect: '/home'
    },
    {
      path: '/home',
      beforeEnter(to, from, next) {
        // root is special as we don't want to show 401 error, so we go straight to login
        if (!store.getters['Auth/isAuthenticated']) next('/login')
        // We dont have right now a home for Unified Commerce merchants, so we redirect to orders
        if (isKeycloackEnabled()) next('/orders')
        else next()
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () =>
            import(/* webpackChunkName: "home" */ './views/home/main')
        },
        {
          path: 'new',
          name: 'home-new',
          component: () =>
            import(/* webpackChunkName: "home-new" */ './views/home/new')
        }
      ]
    },
    {
      path: '/redirect',
      component: Redirect
    },
    {
      path: '/custom_dashboards/:name',
      name: 'root-custom-dashboards',
      component: CustomDashboardsWrap,
      beforeEnter: requireAuth,
      meta: {
        scopes: ['utilities:custom_dashboard:read']
      }
    },
    {
      path: '/customers',
      component: () =>
        import(/* webpackChunkName: "customers" */ './views/customers'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          name: 'customers-list',
          component: () =>
            import(
              /* webpackChunkName: "customers-all" */ './views/customers/all'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['customers:read']
          }
        },
        {
          path: 'new',
          name: 'customer-new',
          component: () =>
            import(
              /* webpackChunkName: "customer_edit" */ './views/customers/edit'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['customers:create']
          }
        },
        {
          path: ':id',
          component: () =>
            import(
              /* webpackChunkName: "customer_edit" */ './views/customers/edit'
            ),
          beforeEnter: requireAuth
        },
        noMatch
      ]
    },
    {
      path: '/supplier-management',
      component: () =>
        import(
          /* webpackChunkName: "supplier_management" */ './views/supplier-management'
        ),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/supplier-management'])
        },
        {
          path: 'suppliers',
          component: () =>
            import(
              /* webpackChunkName: "supplier_management_suppliers" */ './views/supplier-management'
            ),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'suppliers-list',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_suppliers_all" */ './views/supplier-management/suppliers/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:suppliers:read']
              }
            },
            {
              path: 'new',
              name: 'supplier-new',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_supplier_edit" */ './views/supplier-management/suppliers/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:suppliers:create']
              }
            },
            {
              path: ':id',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_supplier_edit" */ './views/supplier-management/suppliers/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:suppliers:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'purchase-orders',
          component: () =>
            import(
              /* webpackChunkName: "supplier_management_purchase_orders" */ './views/supplier-management/purchase-orders'
            ),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'purchase-orders-list',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_purchase_orders_all" */ './views/supplier-management/purchase-orders/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:purchase_orders:read']
              }
            },
            {
              path: 'new',
              name: 'purchase-orders-new',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_purchase_order_new" */ './views/supplier-management/purchase-orders/edit.vue'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:purchase_orders:create']
              }
            },
            {
              path: ':id',
              component: () =>
                import(
                  /* webpackChunkName: "supplier_management_purchase_order_edit" */ './views/supplier-management/purchase-orders/edit.vue'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['supplier_management:purchase_orders:update']
              }
            },
            noMatch
          ]
        }
      ]
    },
    {
      path: '/documents',
      component: () =>
        import(/* webpackChunkName: "documents" */ './views/documents'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/documents'])
        },
        {
          path: 'overview',
          component: () =>
            import(
              /* webpackChunkName: "documents_overview" */ './views/documents/overview'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['documents:read']
          }
        }
      ]
    },
    {
      path: '/resources',
      component: () =>
        import(/* webpackChunkName: "resources" */ './views/resources'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/resources'])
        },
        {
          path: 'branches',
          component: () =>
            import(
              /* webpackChunkName: "resources_branches" */ './views/resources/branches'
            ),
          meta: {
            scopes: ['resources:branches:read'],
            featurePath: 'resources.branches'
          },
          children: [
            {
              path: '',
              name: 'resources-branches',
              component: () =>
                import(
                  /* webpackChunkName: "branches_all" */ './views/resources/branches/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:branches:read']
              }
            },
            {
              path: 'new',
              name: 'resources-branches-new',
              component: () =>
                import(
                  /* webpackChunkName: "branches_edit" */ './views/resources/branches/edit'
                ),
              meta: {
                scopes: ['resources:branches:create']
              }
            },
            {
              path: ':id',
              name: 'resources-branches-edit',
              component: () =>
                import(
                  /* webpackChunkName: "branches_edit" */ './views/resources/branches/edit'
                ),
              meta: {
                scopes: ['resources:branches:read']
              }
            }
          ]
        },
        {
          path: 'branch_groups',
          component: () =>
            import(
              /* webpackChunkName: "resources_branch-groups" */ './views/resources/branch-groups'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['resources:branch_groups:read'],
            featurePath: 'resources.branch_groups'
          },
          children: [
            {
              path: '',
              name: 'branch-groups-list',
              component: () =>
                import(
                  /* webpackChunkName: "branch-groups" */ './views/resources/branch-groups/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:branch_groups:read']
              }
            },
            {
              path: 'new',
              name: 'branch-group-new',
              component: () =>
                import(
                  /* webpackChunkName: "branch-groups_edit" */ './views/resources/branch-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:branch_groups:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'branch-group-edit' }
            },
            {
              path: ':id/edit',
              name: 'branch-group-edit',
              component: () =>
                import(
                  /* webpackChunkName: "branch-groups_edit" */ './views/resources/branch-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:branch_groups:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'warehouses',
          component: () =>
            import(/* webpackChunkName: "warehouses" */ './views/warehouses'),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['resources:warehouses:read'],
            featurePath: 'resources.warehouses'
          },
          children: [
            {
              path: '',
              name: 'warehouses-list',
              component: () =>
                import(
                  /* webpackChunkName: "warehouses" */ './views/warehouses/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:warehouses:read']
              }
            },
            {
              path: 'new',
              name: 'warehouses-new',
              component: () =>
                import(
                  /* webpackChunkName: "warehouses_edit" */ './views/warehouses/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:warehouses:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'warehouses-edit' }
            },
            {
              path: ':id/edit',
              name: 'warehouses-edit',
              component: () =>
                import(
                  /* webpackChunkName: "warehouses_edit" */ './views/warehouses/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:warehouses:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'registers',
          component: Defaulter,
          beforeEnter: requireAuth,
          meta: {
            scopes: ['resources:registers:read'],
            featurePath: 'resources.registers'
          },
          children: [
            {
              path: '',
              name: 'resources-registers-all',
              component: () =>
                import(
                  /* webpackChunkName: "registers_all" */ './views/registers/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:registers:read']
              }
            },
            {
              path: ':id',
              name: 'resources-registers-edit',
              component: () =>
                import(
                  /* webpackChunkName: "registers_edit" */ './views/registers/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:registers:read']
              }
            }
          ]
        },
        {
          path: 'licenses',
          component: () =>
            import(/* webpackChunkName: "licenses" */ './views/licenses'),
          meta: {
            scopes: ['resources:licenses:read'],
            featurePath: 'resources.licenses'
          },
          children: [
            {
              path: '',
              name: 'resources-licenses',
              component: () =>
                import(
                  /* webpackChunkName: "licenses_all" */ './views/licenses/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:licenses:read']
              }
            }
          ]
        },
        {
          path: 'devices',
          component: () =>
            import(/* webpackChunkName: "devices" */ './views/devices'),
          meta: {
            scopes: ['resources:devices:read'],
            featurePath: 'resources.devices'
          },
          children: [
            {
              path: '',
              name: 'resources-devices',
              component: () =>
                import(
                  /* webpackChunkName: "devices_all" */ './views/devices/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:devices:read']
              }
            },
            {
              path: 'new',
              name: 'resources-devices-new',
              component: () =>
                import(
                  /* webpackChunkName: "devices_edit" */ './views/devices/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:devices:create']
              }
            },
            {
              path: ':id',
              name: 'resources-devices-edit',
              component: () =>
                import(
                  /* webpackChunkName: "devices_edit" */ './views/devices/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:devices:update']
              }
            }
          ]
        },
        {
          path: 'device_groups',
          component: () =>
            import(
              /* webpackChunkName: "device_groups" */ './views/device-groups'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['resources:device_groups:read'],
            featurePath: 'resources.device_groups'
          },
          children: [
            {
              path: '',
              name: 'device-groups-list',
              component: () =>
                import(
                  /* webpackChunkName: "device-groups" */ './views/device-groups/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:device_groups:read']
              }
            },
            {
              path: 'new',
              name: 'device-group-new',
              component: () =>
                import(
                  /* webpackChunkName: "device-groups_edit" */ './views/device-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:device_groups:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'device-group-edit' }
            },
            {
              path: ':id/edit',
              name: 'device-group-edit',
              component: () =>
                import(
                  /* webpackChunkName: "device-groups_edit" */ './views/device-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['resources:device_groups:update']
              }
            },
            noMatch
          ]
        }
      ]
    },
    {
      path: '/utilities',
      component: () =>
        import(/* webpackChunkName: "utilities" */ './views/utilities'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/utilities'])
        },
        {
          path: 'audits',
          component: () =>
            import(
              /* webpackChunkName: "utilities_audits" */ './views/utilities/audits'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:audits']
          },
          children: [
            {
              path: '',
              redirect: (to) => getParentRedirectTo(['/utilities', 'audits'])
            },
            {
              path: 'actions',
              name: 'utilities-audits-actions',
              component: () =>
                import(
                  /* webpackChunkName: "settings_customers" */ './views/utilities/audits/actions'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:audits'],
                featurePath: 'utilities.audits.actions'
              }
            },
            {
              path: 'logs',
              name: 'utilities-audits-logs',
              component: () =>
                import(
                  /* webpackChunkName: "settings_customers" */ './views/utilities/audits/logs'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:audits'],
                featurePath: 'utilities.audits.logs'
              }
            },
            noMatch
          ]
        },
        {
          path: 'document_designer',
          component: () =>
            import(
              /* webpackChunkName: "templater" */ './views/utilities/templater/templater'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:document_designer:read'],
            featurePath: 'utilities.document_designer'
          },
          children: [
            {
              path: '',
              redirect: (to) =>
                getParentRedirectTo(['/utilities', 'document_designer'])
            },
            {
              path: 'invoices',
              name: 'invoices',
              component: () =>
                import(
                  /* webpackChunkName: "invoices" */ './views/utilities/templater/invoices'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:document_designer:read']
              }
            },
            {
              path: 'full_receipts',
              name: 'full_receipts',
              component: () =>
                import(
                  /* webpackChunkName: "full_receipts" */ './views/utilities/templater/full-receipts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:document_designer:read']
              }
            },
            {
              path: 'delivery_notes',
              name: 'delivery_notes',
              component: () =>
                import(
                  /* webpackChunkName: "delivery_notes" */ './views/utilities/templater/delivery-notes'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:document_designer:read']
              }
            }
          ]
        },
        {
          path: 'cms',
          component: () =>
            import(
              /* webpackChunkName: "utilities_cms" */ './views/utilities/cms'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:cms:read']
          },
          children: [
            {
              path: '',
              redirect: (to) => getParentRedirectTo(['/utilities', 'cms'])
            },
            {
              path: 'contents',
              component: () =>
                import(
                  /* webpackChunkName: "cms_contents" */ './views/utilities/cms/contents'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:cms:read'],
                featurePath: 'utilities.cms.content'
              },
              children: [
                {
                  path: '',
                  name: 'utilities-cms-contents-all',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_contents_all" */ './views/utilities/cms/contents/all'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:read']
                  }
                },
                {
                  path: 'new',
                  name: 'utilities-cms-contents-new',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_contents_new" */ './views/utilities/cms/contents/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:create']
                  }
                },
                {
                  path: ':id',
                  name: 'utilities-cms-contents-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_contents_edit" */ './views/utilities/cms/contents/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:update']
                  }
                }
              ]
            },
            {
              path: 'content_templates',
              component: () =>
                import(
                  /* webpackChunkName: "cms_content_templates" */ './views/utilities/cms/content-templates'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:cms:read'],
                featurePath: 'utilities.cms.content_templates'
              },
              children: [
                {
                  path: '',
                  name: 'utilities-cms-content-templates-all',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_content_templates_all" */ './views/utilities/cms/content-templates/all'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:read']
                  }
                },
                {
                  path: 'new',
                  name: 'utilities-cms-content-templates-new',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_content_templates_new" */ './views/utilities/cms/content-templates/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:create']
                  }
                },
                {
                  path: ':id',
                  name: 'utilities-cms-content-templates-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "cms_content_templates_edit" */ './views/utilities/cms/content-templates/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['utilities:cms:update']
                  }
                }
              ]
            },
            noMatch
          ]
        },
        {
          path: 'custom_dashboards',
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:custom_dashboard:read'],
            featurePath: 'utilities.custom_dashboards'
          },
          children: [
            {
              path: '',
              name: 'utilities-custom-dashboards',
              component: () =>
                import(
                  /* webpackChunkName: "utilities_custom-dashboards" */ './views/utilities/custom-dashboards'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:custom_dashboard:read']
              }
            },
            {
              path: ':name',
              component: CustomDashboardsWrap,
              meta: {
                scopes: ['utilities:custom_dashboard:read']
              }
            },
            noMatch
          ]
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: () =>
            import(
              /* webpackChunkName: "device_notifications" */ './views/utilities/device-notifications'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:notifications:create'],
            featurePath: 'utilities.notifications'
          }
        },
        {
          path: 'favourites',
          name: 'favourites',
          component: () =>
            import(
              /* webpackChunkName: "favourites_editor" */ './views/utilities/favourites/favourites'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: [
              'utilities:favourites:create',
              'utilities:favourites:update'
            ],
            featurePath: 'utilities.favourites'
          }
        },
        {
          path: 'custom_dashboards/standalone/:name',
          name: 'utilities-custom-dashboards-standalone',
          component: CustomDashboardsWrap,
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:custom_dashboard:read']
          },
          props: { standalone: true }
        },
        {
          path: 'export_history',
          name: 'export-history',
          component: () =>
            import(
              /* webpackChunkName: "utilities_export-history" */ './views/utilities/export-history'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:export_history']
          }
        },
        noMatch
      ]
    },
    {
      path: '/staff',
      component: () => import(/* webpackChunkName: "staff" */ './views/staff'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/staff']),
          meta: {
            scopes: ['staff:read'],
            featurePath: 'staff'
          }
        },
        {
          path: 'manager',
          component: () =>
            import(/* webpackChunkName: "staff" */ './views/staff'),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['staff:read']
          },
          children: [
            {
              path: '',
              name: 'staff-list',
              component: () =>
                import(/* webpackChunkName: "staff" */ './views/staff/all'),
              meta: {
                scopes: ['staff:read']
              }
            },
            {
              path: 'new',
              name: 'staff-new',
              component: () =>
                import(
                  /* webpackChunkName: "staff_new" */ './views/staff/edit'
                ),
              meta: {
                scopes: ['staff:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'staff-edit' }
            },
            {
              path: ':id/edit',
              name: 'staff-edit',
              component: () =>
                import(
                  /* webpackChunkName: "staff_edit" */ './views/staff/edit'
                ),
              meta: {
                scopes: ['staff:update']
              }
            }
          ]
        },
        {
          path: 'staff_groups',
          component: () =>
            import(
              /* webpackChunkName: "staff-groups" */ './views/staff-groups'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['staff:groups:read']
          },
          children: [
            {
              path: '',
              name: 'staff-groups-list',
              component: () =>
                import(
                  /* webpackChunkName: "staff-groups" */ './views/staff-groups/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['staff:groups:read']
              }
            },
            {
              path: 'new',
              name: 'staff-group-new',
              component: () =>
                import(
                  /* webpackChunkName: "staff-groups_edit" */ './views/staff-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['staff:groups:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'staff-group-edit' }
            },
            {
              path: ':id/edit',
              name: 'staff-group-edit',
              component: () =>
                import(
                  /* webpackChunkName: "staff-groups_edit" */ './views/staff-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['staff:groups:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'shift_plan',
          name: 'shift-plan',
          component: () =>
            import(
              /* webpackChunkName: "shift-plan" */ './views/shift-plan/index.vue'
            ),
          beforeEnter: requireAuth
        },
        noMatch
      ]
    },
    {
      path: '/reservations',
      component: () =>
        import(/* webpackChunkName: "reservations" */ './views/reservations'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/reservations'])
        },
        {
          path: 'service_category',
          component: () =>
            import(
              /* webpackChunkName: "reservations" */ './views/reservations'
            ),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'service-category',
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:service_categories:read'],
                featurePath: 'reservations'
              },
              component: () =>
                import(
                  /* webpackChunkName: "reservations_service_category_all" */ './views/reservations/service-category/all'
                )
            },
            {
              path: 'new',
              name: 'category-new',
              component: () =>
                import(
                  /* webpackChunkName: "reservations_service_category_edit" */ './views/reservations/service-category/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:service_categories:create'],
                featurePath: 'reservations'
              }
            },
            {
              path: ':id',
              redirect: { name: 'service-category-edit' }
            },
            {
              path: ':id/edit',
              name: 'service-category-edit',
              component: () =>
                import(
                  /* webpackChunkName: "reservations_service_category_edit" */ './views/reservations/service-category/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:service_categories:update'],
                featurePath: 'reservations'
              }
            }
          ]
        },
        {
          path: 'services',
          component: () =>
            import(
              /* webpackChunkName: "reservations" */ './views/reservations'
            ),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'services',
              component: () =>
                import(
                  /* webpackChunkName: "reservations_services_all" */ './views/reservations/services/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:services:read'],
                featurePath: 'reservations'
              }
            },
            {
              path: 'new',
              name: 'services-new',
              component: () =>
                import(
                  /* webpackChunkName: "reservations_services_edit" */ './views/reservations/services/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:service_categories:create'],
                featurePath: 'reservations'
              }
            },
            {
              path: ':id',
              redirect: { name: 'services-edit' }
            },
            {
              path: ':id/edit',
              name: 'services-edit',
              component: () =>
                import(
                  /* webpackChunkName: "reservations_services_edit" */ './views/reservations/services/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reservations:services:update'],
                featurePath: 'reservations'
              }
            }
          ]
        },
        noMatch
      ]
    },
    {
      path: '/products',
      component: () =>
        import(/* webpackChunkName: "products" */ './views/products'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/products'])
        },
        {
          path: 'manager',
          component: () =>
            import(/* webpackChunkName: "products" */ './views/products'),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:read'],
            featurePath: 'products.manager'
          },
          children: [
            {
              path: '',
              name: 'products-manager',
              component: () =>
                import(
                  /* webpackChunkName: "products_all" */ './views/products/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:read']
              }
            },
            {
              path: 'bulk_edit',
              name: 'products-bulk-edit',
              component: () =>
                import(
                  /* webpackChunkName: "products_bulk_edit" */ './views/products/components/bulk-edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:update']
              }
            },
            {
              path: 'new',
              name: 'products-new',
              component: () =>
                import(
                  /* webpackChunkName: "products_edit_v2" */ './views/products/v2/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'products-edit' }
            },
            {
              path: ':id/edit',
              name: 'products-edit',
              component: () =>
                import(
                  /* webpackChunkName: "products_edit_v2" */ './views/products/v2/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:update']
              }
            },
            {
              path: 'custom_dashboards/:name',
              name: 'products-custom-dashboards',
              component: CustomDashboardsWrap,
              beforeEnter: requireAuth,
              meta: {
                scopes: ['utilities:custom_dashboard:read']
              }
            },
            noMatch
          ]
        },
        {
          path: 'product_groups',
          component: () =>
            import(
              /* webpackChunkName: "product_groups" */ './views/product-groups'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:product_groups:read'],
            featurePath: 'products.product_groups'
          },
          children: [
            {
              path: '',
              name: 'products-product-groups-list',
              component: () =>
                import(
                  /* webpackChunkName: "products-product-groups-list" */ './views/product-groups/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:product_groups:read']
              }
            },
            {
              path: 'new',
              name: 'products-product-groups-new',
              component: () =>
                import(
                  /* webpackChunkName: "products-product-group-edit" */ './views/product-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:product_groups:create']
              }
            },
            {
              path: ':id',
              name: 'products-product-group-display',
              redirect: { name: 'products-product-groups-edit' },
              scopes: ['products:product_groups:read']
            },
            {
              path: ':id/edit',
              name: 'products-product-groups-edit',
              component: () =>
                import(
                  /* webpackChunkName: "products-product-group-edit" */ './views/product-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:product_groups:update']
              }
            }
          ]
        },
        {
          path: 'product_templates',
          name: 'products-product-templates',
          component: () =>
            import(
              /* webpackChunkName: "product_templates" */ './views/product-templates'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:product_templates:read'],
            featurePath: 'products.product_templates'
          },
          children: [
            {
              path: '',
              name: 'products-product-templates-all',
              component: () =>
                import(
                  /* webpackChunkName: "products-product-templates-all" */ './views/product-templates/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:product_templates:read']
              }
            },
            {
              path: '/products/product_templates/:id',
              name: 'products-product-templates-edit',
              component: () =>
                import(
                  /* webpackChunkName: "products-product-templates-edit" */ './views/product-templates/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:product_templates:update']
              }
            }
          ]
        },
        {
          path: 'product_service_questions',
          name: 'products-service-questions',
          component: () =>
            import(
              /* webpackChunkName: "product-services_questions" */ './views/product-services/'
            ),
          meta: {
            scopes: ['products:service_questions:read'],
            featurePath: 'products.service.questions'
          },
          children: [
            {
              path: '',
              name: 'products-service-questions-list',
              component: () =>
                import(
                  /* webpackChunkName: "product-services_questions" */ './views/product-services/questions/all'
                ),
              meta: {
                scopes: ['products:service_questions:read']
              }
            },
            {
              path: 'new',
              name: 'products-service-questions-new',
              component: () =>
                import(
                  /* webpackChunkName: "product-services_questions" */ './views/product-services/questions/edit'
                ),
              meta: {
                scopes: ['products:service_questions:create']
              }
            },
            {
              path: ':id',
              name: 'products-service-questions-edit',
              component: () =>
                import(
                  /* webpackChunkName: "product-services_questions" */ './views/product-services/questions/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:service_questions:update']
              }
            }
          ]
        },
        {
          path: 'discounts',
          component: () =>
            import(/* webpackChunkName: "discounts" */ './views/discounts'),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:discounts:read'],
            featurePath: 'products.discounts'
          },
          children: [
            {
              path: '',
              name: 'discounts-list',
              component: () =>
                import(
                  /* webpackChunkName: "discounts" */ './views/discounts/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:discounts:read']
              }
            },
            // V2
            {
              path: 'new',
              name: 'discounts-new',
              component: () =>
                import(
                  /* webpackChunkName: "discounts_edit" */ './views/discounts/v2/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:discounts:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'discount-edit' }
            },
            {
              path: ':id/edit',
              name: 'discount-edit',
              component: () =>
                import(
                  /* webpackChunkName: "discounts_edit" */ './views/discounts/v2/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:discounts:update']
              }
            },
            // V1
            {
              path: 'new-v1',
              name: 'discounts-new-v1',
              component: () =>
                import(
                  /* webpackChunkName: "discounts_edit_v1" */ './views/discounts/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:discounts:create']
              }
            },
            {
              path: ':id/edit-v1',
              name: 'discount-edit-v1',
              component: () =>
                import(
                  /* webpackChunkName: "discounts_edit_v1" */ './views/discounts/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:discounts:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'pricebooks',
          component: () =>
            import(
              /* webpackChunkName: "pricebooks_list" */ './views/pricebooks'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:pricebooks:read'],
            featurePath: 'products.pricebooks'
          },
          children: [
            {
              path: '',
              name: 'pricebooks-list',
              component: () =>
                import(
                  /* webpackChunkName: "pricebooks" */ './views/pricebooks/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:pricebooks:read']
              }
            },
            {
              path: 'new',
              name: 'pricebooks-new',
              component: () =>
                import(
                  /* webpackChunkName: "pricebooks_edit" */ './views/pricebooks/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:pricebooks:create']
              }
            },
            {
              path: ':id/edit',
              name: 'pricebook-edit',
              component: () =>
                import(
                  /* webpackChunkName: "pricebooks_edit" */ './views/pricebooks/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:pricebooks:update']
              }
            },
            {
              path: ':id',
              redirect: { name: 'pricebook-edit' }
            },
            noMatch
          ]
        },
        {
          path: 'promotions',
          component: () =>
            import(/* webpackChunkName: "promotions" */ './views/promotions'),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:promotions:read']
          },
          children: [
            {
              path: '',
              name: 'promotions-list',
              component: () =>
                import(
                  /* webpackChunkName: "promotions" */ './views/promotions/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:promotions:read'],
                featurePath: 'products.promotions'
              }
            },
            {
              path: 'new',
              name: 'promotion-new',
              component: () =>
                import(
                  /* webpackChunkName: "promotion_edit" */ './views/promotions/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:promotions:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'promotion-edit' }
            },
            {
              path: ':id/edit',
              name: 'promotion-edit',
              component: () =>
                import(
                  /* webpackChunkName: "promotion_edit" */ './views/promotions/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:promotions:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'tags',
          name: 'products-tags',
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:tags:read'],
            featurePath: 'products.tags'
          },
          children: [
            {
              path: '',
              name: 'products-tags-all',
              component: () =>
                import(/* webpackChunkName: "tags" */ './views/tags'),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:tags:read'],
                featurePath: 'products.tags'
              }
            },
            {
              path: 'new',
              name: 'products-tags-new',
              component: () =>
                import(/* webpackChunkName: "tags" */ './views/tags'),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:tags:create']
              }
            },
            {
              path: ':id',
              name: 'products-tags-edit',
              component: () =>
                import(/* webpackChunkName: "tags" */ './views/tags'),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:tags:update']
              }
            }
          ]
        },
        {
          path: 'addon-groups',
          name: 'products-addon-groups',
          component: () =>
            import(
              /* webpackChunkName: "product_addon_groups" */ './views/product-addon-groups'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['products:addon_groups:read'],
            featurePath: 'products.addon_groups'
          },
          children: [
            {
              path: '',
              name: 'products-addon-groups-list',
              component: () =>
                import(
                  /* webpackChunkName: "product_addon_groups" */ './views/product-addon-groups/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:addon_groups:read']
              }
            },
            {
              path: 'new',
              name: 'products-addon-groups-new',
              component: () =>
                import(
                  /* webpackChunkName: "product_addon_groups" */ './views/product-addon-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:addon_groups:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'products-addon-groups-edit' }
            },
            {
              path: ':id/edit',
              name: 'products-addon-groups-edit',
              component: () =>
                import(
                  /* webpackChunkName: "product_addon_groups" */ './views/product-addon-groups/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['products:addon_groups:update']
              }
            },
            noMatch
          ]
        }
      ]
    },
    {
      path: '/inventory',
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/inventory'])
        },
        // Stock manager
        {
          path: 'stock_manager',
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'ims-stock-manager',
              component: () =>
                import(
                  /* webpackChunkName: "ims_stock-manager" */ './views/ims/v2/index'
                )
            },
            {
              path: 'goods_in',
              name: 'ims-goods-in',
              component: () =>
                import(
                  /* webpackChunkName: "ims_goods-book-in" */ './views/ims/goods-book-in'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:goods_in:create'],
                featurePath: 'inventory.stock_manager.goods_in'
              }
            },
            {
              path: 'goods_out',
              name: 'ims-goods-out',
              component: () =>
                import(
                  /* webpackChunkName: "ims_goods-book-out" */ './views/ims/goods-book-out'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:goods_out:create'],
                featurePath: 'inventory.stock_manager.goods_out'
              }
            },
            {
              path: 'relocation',
              name: 'ims-relocation',
              component: () =>
                import(
                  /* webpackChunkName: "ims_relocation" */ './views/ims/relocation'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:relocation:create'],
                featurePath: 'inventory.stock_manager.relocation'
              }
            }
          ]
        },
        {
          path: 'stock',
          children: [
            {
              path: '',
              name: 'ims-stock',
              component: () =>
                import(/* webpackChunkName: "stock" */ './views/ims/stock'),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:stock_movements:create'],
                featurePath: 'inventory.stock_movements'
              }
            }
          ]
        },
        {
          path: 'reasons',
          component: () =>
            import(/* webpackChunkName: "reasons" */ './views/ims/reasons'),
          beforeEnter: requireAuth,
          meta: {
            featurePath: 'inventory.reasons'
          },
          children: [
            {
              path: '',
              name: 'reasons-list',
              component: () =>
                import(
                  /* webpackChunkName: "reasons" */ './views/ims/reasons/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:reasons:read']
              }
            },
            {
              path: 'new',
              name: 'reasons-new',
              component: () =>
                import(
                  /* webpackChunkName: "reasons_edit" */ './views/ims/reasons/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:reasons:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'reasons-edit' }
            },
            {
              path: ':id/edit',
              name: 'reasons-edit',
              component: () =>
                import(
                  /* webpackChunkName: "reasons_edit" */ './views/ims/reasons/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:reasons:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'processes',
          component: () =>
            import(
              /* webpackChunkName: "ims_processes" */ './views/ims/processes'
            ),
          beforeEnter: requireAuth,
          meta: {
            featurePath: 'inventory.processes'
          },
          children: [
            {
              path: '',
              name: 'processes-list',
              component: () =>
                import(
                  /* webpackChunkName: "processes" */ './views/ims/processes/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:processes:read']
              }
            },
            {
              path: 'new',
              name: 'processes-new',
              component: () =>
                import(
                  /* webpackChunkName: "process_edit" */ './views/ims/processes/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:processes:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'processes-edit' }
            },
            {
              path: ':id/edit',
              name: 'processes-edit',
              component: () =>
                import(
                  /* webpackChunkName: "process_edit" */ './views/ims/processes/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:processes:update']
              }
            },
            noMatch
          ]
        },
        {
          path: 'stock_takings',
          component: () =>
            import(
              /* webpackChunkName: "ims_stock-takings" */ './views/ims/stock-takings'
            ),
          beforeEnter: requireAuth,
          meta: {
            featurePath: 'inventory.stock_takings'
          },
          children: [
            {
              path: '',
              name: 'stock-takings-list',
              component: () =>
                import(
                  /* webpackChunkName: "stock_takings" */ './views/ims/stock-takings/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:stock_takings:read']
              }
            },
            {
              path: 'new',
              name: 'stock-takings-new',
              component: () =>
                import(
                  /* webpackChunkName: "stock_takings_edit" */ './views/ims/stock-takings/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:stock_takings:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'stock-takings-edit' }
            },
            {
              path: ':id/edit',
              name: 'stock-takings-edit',
              component: () =>
                import(
                  /* webpackChunkName: "stock_takings_edit" */ './views/ims/stock-takings/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['inventory:stock_takings:update']
              }
            },
            noMatch
          ]
        },
        noMatch
      ]
    },
    {
      path: '/orders',
      component: () =>
        import(/* webpackChunkName: "reports" */ './views/orders'),
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/orders'])
        },
        {
          path: 'orders_transactions',
          component: () =>
            import(
              /* webpackChunkName: "reports_orders_transactions" */ './views/orders/orders_transactions'
            ),
          beforeEnter: requireAuth,
          props: (route) => ({}),
          meta: {
            scopes: ['orders_transactions']
          },
          children: [
            {
              path: '',
              name: 'reports-orders_transactions-list',
              component: () =>
                import(
                  /* webpackChunkName: "reports-orders_transactions-all" */ './views/orders/orders_transactions/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['orders_transactions:read'],
                featurePath: 'orders.orders_transactions'
              }
            },
            {
              path: ':id',
              redirect: { name: 'reports-orders_transactions-detail' }
            },
            {
              path: ':id/detail',
              name: 'reports-orders_transactions-detail',
              component: () =>
                import(
                  /* webpackChunkName: "reports-orders_transactions-detail" */ './views/orders/orders_transactions/detail'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['orders_transactions:read'],
                featurePath: 'orders.orders_transactions'
              }
            },
            noMatch
          ]
        },
        {
          path: 'reports_orders',
          component: () =>
            import(
              /* webpackChunkName: "orders_reports_orders" */ './views/orders/reports_orders'
            ),
          beforeEnter: requireAuth,
          props: (route) => ({}),
          meta: {
            scopes: ['reports_orders:read']
          },
          children: [
            {
              path: '',
              name: 'reports-orders-list',
              component: () =>
                import(
                  /* webpackChunkName: "reports-orders-all" */ './views/orders/reports_orders/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_orders:read'],
                featurePath: 'orders.reports_orders'
              }
            },
            {
              path: ':id',
              redirect: { name: 'reports-orders-detail' }
            },
            {
              path: ':id/detail',
              name: 'reports-orders-detail',
              component: () =>
                import(
                  /* webpackChunkName: "reports-orders-detail" */ './views/orders/reports_orders/detail'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_orders:read'],
                featurePath: 'orders.reports_orders'
              }
            },
            noMatch
          ]
        }
      ]
    },
    {
      path: '/reports',
      component: () =>
        import(/* webpackChunkName: "reports" */ './views/reports'),
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/reports'])
        },
        {
          path: 'financial_accounting',
          component: () =>
            import(
              /* webpackChunkName: "reports_financial_accounting" */ './views/reports/financial-accounting'
            ),
          beforeEnter: requireAuth,
          props: (route) => ({}),
          meta: {
            scopes: ['reports_financial_accounting']
          },
          children: [
            {
              path: '',
              name: 'reports-financial-accounting',
              redirect: (to) =>
                getParentRedirectTo(['/reports', 'financial_accounting'])
            },
            {
              path: 'transactions',
              name: 'reports-financial_accounting-transactions',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-transactions" */ './views/reports/financial-accounting/transactions'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:transactions:read'],
                featurePath: 'reports.financial_accounting.transactions'
              }
            },
            {
              path: 'balances',
              name: 'reports-financial_accounting-balances',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-balances" */ './views/reports/financial-accounting/balances'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:balances:read'],
                featurePath: 'reports.financial_accounting.balances'
              }
            },
            {
              path: 'cash_book',
              name: 'reports-financial_accounting-cash_book',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-cash_book" */ './views/reports/financial-accounting/cash_book'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:cash_book:read'],
                featurePath: 'reports.financial_accounting.cash_book'
              }
            },
            {
              path: 'returns',
              name: 'reports-financial_accounting-returns',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-returns" */ './views/reports/financial-accounting/returns'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:returns:read'],
                featurePath: 'reports.financial_accounting.returns'
              }
            },
            {
              path: 'payment_options',
              name: 'reports-financial_accounting-payment_options',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-payment_options" */ './views/reports/financial-accounting/payment_options'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:payment_options:read'],
                featurePath: 'reports.financial_accounting.payment_options'
              }
            },
            {
              path: 'payments',
              name: 'reports-financial_accounting-payments',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-payments" */ './views/reports/financial-accounting/payments'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:payments:read'],
                featurePath: 'reports.financial_accounting.payments'
              }
            },
            {
              path: 'vat',
              name: 'reports-financial_accounting-vat',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-vat" */ './views/reports/financial-accounting/vat'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_financial_accounting:vat:read'],
                featurePath: 'reports.financial_accounting.vat'
              }
            },
            {
              path: 'counting_protocols',
              name: 'counting-protocols',
              component: () =>
                import(
                  /* webpackChunkName: "reports-financial_accounting-counting_protocols" */ './views/reports/financial-accounting/counting-protocols'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: [
                  'reports_financial_accounting:counting_protocols:read'
                ],
                featurePath: 'reports.financial_accounting.counting_protocols'
              }
            },
            noMatch
          ]
        },
        {
          path: 'statistics',
          component: () =>
            import(
              /* webpackChunkName: "reports_statistics" */ './views/reports/statistics'
            ),
          beforeEnter: requireAuth,
          props: (route) => ({}),
          meta: {
            scopes: ['reports_statistics']
          },
          children: [
            {
              path: '',
              name: 'reports-statistics',
              redirect: (to) => getParentRedirectTo(['/reports', 'statistics'])
            },
            {
              path: 'revenues',
              name: 'reports-statistics-revenues',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-revenues" */ './views/reports/statistics/revenues'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:revenues:read'],
                featurePath: 'reports.statistics.revenues'
              }
            },
            {
              path: 'products',
              name: 'reports-statistics-products',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-product" */ './views/reports/statistics/products'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:products:read'],
                featurePath: 'reports.statistics.products'
              }
            },
            {
              path: 'product_groups',
              name: 'reports-statistics-product-groups',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-product-groups" */ './views/reports/statistics/product-groups'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:product_groups:read'],
                featurePath: 'reports.statistics.product_groups'
              }
            },
            {
              path: 'staff',
              name: 'reports-statistics-staff',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-staff" */ './views/reports/statistics/staff'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:staff:read'],
                featurePath: 'reports.statistics.staff'
              }
            },
            {
              path: 'customers',
              name: 'reports-statistics-customers',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-customers" */ './views/reports/statistics/customers'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:customers:read'],
                featurePath: 'reports.statistics.customers'
              }
            },
            {
              path: 'sold_cart_items',
              name: 'reports-statistics-sold-cart-items',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-sold-cart-items" */ './views/reports/statistics/sold-cart-items'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:sold_cart_items:read'],
                featurePath: 'reports.statistics.sold_cart_items'
              }
            },
            {
              path: 'vouchers',
              name: 'reports-statistics-vouchers',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-vouchers" */ './views/reports/statistics/vouchers'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:vouchers:read'],
                featurePath: 'reports.statistics.vouchers'
              }
            },
            {
              path: 'discounts',
              name: 'reports-statistics-discounts',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-discounts" */ './views/reports/statistics/discounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:discounts:read'],
                featurePath: 'reports.statistics.discounts'
              }
            },
            {
              path: 'stock',
              name: 'reports-statistics-stock',
              component: () =>
                import(
                  /* webpackChunkName: "reports-statistics-stock" */ './views/reports/statistics/stock'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['reports_statistics:stocks:read'],
                featurePath: 'reports.statistics.stocks'
              }
            }
          ]
        },
        {
          path: 'exports_manager',
          name: 'reports-exports-manager',
          component: () =>
            import(
              /* webpackChunkName: "reports-exports-manager" */ './views/reports/exports-manager'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: [
              'reports_exports_manager:financial:gobd',
              'reports_exports_manager:financial:datev',
              'reports_exports_manager:financial:safe_logs'
            ],
            featurePath: 'reports.exports'
          }
        },
        {
          path: 'custom_dashboards/:name',
          name: 'reports-custom-dashboards',
          component: CustomDashboardsWrap,
          beforeEnter: requireAuth,
          meta: {
            scopes: ['utilities:custom_dashboard:read'],
            featurePath: 'utilities.custom_dashboard'
          }
        }
      ]
    },
    {
      path: '/loyalty',
      component: () =>
        import(/* webpackChunkName: "loyalty" */ './views/loyalty'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/loyalty'])
        },
        {
          path: 'vouchers',
          component: () =>
            import(
              /* webpackChunkName: "vouchers" */ './views/loyalty/vouchers'
            ),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              redirect: (to) => getParentRedirectTo(['/loyalty', 'vouchers'])
            },
            {
              path: 'manager',
              component: () =>
                import(
                  /* webpackChunkName: "vouchers-manger" */ './views/loyalty/vouchers/vouchers-manager'
                ),
              meta: {
                scopes: ['loyalty:read'],
                featurePath: 'loyalty.vouchers'
              },
              children: [
                {
                  path: '',
                  name: 'vouchers-list',
                  meta: {
                    scopes: ['loyalty:read']
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "vouchers-table" */ './views/loyalty/vouchers/vouchers-manager/all'
                    )
                },
                //V1
                {
                  path: 'new-v1',
                  name: 'voucher-new-v1',
                  component: () =>
                    import(
                      /* webpackChunkName: "vouchers_edit_v1" */ './views/loyalty/vouchers/vouchers-manager/edit'
                    ),
                  props: (route) => ({
                    isNew: true,
                    currentDefaultCurrency: route.params.defaultCurrency
                  }),
                  meta: {
                    beforeResolve(routeTo, routeFrom, next) {
                      routeTo.params.defaultCurrency =
                        store.getters['Config/getCurrentDefaultCurrency']
                      next()
                    },
                    scopes: ['loyalty:create']
                  }
                },
                {
                  path: ':id/edit-v1',
                  name: 'voucher-edit-v1',
                  component: () =>
                    import(
                      /* webpackChunkName: "vouchers_edit_v1" */ './views/loyalty/vouchers/vouchers-manager/edit'
                    ),
                  props: () => ({ isNew: false }),
                  meta: {
                    scopes: ['loyalty:update']
                  }
                },
                // V2
                {
                  path: 'new',
                  name: 'voucher-new',
                  component: () =>
                    import(
                      /* webpackChunkName: "vouchers_edit" */ './views/loyalty/vouchers/vouchers-manager-v2/edit'
                    ),
                  meta: {
                    scopes: ['loyalty:create']
                  }
                },
                {
                  path: ':id',
                  redirect: { name: 'voucher-edit' }
                },
                {
                  path: ':id/edit',
                  name: 'voucher-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "vouchers_edit" */ './views/loyalty/vouchers/vouchers-manager-v2/edit'
                    ),
                  props: () => ({ isNew: false }),
                  meta: {
                    scopes: ['loyalty:update', 'loyalty:read']
                  }
                }
              ]
            },
            {
              path: 'voucher_systems',
              component: () =>
                import(
                  /* webpackChunkName: "voucher_systems" */ './views/loyalty/vouchers/voucher-systems'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['loyalty:voucher_systems:read'],
                featurePath: 'loyalty.voucher_systems'
              },
              children: [
                {
                  path: '',
                  name: 'voucher-systems-list',
                  component: () =>
                    import(
                      /* webpackChunkName: "voucher_systems" */ './views/loyalty/vouchers/voucher-systems/all'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:read']
                  }
                },
                //V1
                {
                  path: 'new-v1',
                  name: 'voucher-systems-new-v1',
                  component: () =>
                    import(
                      /* webpackChunkName: "voucher_systems_edit_v1" */ './views/loyalty/vouchers/voucher-systems/edit'
                    ),
                  beforeEnter: requireAuth,
                  props: (route) => ({
                    isNew: true,
                    currentDefaultCurrency: route.params.defaultCurrency
                  }),
                  meta: {
                    beforeResolve(routeTo, routeFrom, next) {
                      routeTo.params.defaultCurrency =
                        store.getters['Config/getCurrentDefaultCurrency']
                      next()
                    },
                    scopes: ['loyalty:voucher_systems:create']
                  }
                },
                {
                  path: ':id/edit-v1',
                  name: 'voucher-systems-edit-v1',
                  component: () =>
                    import(
                      /* webpackChunkName: "voucher_systems_edit_v1" */ './views/loyalty/vouchers/voucher-systems/edit'
                    ),
                  props: (route) => ({ isNew: false }),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:update']
                  }
                },
                // V2
                {
                  path: 'new',
                  name: 'voucher-systems-new',
                  component: () =>
                    import(
                      /* webpackChunkName: "voucher_systems_edit" */ './views/loyalty/vouchers/voucher-systems-v2/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:create']
                  }
                },
                {
                  path: ':id',
                  redirect: { name: 'voucher-systems-edit' }
                },
                {
                  path: ':id/edit',
                  name: 'voucher-systems-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "voucher_systems_edit" */ './views/loyalty/vouchers/voucher-systems-v2/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: [
                      'loyalty:voucher_systems:update',
                      'loyalty:voucher_systems:read'
                    ]
                  }
                },
                noMatch
              ]
            },
            {
              path: 'external_systems',
              component: () =>
                import(
                  /* webpackChunkName: "external_systems" */ './views/loyalty/vouchers/external-systems'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['loyalty:voucher_systems:read'],
                featurePath: 'loyalty.voucher_systems'
              },
              children: [
                {
                  path: '',
                  name: 'external-systems-list',
                  component: () =>
                    import(
                      /* webpackChunkName: "external_systems" */ './views/loyalty/vouchers/external-systems/all'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:read']
                  }
                },
                {
                  path: 'new',
                  name: 'external-systems-new',
                  component: () =>
                    import(
                      /* webpackChunkName: "external_systems_edit" */ './views/loyalty/vouchers/external-systems/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:create']
                  }
                },
                {
                  path: ':id',
                  redirect: { name: 'external-systems-edit' }
                },
                {
                  path: ':id/edit',
                  name: 'external-systems-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "external_systems_edit" */ './views/loyalty/vouchers/external-systems/edit'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['loyalty:voucher_systems:update']
                  }
                },
                noMatch
              ]
            }
          ]
        },
        {
          path: 'logs',
          component: Defaulter,
          beforeEnter: requireAuth,
          meta: {
            scopes: ['loyalty:logs'],
            featurePath: 'loyalty.vouchers'
          },
          children: [
            {
              path: '',
              name: 'voucher-logs',
              component: () =>
                import(
                  /* webpackChunkName: "voucher_logs" */ './views/loyalty/voucher-logs/all'
                ),
              meta: {
                scopes: ['loyalty:logs']
              }
            },
            noMatch
          ]
        },
        {
          path: 'abocard_systems',
          component: () =>
            import(
              /* webpackChunkName: "abocard_systems" */ './views/loyalty/abocard-systems'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['loyalty:abocard_systems:read'],
            featurePath: 'loyalty.abocard'
          },
          children: [
            {
              path: '',
              name: 'abocard-systems-list',
              component: () =>
                import(
                  /* webpackChunkName: "abocard_systems_all" */ './views/loyalty/abocard-systems/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['loyalty:abocard_systems:read']
              }
            },
            {
              path: 'new',
              name: 'abocard-systems-new',
              component: () =>
                import(
                  /* webpackChunkName: "abocard_systems_edit" */ './views/loyalty/abocard-systems/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['loyalty:abocard_systems:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'abocard-systems-edit' }
            },
            {
              path: ':id/edit',
              name: 'abocard-systems-edit',
              component: () =>
                import(
                  /* webpackChunkName: "abocard_systems_edit" */ './views/loyalty/abocard-systems/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: [
                  'loyalty:abocard_systems:update',
                  'loyalty:abocard_systems:read'
                ]
              }
            },
            noMatch
          ]
        },
        noMatch
      ]
    },
    {
      path: '/user_profile',
      component: () =>
        import(/* webpackChunkName: "user_profile" */ './views/user-profile'),
      beforeEnter: requireAuth,
      meta: {
        async beforeResolve(routeTo, routeFrom, next) {
          const staff = !!safeGet(store, 'state.Auth.staff')
          const isSubUser = !!safeGet(store, 'state.Auth.thUserId')
          if (isSubUser && staff) {
            return next()
          }
          return next('/404')
        }
      },
      children: [
        {
          path: '',
          name: 'user-profile',
          component: () =>
            import(
              /* webpackChunkName: "user-profile" */ './views/user-profile/main'
            ),
          children: [
            {
              path: 'sales',
              name: 'sales',
              component: () =>
                import(
                  /* webpackChunkName: "user-profile" */ './views/user-profile/main'
                )
            },
            {
              path: 'hours',
              name: 'hours',
              component: () =>
                import(
                  /* webpackChunkName: "user-profile" */ './views/user-profile/main'
                )
            }
          ],
          beforeEnter: requireAuth
        },
        noMatch
      ]
    },
    {
      path: '/accounting',
      component: () =>
        import(/* webpackChunkName: "accounting" */ './views/accounting'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          name: 'accounting',
          redirect: (to) => getParentRedirectTo(['/accounting'])
        },
        {
          path: 'expense_accounts',
          name: 'accounting-expense-accounts',
          beforeEnter: requireAuth,
          meta: {
            scopes: ['accounting:expense_accounts:read'],
            featurePath: 'accounting.expense_accounts'
          },
          children: [
            {
              path: '',
              name: 'accounting-expense-accounts-default',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_expense_accounts" */ './views/accounting/expense-accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:expense_accounts:read'],
                featurePath: 'accounting.expense_accounts'
              }
            },
            {
              path: 'new',
              name: 'accounting-expense-accounts-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_expense_accounts" */ './views/accounting/expense-accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:expense_accounts:read']
              }
            },
            {
              path: ':id',
              name: 'accounting-expense-accounts-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_expense_accounts" */ './views/accounting/expense-accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:expense_accounts:read']
              }
            }
          ]
        },
        {
          path: 'taxes',
          name: 'accounting-taxes',
          meta: {
            scopes: ['accounting:taxes:read'],
            featurePath: 'accounting.taxes'
          },
          children: [
            {
              path: '',
              name: 'accounting-taxes-default',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_taxes" */ './views/accounting/taxes'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:taxes:read'],
                featurePath: 'accounting.taxes'
              }
            },
            {
              path: 'new',
              name: 'accounting-taxes-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_taxes" */ './views/accounting/taxes'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:taxes:read']
              }
            },
            {
              path: ':id',
              name: 'accounting-taxes-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_taxes" */ './views/accounting/taxes'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:taxes:read']
              }
            }
          ]
        },
        {
          path: 'accounts',
          name: 'accounting-accounts',
          meta: {
            scopes: ['accounting:revenue_accounts:read'],
            featurePath: 'accounting.accounts'
          },
          children: [
            {
              path: '',
              name: 'accounting-accounts-default',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_accounts" */ './views/accounting/accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:revenue_accounts:read']
              }
            },
            {
              path: 'new',
              name: 'accounting-accounts-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_accounts" */ './views/accounting/accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:revenue_accounts:read']
              }
            },
            {
              path: ':id',
              name: 'accounting-accounts-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_accounts" */ './views/accounting/accounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:revenue_accounts:read']
              }
            }
          ]
        },
        {
          path: 'payment_options',
          component: () =>
            import(
              /* webpackChunkName: "payment-options" */ './views/accounting/payment-options'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['accounting:payment_options:read'],
            featurePath: 'accounting.payment_options'
          },
          children: [
            {
              path: '',
              name: 'accounting-payment-options',
              component: () =>
                import(
                  /* webpackChunkName: "payment_options_all" */ './views/accounting/payment-options/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:payment_options:read']
              }
            },
            {
              path: 'new',
              name: 'payment-options-new',
              component: () =>
                import(
                  /* webpackChunkName: "payment_options_new" */ './views/accounting/payment-options/edit'
                ),
              meta: {
                scopes: ['accounting:payment_options:create']
              }
            },
            {
              path: ':id',
              name: 'payment-options-edit',
              component: () =>
                import(
                  /* webpackChunkName: "payment_options_edit" */ './views/accounting/payment-options/edit'
                ),
              meta: {
                scopes: ['accounting:payment_options:update']
              }
            }
          ]
        },
        {
          path: 'safe_management',
          component: () =>
            import(
              /* webpackChunkName: "accounting_safe_management" */ './views/accounting/safe-management'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['accounting:safe_management:read'],
            featurePath: 'accounting.safe_management'
          },
          children: [
            {
              path: '',
              name: 'accounting-safe-management',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_safe_management_all" */ './views/accounting/safe-management/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:safe_management:read']
              }
            },
            {
              path: '/accounting/safe_management/new',
              name: 'accounting-safe-management-new',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_safe-management_edit" */ './views/accounting/safe-management/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:safe_management:create']
              }
            },
            {
              path: '/accounting/safe_management/:id',
              name: 'accounting-safe-management-overview',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_safe-management_safe-overview" */ './views/accounting/safe-management/safe-overview'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:safe_management:read']
              }
            },
            {
              path: '/accounting/safe_management/:id/edit',
              name: 'accounting-safe-management-edit',
              component: () =>
                import(
                  /* webpackChunkName: "accounting_safe-management_edit" */ './views/accounting/safe-management/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['accounting:safe_management:update']
              }
            }
          ]
        },
        noMatch
      ]
    },
    {
      path: '/timetracking',
      component: () =>
        import(/* webpackChunkName: "timetracking" */ './views/timetracking'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          name: 'timetracking-timesheet',
          component: () =>
            import(
              /* webpackChunkName: "promotions" */ './views/timetracking/timesheet'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['timetracking:read'],
            featurePath: 'timetracking'
          }
        },
        noMatch
      ]
    },
    {
      path: '/settings',
      component: () =>
        import(/* webpackChunkName: "settings" */ './views/settings'),
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: (to) => getParentRedirectTo(['/settings'])
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () =>
            import(
              /* webpackChunkName: "settings_dashboard" */ './views/settings/sections/dashboard'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['settings:dashboard']
          },
          children: [
            {
              path: '',
              name: 'settings-dashboard',
              redirect: (to) => getParentRedirectTo(['/settings', 'dashboard'])
            },
            {
              path: 'general',
              name: 'settings-dashboard-general',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_general" */ './views/settings/sections/dashboard/general'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'searches',
              name: 'settings-dashboard-searches',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_searches" */ './views/settings/sections/dashboard/searches'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'api_users',
              component: Defaulter,
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              },
              children: [
                {
                  path: '',
                  name: 'settings-dashboard-api-users',
                  component: () =>
                    import(
                      /* webpackChunkName: "settings_dashboard_api_users" */ './views/settings/sections/dashboard/api_users'
                    ),
                  beforeEnter: requireAuth,
                  meta: {
                    scopes: ['settings:dashboard:read']
                  }
                }
              ]
            },
            {
              path: 'api_keys',
              name: 'settings-dashboard-api-keys',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_api_keys" */ './views/settings/sections/dashboard/api_keys'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'analytics',
              name: 'settings-dashboard-analytics',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_analytics" */ './views/settings/sections/dashboard/analytics'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'custom_dashboards',
              name: 'settings-dashboard-custom-dashboards',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_custom_dashbaords" */ './views/settings/sections/dashboard/custom-dashboards'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'integrations',
              name: 'settings-dashboard-integrations',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_integrations" */ './views/settings/sections/dashboard/integrations'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'communications',
              name: 'settings-dashboard-communications',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_communications" */ './views/settings/sections/dashboard/communications'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'fiscalization',
              name: 'settings-dashboard-fiscalization',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_communications" */ './views/settings/sections/dashboard/fiscalizations'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'customers',
              name: 'settings-dashboard-customers',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_customers" */ './views/settings/sections/dashboard/customers'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'suppliers',
              name: 'settings-dashboard-suppliers',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_suppliers" */ './views/settings/sections/dashboard/suppliers'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'voucher-systems',
              name: 'settings-dashboard-voucher-systems',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_voucher_systems" */ './views/settings/sections/dashboard/voucher-systems'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'products',
              name: 'settings-dashboard-products',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_products" */ './views/settings/sections/dashboard/products'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'staff',
              name: 'settings-dashboard-staff',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_staff" */ './views/settings/sections/dashboard/staff'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'accounting',
              name: 'settings-dashboard-accounting',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_accounting" */ './views/settings/sections/dashboard/accounting'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'trash',
              name: 'settings-dashboard-trash',
              component: () =>
                import(
                  /* webpackChunkName: "settings_dashboard_trash" */ './views/settings/sections/dashboard/trash'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            },
            {
              path: 'reservations',
              name: 'settings-dashboard-reservations',
              component: () =>
                import(
                  /* webpackChunkName: "reservations" */ './views/settings/sections/dashboard/reservations/index.vue'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:dashboard:read']
              }
            }
          ]
        },
        {
          name: 'account',
          path: 'account',
          component: () =>
            import(
              /* webpackChunkName: "settings_account" */ './views/settings/sections/account'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['settings:account']
          }
        },
        {
          path: 'timetracking',
          component: () =>
            import(
              /* webpackChunkName: "settings_timetracking" */ './views/settings/sections/timetracking'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['settings:timetracking']
          }
        },
        {
          name: 'pos',
          path: 'pos',
          component: () =>
            import(
              /* webpackChunkName: "settings_pos" */ './views/settings/sections/pos'
            ),
          beforeEnter: requireAuth,
          meta: {
            scopes: ['settings:pos']
          },
          children: [
            {
              path: '',
              name: 'settings-pos',
              redirect: (to) => getParentRedirectTo(['/settings', 'pos'])
            },
            {
              path: 'discounts',
              name: 'settings-pos-discounts',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_discounts" */ './views/settings/sections/pos/discounts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'change_suggestions',
              name: 'settings-pos-change-suggestions',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_change_suggestions" */ './views/settings/sections/pos/change-suggestions'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'security',
              name: 'settings-pos-security',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_security" */ './views/settings/sections/pos/security'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'search',
              name: 'settings-pos-search',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_search" */ './views/settings/sections/pos/search'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'check_out',
              name: 'settings-pos-check-out',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_check_out" */ './views/settings/sections/pos/check_out'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'gastronomy',
              name: 'settings-pos-gastronomy',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_gastronomy" */ './views/settings/sections/pos/gastronomy'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'financials',
              name: 'settings-pos-financials',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_financials" */ './views/settings/sections/pos/financials'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'italian_financials',
              name: 'settings-pos-italian-financials',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_italian_financials" */ './views/settings/sections/pos/italian_financials'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'receipts',
              name: 'settings-pos-receipts',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_receipts" */ './views/settings/sections/pos/receipts'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            },
            {
              path: 'tipping',
              name: 'settings-pos-tipping',
              component: () =>
                import(
                  /* webpackChunkName: "settings_pos_tipping" */ './views/settings/sections/pos/tipping'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:pos:read']
              }
            }
          ]
        },
        {
          path: 'users',
          component: Defaulter,
          beforeEnter: requireAuth,
          meta: {
            scopes: ['settings:users:read']
          },
          children: [
            {
              path: '',
              name: 'settings-users',
              component: () =>
                import(
                  /* webpackChunkName: "settings_users" */ './views/settings/sections/users/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:users:read']
              }
            },
            {
              path: 'new',
              name: 'settings-users-new',
              component: () =>
                import(
                  /* webpackChunkName: "settings_users_edit" */ './views/settings/sections/users/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:users:create']
              }
            },
            {
              path: ':id',
              redirect: { name: 'settings-users-edit' }
            },
            {
              path: ':id/edit',
              name: 'settings-users-edit',
              component: () =>
                import(
                  /* webpackChunkName: "settings_users_edit" */ './views/settings/sections/users/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['settings:users:update']
              }
            }
          ]
        },
        {
          path: 'webhooks',
          component: () =>
            import(/* webpackChunkName: "webhooks" */ './views/webhooks'),
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'webhooks-list',
              component: () =>
                import(
                  /* webpackChunkName: "webhooks-all" */ './views/webhooks/all'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['webhooks:read']
              }
            },
            {
              path: 'new',
              name: 'webhooks-new',
              component: () =>
                import(
                  /* webpackChunkName: "webhooks_edit" */ './views/webhooks/edit'
                ),
              beforeEnter: requireAuth,
              meta: {
                scopes: ['webhooks:create']
              }
            },
            {
              path: ':id',
              component: () =>
                import(
                  /* webpackChunkName: "webhooks_edit" */ './views/webhooks/edit'
                ),
              beforeEnter: requireAuth
            },
            noMatch
          ]
        },
        noMatch
      ]
    },
    {
      path: '/login',
      component: Login,
      beforeEnter(to, from, next) {
        // To check IE solution came from:
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
        if (window?.navigator?.userAgent?.indexOf('Trident') > -1)
          next('/browser')
        if (store.getters['Auth/isAuthenticated']) next('/')
        else next()
      }
    },
    {
      path: '/login/support',
      component: () =>
        import(/* webpackChunkName: "auth_support" */ './views/auth/support'),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // TODO: clearing does not help top level authentication state as it is. Deferring.
          store.dispatch('Auth/clearStateOnLogin')
          store.dispatch('Config/clearLocationState')
          next()
        }
      }
    },

    {
      path: '/login/password_reset/:password_reset_id',
      name: 'password-reset',
      component: () =>
        import(
          /* webpackChunkName: "create-password" */ '@/views/auth/create-password'
        )
    },
    {
      path: '/password_forgotten',
      component: () =>
        import(
          /* webpackChunkName: "auth_password_forgotten" */ './views/auth/password-forgotten'
        )
    },
    {
      path: '/logout',
      component: () =>
        import(/* webpackChunkName: "auth_logout" */ './views/auth/logout')
    },
    {
      path: '/401',
      component: () =>
        import(/* webpackChunkName: "errors_401" */ './views/errors/401')
    },
    {
      path: '/403',
      component: () =>
        import(/* webpackChunkName: "errors_403" */ './views/errors/403')
    },
    {
      path: '/404',
      component: () =>
        import(/* webpackChunkName: "errors_404" */ './views/errors/404')
    },
    {
      path: '/500',
      name: '500',
      component: () =>
        import(/* webpackChunkName: "errors_500" */ './views/errors/500'),
      props: true
    },
    {
      path: '/504',
      component: () =>
        import(/* webpackChunkName: "errors_504" */ './views/errors/504')
    },
    {
      path: '/browser',
      component: () =>
        import(
          /* webpackChunkName: "errors_browser" */ './views/errors/browser'
        )
    },
    noMatch
  ]
})

router.onError(function (err) {
  router.push({
    name: err.status ? err.status.toString() : '500',
    params: { error: err }
  })
})

router.afterEach((to, from) => {
  // Reload userPilot on page change
  userPilotReload()

  //If user open a link in a new tab, the `to` and `from` will be the same object
  const isSameRoute = from.fullPath === to.fullPath
  const hasSameBaseRoute = to.path.startsWith(from.path)
  if (!isSameRoute && hasSameBaseRoute) {
    router.options.lastFullPath = from.fullPath
  }

  const nameMatch = to.name?.match(/^(?:reports-)(.*)$/)
  if (nameMatch) {
    ampliPlugin.setProperty('reports_tab', nameMatch[1])
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route?.meta?.scopes) {
          const isParent = !!route?.children?.length
          navigateWithPermissions(route.meta, isParent, next)
        }
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                // NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

function navigateWithPermissions({ scopes }, isParent = false, next) {
  const role = safeGet(store, 'state.Auth.role')
  const userScopes = safeGet(store, 'state.Auth.scopes')
  if (!isParent && !checkPermissions({ scopes }, role, userScopes)) {
    const params = qs.stringify(missingScopes(scopes, userScopes), {
      addQueryPrefix: true
    })
    next({ path: `/403${params}` })
  }
}

function getParentRedirectTo(pathToChildren) {
  const role = safeGet(store, 'state.Auth.role')
  const userScopes = safeGet(store, 'state.Auth.scopes')

  const appConfigStore = useAppConfigStore()
  const { featureLock, featureConfig } = appConfigStore

  return getChildNameByPermissions(
    pathToChildren,
    router.options.routes,
    role,
    userScopes,
    featureConfig,
    featureLock
  )
}

// Prevent vue-router navigation guard from throwing errors
// Solution based on a comment by vue-router's main maintainer
// Source: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
/*
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
Router.prototype.push = routerFailureHandling(originalPush)
Router.prototype.replace = routerFailureHandling(originalReplace)


function routerFailureHandling(originalMethod) {
  return function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalMethod.call(this, location, onResolve, onReject)
    }
    return originalMethod.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err)) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.warn('NavigationFailure', err)
        }
        // resolve err
        return err
      }
      // rethrow error
      return Promise.reject(err)
    })
  }
}
*/
export default router
