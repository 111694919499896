<template>
  <notification-base :class="{ 'opacity-50': disabled }">
    <template #icon_start>
      <svgicon
        :src="require('@/assets/icons/th-icon-warning-outline.svg')"
        :style="{
          height: '22px',
          width: '22px',
          fill: 'var(--disabled-color-light)'
        }"
      />
    </template>
    <template #content>
      <slot name="content">
        <div class="text-th-light-gray font-light text-sm tracking-wide">
          <slot name="content_upper_row" />
        </div>
        <div class="text-th-secondary-gray">
          <slot name="content_lower_row" />
        </div>
      </slot>
    </template>
    <template v-if="canClick" #icon_end>
      <el-button
        text
        class="focus:outline-none focus:shadow-outline p-0"
        :disabled="disabled"
      >
        <el-icon class="th-flip text-2xl"><RefreshRight /></el-icon>
      </el-button>
    </template>
  </notification-base>
</template>

<script>
import NotificationBase from '../base'

export default {
  components: {
    NotificationBase
  },
  props: {
    /**
     * canClick only tells the component if to render the button.
     * The actual click event should propagate to the parent and being triggered there
     */
    canClick: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.th-flip {
  transform: scale(-1, 1);
}
</style>
