<template>
  <th-page-wrapper v-loading="true" />
</template>

<script>
import get from 'just-safe-get'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    redirectTo: null
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isSupport: 'Auth/isSupport',
      userAssignedLocations: 'Auth/getUserAssignedLocations',
      currentLocation: 'Config/getCurrentLocation'
    }),
    userIsEverywhere() {
      return this.userAssignedLocations === null
    },
    promptForLocation() {
      return (
        !this.currentLocation &&
        !this.userIsEverywhere &&
        !(this.isAdmin || this.isSupport)
      )
    }
  },
  watch: {
    currentLocation(newLocation) {
      if (newLocation) this.redirectUser()
    }
  },
  async created() {
    await this.$store.dispatch('Auth/waitThInitializated')
    await this.$store.dispatch('Config/getClientAccountConfiguration')
    // In case a sub-user is assigned to a staff, redirect to the user-profile
    const redirectUserProfile =
      !!get(this.$store, 'state.Auth.thUserId') &&
      !!get(this.$store, 'state.Auth.staff')

    const userProfilePath = redirectUserProfile ? '/user_profile' : null
    this.redirectTo = this.$route.query.redirect || userProfilePath || '/'

    this.redirectUser()
  },
  methods: {
    redirectUser() {
      if (this.promptForLocation) return
      this.redirectTo && this.$router.replace(this.redirectTo)
    }
  }
}
</script>
