<template>
  <section class="sidebar">
    <el-aside :class="isCollapsed ? 'collapsed-width' : 'aside-width'">
      <el-container>
        <el-header
          :key="isCollapsed"
          class="sidebar-brand"
          :class="{ collapsed: isCollapsed }"
          @click="$router.push('/')"
        >
          <th-image
            v-if="isCollapsed === false"
            :src="logo"
            class="sidebar-image"
            contain
            position="left center"
            width="100%"
            height="100%"
          />

          <th-image
            v-if="isCollapsed === true"
            :key="isCollapsed"
            :src="collapsedLogo"
            class="sidebar-brand-image"
            position="center center"
            contain
            width="100%"
            height="100%"
          />
        </el-header>
        <navigation :collapsed="isCollapsed" />
        <bottom>
          <div class="collapse-wrapper">
            <span v-if="!isCollapsed" class="version" @click="toggleQA">
              <version />
            </span>
            <button
              class="icon-wrapper outline-none focus:shadow-outline"
              @click="handleCollapse"
            >
              <el-icon>
                <ArrowLeft v-if="!isCollapsed" />
                <ArrowRight v-else />
              </el-icon>
            </button>
          </div>
        </bottom>
      </el-container>
    </el-aside>
  </section>
</template>
<script>
import Navigation from '@/components/sidebar/navigation.vue'
import Bottom from '@/components/sidebar/bottom.vue'
import Version from '@/components/version'
import debounce from 'debounce'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'
import { useWhitelabelThemeStore } from '@/store/whitelabel-theme'
import { isUnifiedCommerce } from '@/constants'

const DEFAULT_LOGO =
  'https://storage.googleapis.com/tillhub-api-images/dashboard/logos/tillhub-logo-white%403x.png'
const DEFAULT_COLLAPSED_LOGO =
  'https://storage.googleapis.com/tillhub-api-images/dashboard/logos/tillhub-logo-white-collapsed%403x.png'

// const DEFAULT_LOGO_ICON = 'https://storage.googleapis.com/tillhub-api-images/dashboard/logos/tillhub-logo-white-collapsed%403x.png'

export default {
  components: {
    Navigation,
    Version,
    Bottom
  },
  props: {},
  setup() {
    const themeStore = useWhitelabelThemeStore()
    const appConfigStore = useAppConfigStore()
    const { isSidebarCollapsed: isCollapsed } = storeToRefs(appConfigStore)
    const { toggleSidebar, toggleFeatures } = appConfigStore

    return { isCollapsed, toggleSidebar, toggleFeatures, themeStore }
  },
  data() {
    return {
      qaCounter: 0,
      userSelfCollapsedSidebar: false
    }
  },
  computed: {
    logo() {
      return this.themeStore.customLogo || DEFAULT_LOGO
    },
    collapsedLogo() {
      return this.themeStore.customLogoCollapsed || DEFAULT_COLLAPSED_LOGO
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  mounted() {
    this.isCollapsed = false
    this.shouldToggleSidebar()
    window.addEventListener('resize', this.shouldToggleSidebar)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.shouldToggleSidebar)
  },
  methods: {
    handleCollapse() {
      this.toggleSidebar()
      this.userSelfCollapsedSidebar = this.isCollapsed
    },
    toggleQA() {
      if (
        process.env.NODE_END !== 'production' ||
        window.location.origin === 'https://staging-dashboard.tillhub.com'
      ) {
        this.qaCounter++
        setTimeout(() => {
          if (this.qaCounter >= 3) {
            this.toggleFeatures()
            this.qaCounter = 0
            return
          }
          if (this.qaCounter > 0) this.qaCounter--
        }, 350)
      }
    },
    shouldToggleSidebar: debounce(function () {
      const windowWidth = window.innerWidth
      if (this.isCollapsed === false && windowWidth <= 1100) {
        this.isCollapsed = true
        this.userSelfCollapsedSidebar = false
      } else if (
        this.isCollapsed === true &&
        this.userSelfCollapsedSidebar === false &&
        windowWidth > 1100
      ) {
        // Automitically uncollapse only if user didn't collapse the sidebar by themselves
        this.isCollapsed = false
      }
    }, 100)
  }
}
</script>

<style scoped>
.sidebar * {
  user-select: none;
}

.sidebar-brand {
  height: 103px !important;
  flex: 0 1 103px;
  padding: 2rem 1.4rem;
}

.sidebar-brand.collapsed {
  height: 60px !important;
  flex: 0 1 60px;
  padding: 1rem !important;
}

.collapse-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 8px 20px;
  border-top: 1px solid var(--sidebar-separator-color);
}

.version {
  flex: 1 1 auto;
}

.icon-wrapper {
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

.collapsed-width {
  width: 65px;
}

.aside-width {
  width: var(--aside-width);
}
</style>
