import { parse, format, startOfMonth, startOfWeek, endOfWeek } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import isEmpty from 'just-is-empty'
import qs from 'qs'
import store from '@/store'

/**
 * Get the date format that was set by the user in the configuration object or a default format
 * @param {object} [clientConfig] - the client configuration object
 * @returns {string} - date format
 */
export function getDateFormat() {
  return store.getters['Config/getDateFormat']
}

export function getTimeFormat() {
  return store.getters['Config/getTimeFormat']
}

export function getDateTimeFormat() {
  return store.getters['Config/getDateTimeFormat']
}

export function getTimezone() {
  return store.getters['Config/getTimeZone']
}

export function getDefaultDateSelected() {
  return store.getters['Config/getDefaultDateSelected']
}

/**
 * Validate HH:MM time format
 * Source: https://stackoverflow.com/a/7536768
 * The RegEx is intentionally wrapped in a function to make it full proof when using it alongside the test method and the global flag
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#Using_test()_on_a_regex_with_the_global_flag
 */
export function timeRegex() {
  return /^(0[0-9]|1[0-9]|2[0-3]|):[0-5][0-9]$/
}

/**
 * Get the date format that was set by the user in the configuration object or a default format
 * @param {string, Date} [dates] - start, end date
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 28.7.2021
 */
export function formatDateRange(dates, newFormat) {
  if (dates.start && dates.end) {
    const defaultDateFormat = getDateFormat()
    const startLabel = format(
      new Date(dates.start),
      newFormat || defaultDateFormat
    )
    const endLabel = format(new Date(dates.end), newFormat || defaultDateFormat)
    return startLabel === endLabel ? startLabel : `${startLabel} - ${endLabel}`
  } else {
    return null
  }
}

/**
 * Formats a Date according to the ISO8601 standard
 * @warn The returned string contains just the date (2023-01-01)
 * @param {string | Date} date
 */
export function formatDateToISO(date) {
  return format(date, 'yyyy-MM-dd')
}

/**
 * Get formated date with default format from configuration
 * @param {string, Date} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 28.7.2021
 */
export function formatDate(date, newFormat) {
  return format(new Date(date), newFormat || getDateFormat())
}

/**
 * Get formated time with default format from configuration
 * @param {string, Date} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 10:20
 */
export function formatTime(date, newFormat) {
  return format(new Date(date), newFormat || getTimeFormat())
}

/**
 * Get formated date and time with default format from configuration
 * @param {string, Date} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 28.7.2021 10:20
 */
export function formatDateTime(date, newFormat) {
  return format(new Date(date), newFormat || getDateTimeFormat())
}

/**
 * Get formated date with timezone and default format from configuration
 * @param {(string | Date)} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [timezone] - override default time zone
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 28.7.2021
 */
export function formatTimeWithTimezone(date, timezone, newFormat) {
  const zonedDate = utcToZonedTime(new Date(date), timezone || getTimezone())
  return formatTime(zonedDate, newFormat)
}

/**
 * Get formated date with timezone and default format from configuration
 * @param {(string | Date)} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [timezone] - override default time zone
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 10:20
 */
export function formatDateWithTimezone(date, timezone, newFormat) {
  const zonedDate = utcToZonedTime(new Date(date), timezone || getTimezone())
  return formatDate(zonedDate, newFormat)
}

/**
 * Get formated date time with timezone and default format from configuration
 * @param {(string | Date)} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @param {string} [timezone] - override default time zone
 * @param {string} [newFormat] - override default date format
 * @returns {string} - 28.7.2021 10:20
 */
export function formatDateTimeWithTimezone(date, timezone, newFormat) {
  const zonedDate = utcToZonedTime(
    new Date(addTimezoneIfMissing(date)),
    timezone || getTimezone()
  )
  return formatDateTime(zonedDate, newFormat)
}

/**
 * Get default date range for the current month - from beginning of the month until today.
 * @returns {Object} - { start, end }
 */
export function defaultDateRange() {
  return {
    start: startOfMonth(new Date()),
    end: new Date()
  }
}

/**
 * Append +00:00 timezone to date string if missing.
 * @param {string} - 2022-03-09T11:48:38
 * @returns {string} - Date string 2022-03-09T11:48:38+00:00
 */
export function addTimezoneIfMissing(date) {
  const timeRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/g
  if (timeRegex.test(date)) date += '+00:00'
  return date
}

export function applyFiltersBeforeRouteEnter({
  query,
  path,
  next,
  filters,
  dateFilterName,
  sort
}) {
  if (isEmpty(query)) {
    const dateSelected = getDefaultDateSelected()
    const dateKey = dateFilterName || 'date'
    const dateFilter = {}
    dateFilter[dateKey] = getRangeFor[dateSelected]?.()
    const query = qs.stringify({
      filter: { ...dateFilter, ...filters }
    })

    let sortString = ''
    if (sort && !query['orderFields[]']) {
      sortString = qs.stringify({ 'orderFields[]': sort })
    }

    next(`${path}?${query}&${sortString}`)
    return
  } else {
    if (sort && !query['orderFields[]']) {
      const strQuery = qs.stringify(query)
      const sortString = qs.stringify({ 'orderFields[]': sort })
      next(`${path}?${strQuery}&${sortString}`)
    } else {
      next()
    }
  }
}

export function getLastUpdatedDate(buildDate, isProd) {
  const sixMins = 6 * 60 * 1000
  const extraBuildTime = isProd ? sixMins : 0
  return new Date(new Date(buildDate).getTime() + extraBuildTime)
}

export function setHours(date, ...time) {
  date.setHours(...time)
  return date
}

export const currentDateRange = () => ({
  start: setHours(new Date(), 0, 0, 0, 0),
  end: setHours(new Date(), 23, 59, 59, 999)
})

export function getRangeThisMonth() {
  const date = new Date()
  const start = new Date(date.getFullYear(), date.getMonth(), 1)
  const end = new Date()
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeLastMonth() {
  const date = new Date()
  const start = new Date(date.getFullYear(), date.getMonth() - 1, 1)
  const end = new Date(date.getFullYear(), date.getMonth(), 0)
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeThisWeek() {
  const start = startOfWeek(new Date(), { weekStartsOn: 1 })
  const end = new Date()
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeLastWeek() {
  const weekDay = new Date()
  const lastWeek = weekDay.getDate() - 7
  weekDay.setDate(lastWeek)
  const start = startOfWeek(weekDay, { weekStartsOn: 1 })
  const end = endOfWeek(weekDay, { weekStartsOn: 1 })
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeYesterday() {
  const start = new Date()
  const end = new Date()
  start.setDate(start.getDate() - 1)
  start.setHours(0, 0, 0, 0)
  end.setDate(end.getDate() - 1)
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeThisYear() {
  const date = new Date()
  const start = new Date(date.getFullYear(), 0, 1)
  const end = new Date()
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export function getRangeLastYear() {
  const date = new Date()
  const start = new Date(date.getFullYear() - 1, 0, 1)
  const end = new Date(date.getFullYear(), 0, 0)
  end.setHours(23, 59, 59, 999)
  return { start, end }
}

export const getRangeFor = {
  thisMonth: getRangeThisMonth,
  lastMonth: getRangeLastMonth,
  thisWeek: getRangeThisWeek,
  lastWeek: getRangeLastWeek,
  yesterday: getRangeYesterday,
  today: currentDateRange,
  thisYear: getRangeThisYear,
  lastYear: getRangeLastYear
}

/**
 * Get start of the day ISO date
 * @param {(string | Date)} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @returns {string} - "2021-07-28T00:00:00.000Z"
 */
export function getStartOfDay(date) {
  if (!date) {
    return date
  }
  const dateObj = new Date(date)
  dateObj.setUTCHours(0, 0, 0, 0)
  return dateObj.toISOString()
}

/**
 * Get end of the day ISO date
 * @param {(string | Date)} [date] - unformatted date "2021-07-28T10:20:00.000Z"
 * @returns {string} - "2021-07-28T23:59:00.000Z"
 */
export function getEndOfDay(date) {
  if (!date) {
    return date
  }
  const dateObj = new Date(date)
  dateObj.setUTCHours(23, 59, 59, 0) //one second before end of the day
  return dateObj.toISOString()
}

export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export function getLocalizedAbbreviatedMonth(month, locale) {
  const MONTHS = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11
  }

  const now = new Date()
  const date = new Date(now.getFullYear(), MONTHS[month])
  const localizedAbbreviatedMonth = date.toLocaleString(locale, {
    month: 'short'
  })

  return localizedAbbreviatedMonth
}

export function getLocalizedAbbreviatedDaysOfMonth(value, locale) {
  return parse(value, 'dd.MM', new Date()).toLocaleString(locale, {
    day: 'numeric',
    month: 'short'
  })
}
