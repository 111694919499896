import Decimal from 'decimal.js-light'

export function genRoundedPercent(value) {
  const floatValue = parseFloat(value)
  if (!Number.isFinite(floatValue)) return null
  const percent = new Decimal(floatValue).mul(100).toDecimalPlaces(1).toNumber()
  return `${percent} %`
}

export function parseNumberString(string) {
  const number = parseFloat(string)
  return Number.isFinite(number) ? number : null
}

// return float number with rounded numbers as decimal places
export function getRoundedNumber(decimalObj, decimalPlaces = 2) {
  return decimalObj
    .toDecimalPlaces(decimalPlaces, Decimal.ROUND_HALF_UP)
    .toNumber()
}

export function removePercentSign(value) {
  return value?.replace(/%/g, '').trim() ?? value
}

export function encodePercent(value) {
  return new Decimal(value).mul(100).toNumber()
}

export function decodePercent(value) {
  return new Decimal(value).div(100).toNumber()
}

//this function will return number with suffix (1st, 2nd, 3rd...)
export function numberToTextFormat(number, locale) {
  if (locale === 'de-DE') {
    return numberToGermanFormat(number)
  }
  return numberToEnglishFormat(number)
}

export function numberToEnglishFormat(number) {
  let suffix = 'th'

  if (number % 10 === 1 && number % 100 !== 11) {
    suffix = 'st'
  } else if (number % 10 === 2 && number % 100 !== 12) {
    suffix = 'nd'
  } else if (number % 10 === 3 && number % 100 !== 13) {
    suffix = 'rd'
  }

  return number + suffix
}

export function numberToGermanFormat(number) {
  let suffix = '.' //default suffix
  return number + suffix
}

export function getNumberAbbreviationParams(num, digits) {
  // Translations taken from https://www.unicode.org/cldr/cldr-aux/charts/29/verify/numbers/
  const lookup = [
    { value: 1, key: 'common.numbers.abbreviations.regular' },
    { value: 1e3, key: 'common.numbers.abbreviations.thousand' },
    { value: 1e6, key: 'common.numbers.abbreviations.million' },
    { value: 1e9, key: 'common.numbers.abbreviations.billion' },
    { value: 1e12, key: 'common.numbers.abbreviations.trillion' }
  ]

  const regexp = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup.findLast((item) => num >= item.value)
  return {
    value: item ? (num / item.value).toFixed(digits).replace(regexp, '') : '0',
    key: item ? item.key : 'common.numbers.abbreviations.regular'
  }
}
