import safeGet from 'just-safe-get'

export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  return [
    {
      value: '/utilities/cms/contents',
      key: 'nav.main.items.utilities.cms.contents',
      enabled: !!safeGet(featureConfig, 'utilities.cms.contents'),
      scopes: ['utilities:cms:read']
    },
    {
      value: '/utilities/cms/content_templates',
      key: 'nav.main.items.utilities.cms.content_templates',
      enabled: !!safeGet(featureConfig, 'utilities.cms.content_templates'),
      scopes: ['utilities:cms:read']
    }
  ]
}
