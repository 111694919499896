const sleep = (timeout) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })

const waitAndRetry = async (func, timeout, tries = 3) => {
  const response = await func()
  if (tries > 0 && response === undefined) {
    sleep(timeout)
    tries = tries - 1
    return waitAndRetry(func, timeout, tries)
  }
  return response
}

const getEphemeralUrl = async () => {
  try {
    const response = await fetch(`/config.json`)
    const result = await response.json()
    if (!result) return undefined
    return result.VUE_APP_EPHEMERAL_URL
  } catch (err) {
    return undefined
  }
}

const getBaseUrlInit = () => {
  let baseUrl
  return async () => {
    if (baseUrl) return baseUrl

    const ephemeralUrl = await waitAndRetry(getEphemeralUrl, 2000, 3)
    if (ephemeralUrl) {
      baseUrl = ephemeralUrl
    } else {
      baseUrl = process.env.VUE_APP_API_BASE
    }

    return baseUrl
  }
}

export const getBaseUrl = getBaseUrlInit()
