<template>
  <div class="th-middle-page-wrapper" :style="{ 'background-color': bgColor }">
    <!-- Wallpaper -->
    <wallpaper class="wallpaper-wrap" />

    <!-- Logo -->
    <div class="logo-wrapper text-th-secondary">
      <svgicon
        v-if="!logo"
        :src="require('@/assets/icons/th-logo.svg')"
        class="fill-current"
      />
      <th-image v-else contain width="100%" height="100%" :src="logo" />
    </div>

    <!-- Slot -->
    <div class="middle-page" :class="{ ['has-wallpaper']: hasWallpaper }">
      <slot />
    </div>
  </div>
</template>

<script>
/*
  Component used for auth pages where there is only one form in the middle of the screen. 
  It supports wallpaper, logo and background color from whitelabel configuration
*/
import Wallpaper from '@components/wallpaper'
import { useWhitelabelThemeStore } from '@/store/whitelabel-theme'

export default {
  name: 'MiddlePageWrapper',
  components: {
    Wallpaper
  },
  setup() {
    return { themeStore: useWhitelabelThemeStore() }
  },
  computed: {
    bgColor() {
      return this.themeStore.customWelcomeBGColor
    },
    hasWallpaper() {
      return !!this.themeStore.customWallpaper || this.bgColor
    },
    logo() {
      return (
        this.themeStore.customWelcomeLogo || this.themeStore.customLogo || null
      )
    }
  }
}
</script>

<style scoped>
.th-middle-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  overflow: auto;
  padding: 2rem;
}

.logo-wrapper {
  width: 100%;
  height: 100px;
  max-width: 280px;
  max-height: 100px;
  text-align: center;
  margin-bottom: 4rem;
  z-index: 1;
  flex-shrink: 0;
}

.logo-wrapper svg {
  width: 100%;
  height: 100%;
}

.middle-page {
  position: relative;
  width: 280px;
  max-width: 100%;
}

.middle-page.has-wallpaper {
  width: calc(280px + 4rem);
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
}

.wallpaper-wrap {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Override elements ui */
.middle-page :deep(.el-input .el-input__inner[type='number']) {
  text-align: right;
}

.middle-page :deep(.leading-normal .el-form-item__content) {
  line-height: 1;
}

.middle-page :deep(.el-form-item__label) {
  line-height: 1.2;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  padding-right: 0;
  float: none;
  color: var(--label-text-color);
  display: block;
}

.middle-page :deep(.el-button) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.middle-page
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:after,
.middle-page
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:after {
  content: '*';
  color: var(--error-color);
  margin-left: 2px;
}

.middle-page
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.middle-page
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:before {
  content: '';
  margin-right: 0;
}
</style>
