<template>
  <el-popover
    v-if="hasButtons"
    placement="bottom"
    :title="exportOptions.waitingTitle || undefined"
    width="200"
    trigger="manual"
    :visible="
      !!(
        waiting &&
        (exportOptions.waitingContent || exportOptions.waitingTitle)
      )
    "
  >
    <div class="popover-content">
      <p v-text="exportOptions.waitingContent" />
    </div>
    <template #reference>
      <div v-if="hasButtons">
        <el-button
          v-if="computedButtons.length === 1 && !hasDropdownSlot"
          type="primary"
          :class="getClassNames(computedButtons[0], ['main-button'])"
          :loading="loading"
          @click="getHandler(computedButtons[0])"
        >
          <svgicon
            v-if="computedButtons[0].svgicon"
            :src="require(`@/assets/icons/${computedButtons[0].svgicon}.svg`)"
            :style="{ height: '20px', width: '20px' }"
            class="mr-1 fill-current"
          />
          <span>{{ getLabel(computedButtons[0]) }}</span>
        </el-button>

        <el-dropdown
          v-else
          split-button
          type="primary"
          :class="getClassNames(computedButtons[0])"
          class="flex items-center"
          @click="getHandler(computedButtons[0])"
        >
          <svgicon
            v-if="computedButtons[0].svgicon"
            :src="require(`@/assets/icons/${computedButtons[0].svgicon}.svg`)"
            :style="{ height: '20px', width: '20px' }"
            class="mr-1 fill-current"
          />
          <span>{{ getLabel(computedButtons[0]) }}</span>
          <template #dropdown>
            <el-dropdown-menu>
              <slot name="dropdown-items-start" />
              <div
                v-for="(button, index) in computedButtons.slice(1)"
                :key="index"
              >
                <el-dropdown-item
                  :class="getClassNames(button)"
                  @click.stop="getHandler(button)"
                >
                  <svgicon
                    v-if="button.svgicon"
                    :src="require(`@/assets/icons/${button.svgicon}.svg`)"
                    :style="{ height: '20px', width: '20px' }"
                    class="mr-1 fill-current"
                  />
                  <span>{{ getLabel(button) }}</span>
                </el-dropdown-item>
              </div>
              <slot name="dropdown-items-end" />
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </template>
  </el-popover>
</template>

<script>
import pRetry from 'p-retry'
import safeSet from 'just-safe-set'
import safeGet from 'just-safe-get'
import typeOf from 'just-typeof'
import * as axios from 'axios'
import { saveAs } from 'file-saver'

export default {
  props: {
    buttons: {
      type: Array,
      required: false,
      default: () => []
    },
    exportOptions: {
      type: Object,
      default: () => ({})
    },
    resourceOptions: {
      type: Object,
      required: false,
      default: () => {}
    },
    resourceInstance: {
      type: Object,
      required: true
    },
    resource: {
      type: [String, Boolean],
      required: true
    },
    customCreatePath: {
      type: String,
      default: null
    },
    doRoute: {
      type: Boolean,
      required: false,
      default: false
    },
    routeBase: {
      type: String,
      required: false,
      default: () => null
    },
    exportMapList: {
      type: Array,
      required: true
    },
    operations: {
      type: Object,
      required: false,
      default: () => ({
        create: true,
        update: true,
        delete: true
      })
    },
    downloadRetryOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      waiting: false,
      availableDefaultButtons: [
        {
          type: 'create',
          svgicon: 'th-icon-plus',
          label: this.$t('common.components.th_datatable.addNew'),
          clickHandler: () => {
            if (this.doRoute) {
              if (this.customCreatePath) {
                this.$router.push(`${this.customCreatePath}`)
              } else {
                this.$router.push(`${this.routeBaseDefault}/new`)
              }
            }
          },
          className: 'resource-actions-add-new'
        },
        {
          type: 'export',
          svgicon: 'th-icon-download',
          label: this.$t('common.components.th_datatable.export'),
          clickHandler: async ({ exportMapList, downloadUrl }) => {
            this.loading = true
            await this.handleExport({ exportMapList, downloadUrl })
            this.loading = false
          },
          className: 'resource-actions-export'
        },
        {
          type: 'custom_export',
          svgicon: 'th-icon-download',
          label: this.$t('common.components.th_datatable.export'),
          clickHandler: async ({ exportMapList, downloadUrl }) => {
            this.loading = true
            await this.handleExport({
              format: 'csv_custom',
              exportMapList,
              downloadUrl
            })
            this.loading = false
          },
          className: 'resource-actions-export'
        }
      ]
    }
  },
  computed: {
    hasDropdownSlot() {
      return (
        this.$slots['dropdown-items-start']()[0].children.length ||
        this.$slots['dropdown-items-end']()[0].children.length
      )
    },
    computedButtons() {
      const b = this.buttons
        .map((button) => {
          const findImplementation = (type) => {
            return this.availableDefaultButtons.find(
              (item) => item.type === type
            )
          }
          // we allow implementers to pass buttons in different configurations:
          // 1. by string: if the string matches to any available button, let's use that button as implementation
          // 2. as object with a click handler: even with no type the button fully qualifies and we use it as implementation
          // 3. as object with a type: we try to find a matching implementation as in 1.
          if (typeOf(button) === 'string') {
            const availableButton = findImplementation(button)
            if (availableButton) return availableButton
            throw new TypeError(
              'Button was requested as string, but no matching implementation was found'
            )
          } else if (typeOf(button) === 'object' && button.type) {
            const availableButton = findImplementation(button.type)
            if (!availableButton) return button
            const newButton = { ...availableButton, button }
            if (button.clickHandler) {
              newButton.clickHandler = button.clickHandler
            }
            return newButton
          }

          return button
        })
        .filter((button) => !!button)
        .filter((button) => this.isEnabled(button))

      return b
    },
    routeBaseDefault() {
      return this.routeBase || `/${this.resource || this.customResource}`
    },
    hasButtons() {
      return Boolean(this.computedButtons?.length)
    }
  },
  methods: {
    isEnabled(button) {
      // operations are allowed operations. If they are present but are set to be false
      // this button will not be display. This can e.g. happen to override user permissions
      if (
        this.operations &&
        typeOf(this.operations[button.type]) === 'boolean'
      ) {
        return this.operations[button.type]
      }
      return true
    },
    getClassNames(item, defaultNames = []) {
      const result = [...defaultNames]

      if (item.className) result.push(item.className)

      return result
    },
    getLabel(button) {
      if (button.label) {
        return button.label
      }

      return '--'
    },
    getHandler(button) {
      // this function now only sanitises inputs. Previously it assigned the handler itselft. That logic
      // is now done by the computed property
      const exportMapList =
        button.exportMapList && typeOf(button.exportMapList) === 'object'
          ? button.exportMapList
          : {}
      const downloadUrl =
        button.downloadUrl && typeOf(button.downloadUrl) === 'string'
          ? button.downloadUrl
          : undefined
      const filename = button.filename || undefined

      if (!button.clickHandler) {
        throw new TypeError('Button has no click handler but one was required')
      }

      if (typeOf(button.clickHandler) !== 'function') {
        throw new TypeError('Click handler handler is not a function')
      }

      return button.clickHandler({
        handleExport: this.handleExport,
        handleDownload: this.handleDownload,
        exportMapList,
        downloadUrl,
        filename,
        resourceOptions: this.resourceOptions
      })
    },
    /**
     * handle export is a service function function that can be passed to custom callers in order to handle the correct
     * exporting sequence
     */
    async handleExport(
      {
        format = 'csv',
        exportMapList,
        downloadUrl,
        filename,
        query,
        queryOptions,
        downloadRetryOptions,
        onDownloadReady
      } = {},
      cb
    ) {
      try {
        this.loading = true
        const exportMap =
          exportMapList && typeOf(exportMapList) === 'object'
            ? exportMapList
            : {}

        let url
        let result
        if (downloadUrl && typeOf(downloadUrl) === 'string') {
          url = downloadUrl
        } else {
          const opts = {
            ...(this.resourceOptions || {}),
            ...(queryOptions || {})
          }

          safeSet(opts, 'query', { ...opts.query, ...query })

          if (format) {
            opts.format = format
            // for legacy reasons we also attach to query
            safeSet(opts, 'query.format', format)
          }

          if (exportMap) {
            opts.export_map = exportMap
            // for legacy reasons we also attach to query
            safeSet(opts, 'query.export_map', exportMap)
          }

          // TODO: this is a bit optimistic, as it always assumes support of that API call for 'format'. We
          // should probably allow overriding it
          const results = await this.resourceInstance.getAll(opts)
          // TODO: this is a little bit too hard coded maybe
          url = safeGet(results, 'data.url')
          // we are allowing passing on the actual result, for cb-s to handle the incoming data themselves
          result = results
        }

        // for callers that want to conduct downloading themselves, we offer an optional callback
        if (cb) {
          cb(null, url, filename, result)
          return
        }

        await this.handleDownload(
          url,
          filename,
          downloadRetryOptions,
          onDownloadReady
        )
      } catch (err) {
        if (cb) {
          cb(err)
          return
        }
        this.$logException(err, {
          message: err.message
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * This function will try to make a HEAD request on the resource in a loop. A positive reply will indicate the existence of that file
     * and download it.
     */
    async handleDownload(
      downloadUrl,
      filename,
      options = this.downloadRetryOptions,
      cb
    ) {
      if (!downloadUrl) {
        throw new TypeError('Downloading requires URL to be set')
      }
      const inst = axios.create()

      const run = () =>
        inst({
          method: 'HEAD',
          url: downloadUrl,
          headers: {
            crossDomain: true
          }
        }).then((response) => {
          if (![200, 404].includes(response?.status)) {
            throw new pRetry.AbortError('unmatched response code')
          }

          return null
        })

      try {
        this.waiting = true
        await pRetry(run, {
          retries: 10,
          minTimeout: 3000,
          maxTimeout: 15000,
          factor: 1.5,
          ...(options || {}),
          onFailedAttempt: (error) => {
            // eslint-disable-next-line no-console
            console.log(
              `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} attempts left.`
            )
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: err.message
        })
      } finally {
        this.waiting = false
      }

      if (cb) {
        return cb(null, downloadUrl, filename)
      }

      saveAs(downloadUrl, filename ? encodeURIComponent(filename) : undefined)
    }
  }
}
</script>

<style scoped>
.main-button {
  height: var(--action-button-height);
  display: flex;
  align-items: center;
}

.popover-content {
  word-break: break-word;
}
</style>
