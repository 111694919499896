import { inject } from 'vue'

const injectionKey = Symbol('formatNumber')

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$formatNumber = function ThNumberFormatter(
      value,
      formatterOptions = {}
    ) {
      const precision = getPrecision(value, formatterOptions)
      const locale =
        formatterOptions?.locale || options?.i18n?.locale || 'en-US'
      return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        ...formatterOptions
      }).format(value)
    }

    app.provide(injectionKey, app.config.globalProperties.$formatNumber)
  }
}

export const useFormatNumber = () => inject(injectionKey)

function getPrecision(value, { precision = 2, dynamicPrecision = false }) {
  return dynamicPrecision && isInt(value) ? 0 : precision
}

function isInt(n) {
  return Number(n) % 1 === 0
}
