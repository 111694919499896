<template>
  <th-modal
    name="documentExport"
    width="600px"
    height="900px"
    :title="$t('common.actions.export')"
    body-class="flex flex-col"
    data-testid="available-in-modal"
    @close="handleClose"
  >
    <tabs v-model="activeTab" class="el-tabs-full w-full">
      <tab
        name="basic"
        :label="$t('components.th_datatable.export.basic')"
        :active="activeTab === 'basic'"
      >
        <DocumentExportSettingsBasic
          ref="settingsBasic"
          v-model="form"
          :force-all-columns="forceAllColumns"
        />
      </tab>
      <tab
        name="advanced"
        :label="$t('components.th_datatable.export.advanced')"
        :active="activeTab === 'advanced'"
      >
        <DocumentExportSettingsAdvanced v-model="form" />
      </tab>
    </tabs>
    <template #footer>
      <el-button @click="handleClose">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSave">
        {{
          form.is_recurring
            ? $t('common.interactions.buttons.schedule_export')
            : $t('common.interactions.buttons.export')
        }}
      </el-button>
    </template>
  </th-modal>
</template>
<script setup>
import th from '@tillhub/javascript-sdk'
import { defineProps, ref, inject, computed, watch } from 'vue'
import omit from 'just-omit'
import filter from 'just-filter-object'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import ThModal from '@/components/modal/index.vue'
import Tabs from '@/components/tabs'
import Tab from '@/components/tabs/tab'
import DocumentExportSettingsBasic from './document-export-settings-basic.vue'
import DocumentExportSettingsAdvanced from './document-export-settings-advanced.vue'
import {
  RECURRING_CYCLE,
  FILE_TYPES,
  COLUMN_SELECTION,
  ENCLOSURES,
  DELIMITERS,
  getRecurringOptions,
  getExportColumns
} from '../helpers.js'
import { isUnifiedCommerce } from '@/constants/index'

const props = defineProps({
  filters: {
    type: Array,
    default: () => []
  },
  resourceOptions: {
    type: Object,
    default: () => ({})
  },
  documentType: {
    type: String,
    required: true
  },
  filenamePrefix: {
    type: String,
    default: ''
  },
  headers: {
    type: Array,
    default: () => []
  },
  forceAllColumns: {
    type: Boolean,
    default: false
  },
  headerNameToExportColumnMap: {
    type: Object,
    default: () => ({})
  },
  singleExportHandler: {
    type: Function,
    default: () => {}
  },
  wrapQueryInObject: {
    type: Boolean,
    default: false
  }
})

const logException = inject('logException')
const message = inject('message')
const ampli = inject('ampli')

//state
const store = useStore()
const email = computed(() => store.getters['Auth/getMe']?.user?.email)
const isSupport = computed(() => store.getters['Auth/isSupport'])
const clientAccount = computed(() => store.getters['Auth/getUser'])
const clientAccountConfiguration = computed(
  () => store.getters['Config/getClientAccountConfiguration']
)
const timezone = computed(() => store.getters['Config/getTimeZonee'])

//i18n
const { t } = useI18n()

const activeTab = ref('basic')

const formConfiguration = computed(() => {
  return clientAccountConfiguration.value?.reports?.[props.documentType] || {}
})
const form = ref({
  column_selection:
    formConfiguration.value.column_selection ?? COLUMN_SELECTION.ALL,
  current_filters: formConfiguration.value.current_filters ?? true,
  delimiter: formConfiguration.value.delimiter ?? DELIMITERS.SEMICOLON,
  email: formConfiguration.value.email ?? email.value,
  enclosure: formConfiguration.value.enclosure ?? ENCLOSURES.DOUBLE,
  filter: formConfiguration.value.filter ?? props.resourceOptions,
  format: formConfiguration.value.format ?? FILE_TYPES.CSV,
  is_recurring: formConfiguration.value.is_recurring ?? false,
  recurring_cycle:
    formConfiguration.value.recurring_cycle ?? RECURRING_CYCLE.WEEKLY,
  recurring_on: formConfiguration.value.recurring_on ?? 1,
  show_column_name: formConfiguration.value.show_column_name ?? true,
  documentType: props.documentType
})

//watchers for change in form and saving it to the configuration
watch(
  () => form.value,
  () => {
    // do not save form values if user is from support team or document type is not supported
    if (props.documentType && !isSupport.value) {
      store.dispatch('Config/setClientAccountConfigurationValue', {
        path: `reports.${props.documentType}`,
        value: {
          ...omit(form.value, ['documentType', 'filter'])
        }
      })
    }
  },
  {
    deep: true
  }
)

const resourceFilters = computed(() => {
  const filtersToOmit = []
  if (!form.value.current_filters) {
    // When export all is selected, filter out user-configurable filters.
    filtersToOmit.push('q', 'start', 'end', 'date_start', 'date_end')
    props.filters.forEach((filter) => {
      filtersToOmit.push(filter.name)
      if (Array.isArray(filter.prop)) {
        // Include multi-prop filters
        filtersToOmit.push(...filter.prop)
      }
    })
  }

  // Flatten resource options.
  const resourceOptions = {
    ...props.resourceOptions,
    ...props.resourceOptions?.query
  }

  return filter(resourceOptions, (key, value) => {
    return (
      value !== undefined &&
      !['limit', 'offset', 'query', ...filtersToOmit].includes(key)
    )
  })
})

const exportQuery = computed(() => {
  const { forceAllColumns, filenamePrefix, wrapQueryInObject } = props
  const {
    email,
    documentType,
    column_selection,
    delimiter,
    format,
    enclosure,
    show_column_name
  } = form.value
  const columns = getExportColumns(
    props.headers,
    props.headerNameToExportColumnMap,
    forceAllColumns ? COLUMN_SELECTION.ALL : column_selection
  )
  let exportQuery = {
    timezone: timezone.value || 'Europe/Berlin',
    delimiter,
    format,
    enclosure,
    email,
    documentType
  }

  /* It was expected to be cammelCase, but it was rolled back and that broke UnzerOne, so we will do this FIX for the meanwhile, until it's back to cammelCase */
  exportQuery[
    isUnifiedCommerce() ? 'filenamePrefix' : 'filename_prefix'
  ] = filenamePrefix

  exportQuery[
    isUnifiedCommerce() ? 'columnNames' : 'show_column_name'
  ] = show_column_name

  exportQuery[isUnifiedCommerce() ? 'columns' : 'column_selection'] = columns

  exportQuery = wrapQueryInObject
    ? { exportMetaData: exportQuery }
    : exportQuery

  return {
    ...resourceFilters.value,
    ...exportQuery
  }
})

const scheduledExportQuery = computed(() => {
  const { email, documentType, column_selection, ...options } = form.value
  const { interval, startDate } = getRecurringOptions(options)

  return {
    documentType,
    interval,
    startDate,
    email,
    filter: {
      query: omit(exportQuery.value, ['date_start', 'date_end'])
    }
  }
})

const settingsBasic = ref(null)

//methods
const thModal = inject('thModal')

function handleClose() {
  thModal.hide('documentExport')
}
async function handleSave() {
  settingsBasic.value.form.validate(async (valid) => {
    if (valid) {
      const { is_recurring, documentType } = form.value

      //send event to ampli
      ampli.eventWithBaseProps('scheduledExportButtonClick', {
        export_recurring: is_recurring,
        export_document_type: documentType,
        client_id: clientAccount.value
      })
      await store.dispatch('Config/saveClientAccountConfiguration')
      return is_recurring ? createScheduledExport() : createExport()
    } else {
      // Switch to basic tab so user sees failed validations
      activeTab.value = 'basic'
    }
  })
}

async function createScheduledExport() {
  const resource = t('common.components.th_datatable.export')
  const successMessage = t('common.success.action.create.single', { resource })
  const errorMessage = t('common.error.action.create.single', { resource })
  let scheduledExportQueryValue = scheduledExportQuery.value
  if (props.wrapQueryInObject) {
    const filterQuery = {
      ...omit(scheduledExportQueryValue.filter.query, ['exportMetaData']),
      ...scheduledExportQueryValue.filter.query.exportMetaData
    }
    scheduledExportQueryValue.filter.query = filterQuery
  }
  try {
    const { data } = await th
      .scheduledExports()
      .create(scheduledExportQueryValue)
    if (data.id) {
      message({
        type: 'success',
        message: successMessage
      })
    }
  } catch (err) {
    return logException(err, {
      message: errorMessage
    })
  } finally {
    handleClose()
  }
}

async function createExport() {
  try {
    props.singleExportHandler?.(exportQuery.value)
  } catch (err) {
    return logException(err)
  } finally {
    handleClose()
  }
}
</script>

<style scoped>
.el-tabs-full :deep(.th-tabs-body),
.el-tabs-full :deep(.th-tabs-header-label) {
  flex-grow: 1;
  overflow: auto;
}
</style>
