import store from '@/store'
import { checkPermissions } from '@/utils/permissions'
import typeOf from 'just-typeof'

export default {
  install: (app) => {
    app.directive('permissions', {
      mounted(el, binding) {
        const { value } = binding
        const userScopes = store.getters['Auth/getUserScopes']
        const userRole = store.getters['Auth/getUserRole']
        if (value && typeOf(value) === 'object' && Object.keys(value).length) {
          const { scopes = [] } = value
          const isGuarded = scopes?.length
          if (!isGuarded) return true

          const hasPermissions =
            userScopes || userRole
              ? checkPermissions({ scopes }, userRole, userScopes)
              : false

          if (!hasPermissions) {
            app.$log.debug('v-permissions: does not have permissions')
            el.parentNode && el.parentNode.removeChild(el)
          }
        } else {
          app.$log.debug('v-permissions: no permissions object was passed')
        }
      }
    })

    app.config.globalProperties.$checkPermissions = ({ scopes = [] } = {}) => {
      const isGuarded = scopes?.length
      if (!isGuarded) return true
      const userScopes = store.getters['Auth/getUserScopes']
      const userRole = store.getters['Auth/getUserRole']
      if (!userScopes && !userRole) return false
      return checkPermissions({ scopes }, userRole, userScopes)
    }
  }
}
