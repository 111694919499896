/**
 * Factory function for creating plugin with own state.
 */
export const createPiniaPluginGlobalReset = () => {
  /**
   * @type {import('pinia').Store[]}
   */
  const registeredStores = []

  return {
    /**
     * Resets all pinia stores that have been registered with the
     * piniaPluginGlobalReset plugin and have not opted out of global reset.
     */
    resetStores: () => {
      registeredStores.forEach((store) => {
        if (typeof store.$globalReset === 'function') {
          store.$globalReset()
        } else if (typeof store.$reset === 'function') {
          store.$reset()
        }
      })
    },

    /**
     * Registers a store to be reset whenever resetPiniaStores is called.
     * All stores will be reset by default, but can opt out by providing a
     * globalReset: false option when creating the store. globalReset can also be
     * a function if you want to provide a custom reset function.
     *
     * Currently this plugin is used for resetting stores on logout.
     * @param {import('pinia').PiniaPluginContext} context
     */
    plugin: (context) => {
      const { store, options } = context
      const { globalReset = undefined } = options
      // We reset stores by default, so only skip registration when flag is
      // explicity set to false.
      if (globalReset === false) return

      registeredStores.push(store)

      const augmentation = {}

      // Override the $dispose funciton to remove the store from the list of
      // registered stores.
      const originalDispose = store.$dispose.bind(store)
      augmentation.$dispose = () => {
        registeredStores.splice(registeredStores.indexOf(store), 1)
        originalDispose()
      }

      if (typeof globalReset === 'function') {
        // Add the custom reset function to the store.
        augmentation.$globalReset = globalReset
      }

      // Return the augmentation object to be merged with the store.
      return augmentation
    }
  }
}

export const {
  plugin: piniaPluginGlobalReset,
  resetStores: resetPiniaStores
} = createPiniaPluginGlobalReset()
