import {
  formatDateRange,
  formatDate,
  formatTime,
  formatDateTime,
  formatDateTimeWithTimezone,
  formatTimeWithTimezone,
  formatDateWithTimezone,
  getDateFormat,
  getTimeFormat,
  getDateTimeFormat,
  getStartOfDay,
  getEndOfDay
} from '@/utils/date'

export default {
  install: (app) => {
    app.config.globalProperties.$date = {
      formatDateRange,
      getDateFormat,
      getTimeFormat,
      getDateTimeFormat,
      getStartOfDay,
      getEndOfDay,
      formatDate,
      formatTime,
      formatDateTime,
      formatDateWithTimezone,
      formatTimeWithTimezone,
      formatDateTimeWithTimezone
    }
  }
}

/**
 * @typedef {Object} ThFormatToTimeZoneOptions
 * @property {String} timeZone
 */
const defaultOptions = {
  timeZone: 'UTC'
}

// NOTE: this is necessary as the dependency is misaligned with date fns
function normaliseDFNSFormat(format) {
  return format.replace(/y/g, 'Y').replace(/d/g, 'D')
}
