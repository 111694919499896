<script>
import { h } from 'vue'

export default {
  name: 'ThImage',
  functional: true,
  props: {
    contain: {
      type: Boolean,
      default: false
    },
    cover: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'center'
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: undefined
    },
    padding: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    margin: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    maxHeight: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    width: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    src: {
      type: String,
      required: true
    }
  },
  methods: {
    genContent() {
      return h(
        'div',
        {
          staticClass: 'v-responsive__content'
        },
        this.$slots.default
      )
    }
  },
  render: function () {
    const contain = {
      'background-image': `url("${this.src}")`,
      'background-position': this.position,
      'background-repeat': 'no-repeat',
      'background-size': 'contain'
    }

    const cover = {
      'background-image': `url("${this.src}")`,
      'background-position': this.position,
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    }

    let style = {
      height: '100%',
      width: '100%'
    }

    if (this.contain === true) {
      style = {
        ...style,
        ...contain
      }
    } else if (this.cover === true) {
      style = {
        ...style,
        ...cover
      }
    }

    const node = h(
      'div',
      {
        staticClass: 'v-responsive',
        style: {
          margin: this.margin,
          padding: this.padding,
          height: this.height,
          maxHeight: this.maxHeight,
          maxWidth: this.maxWidth,
          width: this.width
        },
        on: this.$attrs
      },
      [
        h('div', {
          style
        })
      ]
    )

    return node
  }
}
</script>

<style scoped></style>
