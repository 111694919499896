<template>
  <div>
    <el-input
      v-show="editable"
      :id="id"
      ref="inputRef"
      v-model="formattedValue"
      class="th-currency-input"
      data-lpignore="true"
      :class="computedClass"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder || defaultPlaceholder"
      :size="size"
      @keypress.enter.prevent="handleEnter"
      @clear="$emit('cleared')"
    >
      <template v-if="appendText" #append>
        {{ appendText }}
      </template>

      <template v-if="$slots.suffix" #suffix>
        <slot name="suffix" />
      </template>
    </el-input>
    <span v-show="!editable" v-text="fallbackValue(formattedValue)" />
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useCurrencyInput } from 'vue-currency-input'
import { isNullish } from '@/utils/general'
import { useFormatCurrency } from '@/plugins'

export default {
  name: 'ThCurrencyInput',
  props: {
    id: {
      type: String,
      default: undefined
    },
    precision: {
      type: Number,
      default: 2,
      validator: (value) => [0, 1, 2, 3, 4].includes(value)
    },
    prominent: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    clearableIsLeft: {
      type: Boolean,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    fallbackValue: {
      type: Function,
      default: (val) => (isNullish(val) ? '–' : val)
    },
    currency: {
      type: String,
      default: undefined
    },
    prefix: {
      type: String,
      default: '',
      validator: (value) => ['-', '+', ''].includes(value)
    },
    upperLimit: {
      type: Number,
      default: undefined
    },
    lowerLimit: {
      type: Number,
      default: undefined
    },
    appendText: {
      type: String,
      default: () => {}
    },
    submitOnEnterKey: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'submit'],
  setup(props, context) {
    //globalProperties
    const formatCurrency = useFormatCurrency()

    //store
    const store = useStore()
    const locale = computed(() => store.getters['Config/getLocale'])
    const currency = computed(
      () => props.currency || store.getters['Config/getCurrentDefaultCurrency']
    )

    // For watching purposes
    const upperLimit = computed(() => props.upperLimit)
    const lowerLimit = computed(() => props.lowerLimit)
    const precision = computed(() => props.precision)

    //computed
    const defaultPlaceholder = computed(() => formatCurrency(0, currency.value))

    const computedClass = computed(() => ({
      prominent: props.prominent,
      'clearable-is-left': props.clearableIsLeft
    }))

    const normalizeLimitValue = (value) => {
      return [null, undefined, ''].includes(value) ? undefined : value
    }

    // If I turn this to computed and watch it change it will break down all the tests
    const getOptions = () => ({
      locale: locale.value,
      currency: currency.value,
      currencyDisplay: 'symbol',
      valueRange: {
        min: normalizeLimitValue(props.lowerLimit),
        max: normalizeLimitValue(props.upperLimit)
      },
      precision: props.precision,
      hideCurrencySymbolOnFocus: false
    })

    const { inputRef, formattedValue, setValue, setOptions } = useCurrencyInput(
      getOptions()
    )

    //watch
    watch(
      () => props.modelValue,
      () => {
        setValue(props.modelValue)
      }
    )

    watch([currency, locale, lowerLimit, upperLimit, precision], () => {
      setOptions(getOptions())
      // Have to do this, because vue-currency-input is emitting "null" on setOptions
      // even autoEmit: false not helping
      context.emit('update:modelValue', props.modelValue)
    })

    //methods
    const handleEnter = () => props.submitOnEnterKey && context.emit('submit')

    return {
      inputRef,
      formattedValue,
      defaultPlaceholder,
      computedClass,
      handleEnter
    }
  }
}
</script>

<style scoped>
.th-currency-input :deep(.el-input__inner) {
  text-align: right;
  padding: 0 0.5em;
}

.th-currency-input :deep(.el-input__inner.no-padding) {
  padding: 0 0;
}

.th-currency-input.prominent :deep(.el-input__inner) {
  font-size: 2em;
  padding: 1em 2em;
  font-weight: bold;
}

.th-currency-input.clearable-is-left :deep(.el-input__inner) {
  padding-left: 15px;
}

.th-currency-input.clearable-is-left :deep(.el-input__suffix) {
  right: unset;
  left: 5px;
  position: absolute;
}
</style>
