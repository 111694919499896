import { defineStore } from 'pinia'
import th from '@tillhub/javascript-sdk'

export const useBranchesStore = defineStore('branches', {
  state: () => ({
    branchesCount: 1
  }),
  actions: {
    async checkBranchesCount() {
      try {
        const { data } = await th
          .branchesV1()
          .meta({ query: { deleted: false } })
        this.branchesCount = data?.count || 0
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.branchesCount = 1
      }
    }
  }
})
