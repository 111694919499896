import { defineStore } from 'pinia'
import * as uuid from 'uuid'
import { useSocketIOStore } from './socket-io'
import { NOTIFICATIONS_TYPES } from '@/constants'
import { useMessagesStore } from './messages'

export const useExportsStore = defineStore('exports', {
  state: () => ({
    exports: {}
  }),
  actions: {
    init(vm) {
      const socketIoStore = useSocketIOStore()
      const socket = socketIoStore.socket
      const parseSocketData = socketIoStore.getSocketDataParser()
      const moduleName = this.$id

      socket?.on('exportPreparing', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.exports
        )
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportPreparing')

        const message = {
          id,
          read: false,
          operation: {
            module: moduleName,
            originKey,
            type: NOTIFICATIONS_TYPES.LOADING,
            ...storedItem
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('exportProgress', (data) => {
        const {
          isValidId,
          id,
          originKey,
          parsedData,
          storedItem
        } = parseSocketData(data, this.exports)
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportProgress')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            progress: parsedData.progress,
            type: NOTIFICATIONS_TYPES.PROGRESS,
            ...storedItem
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('exportSuccess', (data) => {
        const {
          isValidId,
          id,
          originKey,
          parsedData,
          storedItem
        } = parseSocketData(data, this.exports)
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportSuccess')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            url: parsedData.url,
            filename: parsedData.filename,
            expiresAt: parsedData.expires_at,
            type: NOTIFICATIONS_TYPES.SUCCESS,
            ...storedItem
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('exportError', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.exports
        )
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportError')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            type: NOTIFICATIONS_TYPES.ERROR,
            ...storedItem
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('exportEmpty', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.exports
        )
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportEmpty')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            type: NOTIFICATIONS_TYPES.EMPTY,
            ...storedItem
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('exportUploading', (data) => {
        const { isValidId } = parseSocketData(data, this.exports)
        if (!isValidId) return
        vm?.$log?.debug('Exports | exportUploading')
      })
    },
    setNewExport({ exportId, payload }) {
      const messagesStore = useMessagesStore()
      const message = {
        id: exportId,
        read: false,
        operation: {
          module: this.$id,
          type: NOTIFICATIONS_TYPES.LOADING,
          ...payload
        }
      }
      this.exports = { ...this.exports, [exportId]: payload }
      messagesStore.setLocalMessage(message)
      useSocketIOStore().setActiveId(exportId)
    },
    setFinishedExport({ exportResult, payload }) {
      const messagesStore = useMessagesStore()
      const message = {
        id: uuid.v4(),
        read: false,
        operation: {
          module: this.$id,
          url: exportResult.url,
          filename: exportResult.filename,
          expiresAt: exportResult.expires_at,
          type: NOTIFICATIONS_TYPES.SUCCESS,
          ...payload
        }
      }
      messagesStore.setLocalMessage(message)
    }
  }
})
