import { deepClone } from '@/utils/objects'

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$deepClone = function (object) {
      try {
        return deepClone(object)
      } catch (err) {
        app.$log.debug(err)
      }
    }
  }
}
