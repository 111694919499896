import th from '@tillhub/javascript-sdk'
import Store from '@/store'
import router from '../router'
import SafeGet from 'just-safe-get'
import { visibilityChange } from '@/constants'
import { formatDistanceToNowStrict } from 'date-fns'
import { getBaseUrl } from '@/utils/baseUrlProvider'
import { useKeycloak, getToken } from '@baloise/vue-keycloak'
import { isUnifiedCommerce, isKeycloackEnabled } from '@/constants'

const isProduction = process.env.NODE_ENV === 'production'
const timeoutKeycloak = 5000
const keycloakRefreshSeconds = 6000

export const initFactory = (base, token, user) => {
  const obj = { base }
  if (token) {
    obj.credentials = { token }
  }
  if (user) {
    obj.user = user
  }
  // We need a way for API to recognize that its a unified commerce client, so this will add the x-whitelabel to the header request
  if (isUnifiedCommerce() && isKeycloackEnabled()) {
    obj.whitelabel = 'unified-commerce'
  }

  /**
   * Error response interceptor for the SDK. It is called with an Axios error response, whenever the SDK receives one.
   * Check here for its schema: https://github.com/axios/axios#response-schema
   * @param {AxiosResponse} resp - error response received by the SDK
   * @returns {Promise<never>} - will always return a rejected promise
   */
  const interceptor = (resp) => {
    const statusCode = SafeGet(resp, 'response.status', 0)
    // it's probably best to use whitelist of codes, otherwise me might reroute when we don't want to, e.g. 404 or 422
    // FIXME 401 has been removed for now, as it breaks a lot of e2e tests. Please readd after workaround has been found
    const errorCodes = [504]
    // Taking out 403 for keycloak
    if (!isKeycloackEnabled()) errorCodes.push(403)
    // Logout keycloak if 401 (Unauthorized)
    if (isKeycloackEnabled() && statusCode === 401) {
      Store.dispatch('Auth/logout')
      router.replace('/logout')
    }
    if (errorCodes.includes(statusCode)) {
      router.push(`/${statusCode}`)
    }

    // NOTE always reject with original argument, see here: https://github.com/axios/axios#interceptors
    return Promise.reject(resp)
  }

  obj.responseInterceptors = [interceptor]

  return obj
}

export const getCredentials = () => ({
  token: window.localStorage.getItem('token') || '',
  user: window.localStorage.getItem('user') || ''
})

/**
 * Global login function for external services
 * NOTE: This function is used by iOS POS.
 *
 * @param {String} token - jwt access token
 * @param {String} user - account uuid
 * @param {String} url - url to redirect to (optional)
 * @returns {Boolean|Error} returns boolean or throws error
 */
window.applicationLogin = function (token, user, url) {
  if (Store.getters['Auth/isAuthenticated']) {
    // eslint-disable-next-line no-console
    console.log('[AUTH] Already logged in!')
    if (url) router.replace(url)
    return true
  }
  if (!token) throw new Error('Token is required for login')
  if (!user) throw new Error('User is required for login')
  if (typeof token !== 'string') throw new Error('Token must be JWT string')
  if (typeof user !== 'string')
    throw new Error('User must be account uuid string')
  if (url && typeof url !== 'string') throw new Error('Url must be string')

  // Navigate to login to avoid issues
  router.replace('/login')

  // Set max expire date, because exp does not exist in the JWT token
  const date = new Date()
  date.setMonth(date.getMonth() + 3)
  const expiresAt = date

  // Set store state
  Store.dispatch('Auth/clearLocationState', null, { root: true })
  Store.dispatch('Config/clearLocationState', null, { root: true })
  Store.commit('Auth/addAuthToken', token)
  Store.commit('Auth/setUser', user)
  Store.commit('Auth/setExpiresAt', expiresAt)

  // Set session
  new AuthService()
    .setSession({ token, expiresAt, user })
    .then(async function () {
      // Fetch me data
      await Store.dispatch('Auth/getMeData')
      Store.commit('Auth/setRole', 'owner')
      Store.commit('Auth/SET_SCOPES', ['all'])

      // Navigate back
      router.replace(url ? `/redirect?redirect=${url}` : '/redirect')
    })

  return true
}

/**
 * Global logout function for external services
 *
 * @returns {void} on return
 */
window.applicationLogout = function () {
  Store.dispatch('Auth/logout')
  router.replace('/logout')
}

class AuthService {
  constructor() {
    // Listen to tab visibility change
    document.addEventListener(
      visibilityChange,
      () => {
        if (document.visibilityState === 'visible') {
          this.setExpiresAtTimeout()
        }
      },
      false
    )
  }

  expiresAt = null
  expiresAtTimeout = null

  /*
    Clear token expire timeout
  */
  clearExpiresAtTimeout() {
    this._log('clear timeout')
    clearTimeout(this.expiresAtTimeout)
  }

  handleLogout() {
    this._log('token expired!')
    Store.dispatch('Auth/logout')
    router.replace('/401')
  }

  /*
    Set logout timeout that logs out the user after token expires
  */
  async setExpiresAtTimeout() {
    // Refresh token for keycloak
    if (isUnifiedCommerce() && isKeycloackEnabled()) {
      await this.refreshKeycloakToken()
    } else {
      this.clearExpiresAtTimeout()
    }
    const expiresAtCookie = window.localStorage.getItem('expires_at')
    if (expiresAtCookie) {
      this.expiresAt = JSON.parse(expiresAtCookie).date
      let diff = new Date(this.expiresAt) - new Date()
      this._log(
        'token expires in',
        formatDistanceToNowStrict(new Date(this.expiresAt))
      )
      // Check if expires at is later than now
      if (diff <= 0) {
        // Logout because token has expired
        this.handleLogout()
      } else {
        // setTimeout max delay
        const maxDelay = 2147483647
        // Replace time difference if larger than max delay. If diff is larger than max delay setTimeout will fire immediately
        if (diff > maxDelay) diff = maxDelay
        this.expiresAtTimeout = setTimeout(() => {
          // Token expired, logout
          this.handleLogout()
        }, diff)
      }
    } else {
      // we dont handle expire timeout for keycloak yet
      if (isUnifiedCommerce() && isKeycloackEnabled()) return
      // Expires at data not set, "probably" not logged in
      else Store.dispatch('Auth/logout')
    }
  }

  async handleAuthentication(authResult) {
    await this.setSession(authResult)
  }

  async refreshKeycloakToken() {
    try {
      const refreshed = await useKeycloak().keycloak.updateToken(
        keycloakRefreshSeconds
      )
      // If we have token that means that it was refreshed
      if (refreshed) {
        const baseUrl = await getBaseUrl()
        const user = window.localStorage.getItem('user')
        const token = await getToken()
        this.initializeSDK(baseUrl, token, user)
      }
    } catch (error) {
      // token could not be refreshed, time to logout
      this.handleLogout()
    }
  }

  async setSession(authResult) {
    Store.commit('Auth/setIsThInitialized', false)
    const baseUrl = await getBaseUrl()
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify({
      date: authResult.expiresAt
    })

    window.localStorage.setItem('token', authResult.token)
    window.localStorage.setItem('user', authResult.user)
    // app.axios.defaults.headers.common['Authorization'] = `Bearer ${authResult.token}`
    window.localStorage.setItem('expires_at', expiresAt)
    Store.commit('Auth/setAuthenticated', !!authResult.token)
    this.setExpiresAtTimeout()

    th.init(initFactory(baseUrl, authResult.token, authResult.user))
    Store.commit('Auth/setIsThInitialized', true)
  }

  async login({ username, password, organisation, recaptchaToken }) {
    if (organisation) {
      return th.auth.loginWithOrganisation({
        organisation,
        username,
        password,
        recaptcha_token: recaptchaToken
      })
    }
    const {
      token,
      expiresAt,
      user,
      features,
      name,
      scopes,
      role,
      orgName,
      whitelabel
    } = await th.auth.loginUsername({
      username,
      password,
      recaptcha_token: recaptchaToken
    })

    return {
      token,
      expiresAt,
      user,
      features,
      name,
      scopes,
      role,
      orgName,
      whitelabel
    }
  }

  async loginAsSupport({ token: supportToken, clientAccount, recaptchaToken }) {
    const {
      token,
      expiresAt,
      user,
      features,
      name,
      username,
      is_support: isSupport,
      role,
      scopes
    } = await th.auth.loginAsSupport({
      token: supportToken,
      client_account: clientAccount,
      recaptcha_token: recaptchaToken
    })

    return {
      token,
      expiresAt,
      user,
      features,
      name,
      username,
      isSupport,
      role,
      scopes
    }
  }

  async resetPassword({ email, organisation }) {
    if (organisation) {
      const { msg } = await th.auth.requestPasswordResetWithOrganisation({
        email,
        organisation
      })
      return { msg }
    }
    const { msg } = await th.auth.requestPasswordReset({ email })
    return { msg }
  }

  async newPassword(data) {
    const { msg } = await th.auth.setNewPassword(data)

    return { msg }
  }

  async getSession() {
    Store.commit('Auth/setIsThInitialized', false)
    const baseUrl = await getBaseUrl()
    let token, user
    if (isUnifiedCommerce() && isKeycloackEnabled()) {
      this.startGetKey = Date.now()
      // We add a user to avoid SDK issues, to be replaces by the proper client id on meData request
      user = await new Promise(this.getClientIdKeyCloak.bind(this))
      if (!user) {
        // Now we are going to trigger the login here to be able to select the locale
        const keycloak = useKeycloak()
        // Hardcoding for now always german, follow up will come
        keycloak.keycloak.login({ locale: 'de' })
      }
      // This function checks if the token is still valid and will update it if it is expired
      token = await getToken()
    } else {
      const credentials = getCredentials()
      token = credentials.token
      user = credentials.user
    }

    Store.commit('Auth/setAuthenticated', !!token)
    this.initializeSDK(baseUrl, token, user)
    if (isUnifiedCommerce() && isKeycloackEnabled()) {
      // Will get the TH client id
      await Store.dispatch('Auth/getMeData')
      // reinitialize SDK with TH client id
      user = window.localStorage.getItem('user')
      this.initializeSDK(baseUrl, token, user)
      await Store.dispatch('Config/getClientAccountConfiguration')
      // Redirect to home after login and avoid redirecting to 401
      if (['/login', '/401'].includes(window.location.pathname)) {
        router.replace('/home')
      }
    } else {
      this.setExpiresAtTimeout()
    }
    Store.commit('Auth/setIsThInitialized', true)
  }

  initializeSDK(baseUrl, token, user) {
    if (!token) {
      th.init(initFactory(baseUrl))
    } else {
      th.init(initFactory(baseUrl, token, user))
    }
  }

  getClientIdKeyCloak(resolve, reject) {
    // Missing the timeout
    const keycloak = useKeycloak()
    if (keycloak.isPending.value) {
      setTimeout(this.getClientIdKeyCloak.bind(this, resolve, reject), 100)
    } else if (Date.now() - this.startGetKey >= timeoutKeycloak) {
      // We got timeout
      reject()
    } else {
      resolve(keycloak.keycloak.subject)
    }
  }

  async logout() {
    if (
      isUnifiedCommerce() &&
      isKeycloackEnabled() &&
      useKeycloak().keycloak.authenticated
    ) {
      const logoutOptions = { redirectUri: `${window.location.origin}/login` }
      useKeycloak().keycloak.logout(logoutOptions)
    }
    // Set IsSupport to null in case of logout to prevent issues on new login.
    Store.commit('setIsSupport', null)

    // Clear access token and ID token from local storage
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('expires_at')
    this.userProfile = null
    // the caller must navigate to the home route
    this.clearExpiresAtTimeout()
  }

  isAuthenticated() {
    // Get token from storage
    const token = window.localStorage.getItem('token')
    return !!token
  }

  _log() {
    // eslint-disable-next-line no-console
    if (!isProduction) console.log('debug | auth |', ...arguments)
  }
}

export default new AuthService()
