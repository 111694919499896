<template>
  <div class="th-loading" :style="{ '--size': size + 'px' }" :class="{ fill }">
    <svg viewBox="25 25 50 50" class="th-loading-spinner">
      <circle cx="50" cy="50" r="20" fill="none" class="path" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ThLoading',
  props: {
    size: {
      type: Number,
      default: 30
    },
    fill: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.th-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.th-loading-spinner {
  height: var(--size);
  width: var(--size);
  animation: loading-rotate 2s linear infinite;
}

.th-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 3;
  stroke: var(--primary-color);
  stroke-linecap: round;
}

.th-loading.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
</style>
