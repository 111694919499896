<template>
  <div
    v-if="!hasOneBranch"
    class="branch-dropdown flex items-center"
    @click="toggle"
  >
    <span>{{ location }}</span>
    <el-icon class="ml-1">
      <ArrowUp v-if="show" />
      <ArrowDown v-else />
    </el-icon>
  </div>
</template>

<script>
import get from 'just-safe-get'
import { mapState } from 'vuex'
import { storeToRefs } from 'pinia'
import { useBranchesStore } from '@/store/branches'
import { allBranches, allBranchesContext } from './constants'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle'],
  setup() {
    const branchesStore = useBranchesStore()
    const { branchesCount } = storeToRefs(branchesStore)
    return { branchesCount }
  },
  data() {
    return {
      location: ''
    }
  },
  computed: {
    ...mapState({
      branches: (state) => state.Config.locationContext.branches
    }),
    hasOneBranch() {
      return this.branchesCount === 1
    },
    label() {
      return get(this.branches, '0.label')
    }
  },
  watch: {
    label: {
      immediate: true,
      handler() {
        this.location = this.getLocation()
      }
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    getLocation() {
      if (!this.label) {
        this.$store.dispatch('Config/setLocationContext', allBranchesContext)
        return this.$t('nav.main.items.location.select_placeholder')
      }

      if (this.label === allBranches.label) {
        return this.$t('nav.main.items.location.set_all')
      }

      return this.label
    }
  }
}
</script>

<style scoped>
.branch-dropdown {
  cursor: pointer;
  font-size: var(--control-font-size);
  font-weight: var(--control-font-weight);
  line-height: var(--control-line-height);
  width: 100%;
  white-space: nowrap;
}

.branch-dropdown :deep(i) {
  font-weight: inherit;
}
</style>
