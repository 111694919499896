<template>
  <div
    v-if="wallpaper"
    class="wallpaper"
    :style="{ 'background-color': backgroundColor }"
    :class="{ empty: !wallpaper }"
  >
    <th-image
      :src="wallpaper"
      cover
      position="left center"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import { useWhitelabelThemeStore } from '@/store/whitelabel-theme'

export default {
  setup() {
    return { themeStore: useWhitelabelThemeStore() }
  },
  computed: {
    wallpaper() {
      return this.themeStore.customWallpaper
    },
    backgroundColor() {
      return (
        this.themeStore.customBGColor ||
        this.themeStore.customWelcomeBGColor ||
        '#0f1f2b'
      )
    }
  }
}
</script>

<style lang="css" scoped>
.wallpaper {
  width: 100%;
  height: 100%;
}

.empty::before {
  content: '\200B';
}
</style>
