export default {
  install: (app) => {
    app.config.globalProperties.$rootMakeMeCall = function rootMakeMeCall() {
      return this.$root.makeMeCall()
    }

    app.config.globalProperties.$rootMaybeChangeLocale = function rootMaybeChangeLocale(
      ...args
    ) {
      this.$root.maybeChangeLocale(...args)
    }
  }
}
