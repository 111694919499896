<template>
  <el-drawer
    v-model="value"
    :title="title"
    :with-header="false"
    :size="size"
    :destroy-on-close="destroyOnClose"
    class="th-drawer"
  >
    <slot />
  </el-drawer>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '434px'
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>
.th-drawer :deep(.el-drawer:focus) {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
</style>
