import * as Sentry from '@sentry/vue'

const isProd = process.env.VUE_APP_PRODUCTION === 'true'

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$logException = function logException(
      err,
      { trackError = true, message, messageType = 'error' } = {}
    ) {
      app.config.globalProperties.$log.error(err)

      if (isProd && trackError) {
        try {
          Sentry.captureException(err)
        } catch (error) {
          app.config.globalProperties.$log.error('Sentry not available', error)
        }
      }

      if (message) {
        app.config.globalProperties.$message({
          type: messageType,
          message
        })
      }
    }
    app.provide('logException', app.config.globalProperties.$logException)
    app.provide('message', app.config.globalProperties.$message)
  }
}
