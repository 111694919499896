import safeGet from 'just-safe-get'

/**
 * Delete plugin. It makes confirm modal for deleting easier
 */
export default {
  install: (app, options = {}) => {
    /**
     * A function that is used to confirm a delete action.
     *
     * @async
     * @param {string} name - Do you really want to delete '{name}'.
     * @param {string} phraseKey (optional) - Do you really want to delete {phraseKey} '{name}'.
     * @param {string} appendText (optional) - Do you really want to delete '{name}'. {appendText}
     * @returns {Promise<boolean>} Returns if user confirmed the action.
     */
    app.config.globalProperties.$askToDelete = async function (
      name,
      phraseKey,
      appendText
    ) {
      try {
        await this.$confirm(
          this.$t('common.delete_confirm.content', {
            objectName: `${phraseKey ? phraseKey + ' ' : ''}'${name}'`
          }) + (appendText ? ' ' + appendText : ''),
          this.$t('common.delete_confirm.title'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
        return true
      } catch (error) {
        return false
      }
    }

    /**
     * A function that is used to confirm a bulk delete action.
     *
     * @async
     * @param {Array<object>} list - Array of items to delete.
     * @param {string} phraseKeyPlural (optional) -Phrase key for multiple items.
     * @param {string} key (optional) - Object key for value to display when there is only only item.
     * @param {string} name (optional) - Value to display when there is only only item. This value is used before 'key'.
     * @param {string} appendText (optional) - Do you really want to delete '{name}'. {appendText}
     * @returns {Promise<boolean>} Returns if user confirmed the action.
     */
    app.config.globalProperties.$askToDeleteMany = async function (
      list,
      phraseKeyPlural,
      key = 'name',
      name,
      appendText
    ) {
      if (list.length > 1) {
        try {
          await this.$confirm(
            this.$t('common.multiple_delete_confirm.content', {
              quantity: list.length,
              objectsOrObject:
                phraseKeyPlural ||
                this.$t('components.th_datatable.pagination.items')
            }) + (appendText ? ' ' + appendText : ''),
            this.$t('common.delete_confirm.title'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
          return true
        } catch (error) {
          return false
        }
      } else {
        return this.$askToDelete(
          name || safeGet(list[0], key, '-'),
          null,
          appendText
        )
      }
    }
  }
}
