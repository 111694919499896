import { currencyPrecisions } from '@/constants'
import { inject } from 'vue'

const injectionKey = Symbol('formatCurrency')

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$formatCurrency = function ThCurrencyFormatter(
      value,
      currency,
      formatterOptions = {}
    ) {
      //Intl.NumberFormat requires a currency
      if (!currency) return

      // ----------------------  locale  -----------------------
      const locale =
        formatterOptions?.locale ||
        options?.i18n?.global?.locale?.value ||
        'en-US'

      // ---------------------  precision  ----------------------
      let precision
      if (typeof formatterOptions.precision === 'number') {
        precision = formatterOptions.precision
      } else if (currencyPrecisions[currency]) {
        precision = currencyPrecisions[currency].precision
      } else {
        precision = 2
      }

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }).format(value)
    }

    app.provide(injectionKey, app.config.globalProperties.$formatCurrency)
  }
}

export const useFormatCurrency = () => inject(injectionKey)
