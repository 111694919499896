import typeOf from 'just-typeof'

/**
 * Check for object type and that it is empty
 * @param {Object} item
 * @returns {boolean}
 */
export function isEmptyObject(item) {
  return item && typeOf(item) === 'object' && !Object.keys(item).length
}

/**
 * Check if empty array or array with objects whose keys are all null
 * @param {Array} item
 * @returns {boolean}
 */
export function isEmptyArray(item) {
  if (Array.isArray(item)) {
    if (!item.length) return true

    const onlyEmptyObjects = item.every(
      (el) =>
        typeOf(el) === 'object' &&
        Object.values(el).every((key) => key === null)
    )

    if (onlyEmptyObjects) return true
  }
  return false
}

/**
 * Check for string type and that it is empty
 * @param {*} item
 * @returns {boolean}
 */
export function isEmptyString(item) {
  return typeof item === 'string' && !item.length
}

function pruneAddresses(addresses) {
  if (Array.isArray(addresses)) {
    addresses = addresses.map((item) => {
      return pruneLegacyAddress(item)
    })
  }

  return addresses
}

function pruneLegacyAddress(address) {
  const obj = { ...address }

  if (Array.isArray(obj.lines)) {
    obj.lines = obj.lines.filter((item) => item)
  }

  if (Array.isArray(obj.lines) && obj.lines.length === 0) {
    obj.lines = null
  }

  return obj
}

export default {
  install: (app) => {
    app.config.globalProperties.$objectPrunePrimitives = function prunePrimitives(
      obj
    ) {
      return Object.keys(obj).reduce((result, key) => {
        const isEmpty =
          isEmptyObject(obj[key]) ||
          isEmptyArray(obj[key]) ||
          isEmptyString(obj[key])
        result[key] = isEmpty ? null : obj[key]
        return result
      }, {})
    }

    app.config.globalProperties.$pruneAddresses = function vuePruneAddresses(
      addresses
    ) {
      return pruneAddresses(addresses)
    }
  }
}
