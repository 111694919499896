<template>
  <div class="th-tabs">
    <div class="th-tabs-header">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        class="th-tabs-header-label"
        :class="{ active: tab.name === modelValue }"
        type="button"
        @click="selectTab(tab.name)"
      >
        {{ tab.label }}
      </button>
    </div>
    <div class="th-tabs-body">
      <slot />
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  name: 'ThTabs',

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  computed: {
    tabs() {
      return (
        this.$slots
          .default()
          .map((slot) => safeGet(slot, 'props', {}))
          // Takes care of children that are not actual components, like
          // comments or vnodes removed with v-if directive
          .filter((props) => !!props)
      )
    }
  },

  methods: {
    selectTab(tab) {
      this.$emit('update:modelValue', tab)
    }
  }
}
</script>

<style scoped>
.th-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.th-tabs-header {
  flex-shrink: 0;
  display: flex;
  grid-gap: 1rem;
}

.th-tabs-header-label {
  padding-bottom: 0.5rem;
  border-bottom: 3px solid transparent;
}

.th-tabs-header-label.active,
.th-tabs-header-label:active {
  border-color: var(--primary-color);
  color: var(--primary-color);
  outline: 0 none;
}

.th-tabs-body {
  flex-grow: 1;
}
</style>
