<template>
  <el-popover
    v-model="menuOpen"
    :placement="rowMenuDirectionLeft ? 'bottom-end' : 'bottom-start'"
    popper-class="menu-popover"
    @show="$emit('row-menu-opened', row)"
    @hide="$emit('row-menu-closed', row)"
  >
    <template #reference>
      <el-icon><More /></el-icon>
    </template>
    <div
      v-for="option in options"
      :key="option.value"
      :class="getClasses(option)"
      @click="handleMenuItemClick(option.value)"
    >
      <span class="menu-text">
        {{ option.label }}
      </span>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      required: true
    },
    rowMenuDirectionLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    getClasses(option) {
      return ['menu-item', option.enabled ? 'enabled' : 'disabled']
    },
    handleMenuItemClick(type) {
      this.$emit('row-menu-click', type, this.row)
      this.menuOpen = false
    }
  }
}
</script>

<style scoped>
.disabled {
  color: lightgrey;
  pointer-events: none;
}

.menu-text {
  margin: 0 5px;
}

.menu-item.enabled {
  cursor: pointer;
}

.menu-item.enabled:hover {
  background-color: var(--light-blue);
  color: #279ff6;
}

.el-icon-more {
  cursor: pointer;
  color: grey;
}
</style>

<style>
.menu-popover.el-popover {
  padding: 5px 1px;
  line-height: 23px;
  color: #232e3d;
}
</style>
