<template>
  <div class="th-input-title" :class="{ required }">
    <th-popover v-if="info" :text="info" class="pr-2" />
    <label :for="titleFor" class="th-input-title-label">
      <slot>{{ title }}</slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ThInputTitle',

  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    info: {
      type: String,
      required: false,
      default: undefined
    },
    titleFor: {
      type: String,
      required: false,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.th-input-title {
  line-height: 1;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  color: var(--label-settings-text-color);
  font-weight: var(--label-font-weight);
  min-height: var(--input-title-height);
  margin-bottom: var(--label-margin-bottom);
}

.th-input-title .th-input-title-label {
  color: var(--label-settings-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--label-line-height);
}

.th-input-title.required .th-input-title-label::after {
  content: '*';
  color: var(--error-color);
  margin-left: 2px;
}
</style>
