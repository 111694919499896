<template>
  <el-select
    :id="id"
    ref="remoteSearch"
    v-model="value"
    v-loading="isLoading"
    v-cancel-read-only
    :remote-method="searchResource"
    filterable
    remote
    :clearable="clearable"
    :popper-append-to-body="appendToBody"
    :disabled="disabled"
    :placeholder="
      placeholder || $t('components.remote_search_select.placeholder_default')
    "
    @change="handleInput"
    @clear="handleClear"
  >
    <el-option
      v-for="(item, index) in resourceOptions"
      :key="item.id"
      :label="item.computed_name"
      :value="String(item[optionsValue || 'id'])"
    >
      <slot :default="{ label: item.computed_name, item, index }">
        <!-- BEGIN fallback -->
        <span>
          {{ item.computed_name }}
        </span>
        <!-- END fallback -->
      </slot>
    </el-option>
  </el-select>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import debounce from 'debounce'

export default {
  props: {
    modelValue: {
      required: false,
      default: () => '',
      validator: (prop) => {
        return typeof prop === 'string' || prop === null
      }
    },
    resource: {
      type: String,
      required: true,
      validator: function (value) {
        const allowedResources = [
          'products',
          'customers',
          'users',
          'staff',
          'branches',
          'productGroups',
          'registers',
          'reasons',
          'accounts'
        ]
        return allowedResources.indexOf(value) !== -1
      }
    },
    // Case of users, it needs a configurationId to initializate
    resourceId: {
      type: String,
      default: undefined
    },
    modifyQuery: {
      type: Function,
      required: false,
      default: undefined
    },
    computedFields: {
      type: Array,
      default: () => ['name']
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {}
    },
    resultFilter: {
      type: Function,
      required: false,
      default: (results) => results
    },
    computeName: {
      type: Function,
      required: false,
      default: undefined
    },
    options: {
      type: Array,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    doInitialFetch: {
      type: Boolean,
      default: true
    },
    // Fetch all the results available from start, for later filtering
    doInitialFetchFullList: {
      type: Boolean,
      default: false
    },
    // Case of user, there is no search handler, so we use a custom
    fetchHandler: {
      type: String,
      default: undefined
    },
    minSearchTextLength: {
      type: Number,
      default: undefined
    },
    /**
     * The key used to get the value for the options-children of the dropdown.
     * Defaults to "id"
     */
    optionsValue: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * In some use cases we care for just a specific value in the returned search api array of matched result objects, and don't care about the other part of the result. In that case we don't want to have duplicate values presented to the user. "noRepeatKey" prop should provide a path to the specific value in the result object.
     *
     * E.g. we want to get all the cities that starts with "ber" that branches are located in. The search-api will return the entire branches' objects for this result, so we might get a lot of results with "Berlin" as the city key value. In order to not show the user a dropdown with repeated "Berlin" values, we will pass "noRepeatKey" with the 'city' as its value (or whatever is the relevant specfic key).
     */
    noRepeatKey: {
      type: String,
      required: false,
      default: undefined
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'resource-set', 'clear', 'loading-error'],
  data() {
    const c = this.computeOptions(this.options || [], undefined)
    return {
      resourceOptions: [...c],
      resources: [],
      isLoading: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue() {
      const value = this.resources.find(
        (resource) =>
          String(resource[this.optionsValue || 'id']) ===
          String(this.modelValue)
      )
      if (!value) this.fetchInitialValue()
      else this.$emit('resource-set', value)
    }
  },
  async mounted() {
    await this.fetchInitialValue()
    await this.fetchFullList()
  },
  methods: {
    fetchInitialValue() {
      if (!this.modelValue || !this.doInitialFetch) return

      this.fetch({
        handlerName: this.fetchHandler || this.optionsValue ? 'search' : 'get',
        query: this.modelValue
      })
    },
    fetchFullList() {
      if (
        !this.doInitialFetchFullList ||
        (this.doInitialFetch && this.modelValue)
      )
        return
      this.fetch({
        handlerName: this.fetchHandler || 'getAll',
        query: this.modelValue
      })
    },
    searchResource: debounce(function (searchTerm) {
      if (!searchTerm) {
        this.resourceOptions = this.computeOptions(this.options || [])
        return
      }

      if (
        Number.isFinite(this.minSearchTextLength) &&
        searchTerm.length < this.minSearchTextLength
      )
        return

      this.fetch({
        handlerName: this.fetchHandler || 'search',
        query: searchTerm
      })
    }),
    async fetch({ handlerName, query }) {
      let inst

      if (th[this.resource]) {
        inst = this.resourceId
          ? th[this.resource](this.resourceId)
          : th[this.resource]()
      } else {
        return this.$logException(
          `${this.resource} is not an instantiable resource`,
          {
            trackError: false
          }
        )
      }

      if (typeOf(inst[handlerName]) !== 'function')
        return this.$logException(`.${handlerName} ist not a function`, {
          trackError: false
        })

      this.isLoading = true

      let q = () => query

      if (this.modifyQuery) {
        q = () => this.modifyQuery(query, handlerName)
      }

      let response

      try {
        response = await inst[handlerName](q())
      } catch (err) {
        this.isLoading = false
        this.resources = this.resourceOptions = []
        return this.$emit('loading-error', err)
      }

      this.isLoading = false

      if (!this.resources || !Array.isArray(this.resources)) {
        this.resources = []
      }

      // Search API returns data inside of "starts_with" or "search" property - depends on the version of the handler.
      let data =
        safeGet(response, 'data.starts_with') ||
        safeGet(response, 'data.search') ||
        response.data
      // In case of the response being one object, turn it into an array
      data = !Array.isArray(data) ? [data] : data
      // Search API version that returns the data under the "search" property also wraps each data item inside of a "doc" property.
      data = data.map((item) => item.doc || item)

      // filter results
      if (typeOf(this.resultFilter) === 'function') {
        data = this.resultFilter(data)
      }

      this.resources = this.resourceOptions = this.computeOptions(data, query)

      if (typeOf(this.noRepeatKey) === 'string') {
        const seen = new Set()
        this.resourceOptions = this.resourceOptions.filter((opt) => {
          const key = safeGet(opt, this.noRepeatKey)
          const duplicate = seen.has(key)
          seen.add(key)
          return !duplicate
        })
      }

      if (
        this.modelValue &&
        (handlerName === 'search' || handlerName === 'get')
      ) {
        this.$emit(
          'resource-set',
          this.resources.find(
            (resource) =>
              String(resource[this.optionsValue || 'id']) ===
              String(this.modelValue)
          )
        )
      }
    },
    handleInput(item) {
      // after a user clicked on an option, we will clear the previous search results
      this.resourceOptions = []

      if (this.handleChange) this.handleChange()
      this.$emit('update:modelValue', item)
      this.$emit(
        'resource-set',
        this.resources.find(
          (resource) =>
            String(resource[this.optionsValue || 'id']) === String(item)
        )
      )
    },
    handleClear() {
      this.resourceOptions = this.computeOptions(this.options || [])
      this.$emit('clear')
    },
    computeOptions(data, query) {
      return data.map((item) => {
        if (!item || typeOf(item) !== 'object') return

        // NOTE: Persist original user input as "queryInput".
        // E.g. to optionally use with a provided expandOriginalData function in the filter object which would be passed to the parent component.
        const obj = { ...item, queryInput: query }

        if (typeOf(this.computeName) === 'function') {
          obj.computed_name = this.computeName(item, query)
        } else {
          obj.computed_name = this.defaultCompute(item)
        }

        return obj
      })
    },
    defaultCompute(item) {
      return Object.entries(pick(item, [...this.computedFields]))
        .filter((item) => item[1] !== null && item[1] !== undefined)
        .map((item) => item[1])
        .join(' - ')
        .trim()
    }
  }
}
</script>
