<template>
  <notification-base>
    <template #icon_start>
      <svgicon
        :src="require('@/assets/icons/th-icon-warning-outline.svg')"
        :style="{
          height: '22px',
          width: '22px',
          color: 'var(--info-color)'
        }"
      />
    </template>
    <template #content>
      <slot name="content">
        <div class="text-th-light-gray font-light text-sm tracking-wide">
          <slot name="content_upper_row" />
        </div>
        <div class="text-th-info-yellow">
          <slot name="content_lower_row" />
        </div>
      </slot>
    </template>
  </notification-base>
</template>

<script>
import NotificationBase from '../base'

export default {
  components: {
    NotificationBase
  }
}
</script>
