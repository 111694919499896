import { useMessagesStore } from '../messages'
import { useSocketIOStore } from '../socket-io'

const state = {}

// actions
const actions = {
  initNotifications() {
    const socket = useSocketIOStore().socket

    socket?.on('notification', (data) => {
      // TODO: currently API socket.io returns double stringified response, when fixed on API side, should remove one of the parses.
      const parsedData = JSON.parse(JSON.parse(data))
      const { eventType, payload } = parsedData

      if (eventType === 'purchaseOrderDone') {
        const message = {
          id: payload.purchaseOrderId,
          read: false,
          operation: {
            module: 'purchaseOrder',
            date: new Date(),
            originKey: payload.purchaseOrderNumber ?? payload.purchaseOrderId
          }
        }
        const messagesStore = useMessagesStore()
        messagesStore.setLocalMessage(message)
      }
    })
  }
}

export default {
  state,
  actions,
  namespaced: true
}
