<template>
  <div class="th-container">
    <img
      src="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/no-results.svg"
      alt="no results image"
    />
    <div class="title">
      {{ computedTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    computedTitle() {
      // NOTE: using computed property here, as `$t` cannot be used while defining props, and we don't want to mutate it afterwards
      return this.title.length ? this.title : this.$t('common.data.no_results')
    }
  }
}
</script>

<style scoped>
.th-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin-top: 25px;
  font-size: var(--empty-font-size);
  font-style: var(--empty-font-style);
}
</style>
