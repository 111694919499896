<template>
  <div class="actions-wrapper items-center">
    <onboarding v-if="!isUnifiedCommerceClient" />
    <help v-if="!isUnifiedCommerceClient" />
    <notifications />
  </div>
</template>

<script>
import Onboarding from './onboarding'
import Help from './help'
import Notifications from './notifications'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    Onboarding,
    Help,
    Notifications
  },
  computed: {
    isUnifiedCommerceClient() {
      return isUnifiedCommerce()
    }
  }
}
</script>

<style scoped>
.actions-wrapper {
  display: flex;
}

.actions-wrapper *:not(:first-child) {
  margin-left: 1em;
}

.actions-wrapper :deep(.el-button--primary) {
  background-color: transparent;
  border-color: #637480;
}

.actions-wrapper :deep(.el-button--primary:hover) {
  background-color: #637480;
}
</style>
