import { defineStore } from 'pinia'
import th from '@tillhub/javascript-sdk'

export const useProductsStore = defineStore('products', {
  state: () => ({
    selectedItems: [],
    allSelected: false,
    persistedFilters: {},
    productsCount: 1
  }),
  actions: {
    clearSelectedItems() {
      this.$patch({
        selectedItems: [],
        allSelected: false,
        persistedFilters: {}
      })
    },
    async checkProductsCount() {
      try {
        const { data } = await th.products().meta({ query: { deleted: false } })
        this.productsCount = data?.count || 0
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.productsCount = 1
      }
    }
  }
})
