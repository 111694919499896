import safeGet from 'just-safe-get'

export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  return [
    {
      value: '/utilities/audits/actions',
      key: 'nav.main.items.audits.actions',
      enabled: !!safeGet(featureConfig, 'utilities.audits.actions'),
      scopes: ['utilities:audits']
    },
    {
      value: '/utilities/audits/logs',
      key: 'nav.main.items.audits.logs',
      enabled: !!safeGet(featureConfig, 'utilities.audits.logs'),
      scopes: ['utilities:audits']
    }
  ]
}
