/**
 * Check for string type and that it is empty
 * @param {*} item
 * @returns {boolean}
 */
export function isEmptyString(item) {
  return typeof item === 'string' && !item.length
}

export function replaceEmptyStringWithNull(item) {
  return isEmptyString(item) ? null : item
}

export function replaceEmptyStringPropsWithNull(obj) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    return { ...result, [key]: replaceEmptyStringWithNull(value) }
  }, {})
}

/**
 * Convert camel case formatted string to snake case
 * @param {String} str
 * @returns {String}
 */
export function camelToSnakeCase(str) {
  if (typeof str !== 'string') return str
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

/**
 * Capitalize first letter in a sentence
 * @param {String} str
 * @returns {String}
 */
export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Join array items into string label
 * @param {Array} array
 * @param {String} separator
 * @returns {String}
 */
export function createLabel(array, separator = ' - ') {
  return array.filter(Boolean).join(separator)
}

/**
 * Sort object in array by attribute name
 * @param {Array} array
 * @param {String} attrName
 * @returns {Array}
 */
export function sortBy(array, attrName = '') {
  return [...array].sort((a, b) => a[attrName].localeCompare(b[attrName]))
}
