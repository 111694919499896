import th from '@tillhub/javascript-sdk'

export default {
  /**
   * Define which SDK resource is allowed and will be built.
   */
  resource: {
    type: [String, Boolean],
    required: true,
    validator: (resource) => {
      if (resource === false) return true
      return !!th[resource]
    }
  },
  /**
   * Define which SDK resource is allowed and will be built specifically for metadata.
   * If not selected, then will use resource.
   */
  metaResource: {
    type: String,
    default: undefined
  },
  customResource: {
    type: Object,
    default: undefined
  },
  /**
   * Define which path to use for create default button
   */
  customCreatePath: {
    type: String,
    default: undefined
  },
  /**
   * Define which path to use for edit default button
   */
  customEditPath: {
    type: String,
    default: undefined
  },
  /**
   * Define which SDK handler to use
   */
  getHandlerKey: {
    type: String,
    default: 'getAll'
  },
  /**
   * Define id for the fetch in case is not getAll()
   */
  customFetchId: {
    type: String,
    default: undefined
  },
  /**
   * The limit of API items and hence size of an API page.
   */
  resourceLimit: {
    type: Number,
    default: undefined
  },
  /**
   * The options object that will be passed to the SDK
   */
  resourceQuery: {
    type: Object,
    default: () => ({})
  },
  /**
   * The options object that will be passed to SDK when calling .meta functions
   */
  metaOptions: {
    type: Object,
    default: undefined
  },
  /**
   * Height in DOM
   */
  height: {
    type: String,
    required: false,
    default: '100%'
  },
  /**
   * Width in DOM
   */
  width: {
    type: String,
    required: false,
    default: '100%'
  },
  /**
   * Font color in DOM (not in use)
   */
  color: {
    type: String,
    required: false,
    default: 'black'
  },
  /**
   * Injectable data. This will lead to API calls not being made
   */
  tableData: {
    type: Array,
    required: false,
    default: null
  },
  /**
   * ElTable header configuration
   */
  headers: {
    type: Array,
    required: true
  },
  summaryHeaders: {
    type: Array,
    required: false,
    default: undefined
  },
  /**
   * Injectable data in the exported csv
   */
  exportMapList: {
    type: Array,
    required: false,
    default: () => []
  },

  /**
   * Drive behaviour in exports buttons
   */
  exportOptions: {
    type: Object,
    default: () => ({})
  },
  /**
   * Toggle availibility of the document export feature. Only appears if a resource is provided.
   */
  documentExport: {
    type: Boolean,
    default: false
  },
  /**
   * Option for document export feature. The filename prefix for the exported file.
   */
  exportFilenamePrefix: {
    type: String,
    required: false,
    default: ''
  },
  /**
   * Option for document export feature. The query is embed in an object called exportMetaData.
   */
  wrapQueryInObject: {
    type: Boolean,
    default: false
  },
  /**
   * Option for document export feature. The document type as used by the DMS. Required if documentExport is true.
   */
  exportDocumentType: {
    type: String,
    required: false,
    default: ''
  },
  /**
   * Function for document export feature.
   */
  handleExport: {
    type: Function,
    required: false,
    default: () => {}
  },
  /**
   * Option for document export feature. Hides the option to only export current view.
   */
  forceAllExportColumns: {
    type: Boolean,
    default: false
  },
  /**
   * Used by the document export modal feature.
   * An object that maps header names (`headers.field`) from the datatable to the column names used by the backend during export.
   * You can select multiple export columns for a single datatable header by providing an array of strings.
   * Any field that is not mapped will be automatically included using the header name.
   * You can drop fields by mapping them to something that is falsy but not undefined.
   */
  headerNameToExportColumnMap: {
    type: Object,
    required: false,
    default: () => ({})
  },

  /**
   * Toggle visibility of the toolbar.
   */
  showTools: {
    type: Boolean,
    default: true
  },
  /**
   * Toggle visibility of the action bar.
   */
  showActions: {
    type: Boolean,
    default: true
  },
  /**
   * Toggle visibility of the operations bar.
   */
  showOperations: {
    type: Boolean,
    default: true
  },
  /**
   * Toggle filterability of the headers
   */
  headersFilterable: {
    type: Boolean,
    default: undefined
  },
  /**
   * Toggle visibility of the search and filter.
   */
  showSearchFilter: {
    type: Boolean,
    required: false,
    default: undefined
  },
  /**
   * Toggle visibility of the search.
   */
  showSearch: {
    type: Boolean,
    required: false,
    default: undefined
  },
  /**
   * Toggle visibility of the filter.
   */
  showFilter: {
    type: Boolean,
    required: false,
    default: undefined
  },
  /**
   * Search and filters config and values.
   */
  searchFilters: {
    type: Array,
    required: false,
    default: () => []
  },

  /**
   * Define to a show a summary or not
   */
  showSummary: {
    type: Boolean,
    required: false,
    default: undefined
  },

  /**
   * Array of summary items or function to compute them
   */
  summary: {
    type: [Array],
    required: false,
    default: undefined
  },

  summaryLabel: {
    type: String,
    required: false,
    default: undefined
  },
  /**
   * Allow multiple select and multi actions in the item list.
   */
  multipleSelect: {
    type: Boolean,
    default: false
  },
  /**
   * Limit the mumber of multiple select items.
   */
  multipleSelectLimit: {
    type: Number,
    default: null
  },
  /**
   * Function that determines if a certain row can be selected: function(row, index)
   */
  selectable: {
    type: Function,
    required: false,
    default: undefined
  },
  /**
   * Toggle visibility of the tool header.
   */
  showTotalCount: {
    type: Boolean,
    default: false
  },
  /**
   * Toggle visibility of the tool header.
   */
  showToolHeader: {
    type: Boolean,
    default: true
  },
  /**
   * Configure customisable operations.
   */
  operations: {
    type: Object,
    required: false,
    default: () => {
      return {
        create: true,
        update: true,
        delete: true
      }
    }
  },
  /**
   * Define whether to page or not (not in use)
   */
  paging: {
    type: Boolean,
    required: false,
    default: true
  },
  /**
   * Allow or disallow meta checks. If disallowed, pages will rely on the local
   * data count
   */
  noMetaCheck: {
    type: Boolean,
    required: false,
    default: false
  },
  // TODO: this behavioiur should be re-evaluated as it merely is a hack
  /**
   * Force a meta checks if a caller absolutely requires it.
   */
  forceMetaCheck: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
   * Toggle loading indicator
   */
  showLoading: {
    type: Boolean,
    required: false,
    default: true
  },
  loadingText: {
    type: String,
    required: false,
    default: ''
  },

  doFetch: {
    type: Boolean,
    required: false,
    default: true
  },

  /**
   * Define whether to route, e.g. on click events
   */
  doRoute: {
    type: Boolean,
    required: false,
    default: false
  },
  doRouteDblClick: {
    type: Boolean,
    required: false,
    default: undefined
  },
  /**
   * Define whether to route on filters being set. This e.g. triggers query parameters in the browser
   */
  doRouteFilters: {
    type: Boolean,
    required: false,
    default: undefined
  },
  /**
   * Define a base for routing, e.g. if you do not wish to route on the current URL.
   */
  routeBase: {
    type: String,
    required: false,
    default: () => null
  },
  /**
   * Define available page sizes the user can choose from
   */
  pageSizes: {
    type: Array,
    required: false,
    default: function () {
      return [20, 50, 100]
    },
    validator: function (value) {
      let valid = true
      value.forEach((item) => {
        if (!Number.isFinite(item)) valid = false
      })

      return valid
    }
  },
  /**
   * Transform data after it arrives from the SDK.
   *
   * Note: if you use this transform we do not offer pagination. Consider .transformTableData instead or set .transformFetchedMetaAllowed = true
   */
  transformFetchedData: {
    type: Function,
    required: false,
    default: undefined
  },

  /**
   * Instead of bailing meta, force the component to compute pagination.
   */
  transformFetchedMetaAllowed: {
    // TODO: review whether the below assumptions (for bailing still hold up) and refactor if possible. This here is meant as a regression safe hack.
    type: Boolean,
    required: false,
    default: false
  },

  /**
   * Transform data before it will be injected into the DOM.
   */
  transformTableData: {
    type: Function,
    required: false,
    default: undefined
  },
  /**
   * Transform summary data before it will be injected into the DOM.
   */
  transformSummaryData: {
    type: Function,
    required: false,
    default: undefined
  },
  /**
   * Prune superfluous filters from the search filters. This essentially sanitizes the UI, not to display tags that have not been intended
   */
  pruneSearchFilters: {
    type: [Boolean, Function],
    required: false,
    default: undefined
  },
  /**
   * Toggle expandable rows.
   */
  expandingRow: {
    type: [Boolean, Function],
    default: false
  },

  /**
   * Toggle and show expandable rows if function using row returns true.
   */
  expandRowIf: {
    type: Function,
    required: false,
    default: () => true
  },

  /**
   * General error handler. Will be called with no-op if not set.
   */
  logException: {
    type: Function,
    default: () => {}
  },
  headersConfig: {
    type: Object,
    required: false,
    default: undefined
  },
  message: {
    type: String,
    default: ''
  },
  showMessage: {
    type: Boolean,
    default: false
  },
  blockNoData: {
    type: Boolean,
    default: false
  },
  /**
   * Default minimum headers for the table if all headers are empty
   */
  headersMinActive: {
    type: Array,
    required: false,
    default: () => []
  },
  headersDefaultHide: {
    type: Array,
    required: false,
    default: () => []
  },
  buttons: {
    type: Array,
    required: false,
    default: () => []
  },
  downloadRetryOptions: {
    type: Object,
    default: undefined
  },
  fuzzySearch: {
    type: Boolean,
    required: false,
    default: false
  },
  searchFilterConfig: {
    type: Object,
    required: false,
    default: () => ({})
  },
  /**
   * Toggle sortability
   */
  sortable: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
   * Sorting is being done locally 'local' or triggers a backend sorting with 'remote'
   * The remote sorting works passing a filter called orderFields, that is an array of String
   * Each value is the string of the column to be sorted, if we add '+' then is ASC or '-' DESC, none will default to ASC
   * Supports many columns to sort
   */
  sortType: {
    type: String,
    validator: (value) => ['local', 'remote'].includes(value),
    default: 'local'
  },
  /**
   * Set locale to be used downstream (not in use)
   */
  locale: {
    type: String,
    default: 'en'
  },
  /**
   * Configure ElTable row menus
   */
  rowMenuOptions: {
    type: Array,
    default: () => []
  },
  /**
   * Function that will be called on row menu click
   */
  handleRowMenuClick: {
    type: Function,
    default: () => {}
  },
  handleRowMenuOpen: {
    type: Function,
    default: () => {}
  },
  handleRowMenuClose: {
    type: Function,
    default: () => {}
  },
  rowMenuDirectionLeft: {
    type: Boolean,
    default: false
  },
  /**
   * Permissions object to check before rendering buttons that require permissions
   */
  permissions: {
    type: Object,
    required: false,
    default: () => ({})
  },
  /**
   * Retry options to pass to pRetry when making async API calls.
   * API calls will be retried only if the options object was passed with properties.
   * Documentation of the available properties can be found in the following links:
   * 1. https://github.com/tim-kos/node-retry#retryoperationoptions
   * 2. https://github.com/sindresorhus/p-retry#options
   */
  retryOptions: {
    type: Object,
    required: false,
    default: () => ({})
  },
  /**
   * sets the key for the Table row. Requires unique key.
   */
  rowKey: {
    type: [String, Function],
    default: 'id'
  },

  tableSpanMethod: {
    type: Function,
    required: false,
    default: undefined
  },

  /**
   * User defined labels to user throught this component
   */
  labels: {
    type: Object,
    required: false,
    default: () => ({})
  },
  operationsWidth: {
    type: [String, Number],
    required: false,
    default: '150'
  },
  doSlowerRowDblClick: {
    type: Boolean,
    required: false,
    default: false
  },
  applyCellClassName: {
    type: Function,
    required: false,
    default: () => {}
  },
  /**
   * A function that takes the filters values as arguments and needs to return a result that is expectable to the caller's resource search handler.
   * Should be used to extend the search argument from just the q property, which will be used by default if no function is provided.
   */
  extendSearch: {
    type: Function,
    required: false,
    default: null
  },
  /* The next two are used to sort the columns of the tables as they are stored in the user configuration */
  // We want to see if we have in the user configuration a sorting for the columns of the table that comes from the next field
  // The tableIdentifier field will be the name of the table on the configuration
  /*
  local: {
    (id subuser) 26092209-f9d5-41c6-9bb1-4c284c733d9a: {                                // Subuser id
      tables_columns_order: {
        products: {                                                                     //tableIdentifier name
          hidden: ["name", "_product_group_label", "_account_label", "_tax_label"],
          shown: ["_attributes_names", "_barcodes", "custom_id", "_type_label", "business_partner_company_name", "_price_label", "_purchase_price", "brand", "_stock_info_label"]
        }
      }
    },
    owner: {}                                                                           // Client user
  }
  */
  sortColumnsEnabled: {
    type: Boolean,
    default: false
  },
  // String that serves as key for the table to store later on the order of the columns
  tableIdentifier: {
    type: String,
    default: null
  }
}
