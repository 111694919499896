<template>
  <section class="th-navigation" :class="{ collapsed }">
    <!-- Menu -->
    <div
      v-for="(item, index) in navigationWithPermissions"
      :key="index"
      class="th-menu"
      :class="{ 'th-menu-with-submenu': item.children.length > 0 }"
      @mouseenter="mouseEnter"
    >
      <!-- Menu item -->
      <div class="th-menu-link">
        <router-link
          v-if="item.enabled"
          :ref="`link${item.route}`"
          :to="item.route"
          :data-testid="item.route"
          :class="{ 'router-link-active': currentPath === item.route }"
          @click="handleNavigationLinkClick(item)"
        >
          <svgicon :src="getIcon(item.name)" class="svg-icon" />
          <span :style="style">{{ item.key ? $t(item.key) : item.name }}</span>
        </router-link>
      </div>

      <!-- Submenu -->
      <div
        v-if="item.children.length > 0"
        class="th-submenu"
        :style="style"
        :class="{ open: submenuOpen === item.name }"
      >
        <!-- Submenu item -->
        <div
          v-for="(subitem, subindex) in item.children"
          :key="`${index}-${subindex}`"
          class="th-submenu-link"
        >
          <router-link
            v-if="subitem.enabled"
            :to="`${item.route}${subitem.route}`"
            :data-testid="`${item.route}${subitem.route}`"
            :class="{
              'router-link-active':
                currentPath === `${item.route}${subitem.route}`
            }"
            @click="trackNavigation([item.key, subitem.key])"
          >
            {{ subitem.key ? $t(subitem.key) : subitem.name }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { parseNavByPermissions } from '@/utils/permissions'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const appConfigStore = useAppConfigStore()
    const { navigation } = storeToRefs(appConfigStore)

    return { navigation }
  },

  data() {
    return {
      style: { top: '' },
      submenuOpen: null,
      currentPath: null
    }
  },

  computed: {
    userScopes() {
      return this.$store.state.Auth.scopes
    },

    userRole() {
      return this.$store.state.Auth.role
    },

    navigationWithPermissions() {
      return parseNavByPermissions(
        this.navigation,
        this.userRole,
        this.userScopes
      )
    }
  },

  watch: {
    $route: function () {
      this.routeChanged(this.$route.path)
    }
  },

  mounted() {
    this.routeChanged(this.$route.path)
  },

  methods: {
    getIcon(name) {
      try {
        const icon = require(`@/assets/icons/th-icon-${name}.svg`)
        return icon
      } catch {
        return ''
      }
    },
    handleNavigationLinkClick(item) {
      this.trackNavigation([item.key])
      this.routeChanged(item.route)
    },
    trackNavigation(keysArray) {
      this.$ampli.eventWithBaseProps('navigationPrimary', {
        navigation_to: keysArray.map((item) => this.$t(item, 'en')).join(' - ')
      })
    },
    mouseEnter(e) {
      if (!this.collapsed) return

      const submenu = e.target.getElementsByClassName('th-submenu')[0]
      if (submenu) {
        // Submenu dropdown clipping fix
        setTimeout(() => {
          const submenuTop = e.target.getBoundingClientRect().top
          const submenuHeight = submenu.getBoundingClientRect().height
          if (submenuHeight + submenuTop > window.innerHeight) {
            const diff = submenuHeight + submenuTop - window.innerHeight
            this.style.top = submenuTop - diff + 'px'
          } else {
            this.style.top = submenuTop + 'px'
          }
        }, 100)
      } else {
        this.style.top = e.target.getBoundingClientRect().top + 'px'
      }
    },

    routeChanged(newPath) {
      this.currentPath = newPath
      const path = newPath.substring(1).split('/')
      this.submenuOpen = path[0]
      this.scrollToLink(path[0])
    },

    scrollToLink(path) {
      const element = this.$refs['link/' + path]
      if (element && element[0]?.$el.scrollIntoView) {
        element[0].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }
    }
  }
}
</script>

<style scoped>
.th-navigation {
  overflow-x: hidden;
  height: 100%;
}

.router-link-exact-active,
.router-link-active {
  background: var(--navigation-active-bg-color);
  color: var(--navigation-active-color) !important;
  border-color: var(--navigation-active-color) !important;
  font-weight: var(--control-font-weight);
  font-size: var(--menu-font-size) !important;
  letter-spacing: var(--letter-spacing-menu-titles);
}
.router-link-exact-active .svg-icon,
.router-link-active .svg-icon {
  fill: var(--navigation-active-color);
}

.th-submenu-link a,
.th-menu-link a {
  font-size: 1em;
  height: 50px;
  padding: 0 1rem;
  color: var(--navigation-bg-color);

  display: flex;
  align-items: center;
  cursor: pointer;

  border-left: 5px solid transparent;
}

.th-submenu-link a {
  height: 40px;
}

.svg-icon {
  height: 22px;
  width: 22px;
  fill: var(--navigation-bg-color);
  margin-right: 1rem;
}

.th-menu-link a:hover,
.th-submenu-link a:hover {
  color: var(--navigation-active-color);
  background: var(--navigation-active-bg-color);
}

.th-menu-link a:active,
.th-submenu-link a:active {
  color: var(--navigation-active-color);
  background: var(--navigation-active-bg-color);
}

.th-submenu-link a {
  margin-left: -5px;
  font-size: var(--submenu-font-size) !important;
  letter-spacing: var(--letter-spacing-titles);
}

.th-submenu-link a:hover .svg-icon,
.th-submenu-link a:active .svg-icon,
.th-menu-link a:hover .svg-icon,
.th-menu-link a:active .svg-icon {
  fill: var(--navigation-active-color);
}

.th-submenu {
  overflow: hidden;
  transition: all 0.5s ease;
  max-height: 0;
  border-left: 5px solid transparent;
}

.th-submenu.open {
  max-height: 1000px;
  border-color: var(--navigation-active-color) !important;
  background: var(--navigation-active-bg-color);
}

.th-submenu.open .th-submenu-link a:hover {
  border-color: var(--navigation-active-color) !important;
}

/* Scroll bar customization for firefox */
.th-navigation {
  scrollbar-color: var(--navigation-bg-color) var(--navigation-color);
  scrollbar-width: thin;
}

/* Scroll bar customization for chrome and safari */
.th-navigation::-webkit-scrollbar {
  -webkit-appearance: none;
}
.th-navigation::-webkit-scrollbar:vertical {
  width: 9px;
}
.th-navigation::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid var(--navigation-color);
  background-color: var(--navigation-bg-color);
}

/* Collapsed state */
.th-navigation.collapsed .th-menu-link a {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.th-navigation.collapsed
  .th-menu:not(.th-menu-with-submenu):hover
  .th-menu-link
  a
  span {
  display: flex;
}

.th-navigation.collapsed .th-menu-link a span {
  z-index: 1000;
  position: fixed;
  left: 65px;
  background: var(--navigation-active-bg-color);
  display: none;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  margin-left: 0.5rem;
  margin-top: 12px;
  color: var(--navigation-bg-color);
}

.th-navigation.collapsed .th-menu-link a svg {
  margin-right: 0;
}

.th-navigation.collapsed .th-submenu.open {
  max-height: 0;
}

.th-navigation.collapsed .th-menu:hover .th-submenu {
  max-height: 1000px;
  background: var(--navigation-active-bg-color);
}

.th-navigation.collapsed .th-submenu-link a {
  height: 50px;
}

.th-navigation.collapsed .th-submenu {
  transition: all 0.1s;
  position: fixed;
  top: 50%;
  left: 65px;
  z-index: 10000;
  transform-origin: top left;
}
</style>
