/**
 * This function receives an array of routes and a path,
 * it returns the translation key for a specific path up to 2 levels deep.
 * @param {Array} navigation
 * @param {String} path
 * @returns {String}
 */
export function getPageTranslationKey(navigation, path = '') {
  const [mainRouteKey, subRouteKey] = path.split('/').filter((item) => !!item)

  const mainRoute = findRouteItem(navigation, mainRouteKey) || {}
  const subRoute = findRouteItem(mainRoute.children, subRouteKey) || {}

  const translationKey = subRoute.key || mainRoute.key || ''

  return pageTranslationKeyMapper(translationKey)
}

/**
 * Return the route item with the route key
 * @param {Array} items
 * @param {String} key
 * @returns {String}
 */
function findRouteItem(items = [], key = '') {
  return items.find(({ route = '' }) => route === `/${key}`)
}

/**
 * Mapping some page-translation-keys to alternative translation keys
 * @param {String} key
 * @returns {String}
 */
function pageTranslationKeyMapper(key) {
  return (
    {
      'nav.main.items.loyalty.title': 'pages.voucher.title',
      'nav.main.items.loyalty.all': 'pages.voucher.title',
      'nav.main.items.loyalty.logs': 'pages.voucher-logs.title',
      'nav.main.items.staff.all': 'pages.staff.title',
      'nav.main.items.settings.pos': 'pages.settings.pos.discounts.title'
    }[key] || key
  )
}
