<template>
  <notification-base>
    <template #icon_start>
      <el-icon class="text-th-green font-bold text-2xl"><Check /></el-icon>
    </template>
    <template #content>
      <slot name="content">
        <div class="text-th-light-gray font-light text-sm tracking-wide pb-1">
          <slot name="content_upper_row" />
        </div>
        <div :class="routeToOptions ? 'text-th-secondary-gray' : ''">
          <slot name="content_lower_row" />
        </div>
      </slot>
      <div v-if="routeToOptions" class="mt-2">
        <!-- Link to download (primary click action) -->
        <span v-if="routeToOptions.actionText" class="text-sm mr-4">{{
          routeToOptions.actionText
        }}</span>
        <!-- Link to export history page -->
        <router-link
          :to="routeToOptions.to"
          class="text-sm"
          @click.stop="routeToOptions.onClick"
          v-text="routeToOptions.text"
        />
      </div>
    </template>
    <template v-if="canClick" #icon_end>
      <el-button text class="focus:outline-none focus:shadow-outline p-0">
        <svgicon
          :src="require('@/assets/icons/th-icon-download.svg')"
          :style="{
            height: '23px',
            width: '23px'
          }"
          class="fill-current"
        />
      </el-button>
    </template>
  </notification-base>
</template>

<script>
import NotificationBase from '../base'

export default {
  components: {
    NotificationBase
  },
  props: {
    /**
     * canClick only tells the component if to render the button.
     * The actual click event should propagate to the parent and being triggered there
     */
    canClick: {
      type: Boolean,
      default: false
    },
    routeToOptions: {
      type: Object,
      default: null
    }
  }
}
</script>
