import flush from 'just-flush'

const getInitials = function (firstname, lastname) {
  const initials = []
  if (firstname) initials.push(firstname[0].toUpperCase())
  if (lastname) initials.push(lastname[0].toUpperCase())
  return initials.length ? initials.join('') : null
}

const getFullName = function (firstname, lastname) {
  const name = []
  if (firstname) name.push(firstname)
  if (lastname) name.push(lastname)

  return name.length ? name.join(' ') : null
}

/**
 * Customized formats staff's name according to configList input
 * @param {Array} configList - array of objects
 * @param {string} joiner
 */
export default {
  install: (app, options = {}) => {
    // eslint-disable-next-line camelcase
    app.config.globalProperties.$formatStaff = function (
      staff,
      configList = ['initials', 'staff_number'],
      joiner = ' '
    ) {
      if (!staff) return null
      const { firstname, lastname, staff_number, displayname, username } = staff
      let formattedStaff = configList.map((type) => {
        // eslint-disable-next-line camelcase
        if (type === 'staff_number') return staff_number
        if (type === 'firstname') return firstname
        if (type === 'lastname') return lastname
        if (type === 'displayname') return displayname
        if (type === 'username') return username
        if (type === 'initials') return getInitials(firstname, lastname)
        if (type === 'fullName') return getFullName(firstname, lastname)
        return null
      })
      formattedStaff = flush(formattedStaff)
      return formattedStaff.length ? formattedStaff.join(joiner) : null
    }
  }
}
