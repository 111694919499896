import ClickOutside from '@/plugins/close-outside-click'
import filters from '@/plugins/filters'
import formatters from '@/plugins/formatters'
import prune from '@/plugins/prune'
import safeRoutePush from '@/plugins/safe-push'
import rootMethods from '@/plugins/root-methods'
import ampli from '@/plugins/ampli'
import handleResourceFetchError from '@/plugins/handle-resource-fetch-error'
import cancelReadOnly from '@/plugins/cancel-read-only'
import ThCommonResourceUtils from '@/plugins/common-resource-utils'
import ThCurrencyFormatter from '@/plugins/currency-formatter'
import ThNumberFormatter from '@/plugins/number-formatter'
import ThCurrencyInputV2 from '@/components/inputs/currency-input'
import ThCurrencySelect from '@/components/currency-select'
import ThDatatable from '@/components/datatable'
import ThDateFormatter from '@/plugins/date-formatter'
import ThDeepClone from '@/plugins/deep-clone'
import ThImage from '@/components/image'
import ThImageUploadV2 from '@/components/image-upload-v2'
import ThInputTitle from '@/components/input-title'
import ThLogException from '@/plugins/log-exception'
import ThNumberInputV2 from '@/components/inputs/number-input'
import ThPageWrapper from '@/components/tillhub/page-wrapper.vue'
import ThPagination from '@/components/pagination'
import ThPopover from '@/components/popover'
import ThDrawer from '@/components/drawer'
import ThQuickView from '@/components/tillhub/quick-view.vue'
import ThRemoteSearchSelect from '@/components/remote-search-select'
import ThResourceSelect from '@/components/resource-select'
import ThStaffNameFormatter from '@/plugins/staff-name-formatter'
import ThTabPageWrapper from '@/components/tab-page-wrapper'
import ThTabsFormWrapper from '@/components/tabs-form-wrapper'
import ThWrapper from '@/components/wrapper'
import ThModalPlugin from '@/plugins/th-modal'
import ThModal from '@/components/modal'
import ThAskToDeletePlugin from '@/plugins/th-ask-to-delete'
import { Calendar, DatePicker, setupCalendar } from 'v-calendar'
import VueUniversalModal from 'vue-universal-modal'
import lazyPlugin from 'vue3-lazy'
import Debug from '@/plugins/debug'
// TODO: slow loading of the icons, why?
import InlineSvg from 'vue-inline-svg'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//import Meta from 'vue-meta'

export function registerDependencies(app, { i18n, isProd = false }) {
  app.component('ThCurrencyInput', ThCurrencyInputV2)
  app.component('ThCurrencySelect', ThCurrencySelect)
  app.component('ThDatatable', ThDatatable)
  app.component('ThImage', ThImage)
  app.component('ThImageUploadV2', ThImageUploadV2)
  app.component('ThInputTitle', ThInputTitle)
  app.component('ThNumberInput', ThNumberInputV2)
  app.component('ThPageWrapper', ThPageWrapper)
  app.component('ThPagination', ThPagination)
  app.component('ThPopover', ThPopover)
  app.component('ThDrawer', ThDrawer)
  app.component('ThQuickView', ThQuickView)
  app.component('ThRemoteSearchSelect', ThRemoteSearchSelect)
  app.component('ThResourceSelect', ThResourceSelect)
  app.component('ThTabPageWrapper', ThTabPageWrapper)
  app.component('ThTabsFormWrapper', ThTabsFormWrapper)
  app.component('ThWrapper', ThWrapper)
  app.component('ThModal', ThModal)
  app.component('Svgicon', InlineSvg)
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  app.use(setupCalendar)
  app.component('VCalendar', Calendar)
  app.component('VDatePicker', DatePicker)

  app.use(ClickOutside)
  app.use(filters)
  app.use(formatters)
  app.use(prune)
  app.use(safeRoutePush)
  app.use(rootMethods)
  app.use(ampli, { init: process.env.NODE_ENV === 'production' })
  app.use(handleResourceFetchError)
  app.use(cancelReadOnly)
  app.use(ThCommonResourceUtils)
  app.use(ThCurrencyFormatter, { i18n })
  app.use(ThNumberFormatter, { i18n })
  app.use(ThDateFormatter)
  app.use(ThDeepClone)
  app.use(ThLogException)
  app.use(ThModalPlugin)
  app.use(ThAskToDeletePlugin)
  app.use(ThStaffNameFormatter)
  app.use(VueUniversalModal, {
    teleportTarget: '#th-modals'
  })
  // vue3-lazyload is broken so far, wait for a new version
  // https://github.com/murongg/vue3-lazyload/issues/31
  app.use(lazyPlugin, {})
  app.use(Debug)
  //app.use(Meta)
  //app.use(VueSVGIcon)
}
