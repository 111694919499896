<template>
  <div v-if="tags.length" class="mt-2">
    <el-tag
      v-for="tag in tags"
      :key="tag.name"
      class="my-1 mr-2"
      :disable-transitions="true"
      :closable="tag.closable === false ? false : true"
      @close="removeTag(tag.name)"
    >
      {{ tag.label }}: {{ tag.value }}
    </el-tag>
  </div>
</template>

<script>
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import typeOf from 'just-typeof'
import isEmpty from 'just-is-empty'

export default {
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filters: {
      type: Array,
      required: true
    },
    textFieldName: {
      type: String,
      required: false,
      default: 'search'
    }
  },
  data() {
    return {
      tagsObject: this.normaliseIncomingFilters()
    }
  },
  computed: {
    tags() {
      const tags = Object.keys(this.tagsObject)
        .filter((name) => name !== this.textFieldName)
        .map((name) => this.tagsObject[name])
        .filter((tag) => !isEmpty(tag.value))
        .map((tag) => {
          const _tag = { ...tag }

          const filter = this.filters.find((filter) => filter.name === tag.name)

          if (!filter) return _tag

          const originalData = tag.originalData || filter.originalData
          // this caters for the case when the customer was not pre-downloaded yet
          // and the tag label needs to be built from the customer data
          // that has just been retrieved from the remote-search-select component
          if (
            _tag.type === 'remote-search-select' &&
            originalData &&
            typeOf(filter.computeName) === 'function'
          ) {
            const queryInput = safeGet(tag, 'originalData.queryInput')
            return {
              ..._tag,
              value: filter.computeName(originalData, queryInput),
              closable: filter.closable !== false
            }
          }

          // Override value with original data computed name
          if (
            _tag.type === 'remote-search-select' &&
            originalData &&
            originalData.computeName
          ) {
            return {
              ..._tag,
              value: originalData.computeName,
              closable: filter.closable !== false
            }
          }

          // Override value with original data and computed fields
          if (
            _tag.type === 'remote-search-select' &&
            originalData &&
            tag.computedFields
          ) {
            const value = Object.entries(
              pick(originalData, [...tag.computedFields])
            )
              .filter((item) => item[1] !== null && item[1] !== undefined)
              .map((item) => item[1])
              .join(' - ')
              .trim()
            return {
              ..._tag,
              value,
              closable: filter.closable !== false
            }
          }

          // the next two cases cater for the constructed tag labels of the select options
          // where the data was pre-downloaded and is available
          if (
            (_tag.type === 'select' || _tag.type === 'remote-search-select') &&
            filter &&
            filter.options &&
            filter.options.length
          ) {
            const option = filter.options.find(
              (item) => item.value === _tag.value
            )
            if (option && option.label) {
              return {
                ..._tag,
                value: option.label,
                closable: filter.closable !== false
              }
            }
          }

          if (
            _tag.type === 'multiselect' &&
            filter &&
            filter.options &&
            filter.options.length
          ) {
            const options = filter.options
              .filter((item) => _tag.value.includes(item.value))
              .map((item) => item.label)
              .filter((item) => item)
            return {
              ..._tag,
              value: this.getMultipleTagValue(options),
              closable: filter.closable !== false
            }
          }

          // Override value with original data and computed fields
          if (
            _tag.type === 'multiselect' &&
            originalData &&
            tag.computedFields
          ) {
            const labels = originalData
              .filter((item) =>
                _tag.value.includes(item[filter.optionsValue || 'id'])
              )
              .map((originalDataItem) => {
                return Object.entries(
                  pick(originalDataItem, [...tag.computedFields])
                )
                  .filter((item) => item[1] !== null && item[1] !== undefined)
                  .map((item) => item[1])
                  .join(' - ')
                  .trim()
              })

            return {
              ..._tag,
              value: this.getMultipleTagValue(labels),
              closable: filter.closable !== false
            }
          }

          // in case the caller specifies a function to format the tag label,
          // and none of the above cases were matched, we are calling it here
          if (filter.formatValue && typeOf(filter.formatValue) === 'function') {
            return {
              ..._tag,
              value: filter.formatValue(tag.value, originalData)
            }
          }

          return _tag
        })
        .filter((tag) => !!tag) // remove tags, where "filter" was not found in previous step

      return tags
    }
  },
  watch: {
    modelValue() {
      this.tagsObject = this.normaliseIncomingFilters()
    }
  },
  methods: {
    normaliseIncomingFilters() {
      const incomingFilters = {
        ...this.modelValue
      }

      this.filters.forEach((item) => {
        // REVIEW: watch for unwanted overrides
        incomingFilters[item.name] = {
          value: null,
          ...item,
          ...incomingFilters[item.name]
        }
      })

      const result = {
        ...incomingFilters
      }

      if (!result[this.textFieldName]) {
        result[this.textFieldName] = { label: null, value: null }
      }

      return result
    },
    removeTag(name) {
      this.$emit('before-remove-tag', this.tagsObject[name])
      this.tagsObject[name].value = null
      this.handleSubmit()
    },
    handleSubmit() {
      const trimmedTagsObject = this.prepareTagsObject(this.tagsObject)
      this.$emit('submit', trimmedTagsObject)
    },
    prepareTagsObject(tagsObject) {
      const trimmed = { ...tagsObject }

      // remove trailing whitespace in all nested levels
      function trimInputs(obj) {
        for (const key in obj) {
          if (obj[key] !== null && typeof obj[key] === 'object') {
            // works on objects and arrays
            obj[key] = trimInputs(obj[key])
          }

          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim()
          }
        }
        return obj
      }

      trimInputs(trimmed)

      return trimmed
    },

    getMultipleTagValue(labels) {
      return labels.length >= 2
        ? `${labels[0]}, +${labels.length - 1}`
        : labels[0]
    }
  }
}
</script>
