<template>
  <el-input
    v-model="tagsObject[textFieldName].value"
    class="w-40 h-full ml-0 mr-2 text-sm text-th-primary bg-white"
    :placeholder="
      placeholder ||
      $t('components.th_datatable.search_filter.search.placeholder')
    "
    clearable
    data-lpignore="true"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    :style="inputFieldStyle"
    @input="onFreetextInput"
    @clear="handleSubmit"
    @keyup.enter="handleSubmit"
  >
    <template #prefix>
      <div class="flex items-center el-input__prefix">
        <svgicon
          :src="require('@/assets/icons/th-icon-magnifying-glass.svg')"
          :style="{
            height: '24px',
            width: '24px'
          }"
        />
      </div>
    </template>
  </el-input>
</template>

<script>
export default {
  name: 'SearchInput',
  components: {},
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    },
    textFieldName: {
      type: String,
      required: false,
      default: 'q'
    },
    placeholder: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      input: '',
      freetextInput: null,
      tagsObject: this.normaliseIncomingFilters()
    }
  },
  computed: {
    inputFieldStyle() {
      const obj = {}

      if (this.width && this.width > 350) {
        return { ...obj, width: `${parseFloat(this.width) + 60}px` }
      }
      return obj
    }
  },
  watch: {
    tagsObject: {
      handler: function (newValues, oldValues) {
        this.$emit('update:modelValue', newValues)
      },
      deep: true
    }
  },
  methods: {
    normaliseIncomingFilters() {
      return this.filters.reduce(
        (incomingFilters, item) => {
          incomingFilters[item.name] = {
            value: null,
            ...item,
            ...incomingFilters[item.name]
          }
          return incomingFilters
        },
        {
          [this.textFieldName]: { label: null, value: null },
          ...this.modelValue
        }
      )
    },
    dispatchFilter() {
      const filter = {}
      Object.entries(this.tagsObject)
        .filter((item) => {
          return !(
            item[1].value === null ||
            item[1].value === '' ||
            this.isEmptyArray(item[1].value)
          )
        })
        .forEach((item) => {
          filter[item[0]] = item[1].value
        })

      this.$emit('filter', filter, this.tagsObject)
    },
    onFreetextInput(v) {
      if (v === '') this.tagsObject[this.textFieldName].value = null
      this.dispatchFilter()
    },

    handleSubmit() {
      const trimmedTagsObject = this.prepareTagsObject(this.tagsObject)
      this.$emit('submit', trimmedTagsObject)
    },
    prepareTagsObject(tagsObject) {
      const trimmed = { ...tagsObject }

      // remove trailing whitespace in all nested levels
      function trimInputs(obj) {
        for (const key in obj) {
          if (obj[key] !== null && typeof obj[key] === 'object') {
            // works on objects and arrays
            obj[key] = trimInputs(obj[key])
          }

          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim()
          }

          // Clean q when empty
          if (!obj[key]) delete obj[key]
        }
        return obj
      }

      trimInputs(trimmed)

      return trimmed
    },

    isEmptyArray(value) {
      return Array.isArray(value) && !value.length
    }
  }
}
</script>

<style scoped>
* {
  border-color: var(--border-color);
}

.el-input :deep(.el-input__inner) {
  height: 100%;
}

.el-input.no-left-border :deep(.el-input__inner) {
  border-left: 0;
}

.el-input :deep(.el-input__suffix) {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
