<template>
  <div
    class="th-base-card rounded relative bg-white p-6 shadow-md overflow-hidden"
  >
    <slot />
  </div>
</template>

<style scoped>
.th-base-card {
  border: 1px solid #ebeef5;
}
</style>
