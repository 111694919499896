<template>
  <el-select
    :id="id"
    v-model="selectValue"
    v-loading="isLoading"
    v-cancel-read-only
    class="th-resource-select"
    filterable
    remote
    :clearable="clearable"
    reserve-keyword
    :remote-method="searchResource"
    :popper-append-to-body="appendToBody"
    :multiple="multiple"
    :disabled="disabled"
    :placeholder="placeholder"
    @change="handleInput"
    @focus="handleFocus"
  >
    <template #prefix>
      <i
        v-if="!!prefixIcon"
        class="el-input__icon"
        :class="{ [prefixIcon]: true }"
      />
    </template>
    <el-option
      v-for="item in resoureOptions"
      :key="item.id"
      :label="item.computed_name"
      :value="item[defaultKey]"
    />
  </el-select>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import debounce from 'debounce'
import isEmpty from 'just-is-empty'

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: 'resource-id'
    },
    modelValue: {
      required: true,
      validator: (prop) => {
        return typeof prop === 'string' || Array.isArray(prop) || prop === null
      }
    },
    resource: {
      type: String,
      required: true,
      validator: function (value) {
        const allowedResources = [
          'taxes',
          'productGroups',
          'accounts',
          'branches',
          'registers',
          'tags',
          'contents',
          'contentTemplates',
          'branchGroups',
          'favourites',
          'voucherSystems',
          'products',
          'reasons',
          'expenseAccounts'
        ]
        return allowedResources.indexOf(value) !== -1
      }
    },
    resourceQuery: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      required: false,
      default: undefined
    },
    computedFields: {
      type: Array,
      default: () => ['name']
    },
    defaultKey: {
      type: String,
      default: 'id'
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    prefixIcon: {
      type: String,
      required: false,
      default: undefined
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loadLazy: {
      type: Boolean,
      required: false,
      default: false
    },
    emitClean: {
      type: Boolean,
      required: false,
      default: false
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'resource-set', 'loading-error'],
  data() {
    return {
      resoureOptions: [],
      resources: [],
      isLoading: false
    }
  },
  computed: {
    selectValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  async created() {
    if (this.options) {
      this.handleOptions()
    } else {
      await this.fetchResources()
    }
  },
  methods: {
    searchResource: debounce(function (text) {
      if (!text) return
      if (text.length < 2) return
      this.resoureOptions = this.resources.filter((item) => {
        return item.computed_name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
    }, 50),
    async fetchResources() {
      try {
        const inst = th[this.resource]()

        let resourceOptions = {}
        if (this.resourceQuery) {
          resourceOptions = {
            ...resourceOptions,
            ...this.resourceQuery
          }
        }

        if (isEmpty(resourceOptions)) resourceOptions = undefined

        this.isLoading = true
        const { data } = await inst.getAll(resourceOptions)
        this.isLoading = false

        if (!this.resources || !Array.isArray(this.resources)) {
          this.resources = []
          return
        }

        this.resources = this.resoureOptions = this.computeValues(data)

        if (this.selectValue) {
          this.$emit(
            'resource-set',
            this.resources.find((resource) => resource.id === this.selectValue)
          )
        }
      } catch (err) {
        this.isLoading = false
        this.resources = []
        return this.$emit('loading-error', err)
      }
    },
    computeValues(data) {
      return data.map((item) => {
        const obj = { ...item }

        obj.computed_name = Object.entries(pick(item, [...this.computedFields]))
          .filter((item) => item[1] !== null && item[1] !== undefined)
          .map((item) => item[1])
          .join(' - ')
          .trim()

        return obj
      })
    },
    handleOptions() {
      if (this.loadLazy && this.selectValue) {
        this.resources = this.resoureOptions = this.computeValues(
          this.options
        ).filter((resource) => resource.id === this.selectValue)
      } else if (this.loadLazy) {
        this.resources = this.resoureOptions = []
      } else {
        this.resources = this.resoureOptions = this.computeValues(this.options)
      }

      const emittable = this.resources.find(
        (resource) => resource.id === this.selectValue
      )
      if (this.emitClean !== true) {
        this.$emit('resource-set', emittable)
      } else if (this.emitClean === true && !!emittable) {
        this.$emit('resource-set', emittable)
      }
    },
    handleInput(item) {
      if (this.clearable && !this.multiple && !item) {
        this.$emit('update:modelValue', null)
        this.$emit('resource-set', undefined)
        return
      }

      this.$emit('update:modelValue', item)
      this.$emit(
        'resource-set',
        this.resources.find((resource) => resource.id === item)
      )
    },
    handleFocus() {
      if (this.loadLazy) {
        this.resources = this.resoureOptions = this.computeValues(this.options)
      }
    }
  }
}
</script>

<style scoped></style>
