<template>
  <div class="th-quick-view">
    <el-dialog
      v-model="isVisible"
      class="th-quick-view__basic"
      custom-class="th-quick-view__basic"
      close-on-press-escape
      close-on-click-modal
      :show-close="false"
      modal
      :append-to-body="nested"
      :width="width"
      :top="top"
      @close="$emit('close-requested')"
    >
      <div class="th-quick-view__baisc-content">
        <!-- inject content via default slot -->
        <slot />
      </div>
      <template #header>
        <div class="dialog-header">
          <!-- if not header is passed, use the default header -->
          <slot v-if="$slots.header" name="header" />
          <!-- default header -->
          <div v-else class="th-quick-view__default-header">
            <div
              class="th-quick-view__default-header-close"
              @click="handleClose"
            >
              <el-icon><Close /></el-icon>
            </div>
            <div class="th-quick-view__default-header-title">
              <h3 v-if="title">
                {{ title }}
              </h3>
            </div>
            <div class="th-quick-view__default-header-action">
              <slot v-if="$slots['actions']" name="actions" />
              <div v-else>
                <el-button
                  v-if="showActions"
                  type="primary"
                  @click="handleView"
                >
                  {{ labelsView }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <slot v-if="$slots.footer" name="footer" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ThQuickView',
  props: {
    labelsView: {
      type: String,
      required: false,
      default: 'View'
    },
    visible: {
      type: Boolean,
      required: true
    },
    nested: {
      type: Boolean,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: undefined
    },
    top: {
      type: String,
      required: false,
      default: undefined
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      dialogInvisibleForced: false
    }
  },
  computed: {
    isVisible() {
      return !!this.visible
    }
  },
  methods: {
    handleClose() {
      this.$emit('close-requested')
      this.$nextTick(() => {
        this.$emit('close-completed')
      })
    },
    handleView() {
      this.$emit('view-requested')
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper :deep(.el-dialog) {
  max-height: 75%;
  overflow: auto;
}

.th-quick-view__basic .th-quick-view__baisc-content {
  width: 100%;
  height: 100%;
}

.dialog-header {
  width: 100%;
  height: 100%;
}

.th-quick-view__basic :deep(.el-dialog .el-dialog__header) {
  padding: 0;
  height: 60px;
  box-shadow: 0 2px 4px 0 #a7abb1;
  overflow: hidden;
  width: 100%;
}

.th-quick-view__basic :deep(.el-dialog .el-dialog__body) {
  height: calc(100% - 60px);
  overflow-y: auto;
  box-sizing: border-box;
}

.th-quick-view__default-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.th-quick-view__default-header-close > i {
  font-size: 30px;
  color: var(--navigation-color);
}

.th-quick-view__basic :deep(.el-icon-close) {
  cursor: pointer;
}
</style>
