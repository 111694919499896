function determineHidden() {
  let hidden = 'hidden'
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
  }

  return hidden
}

function determineVisibilityChange() {
  let visibilityChange = 'visibilitychange'
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange'
  }

  return visibilityChange
}

export const hidden = determineHidden()
export const visibilityChange = determineVisibilityChange()

export const availableCountries = ['AT', 'CH', 'DE', 'HU', 'UK', 'US']
export const currencies = ['EUR', 'GBP', 'USD', 'HUF', 'CHF', 'DKK', 'SEK']
export const currencySymbol = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  HUF: 'Ft',
  CHF: 'CHF',
  DKK: 'kr.',
  SEK: 'kr'
}
export const currencyPrecisions = {
  EUR: { precision: 2 },
  USD: { precision: 2 },
  GBP: { precision: 2 },
  CHF: { precision: 2 },
  HUF: { precision: 0 },
  DKK: { precision: 2 },
  SEK: { precision: 2 }
}

export const taxClasses = {
  NORMAL: 'normal',
  REDUCED: 'reduced',
  SUPER_REDUCED: 'super_reduced'
}

/**
 * @name License options
 * @description map of avaliable license options. Example: license.GASTRO -> gastropos-mobile
 */
export const license = {
  GASTRO: 'gastropos-mobile', // Gastro License - iOS POS with tables
  FULL_MOBILE: 'fullpos-mobile', // Retail License
  FULL_STATIONATY: 'fullpos-stationary', // Tillhub Multi License
  LITEPOS: 'litepos-mobile', // POS Go License
  EDA: 'eda' // Warehub License
}

/**
 * @name Language ISO
 * @description map of avaliable language names with ISO code values. Example: languageISO.GERMAN -> de-DE
 */
export const languageISO = {
  GERMAN: 'de-DE',
  ENGLISH: 'en-US',
  CZECH: 'cs-CZ',
  FRENCH: 'fr-FR',
  ITALIAN: 'it-IT'
}

/**
 * @name Gastro locale suffix
 * @description string suffix for locale when license includes 'gastropos-mobile'. Example: de-DE => de-DE-gastro
 */
export const gastroLocaleSuffix = '-gastro'

export const HEADERS_MAP = (vm) => ({
  quantity: {
    default: vm.$t('pages.document_designer.headers.quantity'),
    custom: null,
    show: true,
    section: 'items'
  },
  product_name: {
    default: vm.$t('pages.document_designer.headers.productName'),
    custom: null,
    show: true,
    section: 'items'
  },
  attributes: {
    default: vm.$t('pages.document_designer.headers.attributes'),
    custom: null,
    show: true,
    section: 'items'
  },
  comments: {
    default: vm.$t('pages.document_designer.headers.comments'),
    custom: null,
    show: false,
    section: 'items'
  },
  unit_price_exclude_vat: {
    default: vm.$t('pages.document_designer.headers.unitPriceExcludeVat'),
    custom: null,
    show: true,
    section: 'items'
  },
  unit_price_include_vat: {
    default: vm.$t('pages.document_designer.headers.unitPriceIncludeVat'),
    custom: null,
    show: true,
    section: 'items'
  },
  discount: {
    default: vm.$t('pages.document_designer.headers.discount'),
    custom: null,
    show: true,
    section: 'items'
  },
  total_price_include_vat: {
    default: vm.$t('pages.document_designer.headers.totalPriceIncludeVat'),
    custom: null,
    show: true,
    section: 'items'
  },
  vat_percentage: {
    default: vm.$t('pages.document_designer.headers.vatPercentage'),
    custom: null,
    show: true,
    section: 'items'
  },
  net_total: {
    default: vm.$t('pages.document_designer.headers.netTotal'),
    custom: null,
    show: true,
    section: 'summary'
  },
  total: {
    default: vm.$t('pages.document_designer.headers.total'),
    custom: null,
    show: true,
    section: 'summary'
  },
  total_discount: {
    default: vm.$t('pages.document_designer.headers.totalDiscount'),
    custom: null,
    show: true,
    section: 'summary'
  },
  VAT: {
    default: vm.$t('pages.document_designer.headers.VAT'),
    custom: null,
    show: true,
    section: 'summary'
  },
  product_number: {
    default: vm.$t('pages.document_designer.headers.product_number'),
    custom: null,
    show: true,
    section: 'items'
  }
})

export const NOTIFICATIONS_TYPES = {
  LOADING: 'loading',
  PROGRESS: 'progress',
  SUCCESS: 'success',
  ERROR: 'error',
  EXPIRED: 'expired',
  EMPTY: 'empty',
  INFO: 'info'
}

export const whitelabels = {
  LOCALSEARCH: 'localsearch'
}

export const localsearchLinks = {
  help_center: 'https://www.mycash-help.localsearch.ch',
  help_center_search: 'https://mycash-help.localsearch.ch/api.php/search?q',
  contact: 'https://www.localsearch.ch/de/mycash'
}

export const tillhubLinks = {
  help_center: 'https://help.unzer.com/kb/de/point-of-sale-271089',
  help_center_search: '#',
  contact: 'https://www.tillhub.de/kontakt/'
}

export const popoverPlacements = [
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
  'right',
  'right-start',
  'right-end'
]

export const ADDRESS_FIELDS = {
  STREET: 'street',
  STREET_NUMBER: 'street_number',
  LOCALITY: 'locality',
  POSTAL_CODE: 'postal_code',
  REGION: 'region',
  COUNTRY: 'country'
}

export const STAFF_PHONE_TYPES = {
  ANY: 'any',
  HOME: 'home',
  WORK: 'work',
  MOBILE: 'mobile'
}

export const CUSTOMERS_PHONE_TYPES = {
  MAIN: 'main',
  HOME: 'home',
  WORK: 'work',
  MOBILE: 'mobile'
}

export const SUPPLIERS_PHONE_TYPES = {
  MAIN: 'main',
  HOME: 'home',
  WORK: 'work',
  MOBILE: 'mobile'
}

/** UNIFIED COMMERCE WHITELABEL START */

import unzerLogo from '@/assets/images/unzer-logo.svg'
import unzerColorLogo from '@/assets/images/unzer-logo-color.svg'
import unzerLogoCollapsed from '@/assets/images/unzer-logo-collapsed.svg'

export const unifiedCommerceWhitelabel = {
  dashboard_welcome_logo: unzerColorLogo,
  dashboard_logo: unzerLogo,
  dashboard_logo_callapsed: unzerLogoCollapsed,
  css_variables: {
    'primary-color': '#1B6AD7',
    'secondary-color': '#FC1154',
    'primary-color-focus': '#FC1154',
    'primary-color-hover': '#F4F8FD',
    'primary-button-color': '#FC1154',
    'bright-yellow': '#FBF1E6',
    'primary-gray': '#9295A3',
    'font-face': '"Unzer Visuelt", sans-serif',
    'font-color': '#0C1332',
    'navigation-color': '#0C1332',
    'navigation-active-bg-color': '#20294B',
    'checkbox-color': '#1B6AD7',
    'checkbox-text-checked-color': '#0C1332',
    'input-border-color': '#DBDCE0',
    'input-border': '2px solid #DBDCE0',
    'input-box-shadow': 'none',
    'select-double-input-border': '2px solid #DBDCE0',
    'checkbox-double-input-border': '2px solid #B6B8C2',
    'disabled-color': '#F1F1F3',
    'dropdown-disabled-color': '#F1F1F3',
    'popover-color': '#1B6AD7',
    'error-color-strong': '#EB3333',
    'error-color-light': '#FBD5D5',
    'background-color': '#F1F1F3',
    'text-color-input': '#0C1332',
    'navigation-font-color': '#0C1332',
    'navigation-active-color': '#FC1154',
    'button-primary-color': '#FC1154',
    'button-primary-color-hover': '#E30B5D',
    'button-primary-plain-color': '#1B6AD7',
    'button-primary-plain-color-hover': '#4187e7',
    'button-primary-icon-background': '#ffffff',
    'button-primary-icon-border-color': '#1B6AD7',
    'button-primary-icon-color': '#1B6AD7',
    'graph-highlight-color': '#FEB8CC',
    'header-box-shadow':
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
    'bottom-box-shadow':
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px -8px 16px rgba(0, 0, 0, 0.08)',
    'card-box-shadow':
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
    'el-border-color': '#F1F1F3',
    'el-border-color-main': '#DBDCE0',
    'el-border-color-lighter': '#F1F1F3',
    'button-padding': '0 16px',
    'button-border-radius': '99px',
    'button-border-width': '2px',
    'button-border-color': '#DBDCE0',
    'button-font-size': '12px',
    'button-font-weight': '700',
    'button-text-color': '#6D7184',
    'button-text-transform': 'uppercase',
    'button-disabled-color': '#FEB8CC',
    'button-disabled-border': '#FEB8CC',
    'button-plain-color': '#1B6AD7',
    'button-plain-background': '#FFFFFF',
    'button-plain-border-color': '#1B6AD7',
    'h3-font-size': '20px',
    'h3-font-height': '24px',
    'el-line-height': '20px',
    'control-font-size': '14px',
    'control-font-weight': '700',
    'control-line-height': '20px',
    'el-table-text-color-control': '#0C1332',
    'el-table-text-color-deep': '#0C1332',
    'el-table-text-sm': '12px',
    'el-checkbox-text-color-base': '#0C1332',
    'el-menu-text-color': '#0C1332',
    'el-th-color': '#B6B8C2',
    'label-text-color': '#6D7184',
    'label-settings-text-color': '#0C1332',
    'label-font-weight': '700',
    'label-margin-bottom': '8px',
    'empty-font-size': '18px',
    'empty-font-style': 'italic',
    'select-box-shadow': 'none',
    'el-icon-color': '#279ff6',
    'background-color-settings-menu': '#ffffff',
    'settings-menu-selected-color': '#E6E7EA',
    'settings-menu-selected-weight': '700',
    'letter-spacing-titles': '0.04em',
    'letter-spacing-menu-titles': '0.03em',
    'letter-spacing-table': '0.05em',
    'label-line-height': '20px',
    'label-color': '#0C1332',
    'label-color-active': '#0C1332',
    'checkbox-text-color': '#0C1332',
    'radio-button-color': '#0C1332',
    'text-default-color': '#0C1332',
    'selector-chip-border': '2px solid #BBD2F3',
    'selector-chip-background': '#F4F8FD',
    'selector-chip-disabled-color': '#9295A3',
    'selector-chip-disabled-bg-color': '#F1F1F3',
    'selector-chip-disabled-border-color': '#DBDCE0',
    'selector-chip-spacing': '0.05em',
    'selector-chip-line-height': '20px',
    'selector-chip-font-size': '14px',
    'el-radio-button-transform': 'uppercase',
    'el-radio-button-weight': '700',
    'el-radio-color': '#6D7184',
    'el-radio-button-padding': '8px 12px',
    'el-radio-button-border-width': '2px',
    'el-radio-border-left-overwrite': '2px solid #DBDCE0',
    'el-message-content-color': '#0C1332',
    'el-message-content-background': 'white',
    'el-message-content-success-bg': 'white',
    'el-message-content-warning-bg': 'white',
    'el-message-content-error-bg': 'white',
    'el-message-main-border': '0.5px solid #E6E7EA',
    'el-message-content-border': 'var(--el-message-main-border)',
    'el-message-content-success-border': 'var(--el-message-main-border)',
    'el-message-content-warning-border': 'var(--el-message-main-border)',
    'el-message-content-error-border': 'var(--el-message-main-border)',
    'el-message-shadow':
      'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08))',
    'color-complete': '#00A862',
    'info-color': '#D97000',
    'el-message-content-font-size': '16px',
    'el-message-content-line-height': '24px',
    'child-item-style': 'normal',
    'caret-color': '#B6B8C2',
    'input-inner-color': '#0C1332',
    'menu-font-size': '20px',
    'submenu-font-size': '16px',
    'aside-width': '280px',
    'padding-default': '24px',
    'padding-bottom-message-box': '24px',
    'select-option-text': '#0C1332',
    'pagination-color': '#B6B8C2',
    'stock-border-size': '1px',
    'order-table-subtitle-font': '11px',
    'sidebar-separator-color': '#3d425c'
  }
}

export const isUnifiedCommerce = () => {
  return true || window.location.hostname.includes('unzer.')
}

export const isKeycloackEnabled = () => {
  const keyCloakEnabled =
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_KEYCLOAK_ENABLED_PROD
      : process.env.VUE_APP_KEYCLOAK_ENABLED_TEST
  return keyCloakEnabled === 'true'
}

export const unifiedCommerceExcludedScopes = [
  'settings:account',
  'resources:branches:create',
  'resources:branches:update',
  'resources:branches:delete',
  'resources:registers:create',
  'resources:registers:update'
]

export const setUnifiedCommerceWindow = (isUnifiedCommerce) => {
  Object.defineProperty(window, 'location', {
    value: {
      hostname: isUnifiedCommerce ? 'dashboard.unzer.com' : 'localhost'
    },
    writable: true
  })
}

/** UNIFIED COMMERCE WHITELABEL END */

// We support just DE & EN for UFC
export const locales = [
  {
    value: 'de-DE',
    label: 'Deutsch (de-DE)'
  },
  {
    value: 'en-US',
    label: 'English (en-US)'
  }
].concat(
  !isUnifiedCommerce()
    ? [
        {
          value: 'cs-CZ',
          label: 'Čeština (cs-CZ)'
        },
        {
          value: 'fr-FR',
          label: 'Français (fr-FR)'
        },
        {
          value: 'it-IT',
          label: 'Italiano (it-IT)'
        }
      ]
    : []
)
