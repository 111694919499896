import Auth from './auth'
import Config from './configurations'
import Templates from './templates'
import Notifications from './notifications'

export default {
  Auth,
  Config,
  Templates,
  Notifications
}
