import get from 'just-safe-get'
import typeOf from 'just-typeof'

/**
 * Mapping of features flags (by their DB definition) to their path(s) in the app-config/features-config object.
 * NOTE: order of items in the feature array value is important, as isFeatureEnabled checks for the first item.
 * @typedef {Object} FeatureFlags
 */
export const restrictedFeaturesPaths = {
  /* customers */
  crm: [
    'customers.crm',
    'customers.customer.notes',
    'customers.customer.contact',
    'customers.customer.customer_details'
  ],

  /* reports */
  exports: ['reports.exports'],
  cash_book: ['reports.financial_accounting.cash_book'],

  /* timetracking */
  timetracking: ['timetracking', 'settings.timetracking'],

  /* webhooks */
  webhooks: ['webhooks'],

  /* suppliers */
  suppliers: ['suppliers'],

  /* documents */
  documents: ['documents'],

  /* accounting */
  safe_management: ['accounting.safe_management'],

  /* products */
  label_printing: ['products.label_printing'],
  promotion_engine: ['products.promotions'],
  pricebooks: ['products.pricebooks'],

  /* resources */
  branch_management: ['resources.branches', 'resources.branch_groups'],

  /* loyalty */
  vouchers: ['loyalty.vouchers', 'loyalty.voucher_systems'],
  abocard: ['loyalty.abocard'],

  /* settings */
  pagers: ['settings.pos.pagers'],
  kitchen_printers: ['settings.pos.kitchen_printers'],

  /* misc */
  gastro_tables: ['misc.gastro_tables'],
  datev: ['misc.datev'],
  takeaway: ['misc.takeaway'],
  fiscalisation: ['misc.fiscalisation']
}

/**
 * Takes a flag and the feature config and determines if the feature was enabled for the account
 * @param {String} flag
 * @param {Object} featuresConfig
 * @returns {Boolean}
 */
export function isFeatureEnabled(flag, featuresConfig) {
  if (typeOf(flag) !== 'string' || typeOf(featuresConfig) !== 'object')
    return false

  if (!restrictedFeaturesPaths[flag]) {
    return get(featuresConfig, flag, true)
  }

  const featurePath = get(restrictedFeaturesPaths, `${flag}.0`)
  if (featurePath) return get(featuresConfig, featurePath) !== false

  // features are enabled by default
  return true
}
