/**
 * Plugin for th-modal
 */
const emitter = require('tiny-emitter/instance')

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$thModal = {
      debug: options.debug || false,
      app,

      listen: (cb) => emitter.on('thmodal', cb),

      show: (name, params) => {
        emitter.emit('thmodal', name, true, params)
      },

      hide: (name, params) => {
        emitter.emit('thmodal', name, false, params)
      },

      toggle: (name, params) => {
        emitter.emit('thmodal', name, null, params)
      }
    }
    app.provide('thModal', app.config.globalProperties.$thModal)
  }
}
