import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

/* 
This mixin helps to get the url when selecting the back button
 Gets the last full path url, get the params and set the page from whats stored in the store
*/

export function backButton(defaultPath) {
  const store = useStore()
  const router = useRouter()

  // Browser back button functionality override
  window.onpopstate = function () {
    goBack()
  }

  function goBack() {
    const lastFullPath = router?.options?.lastFullPath
    let path = defaultPath
    if (lastFullPath && lastFullPath !== '/') {
      const lastUrlWithPageNumber =
        store?.getters['Config/getLastUrlWithPageNumber']
      let queryStringToObject = [
        ...new URLSearchParams(lastFullPath.split('?')[1])
      ].reduce((a, [k, v]) => ((a[k] = v), a), {})
      // We check that we are on the same path to apply the page number stored in the store
      if (
        lastFullPath.includes(lastUrlWithPageNumber?.path) &&
        lastUrlWithPageNumber?.pageNumber
      )
        queryStringToObject.page = lastUrlWithPageNumber?.pageNumber
      const paramsString = Object.keys(queryStringToObject)
        .map((key) => {
          return (
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(queryStringToObject[key])
          )
        })
        .join('&')
      path =
        lastFullPath.split('?')[0] + `${paramsString ? '?' : ''}` + paramsString
    }
    router.push(path)
  }
  return {
    goBack
  }
}

export default backButton
