import * as Sentry from '@sentry/vue'

export default {
  install: (app) => {
    app.config.globalProperties.$handleResourceFetchError = function (
      error,
      resource
    ) {
      this.$logException(error, {
        trackError: false,
        message: this.$t('common.error.action.read.single', {
          resource
        })
      })
    }
  }
}
