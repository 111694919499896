<template>
  <el-pagination
    class="pagination flex items-center justify-center py-3"
    :layout="layout"
    :current-page="currentPage"
    :page-sizes="pageSizes"
    :page-size="computedPageSize"
    :total="total"
    @current-change="currentPageChanged"
    @size-change="sizeChanged"
  />
</template>

<script>
// Element UI Pagination wrapper
// https://element.eleme.io/#/en-US/component/pagination#pagination

export default {
  name: 'THPagination',
  props: {
    // Current page number,
    currentPage: {
      type: Number,
      required: true
    },

    // Total item count
    total: {
      type: Number,
      required: true
    },

    // Options of item count per page
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20]
    },

    // Item count of each page
    pageSize: {
      type: Number,
      default: null
    },

    // Layout of the pagination
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    }
  },
  emits: ['size-change', 'current-change'],

  computed: {
    computedPageSize() {
      return this.pageSize || this.pageSizes[0]
    }
  },

  methods: {
    sizeChanged(newSize) {
      this.$emit('size-change', newSize)
    },

    currentPageChanged(newPage) {
      this.$emit('current-change', newPage)
    }
  }
}
</script>

<style scoped>
.pagination :deep(.el-pager),
.pagination :deep(.btn-prev),
.pagination :deep(.btn-next),
.pagination :deep(.more) {
  @apply text-gray-600;
}

.pagination :deep(.el-pager li) {
  font-size: 14px !important;
  font-weight: normal;
}

.pagination :deep(.el-pagination__jump) {
  display: flex;
  align-items: center;
}

.pagination :deep(.el-pagination__jump input) {
  text-align: center !important;
}
</style>
