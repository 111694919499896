import { getBaseUrl } from '@/utils/baseUrlProvider'
import * as axios from 'axios'

const allowedConfigs = [
  'dashboard_welcome_bg_image',
  'dashboard_welcome_logo',
  'dashboard_welcome_bg_color',
  'dashboard_bg_color',
  'dashboard_logo',
  'dashboard_logo_callapsed'
]

/**
 * @async
 */
export async function getTXTs() {
  if (
    [
      'localhost',
      'dashboard.tillhub.com',
      'staging.dashboard.tillhub.com'
    ].includes(window.location.hostname)
  )
    return
  const inst = axios.create()
  const baseUrl = await getBaseUrl()
  const { data: { data: { answer = null } = {} } = {} } = await inst({
    method: 'GET',
    url: `${baseUrl}/api/v0/dns/query`,
    params: {
      name: window.location.hostname,
      type: 'TXT'
    },
    crossDomain: true,
    headers: {}
  })

  return answer
}

export function parseTXTs(txtResult) {
  const result = {}
  if (!Array.isArray(txtResult)) return result

  txtResult.forEach((item) => {
    if (!item || !item.data) return

    item.data
      .split(',')
      .map((configItem) => {
        return configItem.trim().replace(/\s/g, '')
      })
      .forEach((configItem) => {
        const config = configItem.split('=')
        config[0] = config[0].replace('tillhub_', '')
        if (
          config &&
          config[0] &&
          config[1] &&
          allowedConfigs.includes(config[0])
        ) {
          result[config[0]] = config[1]
        }
      })
  })

  return result
}
