<template>
  <el-form :model="formData" :rules="rules" class="mt-4">
    <!-- delimiter -->
    <el-form-item
      prop="delimiter"
      class="mr-4 mb-5 flex-shrink-0"
      :label="
        $t('components.th_datatable.export.settings.file_separator.lable')
      "
    >
      <el-radio-group id="delimiter" v-model="delimiter" class="flex flex-col">
        <el-radio
          v-for="option in Object.values(DELIMITERS)"
          :key="option"
          :label="option"
        >
          {{
            $t(
              `components.th_datatable.export.settings.file_separator.${option}`
            )
          }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- Text enclosure -->
    <el-form-item
      prop="enclosure"
      class="mr-4 mb-5 flex-shrink-0"
      :label="$t('components.th_datatable.export.settings.enclosing.lable')"
    >
      <el-radio-group id="enclosure" v-model="enclosure" class="flex flex-col">
        <el-radio
          v-for="option in Object.values(ENCLOSURES)"
          :key="option"
          :label="option"
        >
          {{
            $t(`components.th_datatable.export.settings.enclosing.${option}`)
          }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item>
      <!-- Column Names -->
      <el-checkbox
        v-model="columnNames"
        :label="
          $t('components.th_datatable.export.settings.enclosing.column_names')
        "
      />
    </el-form-item>
  </el-form>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { DELIMITERS, ENCLOSURES } from '../helpers.js'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const formData = computed(() => ({
  ...props.modelValue
}))

const columnNames = computed({
  get() {
    return !!props.modelValue.show_column_name
  },
  set(value) {
    emitValue('show_column_name', value)
  }
})

const delimiter = computed({
  get() {
    return props.modelValue.delimiter
  },
  set(value) {
    emitValue('delimiter', value)
  }
})

const enclosure = computed({
  get() {
    return props.modelValue.enclosure
  },
  set(value) {
    emitValue('enclosure', value)
  }
})

const rules = computed(() => ({}))

//methods
function emitValue(path, value) {
  emit('update:modelValue', {
    ...props.modelValue,
    [path]: value
  })
}
</script>
