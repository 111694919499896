import * as Sentry from '@sentry/vue'
import snakeCase from 'just-snake-case'
import cloneDeep from 'clone-deep'
import safeSet from 'just-safe-set'
import get from 'just-safe-get'
import typeOf from 'just-typeof'
import store from '@/store'
import { isNullish } from '@/utils/general'
import { restrictedFeaturesPaths } from '@/utils/feature-flags'

/**
 * toggle specific features to false for whitelabel user per the config param
 * @param {Object} whitelabelsOptOutConfig config of the excluded feature for specific whitelabels
 * @param {String|Null} whitelabel - name of current whitelabel if relevant
 * @returns {Object}
 */
export function setWhitelabelsFeatures(whitelabelsOptOutConfig, whitelabel) {
  return (featureConfig) => {
    if (!whitelabel) return featureConfig
    const clonedConfig = cloneDeep(featureConfig)

    Object.entries(whitelabelsOptOutConfig).forEach(([key, value]) => {
      const shouldOptOut = value?.exclude?.includes(whitelabel)
      const featurePath = value.path || key
      const featureValue = get(clonedConfig, featurePath)
      if (shouldOptOut && featureValue) {
        return safeSet(clonedConfig, featurePath, false)
      }

      const shouldOptIn = value?.include?.includes(whitelabel)
      if (shouldOptIn && featureValue === false) {
        return safeSet(clonedConfig, featurePath, true)
      }
    })
    return clonedConfig
  }
}

/**
 * @param {Object} config
 * @param {Object} featureFlags
 * @returns {Object} clone of the provided config with the feature flags properly attached to it.
 */
export function setFeatureFlagsOnConfig(config, featureFlags) {
  if (typeOf(config) !== 'object') return {}
  if (typeOf(featureFlags) !== 'object') return config

  const featureFlagsCloned = cloneDeep(featureFlags)

  // make sure all feature keys are lowercase since there are old accounts with some uppercase feature keys
  Object.keys(featureFlagsCloned).forEach((feature) => {
    const lowercaseFeatureKey = feature.toLowerCase()
    if (lowercaseFeatureKey !== feature) {
      featureFlagsCloned[lowercaseFeatureKey] = featureFlagsCloned[feature]
      delete featureFlagsCloned[feature]
    }
  })

  store.commit('Auth/setFeatures', featureFlagsCloned)

  const clonedConfig = cloneDeep(config)

  Object.entries(featureFlagsCloned).forEach(([feature, flagValue]) => {
    const featurePaths = restrictedFeaturesPaths[feature]
    if (!featurePaths) {
      // in case a feature flag is not in the local restricted features object, we just put it on the feature config as is.
      return safeSet(clonedConfig, feature, flagValue)
    }

    featurePaths?.forEach((featurePath) => {
      safeSet(clonedConfig, featurePath, flagValue)
    })
  })

  return clonedConfig
}

export function handleAtLeastSome(featureConfig, path) {
  const payload = get(featureConfig, path)
  if (payload === true) return true

  if (!payload) return false

  return Object.values(flattenObject(payload || {})).some(
    (item) => item === true
  )
}

/**
 * Handle insert of custom dashboards
 * @param {String} location location name of custom dashboard e.g. 'nav'
 * @param {String|Number} position position inside of a nav array e.g. 'last' or 'first'
 * @param {Object[]} customDashboards list of all custom dashboards
 */
export function handleHook(location, position, customDashboards) {
  const APP = 'dashboard'
  if (
    !customDashboards ||
    !Array.isArray(customDashboards) ||
    !customDashboards.length
  )
    return []
  const eligibleDashboards = []

  function parseDashboards() {
    customDashboards.forEach((item) => {
      const hooks = item.hooks

      if (!hooks || !Array.isArray(hooks) || !hooks.length) return false

      hooks.forEach((hookItem) => {
        if (
          !hookItem ||
          hookItem.app !== APP ||
          hookItem.position !== position ||
          !hookItem.locations ||
          !Array.isArray(hookItem.locations) ||
          !hookItem.locations.length
        )
          return

        hookItem.locations.forEach((locationItem) => {
          if (locationItem !== location) return

          const route = `/custom_dashboards/${snakeCase(item.name)}`

          eligibleDashboards.push({
            type: 'custom_dashboard',
            route,
            name: item.name,
            enabled: true,
            children: [],
            meta: item,
            handleKey: function (item) {
              return `${locationItem}-${route}-${position}`
            }
          })
        })
      })
    })
  }

  try {
    parseDashboards()
  } catch (err) {
    Sentry.captureException(err)
  }

  return eligibleDashboards
}

function mapObject(obj, fn) {
  return Object.keys(obj).reduce((res, key) => {
    res[key] = fn(isNullish(obj[key]) ? false : obj[key])
    return res
  }, {})
}

export function deepMap(obj, fn) {
  const deepMapper = (val) =>
    typeof val === 'object' ? deepMap(val, fn) : fn(val)
  if (Array.isArray(obj)) {
    return obj.map(deepMapper)
  }
  if (typeof obj === 'object') {
    return mapObject(obj, deepMapper)
  }
  return obj
}

function flattenObject(obj, name, stem) {
  var out = {}
  var newStem =
    typeof stem !== 'undefined' && stem !== '' ? stem + '_' + name : name

  if (typeof obj !== 'object') {
    out[newStem] = obj
    return out
  }

  for (var p in obj) {
    var prop = flattenObject(obj[p], p, newStem)
    out = merge([out, prop])
  }

  return out
}

function merge(objects) {
  var out = {}

  for (var i = 0; i < objects.length; i++) {
    for (var p in objects[i]) {
      out[p] = objects[i][p]
    }
  }

  return out
}

/**
 * Handle replacement of css variables for whitelabel
 * @param {Object[]} variables list of all css variables to replace
 */
export function setCSSVariablesWhitelabel(variables) {
  if (!variables) return
  const r = document.querySelector(':root')
  Object.keys(variables).forEach((variable) => {
    r.style.setProperty(`--${variable}`, variables[variable])
  })
}
