import { defineStore } from 'pinia'
import * as uuid from 'uuid'
import pLimit from 'p-limit'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import typeOf from 'just-typeof'
import omit from 'just-omit'
import { differenceInDays } from 'date-fns'

export const useMessagesStore = defineStore('messages', {
  // Persist this store to localStorage (via pinia-plugin-persistedstate plugin)
  persist: true,
  state: () => ({
    messages: {},
    operations: {}
  }),
  getters: {
    getGlobalMessages: (state) => Object.values(state.messages)
  },
  actions: {
    async startLocalOperation({ operations = [], label, fulfillment }) {
      const now = new Date().toISOString()
      const operation = {
        id: uuid.v4(),
        created_at: now,
        type: 'local',
        progress: {
          current: 0,
          of: operations.length
        }
      }

      const message = {
        id: uuid.v4(),
        created_at: now,
        label: label || 'N/A',
        // Associate this message with the local operation.
        localOperationId: operation.id,
        operation: {}
      }

      this.$patch((state) => {
        state.messages = { ...state.messages, [message.id]: message }
        state.operations = { ...state.operations, [operation.id]: operation }
      })

      const limit = pLimit(10)

      const ops = operations.map((fn) => {
        return limit(async () => {
          await fn.call()
          this.incrementOperationProgress(operation.id)
        })
      })

      try {
        await Promise.all(ops)
        this.$patch((state) => {
          state.operations = { ...omit(state.operations, operation.id) }
          state.messages = { ...omit(state.messages, message.id) }
        })
        if (typeOf(fulfillment) === 'function') {
          fulfillment()
        }
      } catch (err) {
        console.error(err) //eslint-disable-line
      }
    },
    setLocalMessage({
      id,
      operation,
      read = false,
      label = 'N/A',
      payload = undefined
    }) {
      this.messages = {
        ...this.messages,
        [id]: {
          id,
          label,
          operation,
          read,
          payload
        }
      }
    },
    updateMessage({ id, changedProp = {} }) {
      if (this.messages[id]) {
        this.messages = {
          ...this.messages,
          [id]: { ...this.messages[id], ...changedProp, id }
        }
      }
    },
    /**
     * Increments the current field of the progress object of an operation.
     * @param {string} id of the local operation.
     */
    incrementOperationProgress(id) {
      const current = safeGet(this.operations, `${id}.progress.current`)
      if (Number.isFinite(current)) {
        safeSet(this.operations, `${id}.progress.current`, current + 1)
      }
    },
    /**
     * Clear all local operations along with their associated messages.
     */
    clearLocalOperations() {
      // Get ids of all operations that have type local.
      const localOpsIds = Object.values(this.operations)
        .filter((item) => item.type === 'local')
        .map((item) => item.id)

      // Get ids of messages that are associated with a local operation.
      const messageIds = Object.values(this.messages)
        .filter(
          (item) =>
            item.localOperationId && localOpsIds.includes(item.localOperationId)
        )
        .map((item) => item.id)

      // Patch state to remove all local operations and their messages.
      this.$patch((state) => {
        this.operations = { ...omit(state.operations, localOpsIds) }
        this.messages = { ...omit(state.messages, messageIds) }
      })
    },
    /**
     * Removes messages that have a created_at property and are older than 5 days.
     */
    clearStaleMessages() {
      const now = new Date()
      const staleMessages = Object.values(this.messages).filter(
        ({ created_at }) =>
          created_at && differenceInDays(now, new Date(created_at)) > 5
      )

      if (staleMessages.length) {
        this.messages = {
          ...omit(
            this.messages,
            staleMessages.map((item) => item.id)
          )
        }
      }
    }
  }
})
