<template>
  <div class="help-wrapper">
    <!-- Icon -->
    <div class="cursor-pointer" @click="toggleOpen">
      <svgicon
        :src="require('@/assets/icons/th-help.svg')"
        :style="{ height: '20px', width: '20px' }"
      />
    </div>

    <!-- Backdrop -->
    <div v-if="open" class="backdrop" @click="closeOnOutsideClick" />

    <!-- Dropdown -->
    <div v-show="open" class="dropdown-holder">
      <div class="border bg-white rounded shadow-sm mt-2">
        <div class="p-6 bg-th-primary-light">
          <div class="flex justify-between items-center">
            <!-- Title -->
            <div
              class="block font-bold"
              v-text="$t('common.interactions.buttons.help_center')"
            />

            <!-- Go to help center -->
            <a
              class="block text-xs text-th-primary-blue hover:underline"
              :class="{ italic: !isWhiteLabel }"
              target="_blank"
              :href="helpCenterLink"
            >
              {{ $t('components.help.goto_help_center') }}
            </a>
          </div>

          <!-- Search input -->
          <el-input
            v-if="!isTillhub"
            v-model="searchTerm"
            class="mt-3"
            clearable
            data-lpignore="true"
            prefix-icon="Search"
            :placeholder="
              $t('components.th_datatable.search_filter.search.placeholder')
            "
            @clear="results = []"
            @input="querySearchAsync"
          />
        </div>

        <!-- Loading -->
        <loading v-if="loading" class="border-t" />

        <!-- No data -->
        <div
          v-if="results && !results.length && !loading"
          class="p-4 text-center text-xs text-gray-700 border-t"
        >
          {{ $t('common.data.no_data') }}
        </div>

        <!-- Results -->
        <div v-if="results" class="results overflow-auto border-t">
          <a
            v-for="(result, index) in results"
            :key="index"
            class="block hover:bg-gray-100 border-b last:border-0 p-6 py-4 text-sm"
            :href="result.link"
            target="_blank"
          >
            <div>{{ result.title }}</div>
            <small class="text-th-primary-gray">{{ result.description }}</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import { mapState } from 'vuex'
import Loading from '@/components/loading'
import { whitelabels, localsearchLinks, tillhubLinks } from '@/constants'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    Loading
  },

  data() {
    return {
      open: false,
      results: null,
      searchTerm: '',
      loading: false
    }
  },

  computed: {
    ...mapState({
      whitelabel: (state) => state.Auth.whitelabel
    }),
    isTillhub() {
      return this.whitelabel === 'tillhub'
    },
    helpCenterLink() {
      return this.whitelabel === whitelabels.LOCALSEARCH
        ? localsearchLinks.help_center
        : tillhubLinks.help_center
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    querySearchAsync: debounce(function () {
      if (!this.searchTerm || this.searchTerm.length < 1) {
        this.results = null
        return
      }
      this.handleSearch(this.searchTerm)
    }, 300),
    async handleSearch(queryString) {
      this.loading = true
      this.results = []
      try {
        const results = await this.handleLocalsearchSearch.call(this, [
          queryString
        ])

        if (!results || !results.data) {
          this.results = []
          return
        }

        this.results = results.data
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.results = null
      } finally {
        this.loading = false
      }
    },
    toggleOpen() {
      this.open = !this.open
    },
    closeOnOutsideClick() {
      if (this.open) this.toggleOpen()
    },
    async handleLocalsearchSearch(q) {
      return this.$axios({
        method: 'get',
        url: `${localsearchLinks.help_center_search}=${encodeURIComponent(q)}`,
        withCredentials: true,
        responseType: 'json',
        timeout: 1000,
        transformResponse: [
          (data) => {
            try {
              data = JSON.parse(data)
              if (!data?.[0]?.results?.pages?.length) {
                return []
              }
              const d = data[0].results.pages

              return d.map((item) => {
                return {
                  title: item.title || '-',
                  description: item.description,
                  link: `${this.helpCenterLink}${item.uri}`
                }
              })
            } catch (e) {
              return {}
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
.help-wrapper {
  position: relative;
}
.results {
  max-height: 400px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 99;
}
.dropdown-holder {
  position: absolute;
  z-index: 100;
  width: 24rem;
  right: 0;
}
</style>
