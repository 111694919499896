import { useAppConfigStore } from '@/store/app-config'
import { isFeatureEnabled } from '@/utils/feature-flags'
import typeOf from 'just-typeof'

export default {
  install: (app, options = {}) => {
    app.directive('flag', {
      mounted(el, binding) {
        const { value } = binding

        if (value && typeOf(value) === 'string') {
          const appConfigStore = useAppConfigStore()
          const featureDisabled =
            isFeatureEnabled(value, appConfigStore.featureConfig) === false

          if (featureDisabled) {
            app.$log.debug('v-flags: does not have features enabled')
            el.parentNode && el.parentNode.removeChild(el)
          }
        } else {
          app.$log.debug('v-flags: no flags were passed to function')
        }
      }
    })

    app.config.globalProperties.$isFeatureEnabled = function (requiredFlag) {
      const appConfigStore = useAppConfigStore()
      return isFeatureEnabled(requiredFlag, appConfigStore.featureConfig)
    }
  }
}
