/*
We have onLoad: 'check-sso' because we want to trigger the event to login, to be able to change the locale
*/
export const keycloakConfig = {
  initOptions: {
    flow: 'standard',
    checkLoginIframe: false,
    onLoad: 'check-sso'
  },
  config: {
    url:
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_KEYCLOAK_URL_PROD
        : process.env.VUE_APP_KEYCLOAK_URL_TEST,
    realm:
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_KEYCLOAK_REALM_PROD
        : process.env.VUE_APP_KEYCLOAK_REALM_TEST,
    clientId:
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_KEYCLOAK_CLIENT_PROD
        : process.env.VUE_APP_KEYCLOAK_CLIENT_TEST
  }
}
