<template>
  <div>
    <nav-button
      :show="isOpen"
      :branches="branchesList"
      @toggle="toggleBranchContext"
    />
    <el-dialog
      v-model="isOpen"
      :title="$t('nav.main.items.location.title')"
      class="context-select"
      top="5rem"
      center
      lock-scroll
      :close-on-press-escape="canClose"
      :close-on-click-modal="canClose"
      :show-close="canClose"
    >
      <!-- Search -->
      <el-input
        v-model="searchString"
        :placeholder="$t('nav.main.items.location.search_placeholder')"
        class="mb-4 h-10"
        clearable
        @input="filtersChanged"
      >
        <template #prefix>
          <div class="flex items-center el-input__prefix">
            <svgicon
              :src="require('@/assets/icons/th-icon-magnifying-glass.svg')"
              :style="{
                height: '24px',
                width: '24px'
              }"
            />
          </div>
        </template>
      </el-input>
      <!-- Table -->
      <location-table
        v-loading="loading"
        :table="locationList"
        :infinite-scroll="!!next"
        @close="toggleBranchContext"
        @paginate="fetchBranches"
      />
      <!-- Footer -->
      <template #footer>
        <div class="flex">
          <!-- Logout -->
          <el-button>
            <router-link to="/logout">
              {{ $t('nav.main.items.logout.title') }}
            </router-link>
          </el-button>

          <div class="flex-grow" />

          <!-- Cancel -->
          <el-button :disabled="!canClose" @click="toggleBranchContext">
            {{ $t('common.interactions.buttons.cancel') }}
          </el-button>

          <!-- All branches -->
          <el-button
            type="primary"
            :disabled="!(isAdmin || isSupport || userIsEverywhere)"
            @click="reset"
          >
            {{ $t('nav.main.items.location.set_all') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import debounce from 'debounce'
import { mapGetters } from 'vuex'
import NavButton from './nav-button'
import LocationTable from './dialog/location-table'
import { allBranchesContext } from './constants'

const branchDataFactory = (data = {}) => ({
  disabled: undefined,
  custom_id: data.branch_number,
  label: data.name || data.branch_number,
  id: data.id
})

export default {
  components: {
    NavButton,
    LocationTable
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      searchString: '',
      branchesList: [],
      next: null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isSupport: 'Auth/isSupport',
      userAssignedLocations: 'Auth/getUserAssignedLocations',
      currentLocation: 'Config/getCurrentLocation'
    }),
    branchesData() {
      return this.branchesList.map(branchDataFactory)
    },
    promptForLocation() {
      return (
        !this.currentLocation &&
        !(this.isAdmin || this.isSupport) &&
        this.userIsNowhere
      )
    },
    canClose() {
      // intermediate solution for users with no branches assigned - currently they are the only one that prompted by default but actually can't select so they are stuck - that is intentional for the moment until a better solution arrives.
      return !this.promptForLocation
    },
    userIsNowhere() {
      return (
        Array.isArray(this.userAssignedLocations) &&
        !this.userAssignedLocations.length
      )
    },
    userIsEverywhere() {
      return this.userAssignedLocations === null
    },
    locationList() {
      return this.branchesData
        .map((item) => {
          let disabled = false
          if (!Array.isArray(this.userAssignedLocations)) {
            disabled = false
          } else if (!this.userAssignedLocations.includes(item.id)) {
            disabled = true
          } else {
            disabled = false
          }

          return {
            ...item,
            disabled
          }
        })
        .sort((a, b) => a.disabled - b.disabled)
    }
  },
  watch: {
    isOpen: function (status) {
      if (status) {
        this.fetchBranches()
      } else {
        // reset values once dialog is closed
        this.next = null
        this.branchesList = []
        this.searchString = ''
      }
    }
  },
  async created() {
    await this.$store.dispatch('Auth/waitThInitializated')
    await this.fetchBranches()
    this.autoSetBranch()
    if (this.promptForLocation) {
      this.isOpen = true
    }
  },
  methods: {
    autoSetBranch() {
      if (
        this.isAdmin ||
        this.isSupport ||
        this.currentLocation ||
        (this.branchesData.length > 1 && this.userIsEverywhere)
      ) {
        // not doing anything - all branches (or already selected branch) will be selected in nav-button component
        return
      }

      // user has only one branch assigned > pick it
      if (
        Array.isArray(this.userAssignedLocations) &&
        this.userAssignedLocations.length === 1
      ) {
        return this.selectBranch(this.userAssignedLocations[0])
      }
      // account has only one branch > pick it
      if (this.branchesData.length === 1 && !this.userIsNowhere) {
        return this.selectBranch(this.branchesData[0].id)
      }
      // user has multiple branches > pick the first
      if (
        this.branchesData.length > 1 &&
        Array.isArray(this.userAssignedLocations) &&
        this.userAssignedLocations.length > 1
      ) {
        return this.selectBranch(this.locationList[0].id)
      }
    },
    toggleBranchContext() {
      this.isOpen = !this.isOpen
    },
    selectBranch(branchId) {
      const branchData = this.getBranch(branchId)
      if (!branchData) return
      this.$store.dispatch('Config/setLocationContext', {
        branches: [branchData],
        location: branchId
      })
    },
    getBranch(id) {
      const branch = this.branchesList.find((branch) => branch.id === id)
      return branchDataFactory(branch)
    },
    reset() {
      this.$store.dispatch('Config/setLocationContext', allBranchesContext)
      this.toggleBranchContext()
      this.$nextTick(() => {
        this.$emitter.emit('refresh-requested')
      })
    },
    filtersChanged: debounce(function () {
      this.branchesList = []
      this.next = null
      this.fetchBranches()
    }, 300),
    async fetchBranches() {
      this.loading = true
      try {
        if (this.next) await this.paginate()
        // Paginate only for users that have access to all of the branches
        else if (this.isAdmin || this.isSupport || this.userIsEverywhere) {
          await this.fetchPaginatedBranches()
        }
        // For sub-users with limited access to branches, fetch all branches at once - as we need to show the available branches at the top of the list. Probably better to find a better solution at one point.
        else await this.fetchAllBranches()
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.multiple', {
            resources: this.$t('common.resource.branch.plural')
          })
        })
      } finally {
        this.loading = false
      }
    },
    async fetchPaginatedBranches() {
      const { data = [], next } = await th.branchesV1().getAll({
        query: {
          limit: 50,
          deleted: false,
          q: this.searchString
        }
      })
      this.next = next
      this.branchesList = data
    },
    async fetchAllBranches() {
      try {
        const { branchesV1 = [] } = await this.$resourceFetch({
          resource: 'branchesV1',
          query: {
            deleted: false,
            q: this.searchString
          }
        })
        this.branchesList = branchesV1
        return this.branchesList
      } catch (err) {
        this.branchesList = []
      }
    },
    async paginate() {
      if (!this.next) return
      const { data = [], next } = await this.next()
      this.next = next
      this.branchesList = this.branchesList.concat(data)
    }
  }
}
</script>

<style scoped>
.el-input :deep(.el-input__inner) {
  height: 100%;
  padding-left: 35px;
}
</style>
