export default function cleanChangeSuggestionData(data) {
  if (!data || !Array.isArray(data) || !data.length) return null

  const result = data.filter((d) => isValidObject(d))

  return result.length ? result : null
}

export function isValidObject(value) {
  return !!(
    value &&
    value.currency &&
    value.currency.length === 3 &&
    Number.isFinite(value.amount)
  )
}
