import safeGet from 'just-safe-get'

export function getNavigation({ useExternal } = {}) {
  const externalTab = useExternal
    ? [
        {
          path: '/loyalty/vouchers/external_systems',
          key: 'pages.voucher_systems.form.external_system.label',
          flag: 'loyalty.vouchers',
          scopes: ['loyalty:vouchers:read']
        }
      ]
    : []

  return [
    {
      path: '/loyalty/vouchers/voucher_systems',
      key: 'nav.main.items.loyalty.voucher_systems',
      flag: 'loyalty.vouchers',
      scopes: ['loyalty:vouchers:read']
    },
    ...externalTab,
    {
      path: '/loyalty/vouchers/manager',
      key: 'nav.main.items.loyalty.all',
      flag: 'loyalty.voucher_systems',
      scopes: ['loyalty:voucher_systems:read']
    }
  ]
}
