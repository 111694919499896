import { handleAtLeastSome, handleHook } from './index'
import Store from '@/store'
import { isUnifiedCommerce } from '@/constants'

export function getNavigation({ featureConfig, customDashboards } = {}) {
  return [
    ...handleHook('nav', 'first', customDashboards),
    {
      route: '/home',
      name: 'home',
      key: 'nav.main.items.home.title',
      enabled: !isUnifiedCommerce(),
      children: []
    },
    {
      route: '/orders',
      name: 'orders',
      enabled: isUnifiedCommerce(),
      key: 'nav.main.items.orders.title',
      meta: {
        scopes: ['reports_orders', 'orders_transactions']
      },
      children: [
        {
          route: '/reports_orders',
          name: 'reports_orders',
          enabled: false,
          key: 'nav.main.items.orders.reports_orders.title',
          meta: {
            scopes: ['reports_orders']
          }
        },
        {
          route: '/orders_transactions',
          name: 'reports_orders_transactions',
          enabled: isUnifiedCommerce(),
          key: 'nav.main.items.orders.orders_transactions.title',
          meta: {
            scopes: ['orders_transactions']
          }
        }
      ]
    },
    {
      route: '/reports',
      name: 'reports',
      enabled: handleAtLeastSome(featureConfig, 'reports'),
      key: 'nav.main.items.reports.title',
      meta: {
        scopes: [
          'reports',
          'reports_financial_accounting',
          'reports_statistics',
          'reports_exports_manager'
        ]
      },
      children: [
        ...handleHook('nav.reports', 'first', customDashboards),
        {
          route: '/financial_accounting',
          name: 'reports_financial_accounting',
          enabled: true,
          key: 'nav.main.items.reports.financial_accounting.title',
          meta: {
            scopes: ['reports_financial_accounting']
          }
        },
        {
          route: '/statistics',
          name: 'reports_statistics',
          enabled: handleAtLeastSome(featureConfig, 'reports.statistics'),
          key: 'nav.main.items.reports.statistics',
          meta: {
            scopes: ['reports_statistics']
          }
        },
        {
          route: '/exports_manager',
          name: 'reports_exports_manager',
          enabled: handleAtLeastSome(featureConfig, 'reports.exports'),
          key: 'nav.main.items.reports.exports',
          meta: {
            scopes: ['reports_exports_manager']
          }
        },
        ...handleHook('nav.reports', 'last', customDashboards)
      ]
    },
    {
      route: '/inventory',
      name: 'inventory',
      enabled: handleAtLeastSome(featureConfig, 'inventory'),
      key: 'nav.main.items.inventory.title',
      meta: {
        scopes: ['inventory']
      },
      children: [
        {
          path: '',
          redirect: '/stock_manager'
        },
        {
          route: '/stock_manager',
          name: 'ims-stock_manager',
          enabled: true,
          key: 'nav.main.items.inventory.stock_manager',
          meta: {
            scopes: ['inventory']
          }
        },
        {
          route: '/stock',
          name: 'ims-stock',
          enabled: true,
          key: 'nav.main.items.inventory.stock_overview',
          meta: {
            scopes: ['inventory:stock_movements:create']
          }
        },
        {
          route: '/reasons',
          name: 'ims-reasons',
          enabled: true,
          key: 'nav.main.items.inventory.reasons',
          meta: {
            scopes: ['inventory:reasons:read']
          }
        },
        {
          route: '/processes',
          name: 'ims-processes',
          enabled: true,
          key: 'nav.main.items.inventory.processes',
          meta: {
            scopes: ['inventory:processes:read']
          }
        },
        {
          route: '/stock_takings',
          name: 'ims-stock-takings',
          enabled: true,
          key: 'nav.main.items.inventory.stock_takings',
          meta: {
            scopes: ['inventory:stock_takings:read']
          }
        }
      ]
    },
    {
      route: '/accounting',
      name: 'accounting',
      enabled: handleAtLeastSome(featureConfig, 'accounting'),
      key: 'nav.main.items.accounting.title',
      meta: {
        scopes: ['accounting']
      },
      children: []
    },
    {
      route: '/products',
      name: 'products',
      enabled: handleAtLeastSome(featureConfig, 'products'),
      key: 'nav.main.items.products.title',
      meta: {
        scopes: ['products']
      },
      children: [
        ...handleHook('nav.products', 'first', customDashboards),
        {
          path: '',
          redirect: '/manager'
        },
        {
          route: '/manager',
          name: 'products-manager',
          key: 'nav.main.items.products.manager',
          enabled: handleAtLeastSome(featureConfig, 'products.manager'),
          meta: {
            scopes: ['products:read']
          }
        },
        {
          route: '/product_groups',
          name: 'products-product-groups',
          key: 'nav.main.items.products.product_groups',
          enabled: handleAtLeastSome(featureConfig, 'products.product_groups'),
          meta: {
            scopes: ['products:product_groups:read']
          }
        },
        {
          route: '/addon-groups',
          name: 'products-addon-groups',
          key: 'nav.main.items.products.addon_groups',
          enabled:
            Store.getters['Auth/isGastro'] || Store.getters['Auth/isLitePos'],
          meta: {
            scopes: ['products:addon_groups:read']
          }
        },
        {
          route: '/product_templates',
          name: 'products-product-templates',
          key: 'nav.main.items.products.product_templates',
          enabled: handleAtLeastSome(
            featureConfig,
            'products.product_templates'
          ),
          meta: {
            scopes: ['products:product_templates:read']
          }
        },
        {
          route: '/product_service_questions',
          name: 'products-service-questions',
          key: 'nav.main.items.products.services',
          enabled: handleAtLeastSome(featureConfig, 'products.service'),
          meta: {
            scopes: ['products:service_questions:read']
          }
        },
        {
          route: '/discounts',
          name: 'products-discounts-groups',
          key: 'nav.main.items.products.discounts',
          enabled: handleAtLeastSome(featureConfig, 'products.discounts'),
          meta: {
            scopes: ['products:discounts:read']
          }
        },
        {
          route: '/pricebooks',
          name: 'products-pricebooks',
          key: 'nav.main.items.products.pricebooks',
          enabled: handleAtLeastSome(featureConfig, 'products.pricebooks'),
          meta: {
            scopes: ['products:pricebooks:read']
          }
        },
        {
          route: '/promotions',
          name: 'products-promotions',
          key: 'nav.main.items.products.promotions',
          enabled: handleAtLeastSome(featureConfig, 'products.promotions'),
          meta: {
            scopes: ['products:promotions:read']
          }
        },
        {
          route: '/tags',
          name: 'products-tags',
          key: 'nav.main.items.products.tags',
          enabled: handleAtLeastSome(featureConfig, 'products.tags'),
          meta: {
            scopes: ['products:tags:read']
          }
        },
        ...handleHook('nav.products', 'last', customDashboards)
      ]
    },
    {
      route: '/loyalty',
      name: 'loyalty',
      enabled: handleAtLeastSome(featureConfig, 'loyalty'),
      key: 'nav.main.items.loyalty.title',
      meta: {
        scopes: ['loyalty']
      },
      children: [
        {
          route: '/vouchers/voucher_systems',
          name: 'loyalty-voucher-systems',
          enabled: handleAtLeastSome(featureConfig, 'loyalty.voucher_systems'),
          key: 'nav.main.items.loyalty.voucher_systems',
          meta: {
            scopes: ['loyalty:voucher_systems:read']
          }
        },
        {
          route: '/abocard_systems',
          name: 'loyalty-abocard-systems',
          enabled: handleAtLeastSome(featureConfig, 'loyalty.abocard'),
          key: 'nav.main.items.loyalty.abocard_systems',
          meta: {
            scopes: ['loyalty:abocard_systems:read']
          }
        },
        {
          route: '/logs',
          name: 'loyalty-vouchers-logs',
          enabled: handleAtLeastSome(featureConfig, 'loyalty.vouchers'),
          key: 'nav.main.items.loyalty.logs',
          meta: {
            scopes: ['loyalty:logs']
          }
        }
      ]
    },
    {
      route: '/resources',
      name: 'resources',
      enabled:
        !isUnifiedCommerce() && handleAtLeastSome(featureConfig, 'resources'),
      key: 'nav.main.items.resources.title',
      meta: {
        scopes: ['resources']
      },
      children: [
        {
          path: '',
          redirect: '/branches'
        },
        {
          route: '/branches',
          name: 'resources-branches',
          enabled: handleAtLeastSome(featureConfig, 'resources.branches'),
          key: 'nav.main.items.resources.branches',
          meta: {
            scopes: ['resources:branches:read']
          }
        },
        {
          route: '/branch_groups',
          name: 'resources-branch-groups',
          enabled: handleAtLeastSome(featureConfig, 'resources.branch_groups'),
          key: 'nav.main.items.resources.branch_groups',
          meta: {
            scopes: ['resources:branch_groups:read']
          }
        },
        {
          route: '/warehouses',
          name: 'resources-warehouses',
          enabled: handleAtLeastSome(featureConfig, 'resources.warehouses'),
          key: 'nav.main.items.resources.warehouses',
          meta: {
            scopes: ['resources:warehouses:read']
          }
        },
        {
          route: '/registers',
          name: 'resources-registers',
          enabled: handleAtLeastSome(featureConfig, 'resources.registers'),
          key: 'nav.main.items.resources.registers',
          meta: {
            scopes: ['resources:registers:read']
          }
        },
        {
          route: '/licenses',
          name: 'resources-licenses',
          enabled: handleAtLeastSome(featureConfig, 'resources.licenses'),
          key: 'nav.main.items.resources.licenses',
          meta: {
            scopes: ['resources:licenses:read']
          }
        },
        {
          route: '/devices',
          name: 'resources-devices',
          enabled: handleAtLeastSome(featureConfig, 'resources.devices'),
          key: 'nav.main.items.resources.devices',
          meta: {
            scopes: ['resources:devices:read']
          }
        },
        {
          route: '/device_groups',
          name: 'resources-device-groups',
          enabled: handleAtLeastSome(featureConfig, 'resources.device_groups'),
          key: 'nav.main.items.resources.device_groups',
          meta: {
            scopes: ['resources:devices:read']
          }
        }
      ]
    },
    {
      route: '/customers',
      name: 'customers',
      enabled: handleAtLeastSome(featureConfig, 'customers.crm'),
      key: 'nav.main.items.customers.title',
      meta: {
        scopes: ['customers']
      },
      children: []
    },
    {
      route: '/staff',
      name: 'staff',
      enabled: handleAtLeastSome(featureConfig, 'staff'),
      key: 'nav.main.items.staff.title',
      meta: {
        //TODO: find why its shown on scope['staff']
        //Fix for no permission
        scopes: ['staff:read', 'staff:groups:read']
      },
      children: [
        {
          route: '/manager',
          name: 'staff',
          enabled: true,
          key: 'nav.main.items.staff.all',
          meta: {
            scopes: ['staff:read']
          }
        },
        {
          route: '/staff_groups',
          name: 'staff-groups',
          enabled: true,
          key: 'nav.main.items.staff.staff-groups',
          meta: {
            scopes: ['staff:groups:read']
          }
        },
        {
          route: '/shift_plan',
          name: 'shift-plan',
          enabled: handleAtLeastSome(featureConfig, 'reservations'),
          key: 'nav.main.items.staff.shift_plan'
          // meta: {
          //   scopes: ['staff:groups:read']
          // }
        }
      ]
    },
    {
      route: '/reservations',
      name: 'reservations',
      enabled: handleAtLeastSome(featureConfig, 'reservations'),
      key: 'nav.main.items.reservations.title',
      meta: {
        scopes: [
          'reservations:services:read',
          'reservations:service_categories:read'
        ]
      },
      children: [
        {
          route: '/service_category',
          name: 'service-category',
          enabled: handleAtLeastSome(featureConfig, 'reservations'),
          key: 'nav.main.items.reservations.service_category',
          meta: {
            scopes: ['reservations:service_categories:read']
          }
        },
        {
          route: '/services',
          name: 'services',
          enabled: handleAtLeastSome(featureConfig, 'reservations'),
          key: 'nav.main.items.reservations.services',
          meta: {
            scopes: ['reservations:service_categories:read']
          }
        }
      ]
    },
    {
      route: '/supplier-management',
      name: 'supplier-management',
      enabled: handleAtLeastSome(featureConfig, 'suppliers'),
      key: 'nav.main.items.suppliers.title',
      meta: {
        scopes: ['supplier_management', 'supplier_management:suppliers']
      },
      children: [
        {
          route: '/suppliers',
          name: 'supplier-manager',
          enabled: true,
          key: 'nav.main.items.supplier.manager',
          meta: {
            scopes: [
              'supplier_management:suppliers',
              'supplier_management:suppliers:read'
            ]
          }
        },
        {
          route: '/purchase-orders',
          name: 'purchase-orders',
          enabled: true,
          key: 'nav.main.items.supplier.purchase_orders',
          meta: {
            scopes: [
              'supplier_management:purchase_orders',
              'supplier_management:purchase_orders:read'
            ]
          }
        }
      ]
    },
    {
      route: '/documents',
      name: 'documents',
      enabled: handleAtLeastSome(featureConfig, 'documents'),
      key: 'nav.main.items.documents.title',
      meta: {
        scopes: ['documents', 'documents:read']
      },
      children: [
        {
          route: '/overview',
          name: 'documents-overview',
          enabled: true,
          key: 'nav.main.items.documents.overview',
          meta: {
            scopes: ['documents:read']
          }
        }
      ]
    },
    {
      route: '/timetracking',
      name: 'timetracking',
      enabled: handleAtLeastSome(featureConfig, 'timetracking'),
      key: 'nav.main.items.timetracking.title',
      meta: {
        scopes: ['timetracking']
      },
      children: []
    },
    {
      route: '/utilities',
      name: 'utilities',
      enabled: handleAtLeastSome(featureConfig, 'utilities'),
      key: 'nav.main.items.utilities.title',
      meta: {
        scopes: ['utilities']
      },
      children: [
        {
          route: '/audits',
          name: 'utilities-audits',
          enabled: handleAtLeastSome(featureConfig, 'utilities.audits'),
          key: 'nav.main.items.utilities.audits',
          meta: {
            scopes: ['utilities:audits']
          }
        },
        {
          route: '/document_designer',
          name: 'document-designer',
          enabled: handleAtLeastSome(
            featureConfig,
            'utilities.document_designer'
          ),
          key: 'nav.main.items.utilities.document_designer',
          meta: {
            scopes: ['utilities:document_designer:read']
          }
        },
        {
          route: '/cms',
          name: 'cms',
          enabled: handleAtLeastSome(featureConfig, 'utilities.cms'),
          key: 'nav.main.items.utilities.cms.title',
          meta: {
            scopes: ['utilities:cms:read']
          }
        },
        {
          route: '/custom_dashboards',
          name: 'utilities-custom-dashboard',
          enabled: handleAtLeastSome(
            featureConfig,
            'utilities.custom_dashboards'
          ),
          key: 'nav.main.items.utilities.custom_dashboards',
          meta: {
            scopes: ['utilities:custom_dashboard:read']
          }
        },
        {
          route: '/notifications',
          name: 'notifications',
          enabled: handleAtLeastSome(featureConfig, 'utilities.notifications'),
          key: 'nav.main.items.utilities.notifications',
          meta: {
            scopes: ['utilities:notifications:create']
          }
        },
        {
          route: '/favourites',
          name: 'favourites',
          enabled: handleAtLeastSome(featureConfig, 'utilities.favourites'),
          key: 'nav.main.items.utilities.favourites',
          meta: {
            scopes: ['utilities:favourites:read']
          }
        },
        {
          route: '/export_history',
          name: 'export-history',
          enabled: true,
          key: 'nav.main.items.utilities.export_history',
          meta: {
            scopes: ['utilities:export_history:read']
          }
        }
      ]
    },
    {
      route: '/settings',
      name: 'settings',
      enabled: handleAtLeastSome(featureConfig, 'settings'),
      key: 'nav.main.items.settings.title',
      meta: {
        scopes: ['settings']
      },
      children: [
        {
          route: '/dashboard',
          name: 'settings-dashboard',
          enabled: true,
          key: 'nav.main.items.settings.general',
          meta: {
            scopes: ['settings:dashboard:read']
          }
        },
        {
          route: '/account',
          name: 'settings-dashboard',
          enabled: true,
          key: 'nav.main.items.settings.account',
          meta: {
            scopes: ['settings:account:read']
          }
        },
        {
          route: '/timetracking',
          name: 'settings-timetracking',
          enabled: handleAtLeastSome(featureConfig, 'settings.timetracking'),
          key: 'nav.main.items.settings.timetracking',
          meta: {
            scopes: ['settings:timetracking:read']
          }
        },
        {
          route: '/pos',
          name: 'settings-pos',
          enabled: true,
          key: 'nav.main.items.settings.pos',
          meta: {
            scopes: ['settings:pos:read']
          }
        },
        {
          route: '/users',
          name: 'settings-users',
          enabled: !isUnifiedCommerce(),
          key: 'nav.main.items.settings.users',
          meta: {
            scopes: ['settings:users:read']
          }
        },
        {
          route: '/webhooks',
          name: 'webhooks',
          enabled: handleAtLeastSome(featureConfig, 'webhooks'),
          key: 'nav.main.items.webhooks.title',
          meta: {
            scopes: ['webhooks']
          },
          children: []
        }
      ]
    },
    ...handleHook('nav', 'last', customDashboards)
  ]
}
