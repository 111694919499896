import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import cloneDeep from 'clone-deep'
import omit from 'just-omit'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import { diff } from 'just-diff'
import snakeCase from 'just-snake-case'
import compare from 'just-compare'
import { ampliPlugin } from '@/plugins/ampli'
import cleanChangeSuggestion from '../../views/settings/sections/pos/components/multi-amount-currency-input/cleanData.js'
import {
  disableIdGeneratorsOnEmpty,
  calculateAvailableCurrencies
} from '../helpers/configurations'
import { isUnifiedCommerce, isKeycloackEnabled } from '@/constants/index'
import auth from '@/plugins/auth'

const debugModeScale = new Map([
  ['none', 1],
  ['data_mode', 2]
])

const initialState = () => ({
  payload: {}, //reference to clientAccountConfiguration
  clientAccountConfiguration: {},
  localConfiguration: {},
  locationContext: {},
  currentLocation: null,
  filterDateRange: null,
  showOnboardList: false,
  onboardingRoute: false,
  branchCurrencies: [],
  staffSalesReportViewNew: true,
  dashboardDates: {},
  lastUrlWithPageNumber: {}
})

const state = initialState()

// The difference to just-safe-set is that we generate and object of a value is null.
// This is necessary when the initial database object defaults to null.
function safeSet(obj, props, value) {
  if (typeof props === 'string') {
    props = props.split('.')
  }
  if (typeof props === 'symbol') {
    props = [props]
  }
  var firstProp = props[0]
  if (firstProp === null && props.length > 1) {
    obj[firstProp] = {}
  }

  var lastProp = props.pop()
  if (!lastProp) {
    return false
  }
  if (firstProp === null && props.length) {
    obj[firstProp] = {}
  }
  var thisProp
  while ((thisProp = props.shift())) {
    if (typeof obj[thisProp] === 'undefined') {
      obj[thisProp] = {}
    }

    if (obj[thisProp] === null) {
      obj[thisProp] = {}
    }

    obj = obj[thisProp]
    if (!obj || typeof obj !== 'object') {
      return false
    }
  }
  obj[lastProp] = value
  return true
}

// getters
const getters = {
  getClientAccountConfiguration: (state) => {
    return state.clientAccountConfiguration || {}
  },
  getPayload: (state) => {
    return state.payload || {}
  },
  getLocalConfiguration: (state) => {
    return state.localConfiguration || {}
  },
  getCurrentDefaultCurrency: (state) => {
    return (
      get(state.clientAccountConfiguration, 'settings.default_currency') ||
      'EUR'
    )
  },
  getPageSizes: (state) => {
    // NOTE: we should keep the original 'pageSize' local config property for backwards compatibility for a while (a couple of months maximum).
    const legacyPageSize = get(state.localConfiguration, 'pageSize')
    const { main, inline } = get(state.localConfiguration, 'pageSizes', {})
    return {
      main: main || legacyPageSize || 20,
      inline: inline || 5
    }
  },
  getLastUrlWithPageNumber: (state) => {
    return state.lastUrlWithPageNumber || {}
  },
  getTableBorders: (state) => {
    return (
      get(state.clientAccountConfiguration, 'settings.general.table_borders') ||
      false
    )
  },
  getPageMode: (state) => {
    return get(state.localConfiguration, 'pageMode')
  },
  getFilterDateRange: (state) => {
    return state.filterDateRange || {}
  },
  getIsOnboardingRoute: (state) => {
    return state.onboardingRoute || false
  },
  hasGlobalDataMode: (state) => {
    return (
      debugModeScale.get(
        get(state.clientAccountConfiguration, 'settings.debug.global')
      ) >= 2
    )
  },
  getLocale: (state) => {
    return (
      get(state.localConfiguration, 'settings.language') ||
      get(state.clientAccountConfiguration, 'settings.language')
    )
  },
  getDateFormat: (state) => {
    return (
      get(state.localConfiguration, 'settings.default_date_format') ||
      get(state.clientAccountConfiguration, 'settings.default_date_format') ||
      'dd.MM.yyyy'
    )
  },
  getTimeFormat: (state) => {
    return (
      get(state.localConfiguration, 'settings.default_time_format') ||
      get(state.clientAccountConfiguration, 'settings.default_time_format') ||
      'HH:mm'
    )
  },
  getDateTimeFormat: (state) => {
    const dateFormat = getters.getDateFormat(state)
    const timeFormat = getters.getTimeFormat(state)
    return dateFormat + ' ' + timeFormat
  },
  getTimeZone: (state) => {
    function getBrowserTimezone() {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
      } catch (e) {
        return null
      }
    }
    return (
      get(state.localConfiguration, 'settings.default_timezone') ||
      get(state.clientAccountConfiguration, 'settings.default_timezone') ||
      getBrowserTimezone() ||
      'Europe/Berlin'
    )
  },
  getCreateMode: (state) => (entity = 'global') => {
    return (
      get(state.localConfiguration, `settings.createMode.${entity}`) || 'single'
    )
  },
  // instruct components to navigate to a newly create resource or not
  getNavigationAfterCreation: (state) => {
    return (
      get(
        state.clientAccountConfiguration,
        'settings.navigation_after_creation'
      ) || 'list'
    )
  },
  // tells the order of the columns for a table in dashboard
  // this way it will be seamless
  getTableColumnOrder: (state) => {
    return (
      get(state.clientAccountConfiguration, `settings.tables_columns_order`) ||
      null
    )
  },
  getDefaultDateSelected: (state) => {
    return (
      get(state.clientAccountConfiguration, 'settings.default_date_selected') ||
      'today'
    )
  },
  getStaffSalesReportViewNew: (state) => {
    return state.staffSalesReportViewNew
  },
  getCustomDashboards: (state) => {
    return (
      get(state.clientAccountConfiguration, 'custom_dashboards.dashboards') ||
      []
    )
  },
  getCustomDashboard: (state) => (name) => {
    return (
      get(state.clientAccountConfiguration, 'custom_dashboards.dashboards') ||
      []
    ).find((item) => snakeCase(item.name) === snakeCase(name))
  },
  getCurrentLocation: (state) => {
    return state.currentLocation || undefined
  },
  getCurrentBranchNumber: (state) => {
    return get(state.locationContext, 'branches.0.custom_id')
  },
  getCurrentBranchName: (state) => {
    return get(state.locationContext, 'branches.0.label')
  },
  getFinancials: (state) => {
    return state.financials || undefined
  },
  getKeepChangeSuggestion: (state) => {
    return get(state, 'financials.keep_change_suggestion')
  },
  getLocationContext: (state) => {
    return state.locationContext
  },
  getAvailableCurrencies: (state) => {
    const defaultCurrency =
      state.clientAccountConfiguration?.settings?.default_currency || 'EUR'
    return calculateAvailableCurrencies(state.branchCurrencies, defaultCurrency)
  },
  getClientAccountConfigurationIsDirty: (state) => {
    return !compare(state.clientAccountConfiguration, state.payload)
  },
  getDashboardDates: (state) => {
    return state.dashboardDates
  }
}

// actions
const actions = {
  async getClientAccountConfiguration({ state, commit }) {
    try {
      const { data } = await th.configurations().getAll({ owner: 'self' })

      if (!data || !data[0])
        throw new Error(
          'Could not get client account configuration unexpectedly'
        )

      const config = data[0]
      commit('SET_CLIENT_ACCOUNT_CONFIGURATION', config)
      commit('SYNC_CLIENT_ACCOUNT_CONFIGURATION')

      const country = safeGet(config, 'tenant.address.country')
      if (country) {
        ampliPlugin.setProperty('country', country)
      }
    } catch (err) {
      commit('SET_CLIENT_ACCOUNT_CONFIGURATION', null)
      return [err]
    }
  },
  // We store the columns per table as an array of shown and hidden, storing the column name
  setTableColumnOrder(
    { state, commit },
    { tableName, shownColumns, hiddenColumns }
  ) {
    let config = cloneDeep(state.clientAccountConfiguration)
    safeSet(
      config,
      `settings.tables_columns_order.${tableName}.shown`,
      shownColumns
    )
    safeSet(
      config,
      `settings.tables_columns_order.${tableName}.hidden`,
      hiddenColumns
    )
    commit('SET_CLIENT_ACCOUNT_CONFIGURATION', config)
  },
  resetClientAccountConfiguration({ state, commit }) {
    commit('RESET_CLIENT_ACCOUNT_CONFIGURATION')
  },
  setFilterDateRange({ commit }, filterDateRange) {
    commit('SET_FILTER_DATE_RANGE', filterDateRange)
  },
  setLocalConfigurationValue({ state, commit }, { path, value }) {
    commit('SET_LOCAL_CONFIGURATION_VALUE', { path, value })
  },
  setClientAccountConfigurationValue({ state, commit }, { path, value }) {
    commit('SET_CLIENT_ACCOUNT_CONFIGURATION_VALUE', { path, value })
  },
  setLastUrlWithPageNumber({ state, commit }, lastUrlWithPageNumber) {
    commit('SET_LAST_URL_WITH_PAGE_NUMBER', lastUrlWithPageNumber)
  },
  checkOnboardingCompleted({ state, commit }) {
    // If there are no more onboarding stages to complete
    // Set the property completed to true
    const completed =
      state.clientAccountConfiguration?.onboarding?.stages &&
      !Object.keys(state.clientAccountConfiguration.onboarding.stages).find(
        (stage) => !state.clientAccountConfiguration.onboarding.stages[stage]
      )
    if (completed) {
      commit('SET_CLIENT_ACCOUNT_CONFIGURATION_VALUE', {
        path: 'onboarding.completed',
        value: true
      })
    } else {
      // If it was not completed, then open the onboarding modal
      commit('SET_SHOW_ONBOARDING_LIST', true)
    }
  },
  async saveClientAccountConfiguration({ state, commit }) {
    // If it's unified commerce, check that the user is logged in to be able to save
    if (isUnifiedCommerce() && isKeycloackEnabled()) {
      try {
        await auth.refreshKeycloakToken()
      } catch (err) {
        return
      }
    }
    try {
      const inst = th.configurations()
      // since we are still using the PUT route , which can be described as "fake" patch
      // we unfortunately need to "fake" diff the object manually and also exclude some properties that
      // are not writable
      const current = cloneDeep(state.clientAccountConfiguration)
      const changedProps = diff(state.payload, current).map((item) => {
        return item.path[0]
      })

      const payload = omit(pick(current, changedProps), [
        'id',
        'updated_at',
        'created_at',
        'metadata'
      ])

      const { data } = await inst.put(state.payload.id, payload)

      if (!data)
        throw new Error(
          'Could not get client account configuration unexpectedly'
        )

      commit('SET_CLIENT_ACCOUNT_CONFIGURATION', data)
      commit('SYNC_CLIENT_ACCOUNT_CONFIGURATION')

      return [null]
    } catch (err) {
      // NOTE: previously we were discarding the whole config on error. This leads to clearing all config options, which is why
      // we removed it.
      // commit('setClientAccountConfiguration', null)
      return [err]
    }
  },
  setLocationContext({ state, commit }, { branches, location = null }) {
    commit('SET_LOCATION_CONTEXT', { branches })
    commit('SET_LOCATION', location)
  },
  setStaffSalesReportViewNew({ state, commit }, value) {
    commit('SET_STAFF_SALES_REPORT_VIEW_NEW', value)
  },
  setShowOnboardList({ state, commit }, showOnboardList) {
    commit('SET_SHOW_ONBOARDING_LIST', showOnboardList)
  },
  clearLocationState({ state, commit }) {
    commit('SET_LOCATION_CONTEXT', {})
    commit('SET_LOCATION', null)
  },
  prepareData({ state, commit }) {
    commit('PREPARE_DATA')
  },
  setIsOnboardingRoute({ commit }, isOnboardingRoute) {
    commit('SET_IS_ONBOARDING_ROUTE', isOnboardingRoute)
  },
  reset({ commit }) {
    commit('RESET_CONFIGURATIONS')
  },
  syncClientAccountConfiguration({ commit }) {
    commit('SYNC_CLIENT_ACCOUNT_CONFIGURATION')
  },
  async setBranchCurrencies({ commit }, { vm } = {}) {
    try {
      const { data = [] } = await th
        .branchesV1()
        .getAll({ query: { deleted: false } })
      commit(
        'SET_BRANCH_CURRENCIES',
        data.map(({ currency_default }) => currency_default)
      )
    } catch (err) {
      vm.$logException(err, { trackError: false })
    }
  },
  setDashboardDates({ commit }, dates) {
    commit('SET_DASHBOARD_DATES', dates)
  }
}

// mutations
const mutations = {
  SET_CLIENT_ACCOUNT_CONFIGURATION(state, configuration) {
    state.clientAccountConfiguration = cloneDeep(configuration)
  },
  SYNC_CLIENT_ACCOUNT_CONFIGURATION(state) {
    state.payload = cloneDeep(state.clientAccountConfiguration)
  },
  RESET_CLIENT_ACCOUNT_CONFIGURATION(state) {
    state.clientAccountConfiguration = cloneDeep(state.payload)
  },
  SET_IS_ONBOARDING_ROUTE(state, isOnboardingRoute) {
    state.onboardingRoute = isOnboardingRoute
  },
  SET_LOCAL_CONFIGURATION_VALUE(state, { path, value }) {
    // TODO: refactor back to normal safe set, since observerd buggy behaviour
    // comes from not setting in and then fetching from the API.
    if (!state.localConfiguration) state.localConfiguration = {}
    const config = cloneDeep(state.localConfiguration)
    safeSet(config, path, value)
    state.localConfiguration = { ...state.localConfiguration, ...config }
  },
  SET_CLIENT_ACCOUNT_CONFIGURATION_VALUE(state, { path, value }) {
    // TODO: refactor back to normal safe set, since observerd buggy behaviour
    // comes from not setting in and then fetching from the API.
    if (!state.clientAccountConfiguration) state.clientAccountConfiguration = {}
    const config = cloneDeep(state.clientAccountConfiguration)
    safeSet(config, path, value)

    state.clientAccountConfiguration = {
      ...state.clientAccountConfiguration,
      ...config
    }
  },
  SET_LAST_URL_WITH_PAGE_NUMBER(state, lastUrlWithPageNumber) {
    state.lastUrlWithPageNumber = lastUrlWithPageNumber
  },
  SET_LOCATION_CONTEXT(state, configuration) {
    state.locationContext = configuration
  },
  SET_LOCATION(state, location) {
    state.currentLocation = location
  },
  SET_SHOW_ONBOARDING_LIST(state, showOnboardList) {
    state.showOnboardList = showOnboardList
  },
  SET_FILTER_DATE_RANGE(state, filterDateRange) {
    state.filterDateRange = filterDateRange
  },
  SET_STAFF_SALES_REPORT_VIEW_NEW(state, value) {
    state.staffSalesReportViewNew = value
  },
  PREPARE_DATA(state) {
    const variableMaximumValue = (
      get(
        state.clientAccountConfiguration,
        'discounts.limits.cart_item.variable.maximum_value'
      ) || []
    ).filter((d) => d.currency && d.amount)
    safeSet(
      state.clientAccountConfiguration,
      'discounts.limits.cart_item.variable.maximum_value',
      variableMaximumValue
    )
    const fixedMaximumValue = (
      get(
        state.clientAccountConfiguration,
        'discounts.limits.cart_item.fixed.maximum_value'
      ) || []
    ).filter((d) => d.currency && d.amount)
    safeSet(
      state.clientAccountConfiguration,
      'discounts.limits.cart_item.fixed.maximum_value',
      fixedMaximumValue
    )
    const changeSuggestion = cleanChangeSuggestion(
      get(state.clientAccountConfiguration, 'financials.keep_change_suggestion')
    )
    const lowCashThreshold = cleanChangeSuggestion(
      get(state.clientAccountConfiguration, 'financials.low_cash_threshold')
    )
    safeSet(
      state.clientAccountConfiguration,
      'financials.keep_change_suggestion',
      changeSuggestion
    )
    safeSet(
      state.clientAccountConfiguration,
      'financials.low_cash_threshold',
      lowCashThreshold
    )
    disableIdGeneratorsOnEmpty(state.clientAccountConfiguration)
  },
  RESET_CONFIGURATIONS(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
  SET_BRANCH_CURRENCIES(state, branchCurrencies) {
    state.branchCurrencies = branchCurrencies
  },
  SET_DASHBOARD_DATES(state, dashboardDates) {
    state.dashboardDates = dashboardDates
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
