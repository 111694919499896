/**
 * That directive serves the purpose of fixing element-plus bug when a filterable el-select
 * is not triggering keyboard on iPad
 * More details: https://github.com/ElemeFE/element/issues/12742
 */

function removeReadonly(el, binding) {
  if (binding.value === false) {
    return
  }
  const input = el.querySelector('.el-input__inner')
  input.removeAttribute('readonly')
}

export default {
  install: (app) => {
    app.directive('cancel-read-only', {
      mounted: removeReadonly,
      updated: removeReadonly
    })
  }
}
