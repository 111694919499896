<template>
  <el-container
    class="tillhub-page-wrapper"
    direction="vertical"
    :style="style"
  >
    <el-header
      v-if="title || (routeBackUrl && routeBackTitle) || $slots.breadcrumbs"
      height="30"
    >
      <div v-if="routeBackUrl && routeBackTitle" class="route-back">
        <router-link :to="routeBackUrl">
          <el-icon><ArrowLeft /></el-icon>
          <span> {{ routeBackTitle }}</span>
        </router-link>
      </div>
      <slot v-else name="breadcrumbs" />
      <h2 v-if="!!title" :style="titleStyle">
        {{ title }}
      </h2>
    </el-header>

    <el-header v-if="$slots.header" class="flex items-center h-auto m-6">
      <slot name="header" />
    </el-header>

    <el-main
      class="content"
      :class="{ 'scroll-content': scrollContent === true }"
    >
      <slot />
    </el-main>

    <el-footer v-if="actions || $slots.actions">
      <slot name="actions">
        <actions v-bind="{ ...$attrs, ...actions }" />
      </slot>
    </el-footer>
  </el-container>
</template>

<script>
import Actions from '@/components/actions'

export default {
  name: 'ThPageWrapper',
  components: {
    Actions
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    margin: {
      type: [Boolean, String],
      default: true
    },
    actions: {
      type: Object,
      default: undefined
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'var(--background-color)'
    },
    color: {
      type: String,
      required: false,
      default: null
    },
    gutter: {
      type: [String, Number],
      required: false,
      default: 0
    },
    scrollContent: {
      type: Boolean,
      default: true
    },
    scrollContentY: {
      type: Boolean,
      default: undefined
    },
    alignTitle: {
      type: String,
      default: undefined
    },
    routeBackUrl: {
      type: [String, Object],
      required: false,
      default: undefined
    },
    routeBackTitle: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    style() {
      let padding = '0'
      if (Number.isFinite(this.gutter) && this.gutter !== 0) {
        padding = `${this.gutter}px`
      } else if (this.gutter) {
        padding = this.gutter
      }

      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
        padding
      }
    },
    titleStyle() {
      return { textAlign: this.alignTitle }
    }
  }
}
</script>

<style scoped>
.tillhub-page-wrapper {
  height: 100%;
  width: 100%;
}
.tillhub-page-wrapper > * {
  margin: 0;
  padding: 0;
}

.el-main {
  overflow: hidden;
}

.content,
.content > * {
  height: 100%;
  width: 100%;
}

.scroll-content,
.scroll-content-y {
  overflow-y: auto;
}

.route-back {
  color: #259ff6;
  padding: 20px 20px 0;
}
</style>
