import th from '@tillhub/javascript-sdk'
import cloneDeep from 'clone-deep'
import get from 'just-safe-get'
import set from 'just-safe-set'
import pAll from 'p-all'
import mapValues from 'just-map-values'
import { HEADERS_MAP } from '../../constants'

const initialState = () => ({
  templates: [],
  currentTemplate: {},
  isDirty: false,
  previewUrl: null,
  previewIsLoading: false
  // previewUrl: 'https://storage.googleapis.com/tillhub-api/users%2FEDDB2494C2434EFE948655D6BA27E69A%2Fpdfs%2Finvoice-169f3882-9940-4c1d-b475-4ad84168e87f.pdf?GoogleAccessId=tillhub-api-storage%40arboreal-groove-198520.iam.gserviceaccount.com&Expires=1537801589&Signature=i9IwC5vR%2FUdHC%2FRKWqMdIRqAv6BeA%2Fx19xYCFhB2pMG5CutxwKbJG7LOuo2YgybyeQAOmKCUJAXC%2BMVJZ0OFBOxAaQTa4C8Srpwv9Fiz%2By2F%2BM2bT8jZUpGqCXWz1vQpjSunDrb5nVtxWlV7VQtfS2Qp3geBfaR%2FlXTm9gW4OCx8InTGGy5aj4Eb0zvVRLP6oHk51%2BxBze8Bg97Ewlp8IJnmgmeS6Hm8QQoVWMr5CF9Nn00MVxhjRLBQHVHRnJMn2uoz8YpkCC031MrvzE7GwYNAXAu%2BtnO%2B7AImqt%2BpCR47Gy9cplX2yWM4Nszc1oyAqNevAUqp%2Bo4trONMX8GXvQ%3D%3D'
})

const state = initialState()

// getters
const getters = {
  getTemplates: (state, getters, rootState) => {
    return state.templates
  },
  getInvoices: (state, getters, rootState) => {
    return state.templates.filter((item) => item.type === 'invoice_v1')
  },
  getDeliveryNotes: (state, getters, rootState) => {
    return state.templates.filter((item) => item.type === 'delivery_note_v1')
  },
  getFullReceipts: (state, getters, rootState) => {
    return state.templates.filter((item) => item.type === 'full_receipt_v1')
  },
  previewIsLoading: (state, getters, rootState) => {
    return !!state.previewIsLoading
  }
}

// actions
const actions = {
  async fetchTemplates({ commit, state }, { vm } = {}) {
    const apiTemplates = th.templates()
    const { data } = await apiTemplates.getAll({
      query: { type: ['invoice_v1', 'full_receipt_v1', 'delivery_note_v1'] }
    })
    const templatesToForceSave = []

    // TODO: remove migration when not needed anymore.
    data.forEach((item) => {
      const _HEADERS_MAP = HEADERS_MAP(vm)
      // NOTE: migration for existing templates that don't have attributes prop
      if (!get(item, 'options.map.attributes')) {
        templatesToForceSave.push(item)
        delete _HEADERS_MAP.attributes.section
        set(item, 'options.map.attributes', _HEADERS_MAP.attributes)
      }

      // NOTE: because of a faulty i18n implementation, there are templates with headers default values of an untranslated i18n key.
      // Here we're converting the faulty value with a valid default i18n translation value.
      const migratedMap = mapValues(
        get(item, 'options.map') || {},
        (val, key) =>
          val.default.includes('headers.')
            ? { ...val, default: _HEADERS_MAP[key].default }
            : val
      )
      set(item, 'options.map', migratedMap)
    })

    commit('setTemplates', data)

    if (templatesToForceSave.length) {
      // NOTE: As part of the migration of old templates, we save straight to DB the changes for the relevant templates.
      // TODO: remove migration when not needed anymore.
      const actions = templatesToForceSave.map((item) => {
        const options = cloneDeep(item.options) || {}
        options.map = completeHeaders(item, vm)
        return () => apiTemplates.put(item.id, { options, type: item.type })
      })
      await pAll(actions)
    }
  },
  async preview({ commit, state }, { payload, vm } = {}) {
    if (!state.currentTemplate.id) return null

    const options = cloneDeep(state.currentTemplate.options) || {}
    options.map = completeHeaders(state.currentTemplate, vm)

    const apiTemplates = th.templates()
    commit('setPreviewIsLoading')

    try {
      const { data: results } = await apiTemplates.preview({
        templateId: state.currentTemplate.id,
        body: options,
        query: {
          format: 'uri'
        }
      })
      commit('resetPreviewIsLoading')
      commit('setPreviewUrl', results[0].data)
    } catch (err) {
      commit('resetPreviewIsLoading')
      throw err
    }
  },
  setCurrentTemplate({ commit, state }, payload) {
    commit('setCurrentTemplate', payload)
  },
  clear({ commit, state }, payload) {
    commit('clearTransient')
  },
  async alterTemplate({ commit, state, dispatch }, { type, vm }) {
    const apiTemplates = th.templates()
    const options = cloneDeep(state.currentTemplate.options) || {}
    options.map = completeHeaders(state.currentTemplate, vm)

    if (state.currentTemplate && state.currentTemplate.id) {
      const { data } = await apiTemplates.put(state.currentTemplate.id, {
        options,
        type: state.currentTemplate.type
      })
      await dispatch('preview', { vm })
      commit('unsetDirty')
      commit('replaceTemplate', data[0])
      commit('setCurrentTemplate', data[0])
      return data
    } else {
      const body = cloneDeep(state.currentTemplate)
      body.options = options
      body.type = type
      const { data } = await apiTemplates.create(body)
      commit('unsetDirty')
      commit('addTemplate', data[0])
      commit('setCurrentTemplate', data[0])
      await dispatch('preview', { vm })
      return data
    }
  },
  reset({ commit }) {
    commit('RESET')
  }
}

// mutations
const mutations = {
  unsetDirty(state) {
    state.isDirty = false
  },
  clearTransient(state) {
    state.isDirty = false
    state.previewUrl = null
    state.currentTemplate = {}
  },
  setTemplates(state, payload) {
    state.templates = payload
  },
  setPreviewUrl(state, payload) {
    state.previewUrl = payload
  },
  setCurrentTemplate(state, payload) {
    state.currentTemplate = {
      options: {},
      ...payload
    }
  },
  setCurrentTemplateOptionsProp(state, payload) {
    state.isDirty = true
    state.currentTemplate.options = {
      ...state.currentTemplate.options,
      ...payload
    }
  },
  addTemplate(state, payload) {
    state.templates.push(payload)
  },
  replaceTemplate(state, payload) {
    state.templates.splice(
      state.templates.findIndex((el) => el.id === payload.id),
      1,
      payload
    )
  },
  setPreviewIsLoading(state) {
    state.previewIsLoading = true
  },
  resetPreviewIsLoading(state) {
    state.previewIsLoading = false
  },
  RESET(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  }
}

function completeHeaders(currentTemplate, vm) {
  let completeHeaders

  if (
    !currentTemplate.id ||
    currentTemplate.type === 'invoice_v1' ||
    currentTemplate.type === 'delivery_note_v1' ||
    currentTemplate.type === 'full_receipt_v1'
  ) {
    // delete 'section' prop from HEADERS_MAP
    completeHeaders = cloneDeep(HEADERS_MAP(vm))
    Object.keys(completeHeaders).forEach((header) => {
      delete completeHeaders[header].section
    })
  }

  if (get(currentTemplate.options, 'map')) {
    Object.keys(completeHeaders).forEach((prop) => {
      completeHeaders[prop] = {
        ...completeHeaders[prop],
        ...currentTemplate.options.map[prop]
      }
    })
  }

  return completeHeaders
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
