/**
 *
 * Colection of simple object formatters to use in formatters plugin or tests
 *
 */

/**
 * Branch formatter
 * @param {object} branch object
 * @returns {string} branch_number - name
 */
export function formatBranch(branch) {
  let branchNumber
  let name
  if (branch.branch_number || Number.isFinite(branch.branch_number)) {
    branchNumber = branch.branch_number.toString()
  }
  if (branch.name) {
    name = branch.name
  }
  return `${branchNumber || ''}${branchNumber && name ? ' - ' : ''}${
    name || ''
  }`
}

/**
 * Customer formatter
 * @param {object} customer object
 * @param {object} showEmail (adds email at the beginning)
 * @returns {string} email(optional) - firstname lastname
 */
export function formatCustomer(customer, { showEmail = false } = {}) {
  const parts = []
  if (showEmail && customer.email) {
    parts.push(customer.email)
    parts.push('-')
  }

  if (customer.firstname) parts.push(customer.firstname)
  if (customer.lastname) parts.push(customer.lastname)

  return parts.join(' ')
}

/**
 * Register formatter
 * @param {object} register object
 * @returns {string} register_number - name
 */
export function formatRegister(register) {
  let registerNumber
  let name
  if (register.register_number || Number.isFinite(register.register_number)) {
    registerNumber = register.register_number.toString()
  }
  if (register.name) {
    name = register.name
  }
  return `${registerNumber || ''}${registerNumber && name ? ' - ' : ''}${
    name || ''
  }`
}

/**
 * Product formatter
 * @param {object} product object
 * @returns {string} custom_id - name
 */
export function formatProduct(product) {
  const productName = product.name
  const productNumber = product.custom_id
  return `${productNumber || ''}${productNumber && productName ? ' - ' : ''}${
    productName || ''
  }`
}
