<template>
  <div>
    <el-input
      v-show="editable"
      :id="id"
      ref="inputRef"
      v-model="formattedValue"
      class="th-number-input"
      data-lpignore="true"
      :class="computedClass"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder || defaultPlaceholder"
      :size="size"
      @keypress.enter.prevent="handleEnter"
      @keydown="onInputKeydown"
      @clear="onClear"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <template v-if="appendText" #append>
        {{ appendText }}
      </template>

      <template v-if="$slots.suffix" #suffix>
        <slot name="suffix" />
      </template>
    </el-input>
    <span v-show="!editable" v-text="fallbackValue(formattedValue)" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { isNullish } from '@/utils/general'
import getCaretPosition from 'caret-position2/get'
import setCaretPosition from 'caret-position2/set'

export default {
  name: 'ThNumberInput',
  props: {
    locale: {
      type: String,
      required: true
    },
    id: {
      type: String,
      default: undefined
    },
    precision: {
      type: Number,
      default: 0,
      validator: (value) => [0, 1, 2, 3, 4].includes(value)
    },
    prominent: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    clearableIsLeft: {
      type: Boolean,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    fallbackValue: {
      type: Function,
      default: (val) => (isNullish(val) ? '–' : val)
    },
    percent: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: '',
      validator: (value) => ['-', '+', ''].includes(value)
    },
    upperLimit: {
      type: Number,
      default: undefined
    },
    lowerLimit: {
      type: Number,
      default: undefined
    },
    appendText: {
      type: String,
      default: () => {}
    },
    submitOnEnterKey: {
      type: Boolean,
      default: false
    },
    positiveOnly: {
      type: Boolean,
      default: false
    },
    leftAligned: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const decimalSeparator = Intl.NumberFormat(props.locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '')

    const thousandSeparator = Intl.NumberFormat(props.locale)
      .format(11111)
      .replace(/\p{Number}/gu, '')

    const parseLocaleNumber = (stringNumber) => {
      return parseFloat(
        stringNumber
          .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
          .replace(new RegExp('\\' + decimalSeparator), '.')
      )
    }

    const format = (value, { allowEmptyFractionDigits = false } = {}) => {
      const formatted = new Intl.NumberFormat(props.locale, {
        style: props.percent ? 'percent' : 'decimal',
        minimumFractionDigits: allowEmptyFractionDigits ? 0 : props.precision,
        maximumFractionDigits: props.precision
      }).format(value)

      return `${props.prefix}${formatted}`
    }

    const getDelimiterSymbolsCount = (value) => {
      if (typeof value !== 'string') {
        return 0
      }
      const result = value.match(new RegExp(thousandSeparator, 'g'))
      return result?.length || 0
    }

    const inputRef = ref(null)
    const lastKeyCode = ref(null)

    const focused = ref(false)

    const getInitialFormattedValue = () => {
      return typeof props.modelValue === 'number'
        ? format(props.modelValue)
        : ''
    }

    const localFormattedValue = ref(getInitialFormattedValue())
    const prevLocalFormattedValue = ref(localFormattedValue.value)

    watch(
      () => props.modelValue,
      () => {
        if (!focused.value) {
          localFormattedValue.value = getInitialFormattedValue()
        }
      }
    )

    watch(
      () => localFormattedValue.value,
      (value, prevValue) => {
        prevLocalFormattedValue.value = prevValue
      }
    )

    const onInputFocus = () => {
      focused.value = true
    }

    const onInputBlur = () => {
      focused.value = false
      localFormattedValue.value = getInitialFormattedValue()
    }

    const onInputKeydown = (event) => {
      lastKeyCode.value = event.keyCode
    }

    const onClear = () => {
      emit('cleared')
      localFormattedValue.value = ''
    }

    const defaultPlaceholder = computed(() => format(0))

    const computedClass = computed(() => ({
      prominent: props.prominent,
      'clearable-is-left': props.clearableIsLeft,
      'left-aligned': props.leftAligned
    }))

    const forbidNegative = computed(
      () => ['-', '+'].includes(props.prefix) || props.positiveOnly
    )

    const formattedValue = computed({
      get: () => {
        if (focused.value) {
          return localFormattedValue.value
        }
        if (props.modelValue === null) {
          return ''
        }

        return format(props.modelValue)
      },
      set: (value) => {
        let preparedValue = value.replace(/%|\s/g, '') //remove spaces and percentage signs
        if (props.prefix) {
          preparedValue = preparedValue.replace(props.prefix, '')
        }

        // letters could come, checking for bullshit
        if (preparedValue && !/^[-+]?[0-9.,]+$/.test(preparedValue)) {
          return
        }

        let finalValue = parseLocaleNumber(preparedValue)

        if (preparedValue === '') {
          localFormattedValue.value = ''
          emit('update:modelValue', null)
          return
        }

        // percent mode
        if (props.percent) {
          finalValue = finalValue / 100
        }

        if (forbidNegative.value) {
          finalValue = Math.abs(finalValue)
        }

        if (props.precision > 0) {
          const [, sourceDecimals] = preparedValue
            .replace(`${decimalSeparator}${decimalSeparator}`, decimalSeparator)
            .split(decimalSeparator)
          const [mainPart] = format(finalValue)
            .replace('%', '')
            .split(decimalSeparator)
          localFormattedValue.value = [
            mainPart,
            preparedValue.includes(decimalSeparator) ? decimalSeparator : '',
            (sourceDecimals || '').slice(0, props.precision),
            props.percent ? '%' : ''
          ].join('')
        } else {
          localFormattedValue.value = format(finalValue)
        }

        // upper and lower limits
        if (
          typeof props.lowerLimit === 'number' &&
          finalValue < props.lowerLimit
        ) {
          finalValue = props.lowerLimit
        } else if (
          typeof props.upperLimit === 'number' &&
          finalValue > props.upperLimit
        ) {
          finalValue = props.upperLimit
        }

        if (isNaN(finalValue)) {
          localFormattedValue.value = ''
        }

        emit('update:modelValue', finalValue)

        const caretPos = getCaretPosition(inputRef.value.input)
        setTimeout(() => {
          // The action is async input could be already destoyed the point
          if (inputRef.value) {
            const delimitersDiff =
              getDelimiterSymbolsCount(localFormattedValue.value) -
              getDelimiterSymbolsCount(prevLocalFormattedValue.value)

            let position = caretPos.caret + delimitersDiff
            if (props.prefix && !prevLocalFormattedValue.value) {
              position += 1
            }

            delimitersDiff
            setCaretPosition(inputRef.value.input, position)
          }
        })
      }
    })

    const handleEnter = () => props.submitOnEnterKey && emit('submit')

    return {
      formattedValue,
      defaultPlaceholder,
      computedClass,
      handleEnter,
      inputRef,
      focused,
      onInputFocus,
      onClear,
      onInputBlur,
      onInputKeydown
    }
  }
}
</script>

<style scoped>
.th-number-input:not(.left-aligned) :deep(.el-input__inner) {
  text-align: right;
}

.th-number-input :deep(.el-input__inner) {
  padding: 0 0.5em;
}

.th-number-input :deep(.el-input__inner.no-padding) {
  padding: 0 0;
}

.th-number-input.prominent :deep(.el-input__inner) {
  font-size: 2em;
  padding: 1em 2em;
  font-weight: bold;
}

.th-number-input.clearable-is-left :deep(.el-input__inner) {
  padding-right: 15px;
}

.th-number-input.clearable-is-left :deep(.el-input__suffix) {
  right: unset;
  left: 5px;
}
</style>
