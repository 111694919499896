import ElementPlus, { ElConfigProvider } from 'element-plus'
import i18n from '../i18n'

// Element UI el-select with filterable not showing keyboard on apple mobile devices fix
// https://github.com/ElemeFE/element/issues/11397
// TODO INVESTIGATE
/*Element.Select.computed.readonly = function () {
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
  return !(this.filterable || this.multiple || !isIE) && !this.visible
}*/

export default {
  install: (app) => {
    app.use(ElementPlus, { i18n: (key, value) => i18n.t(key, value) })
    app.component('ElConfigProvider', ElConfigProvider)
  }
}
