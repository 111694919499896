import {
  formatBranch,
  formatCustomer,
  formatProduct,
  formatRegister
} from '@/utils/formatters'

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$formatBranch = formatBranch
    app.config.globalProperties.$formatCustomer = formatCustomer
    app.config.globalProperties.$formatRegister = formatRegister
    app.config.globalProperties.$formatProduct = formatProduct
  }
}
