import safeGet from 'just-safe-get'

export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  return [
    {
      value: '/accounting/expense_accounts',
      key: 'nav.main.items.accounting.expense_accounts',
      enabled: !!safeGet(featureConfig, 'accounting.expense_accounts'),
      scopes: ['accounting:expense_accounts:read']
    },
    {
      value: '/accounting/taxes',
      key: 'nav.main.items.accounting.taxes',
      enabled: !!safeGet(featureConfig, 'accounting.taxes'),
      scopes: ['accounting:taxes:read']
    },
    {
      value: '/accounting/accounts',
      key: 'nav.main.items.accounting.accounts',
      enabled: !!safeGet(featureConfig, 'accounting.accounts'),
      scopes: ['accounting:revenue_accounts:read']
    },
    {
      value: '/accounting/payment_options',
      key: 'nav.main.items.accounting.payment_options',
      enabled: !!safeGet(featureConfig, 'accounting.payment_options'),
      scopes: ['accounting:payment_options:read']
    },
    {
      value: '/accounting/safe_management',
      key: 'nav.main.items.accounting.safe_management',
      enabled: !!safeGet(featureConfig, 'accounting.safe_management'),
      scopes: ['accounting:safe_management:read']
    }
  ]
}
