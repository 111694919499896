<template #actions>
  <div class="flex items-center h-full px-8">
    <!-- Delete -->
    <el-tooltip
      v-if="!buttons.delete.hide"
      effect="dark"
      placement="top-start"
      :content="deleteTooltip"
      :disabled="!deleteDisabled"
    >
      <!-- In order for the hover event to propagate when the button is disabled, the button element need to be wrapped with a container element, per element-ui docs -->
      <div>
        <el-button
          v-permissions="{ scopes: buttons.delete.scopes }"
          type="danger"
          plain
          data-testid="delete-button"
          :class="{ disabled: deleteDisabled }"
          :disabled="deleteDisabled"
          @click="$emit('delete')"
          v-text="$t('common.interactions.buttons.delete')"
        />
      </div>
    </el-tooltip>

    <div class="flex-grow" />

    <!-- Cancel/Back -->
    <el-button
      v-if="!buttons.cancel.hide"
      data-testid="cancel-button"
      @click="handleCancel"
      v-text="$t('common.interactions.buttons.back')"
    />

    <!-- Close -->
    <el-button
      v-if="!buttons.close.hide"
      data-testid="close-button"
      @click="$emit('close')"
      v-text="$t('common.interactions.buttons.close')"
    />

    <!-- Save -->
    <el-button
      v-if="!buttons.save.hide"
      v-permissions="{ scopes: buttons.save.scopes }"
      type="primary"
      data-testid="save-button"
      @click="$emit('save')"
      v-text="
        buttons.save.type === 'update'
          ? $t('common.interactions.buttons.save')
          : $t('common.interactions.buttons.create')
      "
    />
  </div>
</template>

<script>
import backButton from '@/mixins/back-button'
export default {
  props: {
    //`isNew` will toggle update or create save button
    isNew: {
      type: Boolean,
      default: false
    },
    //Buttons permissions prefix.
    permissionPrefix: {
      type: String,
      default: ''
    },
    //Buttons to hide
    excludeButtons: {
      type: Array,
      default: () => []
    },
    //Buttons to show
    includeButtons: {
      type: Array,
      default: () => []
    },
    //Add tooltip to delete button
    deleteTooltip: {
      type: String,
      default: ''
    },
    //Make delete button disabled
    deleteDisabled: {
      type: Boolean,
      default: false
    },
    //Route path for cancel/back action
    returnedPath: {
      type: [String, Object],
      default: null
    }
  },
  emits: ['save', 'delete', 'close'],
  setup(props) {
    const { goBack } = backButton(props.returnedPath || '/')

    return { goBack }
  },
  computed: {
    buttons() {
      return {
        delete: {
          hide: this.isNew || this.excludeButtons.includes('delete'),
          scopes: this.deletePermissions
        },
        cancel: {
          hide: this.excludeButtons.some((value) =>
            ['cancel', 'back'].includes(value)
          )
        },
        save: {
          hide: this.excludeButtons.includes('save'),
          type: this.isNew ? 'create' : 'update',
          scopes: this.savePermissions
        },
        close: {
          hide: !this.includeButtons.includes('close')
        }
      }
    },
    deletePermissions() {
      return this.permissionPrefix ? [`${this.permissionPrefix}:delete`] : []
    },
    savePermissions() {
      return this.permissionPrefix
        ? [`${this.permissionPrefix}${this.isNew ? ':create' : ':update'}`]
        : []
    }
  },
  methods: {
    handleCancel() {
      this.goBack()
    }
  }
}
</script>

<style scoped></style>
