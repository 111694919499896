import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'core-js/modules/es.promise.finally'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './plugins/auth'
import { registerDependencies } from './register-dependencies-helper'
import Element from './plugins/element.js'
import Axios from './plugins/axios'
import VideoPlayer from './plugins/video-player'
import i18n from './i18n'
import Permissions from '@/plugins/permissions'
import FeatureFlags from '@/plugins/feature-flags'
import 'normalize.css'
import './assets/tailwind.css'
import 'element-plus/dist/index.css'
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css'
import 'vue-universal-modal/dist/index.css'
import './styles/main.css'
import 'v-calendar/dist/style.css'
import { vueKeycloak } from '@baloise/vue-keycloak'
import { isUnifiedCommerce, isKeycloackEnabled } from '@/constants'
import { userPilotInitialize } from '@/user-pilot'

const emitter = require('tiny-emitter/instance')

import { getLastUpdatedDate } from '@/utils/date/index'
import { keycloakConfig } from './plugins/keycloak'
import { piniaPluginGlobalReset } from '@/store/plugins/global-reset'
import { piniaPluginLogException } from './store/plugins/log-exception'

const isProd = process.env.VUE_APP_PRODUCTION === 'true'
const buildTime = process.env.VUE_APP_BUILD_DATE

function enableTracking() {
  try {
    // window.hj('trigger', 'default_recording')
  } catch (err) {
    Sentry.captureException(err)
  }
}

if (isProd) {
  if (Math.random() > 0.1) enableTracking()
}

if (isProd) {
  Sentry.init({
    release: process.env.VUE_APP_VERSION,
    dsn: 'https://affc40d7bda74c3b8993cee5db131d26@sentry.io/251620',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ]
  })
}

const pinia = createPinia()

export const app = createApp(App)

if (isUnifiedCommerce() && isKeycloackEnabled()) {
  app.use(vueKeycloak, keycloakConfig)
}

auth.getSession()

app.config.productionTip = false
if (isProd) {
  app.config.devtools = true
  app.config.debug = false
  app.config.silent = true
}

app.config.globalProperties.$lastUpdated = getLastUpdatedDate(buildTime, isProd)
// Check if there is a better option than this emitter
app.config.globalProperties.$emitter = emitter

//Register permissions and featureFlags plugins
app.use(Permissions)
app.use(pinia)
app.use(FeatureFlags)
app.use(Element)
app.use(Axios)
app.use(VideoPlayer)
app.use(store)
app.use(router)
app.use(i18n)

//Register components and plugins
registerDependencies(app, { i18n, isProd })

pinia.use(piniaPluginPersistedstate)
pinia.use(piniaPluginGlobalReset).use(piniaPluginLogException)

//Register user pilot
userPilotInitialize()

app.mount('#app')

window.app = app
