import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'

const isDevMode = process.env.NODE_ENV !== 'production'

const middlewares = [createPersistedState({ key: 'main' })]

const store = createStore({
  modules,
  devtools: isDevMode,
  strict: isDevMode,
  plugins: middlewares,
  actions: {
    resetStore({ dispatch }) {
      for (const moduleName of Object.keys(modules)) {
        if (modules[moduleName]?.actions?.reset) {
          dispatch(`${moduleName}/reset`)
        }
      }
    }
  }
})

for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

export default store
