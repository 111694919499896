/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/unzer/Unzer/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'default'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} default
 */
export const ApiKey = {
  default: '1434079e93f193cdf6222fbd3093128d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: '9ff19884-eb5d-4b14-821b-eadef8259072'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export class DiscountCreated {
  constructor(properties) {
    this.event_type = 'discountCreated';
    this.event_properties = properties;
  }
}

export class DocumentBulkDownload {
  constructor(properties) {
    this.event_type = 'documentBulkDownload';
    this.event_properties = properties;
  }
}

export class DocumentBulkSend {
  constructor(properties) {
    this.event_type = 'documentBulkSend';
    this.event_properties = properties;
  }
}

export class DocumentDownload {
  constructor(properties) {
    this.event_type = 'documentDownload';
    this.event_properties = properties;
  }
}

export class DocumentPrint {
  constructor(properties) {
    this.event_type = 'documentPrint';
    this.event_properties = properties;
  }
}

export class DocumentSend {
  constructor(properties) {
    this.event_type = 'documentSend';
    this.event_properties = properties;
  }
}

export class EmployeeServicesUpdated {
  constructor(properties) {
    this.event_type = 'employeeServicesUpdated';
    this.event_properties = properties;
  }
}

export class HomeBestWorstProduClick {
  constructor(properties) {
    this.event_type = 'homeBestWorstProduClick';
    this.event_properties = properties;
  }
}

export class HomeNewDashboardClick {
  constructor(properties) {
    this.event_type = 'homeNewDashboardClick';
    this.event_properties = properties;
  }
}

export class HomeOldDashboardClick {
  constructor(properties) {
    this.event_type = 'homeOldDashboardClick';
    this.event_properties = properties;
  }
}

export class IndividualReservationTimesToggled {
  constructor(properties) {
    this.event_type = 'individualReservationTimesToggled';
    this.event_properties = properties;
  }
}

export class Login {
  constructor() {
    this.event_type = 'login';
  }
}

export class Logout {
  constructor(properties) {
    this.event_type = 'logout';
    this.event_properties = properties;
  }
}

export class NavigationPrimary {
  constructor(properties) {
    this.event_type = 'navigationPrimary';
    this.event_properties = properties;
  }
}

export class NavigationSecondary {
  constructor(properties) {
    this.event_type = 'navigationSecondary';
    this.event_properties = properties;
  }
}

export class OnlineBookingToggled {
  constructor(properties) {
    this.event_type = 'onlineBookingToggled';
    this.event_properties = properties;
  }
}

export class PriceBookCreated {
  constructor(properties) {
    this.event_type = 'priceBookCreated';
    this.event_properties = properties;
  }
}

export class ProductBackButtonClick {
  constructor(properties) {
    this.event_type = 'productBackButtonClick';
    this.event_properties = properties;
  }
}

export class ProductBulkDeleted {
  constructor(properties) {
    this.event_type = 'productBulkDeleted';
    this.event_properties = properties;
  }
}

export class ProductCheckoutQuestionCreated {
  constructor(properties) {
    this.event_type = 'productCheckoutQuestionCreated';
    this.event_properties = properties;
  }
}

export class ProductCreateButtonClick {
  constructor(properties) {
    this.event_type = 'productCreateButtonClick';
    this.event_properties = properties;
  }
}

export class ProductEditButtonClick {
  constructor(properties) {
    this.event_type = 'productEditButtonClick';
    this.event_properties = properties;
  }
}

export class ProductExportButtonClick {
  constructor(properties) {
    this.event_type = 'productExportButtonClick';
    this.event_properties = properties;
  }
}

export class ProductGroupCreated {
  constructor(properties) {
    this.event_type = 'productGroupCreated';
    this.event_properties = properties;
  }
}

export class ProductImportButtonClicked {
  constructor(properties) {
    this.event_type = 'productImportButtonClicked';
    this.event_properties = properties;
  }
}

export class ProductNewButtonClick {
  constructor(properties) {
    this.event_type = 'productNewButtonClick';
    this.event_properties = properties;
  }
}

export class ProductNewClick {
  constructor(properties) {
    this.event_type = 'productNewClick';
    this.event_properties = properties;
  }
}

export class ProductRowClick {
  constructor(properties) {
    this.event_type = 'productRowClick';
    this.event_properties = properties;
  }
}

export class ProductRowDoubleClick {
  constructor(properties) {
    this.event_type = 'productRowDoubleClick';
    this.event_properties = properties;
  }
}

export class ProductSaveButtonClick {
  constructor(properties) {
    this.event_type = 'productSaveButtonClick';
    this.event_properties = properties;
  }
}

export class ProductSectionToggled {
  constructor(properties) {
    this.event_type = 'productSectionToggled';
    this.event_properties = properties;
  }
}

export class ProductSingleDeleted {
  constructor(properties) {
    this.event_type = 'productSingleDeleted';
    this.event_properties = properties;
  }
}

export class ProductTypeTabClick {
  constructor(properties) {
    this.event_type = 'productTypeTabClick';
    this.event_properties = properties;
  }
}

export class ProductVariantAddAttributeButtonClick {
  constructor(properties) {
    this.event_type = 'productVariantAddAttributeButtonClick';
    this.event_properties = properties;
  }
}

export class ProductVariantApplyAttributesButtonClick {
  constructor(properties) {
    this.event_type = 'productVariantApplyAttributesButtonClick';
    this.event_properties = properties;
  }
}

export class ProductVariantTemplateCreated {
  constructor(properties) {
    this.event_type = 'productVariantTemplateCreated';
    this.event_properties = properties;
  }
}

export class PurchaseorderBookinButtonClick {
  constructor(properties) {
    this.event_type = 'purchaseorderBookinButtonClick';
    this.event_properties = properties;
  }
}

export class PurchaseOrderCreateButtonClick {
  constructor(properties) {
    this.event_type = 'purchaseOrderCreateButtonClick';
    this.event_properties = properties;
  }
}

export class PurchaseOrderExportButtonClick {
  constructor(properties) {
    this.event_type = 'purchaseOrderExportButtonClick';
    this.event_properties = properties;
  }
}

export class PurchaseOrderNewButtonClick {
  constructor(properties) {
    this.event_type = 'purchaseOrderNewButtonClick';
    this.event_properties = properties;
  }
}

export class PurchaseOrderSaveButtonClick {
  constructor(properties) {
    this.event_type = 'purchaseOrderSaveButtonClick';
    this.event_properties = properties;
  }
}

export class PurchaseOrderStatusChangeFromList {
  constructor(properties) {
    this.event_type = 'purchaseOrderStatusChangeFromList';
    this.event_properties = properties;
  }
}

export class ReportsExportButtonClick {
  constructor(properties) {
    this.event_type = 'reportsExportButtonClick';
    this.event_properties = properties;
  }
}

export class ReportsFiltersSearchButtonClick {
  constructor(properties) {
    this.event_type = 'reportsFiltersSearchButtonClick';
    this.event_properties = properties;
  }
}

export class ScheduledExportButtonClick {
  constructor(properties) {
    this.event_type = 'scheduledExportButtonClick';
    this.event_properties = properties;
  }
}

export class ServiceCategoryCreated {
  constructor(properties) {
    this.event_type = 'serviceCategoryCreated';
    this.event_properties = properties;
  }
}

export class ServiceCategoryDeleted {
  constructor(properties) {
    this.event_type = 'serviceCategoryDeleted';
    this.event_properties = properties;
  }
}

export class ServiceCategoryUpdated {
  constructor(properties) {
    this.event_type = 'serviceCategoryUpdated';
    this.event_properties = properties;
  }
}

export class ServiceCreated {
  constructor(properties) {
    this.event_type = 'serviceCreated';
    this.event_properties = properties;
  }
}

export class ServiceDeleted {
  constructor(properties) {
    this.event_type = 'serviceDeleted';
    this.event_properties = properties;
  }
}

export class ServiceUpdated {
  constructor(properties) {
    this.event_type = 'serviceUpdated';
    this.event_properties = properties;
  }
}

export class ShiftPlanToggled {
  constructor(properties) {
    this.event_type = 'shiftPlanToggled';
    this.event_properties = properties;
  }
}

export class StatisticsExportButtonClick {
  constructor(properties) {
    this.event_type = 'statisticsExportButtonClick';
    this.event_properties = properties;
  }
}

export class StatisticsFiltersSearchButtonClick {
  constructor(properties) {
    this.event_type = 'statisticsFiltersSearchButtonClick';
    this.event_properties = properties;
  }
}

export class StockmgmtBookinOutTabClick {
  constructor(properties) {
    this.event_type = 'stockmgmtBookinOutTabClick';
    this.event_properties = properties;
  }
}

export class StockmgmtBookinTabClick {
  constructor(properties) {
    this.event_type = 'stockmgmtBookinTabClick';
    this.event_properties = properties;
  }
}

export class StockmgmtBookOutButtonClick {
  constructor(properties) {
    this.event_type = 'stockmgmtBookOutButtonClick';
    this.event_properties = properties;
  }
}

export class StockmgmtCreateButtonClick {
  constructor(properties) {
    this.event_type = 'stockmgmtCreateButtonClick';
    this.event_properties = properties;
  }
}

export class StockmgmtRelocateButtonClick {
  constructor(properties) {
    this.event_type = 'stockmgmtRelocateButtonClick';
    this.event_properties = properties;
  }
}

export class StockmgmtRelocateTabClick {
  constructor(properties) {
    this.event_type = 'stockmgmtRelocateTabClick';
    this.event_properties = properties;
  }
}

export class SupplierCreateButtonClick {
  constructor(properties) {
    this.event_type = 'supplierCreateButtonClick';
    this.event_properties = properties;
  }
}

export class SupplierExportButtonClick {
  constructor(properties) {
    this.event_type = 'supplierExportButtonClick';
    this.event_properties = properties;
  }
}

export class SupplierImportButtonClicked {
  constructor(properties) {
    this.event_type = 'supplierImportButtonClicked';
    this.event_properties = properties;
  }
}

export class SupplierNewButtonClick {
  constructor(properties) {
    this.event_type = 'supplierNewButtonClick';
    this.event_properties = properties;
  }
}

export class SupplierRowClick {
  constructor(properties) {
    this.event_type = 'supplierRowClick';
    this.event_properties = properties;
  }
}

export class SupplierSaveButtonClick {
  constructor(properties) {
    this.event_type = 'supplierSaveButtonClick';
    this.event_properties = properties;
  }
}

export class TagCreated {
  constructor(properties) {
    this.event_type = 'tagCreated';
    this.event_properties = properties;
  }
}

export class Test {
  constructor(properties) {
    this.event_type = 'Test';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * discountCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/discountCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string[]} [properties.discount_product_groups] Property has no description in tracking plan.
   * @param {string[]} [properties.discount_products] Property has no description in tracking plan.
   * @param {string[]} [properties.discount_taxes] Property has no description in tracking plan.
   * @param {string} [properties.discount_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  discountCreated(properties, options) {
    return this.track(new DiscountCreated(properties), options);
  }

  /**
   * documentBulkDownload
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/documentBulkDownload)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  documentBulkDownload(properties, options) {
    return this.track(new DocumentBulkDownload(properties), options);
  }

  /**
   * documentBulkSend
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/documentBulkSend)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  documentBulkSend(properties, options) {
    return this.track(new DocumentBulkSend(properties), options);
  }

  /**
   * documentDownload
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/documentDownload)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  documentDownload(properties, options) {
    return this.track(new DocumentDownload(properties), options);
  }

  /**
   * documentPrint
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/documentPrint)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  documentPrint(properties, options) {
    return this.track(new DocumentPrint(properties), options);
  }

  /**
   * documentSend
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/documentSend)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  documentSend(properties, options) {
    return this.track(new DocumentSend(properties), options);
  }

  /**
   * employeeServicesUpdated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/employeeServicesUpdated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  employeeServicesUpdated(properties, options) {
    return this.track(new EmployeeServicesUpdated(properties), options);
  }

  /**
   * homeBestWorstProduClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/homeBestWorstProduClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  homeBestWorstProduClick(properties, options) {
    return this.track(new HomeBestWorstProduClick(properties), options);
  }

  /**
   * homeNewDashboardClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/homeNewDashboardClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  homeNewDashboardClick(properties, options) {
    return this.track(new HomeNewDashboardClick(properties), options);
  }

  /**
   * homeOldDashboardClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/homeOldDashboardClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  homeOldDashboardClick(properties, options) {
    return this.track(new HomeOldDashboardClick(properties), options);
  }

  /**
   * individualReservationTimesToggled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/individualReservationTimesToggled)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {boolean} properties.is_activated Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  individualReservationTimesToggled(properties, options) {
    return this.track(new IndividualReservationTimesToggled(properties), options);
  }

  /**
   * login
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/login)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  login(options) {
    return this.track(new Login(), options);
  }

  /**
   * logout
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/logout)
   *
   * This event tracks when a user logs out of the Tillhub Dashboard
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  logout(properties, options) {
    return this.track(new Logout(properties), options);
  }

  /**
   * navigationPrimary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/navigationPrimary)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.navigation_to] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  navigationPrimary(properties, options) {
    return this.track(new NavigationPrimary(properties), options);
  }

  /**
   * navigationSecondary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/navigationSecondary)
   *
   * This event tracks when a user navigates to a secondary page or section within the application
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.navigation_to] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  navigationSecondary(properties, options) {
    return this.track(new NavigationSecondary(properties), options);
  }

  /**
   * onlineBookingToggled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/onlineBookingToggled)
   *
   * This event tracks when a user toggles the shift plan feature in the Tillhub Dashboard
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {boolean} properties.is_activated Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  onlineBookingToggled(properties, options) {
    return this.track(new OnlineBookingToggled(properties), options);
  }

  /**
   * priceBookCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/priceBookCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  priceBookCreated(properties, options) {
    return this.track(new PriceBookCreated(properties), options);
  }

  /**
   * productBackButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productBackButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productBackButtonClick(properties, options) {
    return this.track(new ProductBackButtonClick(properties), options);
  }

  /**
   * productBulkDeleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productBulkDeleted)
   *
   * This event tracks when multiple products in the product manager are deleted in bulk from the system
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productBulkDeleted(properties, options) {
    return this.track(new ProductBulkDeleted(properties), options);
  }

  /**
   * productCheckoutQuestionCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productCheckoutQuestionCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productCheckoutQuestionCreated(properties, options) {
    return this.track(new ProductCheckoutQuestionCreated(properties), options);
  }

  /**
   * productCreateButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productCreateButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productCreateButtonClick(properties, options) {
    return this.track(new ProductCreateButtonClick(properties), options);
  }

  /**
   * productEditButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productEditButtonClick)
   *
   * This buttons is inside product preview dialog. You can see it when clicking on a product row
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productEditButtonClick(properties, options) {
    return this.track(new ProductEditButtonClick(properties), options);
  }

  /**
   * productExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productExportButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productExportButtonClick(properties, options) {
    return this.track(new ProductExportButtonClick(properties), options);
  }

  /**
   * productGroupCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productGroupCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productGroupCreated(properties, options) {
    return this.track(new ProductGroupCreated(properties), options);
  }

  /**
   * productImportButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productImportButtonClicked)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productImportButtonClicked(properties, options) {
    return this.track(new ProductImportButtonClicked(properties), options);
  }

  /**
   * productNewButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productNewButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productNewButtonClick(properties, options) {
    return this.track(new ProductNewButtonClick(properties), options);
  }

  /**
   * productNewClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productNewClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productNewClick(properties, options) {
    return this.track(new ProductNewClick(properties), options);
  }

  /**
   * productRowClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productRowClick)
   *
   * This event tracks when a user clicks on a product row on the product table in the Tillhub Dashboard
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productRowClick(properties, options) {
    return this.track(new ProductRowClick(properties), options);
  }

  /**
   * productRowDoubleClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productRowDoubleClick)
   *
   * This event tracks when a user double-clicks on a product row in the products table in the Tillhub Dashboard
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productRowDoubleClick(properties, options) {
    return this.track(new ProductRowDoubleClick(properties), options);
  }

  /**
   * productSaveButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productSaveButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productSaveButtonClick(properties, options) {
    return this.track(new ProductSaveButtonClick(properties), options);
  }

  /**
   * productSectionToggled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productSectionToggled)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {boolean} [properties.section_collapsed] Property has no description in tracking plan.
   * @param {string} [properties.section_name] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productSectionToggled(properties, options) {
    return this.track(new ProductSectionToggled(properties), options);
  }

  /**
   * productSingleDeleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productSingleDeleted)
   *
   * This event tracks when a single product is deleted from product manager
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productSingleDeleted(properties, options) {
    return this.track(new ProductSingleDeleted(properties), options);
  }

  /**
   * productTypeTabClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productTypeTabClick)
   *
   * This event tracks when a user clicks on a specific product type tab in the Tillhub Dashboard (**Standard Product, Product with Variants, Composed Product, Ingredient)**
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_type_tab_name] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productTypeTabClick(properties, options) {
    return this.track(new ProductTypeTabClick(properties), options);
  }

  /**
   * productVariantAddAttributeButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productVariantAddAttributeButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productVariantAddAttributeButtonClick(properties, options) {
    return this.track(new ProductVariantAddAttributeButtonClick(properties), options);
  }

  /**
   * productVariantApplyAttributesButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productVariantApplyAttributesButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.product_variants_applied_attributes_json] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productVariantApplyAttributesButtonClick(properties, options) {
    return this.track(new ProductVariantApplyAttributesButtonClick(properties), options);
  }

  /**
   * productVariantTemplateCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/productVariantTemplateCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  productVariantTemplateCreated(properties, options) {
    return this.track(new ProductVariantTemplateCreated(properties), options);
  }

  /**
   * purchaseorderBookinButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseorderBookinButtonClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseorderBookinButtonClick(properties, options) {
    return this.track(new PurchaseorderBookinButtonClick(properties), options);
  }

  /**
   * purchaseOrderCreateButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseOrderCreateButtonClick)
   *
   * This event tracks when a user clicks on the "Create Purchase Order" button in purchase order section
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseOrderCreateButtonClick(properties, options) {
    return this.track(new PurchaseOrderCreateButtonClick(properties), options);
  }

  /**
   * purchaseOrderExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseOrderExportButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseOrderExportButtonClick(properties, options) {
    return this.track(new PurchaseOrderExportButtonClick(properties), options);
  }

  /**
   * purchaseOrderNewButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseOrderNewButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseOrderNewButtonClick(properties, options) {
    return this.track(new PurchaseOrderNewButtonClick(properties), options);
  }

  /**
   * purchaseOrderSaveButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseOrderSaveButtonClick)
   *
   * This event tracks when a user clicks the "Save" button after creating or editing a purchase order
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseOrderSaveButtonClick(properties, options) {
    return this.track(new PurchaseOrderSaveButtonClick(properties), options);
  }

  /**
   * purchaseOrderStatusChangeFromList
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/purchaseOrderStatusChangeFromList)
   *
   * This event tracks when the status of a purchase order in the purcahse order table/overview is changed from a list of available options
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  purchaseOrderStatusChangeFromList(properties, options) {
    return this.track(new PurchaseOrderStatusChangeFromList(properties), options);
  }

  /**
   * reportsExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/reportsExportButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  reportsExportButtonClick(properties, options) {
    return this.track(new ReportsExportButtonClick(properties), options);
  }

  /**
   * reportsFiltersSearchButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/reportsFiltersSearchButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  reportsFiltersSearchButtonClick(properties, options) {
    return this.track(new ReportsFiltersSearchButtonClick(properties), options);
  }

  /**
   * scheduledExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/scheduledExportButtonClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.client_id] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {string} [properties.export_document_type] Property has no description in tracking plan.
   * @param {string} [properties.export_recurring] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  scheduledExportButtonClick(properties, options) {
    return this.track(new ScheduledExportButtonClick(properties), options);
  }

  /**
   * serviceCategoryCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceCategoryCreated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceCategoryCreated(properties, options) {
    return this.track(new ServiceCategoryCreated(properties), options);
  }

  /**
   * serviceCategoryDeleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceCategoryDeleted)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceCategoryDeleted(properties, options) {
    return this.track(new ServiceCategoryDeleted(properties), options);
  }

  /**
   * serviceCategoryUpdated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceCategoryUpdated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceCategoryUpdated(properties, options) {
    return this.track(new ServiceCategoryUpdated(properties), options);
  }

  /**
   * serviceCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceCreated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceCreated(properties, options) {
    return this.track(new ServiceCreated(properties), options);
  }

  /**
   * serviceDeleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceDeleted)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceDeleted(properties, options) {
    return this.track(new ServiceDeleted(properties), options);
  }

  /**
   * serviceUpdated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/serviceUpdated)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  serviceUpdated(properties, options) {
    return this.track(new ServiceUpdated(properties), options);
  }

  /**
   * shiftPlanToggled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/shiftPlanToggled)
   *
   * This event tracks when a user toggles the shift plan feature in the Tillhub Dashboard
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {boolean} properties.is_activated Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  shiftPlanToggled(properties, options) {
    return this.track(new ShiftPlanToggled(properties), options);
  }

  /**
   * statisticsExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/statisticsExportButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  statisticsExportButtonClick(properties, options) {
    return this.track(new StatisticsExportButtonClick(properties), options);
  }

  /**
   * statisticsFiltersSearchButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/statisticsFiltersSearchButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  statisticsFiltersSearchButtonClick(properties, options) {
    return this.track(new StatisticsFiltersSearchButtonClick(properties), options);
  }

  /**
   * stockmgmtBookinOutTabClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtBookinOutTabClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtBookinOutTabClick(properties, options) {
    return this.track(new StockmgmtBookinOutTabClick(properties), options);
  }

  /**
   * stockmgmtBookinTabClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtBookinTabClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtBookinTabClick(properties, options) {
    return this.track(new StockmgmtBookinTabClick(properties), options);
  }

  /**
   * stockmgmtBookOutButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtBookOutButtonClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtBookOutButtonClick(properties, options) {
    return this.track(new StockmgmtBookOutButtonClick(properties), options);
  }

  /**
   * stockmgmtCreateButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtCreateButtonClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtCreateButtonClick(properties, options) {
    return this.track(new StockmgmtCreateButtonClick(properties), options);
  }

  /**
   * stockmgmtRelocateButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtRelocateButtonClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtRelocateButtonClick(properties, options) {
    return this.track(new StockmgmtRelocateButtonClick(properties), options);
  }

  /**
   * stockmgmtRelocateTabClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/stockmgmtRelocateTabClick)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  stockmgmtRelocateTabClick(properties, options) {
    return this.track(new StockmgmtRelocateTabClick(properties), options);
  }

  /**
   * supplierCreateButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierCreateButtonClick)
   *
   * This event tracks when a user clicks on the "Create" button to add a new supplier under supplier management
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierCreateButtonClick(properties, options) {
    return this.track(new SupplierCreateButtonClick(properties), options);
  }

  /**
   * supplierExportButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierExportButtonClick)
   *
   * This event tracks when a user clicks on the "Export" button to export supplier information in the supplier management section
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierExportButtonClick(properties, options) {
    return this.track(new SupplierExportButtonClick(properties), options);
  }

  /**
   * supplierImportButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierImportButtonClicked)
   *
   * This event tracks when a user clicks on the "Export" button to export supplier information in the supplier management section
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierImportButtonClicked(properties, options) {
    return this.track(new SupplierImportButtonClicked(properties), options);
  }

  /**
   * supplierNewButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierNewButtonClick)
   *
   * This event tracks when a user clicks on the "New Supplier" button in supplier management in order to create  a new supplier object
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierNewButtonClick(properties, options) {
    return this.track(new SupplierNewButtonClick(properties), options);
  }

  /**
   * supplierRowClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierRowClick)
   *
   * This event tracks when a user clicks on a row in the supplier table, which leads the user to detail info on that supplier
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierRowClick(properties, options) {
    return this.track(new SupplierRowClick(properties), options);
  }

  /**
   * supplierSaveButtonClick
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/supplierSaveButtonClick)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  supplierSaveButtonClick(properties, options) {
    return this.track(new SupplierSaveButtonClick(properties), options);
  }

  /**
   * tagCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/tagCreated)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_primary] Property has no description in tracking plan.
   * @param {string} [properties.current_navigation_secondary] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  tagCreated(properties, options) {
    return this.track(new TagCreated(properties), options);
  }

  /**
   * Test
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/unzer/Unzer/events/main/latest/Test)
   *
   * Owner: Andrei Solodovnikov
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.account_mode] Property has no description in tracking plan.
   * @param {string} [properties.current_branch] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  test(properties, options) {
    return this.track(new Test(properties), options);
  }
}

export const ampli = new Ampli();
