import pRetry from 'p-retry'
import typeOf from 'just-typeof'

/**
 * Determines if async call should be retried based on the provided retry options object
 * @param {Function} asyncFn
 * @param {Object} retryOptions
 * @returns {Promise}
 */
export function makeAsyncCall(asyncFn, retryOptions) {
  if (typeof asyncFn !== 'function') return undefined
  if (
    retryOptions &&
    typeOf(retryOptions) === 'object' &&
    Object.keys(retryOptions).length
  ) {
    return pRetry(asyncFn, retryOptions)
  }
  return asyncFn()
}
