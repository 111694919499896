<template>
  <div class="th-popover">
    <el-popover
      trigger="click"
      :title="title"
      :placement="placement"
      width="auto"
    >
      <template #reference>
        <button
          type="button"
          class="rounded-full outline-none focus:shadow-outline"
        >
          <slot name="icon" />
          <svgicon
            v-if="!$slots.icon"
            :src="require('@/assets/icons/th-icon-info-bg-white.svg')"
            class="block"
            :style="{
              height: '16px',
              width: '16px',
              fill: 'var(--popover-color)'
            }"
          />
        </button>
      </template>
      <div
        v-if="text"
        class="text-left max-w-sm break-normal bg-white"
        :class="{ italic: !isWhiteLabel }"
      >
        <!-- Supporting new line -->
        <span v-for="(line, lineNumber) of text.split('\n')" :key="lineNumber">
          {{ line }}<br />
        </span>
      </div>
      <template v-if="$slots.default">
        <slot />
      </template>
      <template v-if="content.src">
        <iframe
          :width="content.width || '500'"
          :height="content.height || '311'"
          v-bind="content"
        />
      </template>
    </el-popover>
  </div>
</template>

<script>
import { isUnifiedCommerce } from '@/constants'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  }
}
</script>

<style scoped>
.th-popover {
  line-height: 0;
}
</style>
