<template>
  <div class="text-left" style="max-width: 10rem">
    <div v-if="isProd && title.version">v{{ title.version }}</div>
    <div v-else>
      <div>{{ $t('common.footer.last_updated.label') }}:</div>
      <div :title="title.date">{{ title.timeAgo }}</div>
    </div>
  </div>
</template>

<script>
import { format, formatDistanceToNow } from 'date-fns'
import { enUS, de, cs, es, it } from 'date-fns/locale'

export default {
  data() {
    return {
      isProd: process.env.VUE_APP_PRODUCTION === 'true'
    }
  },
  computed: {
    title() {
      if (this.isProd) return { version: process.env.VUE_APP_VERSION }
      return this.getLastUpdated()
    }
  },
  methods: {
    getLastUpdated() {
      // for testing, fallback to current time
      const lastUpdated = this.$lastUpdated || new Date()
      const date = format(new Date(lastUpdated), 'dd-MM-yy, HH:mm')
      const localUsed = this.matchCorrectLocale(this.$i18n.locale)
      const timeAgo = formatDistanceToNow(new Date(lastUpdated), {
        addSuffix: true,
        locale: localUsed
      })

      return {
        timeAgo,
        date
      }
    },
    matchCorrectLocale(locale) {
      if (locale.includes('de')) {
        return de
      } else if (locale.includes('es')) {
        return es
      } else if (locale.includes('it')) {
        return it
      } else if (locale.includes('cs')) {
        return cs
      } else {
        return enUS
      }
    }
  }
}
</script>
