import safeGet from 'just-safe-get'

export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  return [
    {
      value: '/reports/statistics/revenues',
      key: 'pages.reports.statistics.revenues.revenues',
      enabled: !!safeGet(featureConfig, 'reports.statistics.revenues'),
      scopes: ['reports_statistics:revenues:read']
    },
    {
      value: '/reports/statistics/products',
      key: 'pages.reports.statistics.products.products',
      enabled: !!safeGet(featureConfig, 'reports.statistics.products'),
      scopes: ['reports_statistics:products:read']
    },
    {
      value: '/reports/statistics/product_groups',
      key: 'pages.reports.statistics.productGroups.productGroups',
      enabled: !!safeGet(featureConfig, 'reports.statistics.product_groups'),
      scopes: ['reports_statistics:product_groups:read']
    },
    {
      value: '/reports/statistics/staff',
      key: 'pages.reports.statistics.staff.staff',
      enabled: !!safeGet(featureConfig, 'reports.statistics.staff'),
      scopes: ['reports_statistics:staff:read']
    },
    {
      value: '/reports/statistics/customers',
      key: 'pages.reports.statistics.customers.customers',
      enabled: !!safeGet(featureConfig, 'customers.crm'),
      scopes: ['reports_statistics:customers:read']
    },
    {
      value: '/reports/statistics/sold_cart_items',
      key: 'pages.reports.statistics.sold_cart_items.title',
      enabled: !!safeGet(featureConfig, 'reports.statistics.sold_cart_items'),
      scopes: ['reports_statistics:sold_cart_items:read']
    },
    {
      value: '/reports/statistics/vouchers',
      key: 'pages.reports.statistics.vouchers.vouchers',
      enabled:
        !!safeGet(featureConfig, 'reports.statistics.vouchers') && !!safeGet,
      scopes: ['reports_statistics:vouchers:read']
    },
    {
      value: '/reports/statistics/discounts',
      key: 'pages.reports.statistics.discounts.discounts',
      enabled:
        !!safeGet(featureConfig, 'reports.statistics.discounts') && !!safeGet,
      scopes: ['reports_statistics:discounts:read']
    },
    {
      value: '/reports/statistics/stock',
      key: 'pages.reports.statistics.stocks.stocks',
      enabled: !!safeGet(featureConfig, 'reports.statistics.stocks'),
      scopes: ['reports_statistics:stocks:read']
    }
  ]
}
