import { defineStore } from 'pinia'
import { setCSSVariablesWhitelabel } from './helpers/app-config'
import { isUnifiedCommerce, unifiedCommerceWhitelabel } from '@/constants'
import { getTXTs, parseTXTs } from '@/plugins/dns-config'

export const useWhitelabelThemeStore = defineStore('whitelabelTheme', {
  state() {
    return {
      customWallpaper: undefined,
      customBGColor: undefined,
      customWelcomeBGColor: undefined,
      customWelcomeLogo: undefined,
      customLogo: undefined,
      customLogoCollapsed: undefined
    }
  },
  globalReset: false, // Disable global reset for this store.
  actions: {
    async fetchDNSConfig() {
      this.$reset()
      try {
        let txtConfig = null
        // For whitelabel unified commerce we have the variables hardcoded in a file
        if (isUnifiedCommerce()) {
          txtConfig = unifiedCommerceWhitelabel
        } else {
          const txts = await getTXTs()
          if (!txts) return

          txtConfig = parseTXTs(txts)

          if (!txtConfig) return
        }

        // we allow overriding with null or undefined for the user to recover from bad state
        this.$patch({
          customWallpaper: txtConfig.dashboard_welcome_bg_image,
          customBGColor: txtConfig.dashboard_bg_color,
          customWelcomeBGColor: txtConfig.dashboard_welcome_bg_color,
          customWelcomeLogo: txtConfig.dashboard_welcome_logo,
          customLogo: txtConfig.dashboard_logo,
          customLogoCollapsed: txtConfig.dashboard_logo_callapsed
        })

        // Replace css variables
        setCSSVariablesWhitelabel(txtConfig.css_variables)
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    }
  }
})
