import { ampliPlugin } from '@/plugins/ampli'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import pRetry from 'p-retry'
import auth from '../../plugins/auth'
import { license } from '@/constants'
import { isUnifiedCommerce, isKeycloackEnabled } from '@/constants/index'
import { useAppConfigStore } from '../app-config'
import { useMessagesStore } from '../messages'

const initialState = () => ({
  // the tillhubClientAccount
  authenticated: false,
  me: null,
  user: null,
  token: null,
  imageUrl: null,
  email: null,
  username: null,
  accountName: null,
  orgName: null,
  expiresAt: null,
  isSupport: null,
  features: {},
  role: null,
  staff: null,
  scopes: [],
  // the value from sub_user
  thUserId: null,
  // only these locations are allowed. If null none have been specified and we assume non-shall be restricted
  locations: null,
  whitelabel: null,
  isThInitialized: false
})

const state = initialState()

// getters
const getters = {
  isAuthenticated: (state) => state.authenticated,
  getMe: (state) => state.me,
  getAuthToken: (state) => state.token,
  getUser: (state) => state.user,
  getUserScopes: (state) => state.scopes,
  getUserRole: (state) => state.role,
  getImageUrl: (state) => state.imageUrl,
  getEmail: (state) => state.email,
  getUserAssignedLocations: (state) => state.locations,
  // TODO: refactor once we have proper users
  isAdmin: (state) => !state.thUserId,
  isStaff: (state) => !!state.staff,
  isSupport: (state) => !!state.isSupport,
  isGastro: (state) =>
    safeGet(state, 'me.user.licenses', [])?.includes(license.GASTRO),
  isLitePos: (state) =>
    safeGet(state, 'me.user.licenses', [])?.includes(license.LITEPOS)
}

async function setAmpliLocationProperties(branchId, vm) {
  try {
    const { data } = await th.branchesV1().get(branchId)
    if (!data) {
      return
    }
    const branchCity = safeGet(data, 'addresses.0.locality')
    const branchCountry = safeGet(data, 'addresses.0.country')
    ampliPlugin.setProperty('branch_number', data.branch_number)
    ampliPlugin.setProperty('branch_name', data.name)
    if (branchCity) {
      ampliPlugin.setProperty('branch_city', branchCity)
    }
    if (branchCountry) {
      ampliPlugin.setProperty('branch_country', branchCountry)
    }
  } catch (err) {
    vm.$logException(err, { trackError: false })
  }
}

// Used in case of support users login. Extracts credentials from the URL coming from Endor.
async function getSupportUserCredentials(commit) {
  const url = new URL(window.location.href)
  // Extract client_account and support_token parameters from the URL
  const params = new URLSearchParams(url.search)
  const clientAccountParam = params.get('client_account')
  const supportTokenParam = params.get('support_token')

  const isSupportUserUFC =
    isUnifiedCommerce() && clientAccountParam && supportTokenParam

  if (isSupportUserUFC) {
    commit('setUser', clientAccountParam)
    commit('setIsSupport', true)
  }
}

// actions
const actions = {
  async getAuthToken(
    { commit, dispatch, state },
    { username, password, organisation, recaptchaToken }
  ) {
    try {
      const {
        token,
        expiresAt,
        user,
        features,
        name,
        role,
        scopes,
        subUser,
        errors,
        orgName,
        whitelabel
      } = await auth.login({
        username,
        password,
        organisation,
        recaptchaToken
      })

      const subUserDisabled =
        Array.isArray(errors) && errors.length
          ? errors.find(
              (err) => err.label === 'users.secondary.errors.sub_user.inactive'
            )
          : false

      if (subUserDisabled) {
        throw new Error(subUserDisabled.label)
      }

      const appConfigStore = useAppConfigStore()
      dispatch('Auth/clearLocationState', null, { root: true })
      dispatch('Config/clearLocationState', null, { root: true })
      commit('addAuthToken', token)
      commit('setUser', user)
      commit('setAccountName', name)
      commit('setOrgName', orgName)
      commit('setExpiresAt', expiresAt)
      commit('setUsername', username)
      //commit('setFeatures', features)
      commit('setWhitelabel', whitelabel)
      appConfigStore.setFeatureFlagsOnConfig(features, whitelabel)
      commit('setRole', role)
      //commit('SET_SCOPES', scopes)
      commit('SET_LOCATIONS', safeGet(subUser, 'locations'))
      commit('SET_TH_USER_ID', safeGet(subUser, 'id'))
      commit('setIsSupport', null)
      await auth.handleAuthentication({ token, expiresAt, user })
      return [null, { token, user }]
    } catch (err) {
      commit('addAuthToken', null)
      return [err]
    }
  },
  async getSupportAuthToken(
    { commit, dispatch, state },
    { token: supportToken, clientAccount }
  ) {
    try {
      const {
        token,
        expiresAt,
        user,
        features,
        name,
        username,
        role,
        scopes
      } = await auth.loginAsSupport({
        token: supportToken,
        clientAccount: clientAccount
      })

      const appConfigStore = useAppConfigStore()

      commit('addAuthToken', token)
      commit('setUser', user)
      commit('setAccountName', name)
      commit('setUsername', username)
      commit('setFeatures', features)
      appConfigStore.setFeatureFlagsOnConfig(features, state.whitelabel)
      commit('setRole', role)
      //commit('SET_SCOPES', scopes)
      commit('setIsSupport', true)
      await auth.handleAuthentication({ token, expiresAt, user })
      return [null, { token, user }]
    } catch (err) {
      commit('addAuthToken', null)
      return [err]
    }
  },
  logout({ commit }) {
    //commit('setAuthenticated', false)
    //commit('CLEAR_PERSISTENCE')
    //auth.logout()
  },
  clearStateOnLogin({ commit, dispatch }) {
    try {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
    } catch (err) {
      // no-op
    }
    commit('addAuthToken', null)
    commit('setUser', null)
    dispatch('clearLocationState')
  },

  clearLocationState({ commit }) {
    commit('SET_LOCATIONS', null)
  },

  // Function that waits till th is initilized
  async waitThInitializated({ state }) {
    return new Promise(function (resolve) {
      ;(function waitForTHInitializated() {
        if (state.isThInitialized) return resolve()
        setTimeout(waitForTHInitializated, 30)
      })()
    })
  },

  async getMeData({ state, commit, dispatch, getters }, { vm } = {}) {
    if (!getters.isAuthenticated) return
    try {
      await getSupportUserCredentials(commit)

      const { data, errors } = await pRetry(
        () => th.me().get(getters.isSupport ? getters.getUser : null),
        {
          retries: 3,
          onFailedAttempt: async (error) => {
            const errorCode = safeGet(error, 'properties.error.response.status')
            if (errorCode === 401) {
              vm.$router.replace('/logout')
              throw new pRetry.AbortError('failed to fetch me data')
            }
          }
        }
      )

      commit('setMe', data)

      // On keycloak login we don't have the client id, so we are retrieving it from the me call
      if (isUnifiedCommerce() && isKeycloackEnabled()) {
        commit('setUser', data.user.id)
        commit('setAccountName', data.user.display_name)
        //commit('setRole', data.user.role)
        // commit('SET_SCOPES', data.user.scopes)
        commit('setRole', 'owner')
        // commit('SET_SCOPES', ['all'])
        commit('SET_SCOPES', 'admin')
        /*const scopes = [
          'reports_orders',
          'orders_transactions',
          'settings:dashboard',
          'utilities:export_history',
          'orders_transactions:export',
          'reports_orders:export',
          'resources:branch_groups',
          'resources:branches',
          'settings:api_key_pairs',
          'settings:invoices',
          'settings:users',
          'customers',
          'products:read',
          'products:update',
          'products:create',
          'products:product_groups',
          'products:discounts',
          'products:pricebooks',
          'products:tags',
          'staff',
          'reservations'
        ]
        commit('SET_SCOPES', scopes)*/
        const features = {
          // This flag can be used in cases where a feature that is currently being
          // developed, but is deployed to staging, needs to be hidden in production.
          development: false,
          login: {
            password_forgotten: true
          },
          orders: {
            reports_orders: isUnifiedCommerce(),
            orders_transactions: isUnifiedCommerce()
          },
          reports: {
            financial_accounting: {
              transactions: true,
              balances: true,
              cash_book: true,
              returns: true,
              payment_options: true,
              payments: true,
              vat: true,
              counting_protocols: true
            },
            statistics: {
              revenues: true,
              products: true,
              product_groups: true,
              staff: true,
              customers: true,
              vouchers: true,
              discounts: true,
              stocks: true,
              sold_cart_items: true
            },
            exports: true
          },
          settings: {
            general: {
              integrations: true,
              api_keys: true
            },
            pos: {
              pagers: true,
              kitchen_printers: true
            },
            timetracking: true,
            users: true
          },
          products: {
            manager: true,
            label_printing: true,
            product_groups: true,
            product_templates: true,
            addon_groups: true,
            discounts: true,
            pricebooks: true,
            tags: true,
            promotions: true,
            service: {
              questions: true
            }
          },
          inventory: {
            stock_manager: {
              goods_in: true,
              goods_out: true,
              relocation: true
            },
            stock_movements: true,
            reasons: true,
            processes: true,
            stock_takings: true
          },
          accounting: {
            taxes: true,
            accounts: true,
            expense_accounts: true,
            payment_options: true,
            safe_management: true
          },
          customers: {
            crm: true,
            customer: {
              notes: true,
              contact: true,
              customer_details: true
            }
          },
          staff: true,
          reservations: true,
          suppliers: true,
          documents: true,
          loyalty: {
            vouchers: true,
            voucher_systems: true,
            voucher_systems_v2: true,
            abocard: true
          },
          timetracking: true,
          webhooks: true,
          utilities: {
            document_designer: true,
            custom_dashboards: true,
            audits: {
              actions: true,
              logs: true
            },
            cms: {
              contents: true,
              content_templates: true
            },
            notifications: true,
            favourites: true
          },
          resources: {
            branches: true,
            branch_groups: true,
            warehouses: true,
            registers: true,
            licenses: true,
            devices: true,
            device_groups: true
          },
          misc: {
            // individual features that are not related to a specific page/category
            datev: true,
            fiscalisation: true,
            gastro_tables: true,
            takeaway: true,
            globalSearch: true
          }
        }
        //commit('setFeatures', data.user.features)
        commit('setFeatures', features)
        commit('setUsername', data.user.username)
        window.localStorage.setItem('user', data.user.id)
      }

      const connectedUserId = safeGet(data, 'user.connected_staff')?.[0]?.user
      if (connectedUserId) {
        ampliPlugin.setUser(connectedUserId)
      } else {
        ampliPlugin.setUser(data.user.id)
      }
      ampliPlugin.setProperty('merchant_display_name', data.user.display_name)

      if (data.user.features) {
        //commit('setFeatures', data.user.features)
        const appConfigStore = useAppConfigStore()
        appConfigStore.setFeatureFlagsOnConfig(
          data.user.features,
          data.user.whitelabel
        )
      }
      if (data.user.name) commit('setAccountName', data.user.name)
      if (data.user.display_name) commit('setOrgName', data.user.display_name)
      if (data.user.whitelabel) commit('setWhitelabel', data.user.whitelabel)
      if (data.role) {
        commit('setRole', data.role)
        ampliPlugin.setProperty('role', data.user.role)
      }

      const userScopes = safeGet(data, 'user.scopes') || []
      const staffScopes = (safeGet(data, 'user.connected_staff') || []).reduce(
        (accScopes, item) => accScopes.concat(item.scopes || []),
        []
      )
      const connectedStaffId =
        safeGet(data, 'user.connected_staff')?.[0]?.id || null
      commit('setStaff', connectedStaffId)
      if (connectedStaffId) {
        ampliPlugin.setProperty('staff_id', connectedStaffId)
      }

      const uniqueScopes = [...new Set([...userScopes, ...staffScopes])]
      //commit('SET_SCOPES', uniqueScopes)

      const userLocations = safeGet(data, 'user.locations')

      const userIsEverywhere = userLocations === null
      const userLocationsArray =
        !userIsEverywhere && Array.isArray(userLocations) ? userLocations : []

      const staffLocations = (
        safeGet(data, 'user.connected_staff') || []
      ).reduce(
        (accLocations, item) => accLocations.concat(item.locations || []),
        []
      )

      const uniqueLocations = [
        ...new Set([...userLocationsArray, ...staffLocations])
      ]

      // if we are not a subuser or manager without locations let's permit everyting for now.
      commit(
        'SET_LOCATIONS',
        getters.isAdmin || getters.isSupport || userIsEverywhere
          ? null
          : uniqueLocations
      )

      if (uniqueLocations[0]) {
        await setAmpliLocationProperties(uniqueLocations[0], vm)
      }

      if (errors && errors.length) {
        const messagesStore = useMessagesStore()
        errors.forEach((errorObj) => {
          messagesStore.setLocalMessage({
            id: errorObj.id,
            label: errorObj.label,
            operation: 'local_message',
            payload: errorObj.errorDetails
          })
        })
      }
    } catch (err) {
      if (!vm) return
      const errorCode = safeGet(err, 'properties.error.response.status')
      if (errorCode === 401) {
        vm.$router.replace('/logout')
      }
      vm.$logException(err, { trackError: false })
    }
  },
  reset({ commit }) {
    commit('RESET')
  }
}

// mutations
const mutations = {
  setAuthenticated(state, isAuthenticated) {
    state.authenticated = isAuthenticated
  },
  addAuthToken(state, token) {
    state.token = token
  },
  setImageUrl(state, url) {
    state.imageUrl = url
  },
  setEmail(state, email) {
    state.email = email
  },
  setUser(state, user) {
    state.user = user
  },
  setIsSupport(state, isSupport) {
    state.isSupport = isSupport
  },
  setAccountName(state, accountName) {
    state.accountName = accountName
  },
  setOrgName(state, orgName) {
    state.orgName = orgName
  },
  setExpiresAt(state, expiresAt) {
    state.expiresAt = expiresAt
  },
  setUsername(state, username) {
    state.username = username
  },
  setFeatures(state, features) {
    state.features = features || {}
  },
  setWhitelabel(state, whitelabel) {
    state.whitelabel = whitelabel || {}
  },
  setRole(state, role) {
    state.role = role
  },
  setStaff(state, staff) {
    state.staff = staff
  },
  setMe(state, data) {
    state.me = data
  },
  setIsThInitialized(state, initialized) {
    state.isThInitialized = initialized
  },
  SET_SCOPES(state, scopes) {
    state.scopes = [...(scopes || [])]
  },
  SET_LOCATIONS(state, locations) {
    if (Array.isArray(locations)) {
      state.locations = [...locations]
    } else {
      state.locations = null
    }
  },
  SET_TH_USER_ID(state, thUserId) {
    state.thUserId = thUserId || null
  },
  CLEAR_PERSISTENCE(state) {
    // remove those proeprties if we actually have them. However this is likely unused.
    state.authenticated = false
    state.token = null
    state.user = null
    state.email = null
  },
  SET_PERMISSIONS(state, { scopes, role }) {
    state.scopes = scopes
    state.role = role
  },
  RESET(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
