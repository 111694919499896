<template>
  <teleport v-if="isOpen" to="#th-modals" :disabled="showInline || debug">
    <div class="th-modal-wrapper" :data-testid="dataTestid">
      <transition name="fade" :css="!debug">
        <div v-show="isShown" class="th-modal-overlay" @click="handleClose" />
      </transition>
      <transition name="flyin" :css="!debug">
        <div
          v-show="isShown"
          class="th-modal shadow-md"
          :style="{ width, height }"
        >
          <!-- Header -->
          <div
            v-if="$slots.title || title || $slots.subtitle || subtitle"
            class="th-modal-header leading-normal"
          >
            <div class="flex-grow text-lg">
              <slot v-if="$slots.title || title" name="title">
                {{ title }}
              </slot>
              <div class="text-xs" :class="{ italic: !isWhiteLabel }">
                <slot v-if="$slots.subtitle || subtitle" name="subtitle">
                  {{ subtitle }}
                </slot>
              </div>
            </div>

            <!-- Close -->
            <el-button
              class="text-current text-lg p-0 h-5 w-5 ml-2 flex-shrink-0"
              icon="Close"
              text
              @click="handleClose"
            />
          </div>

          <!-- Body -->
          <div class="th-modal-body" :class="bodyClass">
            <slot />
          </div>

          <!-- Footer -->
          <div v-if="$slots.footer" class="th-modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
import Teleport from 'vue2-teleport'
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'ThModal',

  components: {
    Teleport
  },

  props: {
    name: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    subtitle: {
      type: String,
      required: false,
      default: undefined
    },
    bodyClass: {
      type: String,
      required: false,
      default: null
    },
    showInline: {
      // Use teleport to move modal to body or keep it inline
      type: Boolean,
      required: false,
      default: false
    },
    dataTestid: {
      type: String,
      required: false,
      default: undefined
    }
  },

  emits: ['close'],

  data() {
    return {
      debug: false,
      isOpen: false,
      isShown: false
    }
  },

  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  beforeMount() {
    // Always open if debug is true
    this.debug = this.$thModal.debug
    if (this.debug) {
      this.isOpen = true
      this.isShown = true
    }
  },

  mounted() {
    this.$thModal.listen(this.onToggle)
  },

  methods: {
    handleClose() {
      if (this.$attrs.onClose || this.$attrs.close) return this.$emit('close')
      this.$thModal.hide(this.name)
    },

    onToggle(name, state) {
      if (name === this.name) {
        const newState = state == null ? !this.isOpen : state
        if (newState) {
          this.isOpen = true
          this.$nextTick(() => {
            this.isShown = true
          })
        } else {
          this.isShown = false
          setTimeout(() => {
            this.isOpen = false
          }, 300)
        }
      }
    }
  }
}
</script>

<style scoped>
.th-modal-wrapper {
  --th-modal-padding: 2rem;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.th-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.th-modal {
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  border-radius: var(--border-radius);
  max-width: 90%;
  max-height: 90%;
  z-index: 2;
}

.th-modal-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: var(--th-modal-padding);
  padding-bottom: 0;
}

.th-modal-body {
  overflow: auto;
  flex-grow: 1;
  padding: 0 var(--th-modal-padding);
  margin: var(--th-modal-padding) 0;
  color: var(--text-default-color);
}

.th-modal-footer {
  flex-shrink: 0;
  padding: var(--th-modal-padding);
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.flyin-enter-active,
.flyin-leave-active {
  transition: all 0.3s;
}
.flyin-enter,
.flyin-leave-to {
  opacity: 0;
  /* transform: scale(0.9) translateY(-1rem) translateZ(0) rotateX(90deg); */
  transform: scale(0.9, 0.5) translateY(-1rem);
}

/* Override elements ui */
.th-modal :deep(.el-input .el-input__inner[type='number']) {
  text-align: right;
}

.th-modal :deep(.leading-normal .el-form-item__content) {
  line-height: 1;
}

.th-modal :deep(.el-form-item__label) {
  line-height: 1.2;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  padding-right: 0;
  float: none;
  color: var(--label-text-color);
  display: block;
}

.th-modal :deep(.el-button) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th-modal
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:after,
.th-modal
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:after {
  content: '*';
  color: var(--error-color);
  margin-left: 2px;
}

.th-modal
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.th-modal
  :deep(.el-form-item.is-required:not(.is-no-asterisk))
  > .el-form-item__label:before {
  content: '';
  margin-right: 0;
}
</style>
