<template>
  <div
    v-if="filters && filters.length > 0"
    v-click-outside="vcoConfig"
    class="w-40 h-full ml-0 mr-2"
  >
    <div
      type="text"
      class="h-full text-sm p-2 border rounded border-solid border-color cursor-pointer bg-white"
      @click="toggleDropdown"
    >
      <div class="h-full flex justify-between items-center">
        <svgicon
          :src="require('@/assets/icons/th-icon-equlizer.svg')"
          :style="{
            height: '24px',
            width: '24px'
          }"
        />
        <div class="pl-2 flex-grow">
          {{ $t('components.th_datatable.search_filter.filter.button') }}
        </div>
        <el-icon>
          <ArrowUp v-if="dropdownOpen" />
          <ArrowDown v-else />
        </el-icon>
      </div>
    </div>
    <div
      v-show="dropdownOpen"
      class="th-container border rounded-b absolute bg-white"
      :style="containerStyle"
    >
      <div
        class="th-container-gap w-40 h-3 absolute bg-white border-solid border-r border-l"
      />
      <div class="th-container-items">
        <div
          class="filter-items px-6 pt-4 pb-0"
          :class="{ 'overflow-y-auto pr-5': filters.length >= 10 }"
        >
          <div
            v-for="(filter, index) in filters.filter(
              (item) => item.type || item.render
            )"
            :key="index"
            class="filter-item"
          >
            <component
              :is="componentMapping(filter.type)"
              v-if="tagsObject[filter.name]"
              v-model="tagsObject[filter.name].value"
              v-bind="filter"
              :i18n-config="i18nConfig"
              :filter="filter"
              :use-old="filter.useOld"
              :section-title="tagsObject[filter.name].title"
              :locale="locale"
              :tags="tagsObject"
              :append-to-body="filter.appendToBody"
              @update:modelValue="dispatchFilter"
              @resource-set="(data) => handleInitialFetch(filter, data)"
              @keyup.enter="handleEnter"
            />
          </div>
        </div>

        <div class="flex justify-end px-6 py-4 select-none flex-shrink-0">
          <!-- Reset -->
          <el-button @click="reset">
            {{ $t('components.th_datatable.search_filter.search.reset') }}
          </el-button>

          <!-- Search -->
          <el-button
            type="primary"
            :disabled="searchDisabled"
            @click="handleSearchClick"
          >
            {{ $t('components.th_datatable.search_filter.search.button') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import typeOf from 'just-typeof'
import mapValues from 'just-map-values'
import compare from 'just-compare'
import { defineAsyncComponent } from 'vue'
import TableHeader from '@/views/utilities/templater/components/control/components/table-header.vue'

export default {
  name: 'FilterButton',
  components: {
    SearchFilterInput: defineAsyncComponent(() => import('./types/input')),
    SearchFilterNumberInput: defineAsyncComponent(() =>
      import('./types/number-input')
    ),
    SearchFilterCardInput: defineAsyncComponent(() =>
      import('./types/card-input')
    ),
    SearchFilterIpInput: defineAsyncComponent(() => import('./types/ip-input')),
    SearchFilterExpiryDateInput: defineAsyncComponent(() =>
      import('./types/expiry-date-input')
    ),
    SearchFilterCurrencyInput: defineAsyncComponent(() =>
      import('./types/currency-input')
    ),
    SearchFilterCurrencySelect: defineAsyncComponent(() =>
      import('./types/currency-select')
    ),
    SearchFilterDaterange: defineAsyncComponent(() =>
      import('./types/daterange')
    ),
    SearchFilterSelect: defineAsyncComponent(() => import('./types/select')),
    SearchFilterSlider: defineAsyncComponent(() => import('./types/slider')),
    SearchFilterRange: defineAsyncComponent(() => import('./types/range')),
    SearchResourceSelect: defineAsyncComponent(() =>
      import('./types/resource-select')
    ),
    SearchFilterMultiselect: defineAsyncComponent(() =>
      import('./types/multiselect')
    ),
    SearchFilterSwitch: defineAsyncComponent(() => import('./types/switch')),
    SearchRemoteSearchSelect: defineAsyncComponent(() => import('./types/rsc')),
    SearchCheckbox: defineAsyncComponent(() => import('./types/checkbox'))
  },
  props: {
    locale: {
      type: String,
      default: 'en'
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filters: {
      type: Array,
      required: true
    },
    textFieldName: {
      type: String,
      required: false,
      default: 'q'
    },
    width: {
      type: Number,
      default: 0
    },
    submitOnEnter: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnItem: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnItemRemove: {
      type: Boolean,
      required: false,
      default: false
    },
    submitOnReset: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnSubmit: {
      type: Boolean,
      required: false,
      default: true
    },
    clickAwayListener: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: [
    'update:modelValue',
    'filter',
    'submit',
    'toggled-dropdown',
    'close-dropdown',
    'reset',
    'update-filter'
  ],
  data() {
    return {
      input: '',
      dropdownOpen: false,
      tagsObject: this.normaliseIncomingFilters(),
      vcoConfig: {
        handler: this.closeOnOutsideClick,
        middleware: this.vcoMiddleware
      }
    }
  },
  computed: {
    i18nConfig() {
      return {
        inputPlaceholder: this.$t(
          'components.th_datatable.search_filter.search.placeholder'
        ),
        selectPlaceholder: this.$t(
          'components.th_datatable.search_filter.select.placeholder'
        ),
        searchButtonText: this.$t(
          'components.th_datatable.search_filter.search.button'
        ),
        resetButtonText: this.$t(
          'components.th_datatable.search_filter.search.reset'
        )
      }
    },
    inputFieldStyle() {
      const obj = {}

      if (this.width && this.width > 350) {
        return { ...obj, width: `${parseFloat(this.width) + 60}px` }
      }
      return obj
    },
    containerStyle() {
      if (this.width && this.width > 350) {
        return { width: `${this.width}px` }
      }
      return {}
    },
    searchDisabled() {
      return !Object.keys(this.tagsObject).length
    }
  },
  watch: {
    tagsObject: {
      handler: function (newValues, oldValues) {
        this.$emit('update:modelValue', newValues)
      },
      deep: true
    },
    modelValue(newValues, oldValues) {
      if (!compare(newValues, oldValues)) {
        this.tagsObject = this.normaliseIncomingFilters()
      }
    }
  },
  methods: {
    componentMapping(type) {
      return {
        input: 'SearchFilterInput',
        'number-input': 'SearchFilterNumberInput',
        'ip-input': 'SearchFilterIpInput',
        'card-input': 'SearchFilterCardInput',
        'expiry-date-input': 'SearchFilterExpiryDateInput',
        'currency-input': 'SearchFilterCurrencyInput',
        'currency-select': 'SearchFilterCurrencySelect',
        daterange: 'SearchFilterDaterange',
        select: 'SearchFilterSelect',
        multiselect: 'SearchFilterMultiselect',
        slider: 'SearchFilterSlider',
        range: 'SearchFilterRange',
        'resource-select': 'SearchResourceSelect',
        switch: 'SearchFilterSwitch',
        'remote-search-select': 'SearchRemoteSearchSelect',
        checkbox: 'SearchCheckbox'
      }[type]
    },
    normaliseIncomingFilters() {
      return this.filters.reduce(
        (incomingFilters, item) => {
          incomingFilters[item.name] = {
            value: null,
            ...item,
            ...incomingFilters[item.name]
          }
          return incomingFilters
        },
        {
          [this.textFieldName]: { label: null, value: null },
          ...this.modelValue
        }
      )
    },
    dispatchFilter() {
      const filter = {}
      Object.entries(this.tagsObject)
        .filter((item) => {
          return !(
            item[1].value === null ||
            item[1].value === '' ||
            this.isEmptyArray(item[1].value)
          )
        })
        .forEach((item) => {
          filter[item[0]] = item[1].value
        })

      this.$emit('filter', filter, this.tagsObject)

      if (this.submitOnItem) {
        this.handleSubmit()
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen === false) this.$emit('close-dropdown')
      this.$emit('toggled-dropdown')
    },
    handleSubmit() {
      if (this.closeOnSubmit) this.closeDropdown()

      const trimmedTagsObject = this.prepareTagsObject(this.tagsObject)

      this.$emit('submit', trimmedTagsObject)
      return trimmedTagsObject
    },
    prepareTagsObject(tagsObject) {
      const trimmed = { ...tagsObject }

      // remove trailing whitespace in all nested levels
      function trimInputs(obj) {
        for (const key in obj) {
          if (obj[key] !== null && typeof obj[key] === 'object') {
            // works on objects and arrays
            obj[key] = trimInputs(obj[key])
          }

          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim()
          }
        }
        return obj
      }

      trimInputs(trimmed)

      return trimmed
    },
    handleSearchClick() {
      this.handleSubmit()
    },
    reset() {
      this.tagsObject = this.normaliseIncomingFilters()
      this.tagsObject = mapValues(this.tagsObject, (value, key) => {
        value.value = value.default ?? null
        return value
      })
      this.$emit('reset')
      this.$emit('submit', this.handleSubmit())
    },
    closeDropdown() {
      this.dropdownOpen = false
      this.$emit('close-dropdown')
    },
    handleEnter() {
      if (this.submitOnEnter) {
        this.handleSubmit()
      }
    },
    closeOnOutsideClick(e) {
      if (this.dropdownOpen) {
        this.closeDropdown()
      }
    },
    isEmptyArray(value) {
      return Array.isArray(value) && !value.length
    },
    handleInitialFetch(filter, data) {
      this.tagsObject[filter.name]['originalData'] = data
      this.$emit('update-filter', this.tagsObject[filter.name])
    },
    vcoMiddleware(event) {
      if (event.target?.closest?.('.el-popper')) {
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
* {
  border-color: var(--border-color);
}

.th-container {
  margin-top: 5px;
  z-index: 2100;
}

.th-container-items {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 250px);
}

.filter-items {
  overflow: auto;
}

.th-container-gap {
  top: -12px;
  left: -1px;
}
</style>
