import { defineStore } from 'pinia'
import { NOTIFICATIONS_TYPES } from '@/constants/index'
import { useMessagesStore } from './messages'
import { useSocketIOStore } from './socket-io'

const moduleName = 'assets'

export const useAssetsStore = defineStore('assets', {
  state: () => ({
    assets: {}
  }),
  actions: {
    init(vm) {
      const messagesStore = useMessagesStore()
      const socketIoStore = useSocketIOStore()
      const socket = socketIoStore.socket
      const parseSocketData = socketIoStore.getSocketDataParser()

      socket?.on('imageUploadUploading', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.assets
        )
        if (!isValidId) return
        vm?.$log?.debug('Assets | imageUploadUploading')

        const message = {
          id,
          read: false,
          operation: {
            module: moduleName,
            originKey,
            type: NOTIFICATIONS_TYPES.LOADING,
            ...storedItem
          }
        }
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('imageUploadSuccess', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.assets
        )
        if (!isValidId) return
        vm?.$log?.debug('Assets | imageUploadSuccess')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            showTooltip: false,
            type: NOTIFICATIONS_TYPES.SUCCESS,
            ...storedItem
          }
        }
        messagesStore.updateMessage({ id, changedProp: message })
      })

      socket?.on('imageUploadError', (data) => {
        const { isValidId, id, originKey, storedItem } = parseSocketData(
          data,
          this.assets
        )
        if (!isValidId) return
        vm?.$log?.debug('Assets | imageUploadError')

        const message = {
          read: false,
          operation: {
            originKey,
            module: moduleName,
            type: NOTIFICATIONS_TYPES.ERROR,
            ...storedItem
          }
        }

        messagesStore.updateMessage({ id, changedProp: message })
      })
    },
    setNewAsset(id, payload) {
      const messagesStore = useMessagesStore()
      const message = {
        id,
        read: false,
        operation: {
          module: moduleName,
          type: NOTIFICATIONS_TYPES.LOADING,
          ...payload
        }
      }
      this[id] = payload
      messagesStore.setLocalMessage(message)
      useSocketIOStore().setActiveId(id)
    }
  }
})
