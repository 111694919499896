import { addDays, startOfMonth, setDay, formatISO, addMonths } from 'date-fns'

export const RECURRING_CYCLE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
}

export const RECURRING_CYCLE_MAP = {
  daily: '1 day',
  weekly: '7 days',
  monthly: '1 mons'
}

export const FILE_TYPES = {
  CSV: 'csv',
  EXCEL: 'xls'
}

export const DAYS_OF_WEEK = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  0: 'sunday'
}

export const COLUMN_SELECTION = {
  CURRENT_VIEW: 'current_view',
  ALL: 'all'
}

export const DELIMITERS = {
  SEMICOLON: 'semicolon',
  COMMA: 'comma'
}

export const ENCLOSURES = {
  DOUBLE: 'double',
  SINGLE: 'single'
}

export function getRecurringOptions({ recurring_cycle, recurring_on = 0 }) {
  const now = new Date()
  let startDate

  if (recurring_cycle === RECURRING_CYCLE.DAILY) {
    startDate = addDays(now, 1)
  }

  if (recurring_cycle === RECURRING_CYCLE.WEEKLY) {
    const weeklyStartDate = setDay(now, recurring_on)
    startDate =
      weeklyStartDate > now ? weeklyStartDate : addDays(weeklyStartDate, 7)
  }

  if (recurring_cycle === RECURRING_CYCLE.MONTHLY) {
    const monthlyStartDate =
      recurring_on - 1 > 0
        ? addDays(startOfMonth(now), recurring_on - 1)
        : startOfMonth(now)
    startDate =
      monthlyStartDate > now ? monthlyStartDate : addMonths(monthlyStartDate, 1)
  }

  return {
    interval: RECURRING_CYCLE_MAP[recurring_cycle],
    startDate: formatISO(startDate, { representation: 'date' })
  }
}

export function getExportColumns(
  headers,
  headerNameToExportColumnMap,
  columnSelection
) {
  const columns = []
  if (columnSelection === COLUMN_SELECTION.CURRENT_VIEW) {
    // One datatable header can map to multiple export columns so We expect the
    // type of headerNameToExportColumnMap to be: { [headerName]: string | string[] }
    headers.forEach(({ field }) => {
      const mapValue = headerNameToExportColumnMap[field]
      if (mapValue === undefined) {
        // Include all headers that are not explicitly mapped.
        columns.push(field)
      } else if (Array.isArray(mapValue)) {
        // Include headers mapped to multiple export columns.
        columns.push(...mapValue.filter((value) => typeof value === 'string'))
      } else if (mapValue && typeof mapValue === 'string') {
        // We drop headers that are falsy or not a string.
        columns.push(mapValue)
      }
    })
  }
  return columns
}
