<template>
  <el-table
    ref="table"
    :data="table"
    :row-style="() => ({ cursor: 'pointer' })"
    height="40vh"
    :row-class-name="tableRowClassName"
    @row-click="selectBranch"
  >
    <el-table-column
      property="label"
      :label="$t('nav.main.items.location.branch_location')"
      sortable
    />
    <el-table-column
      property="custom_id"
      :label="$t('nav.main.items.location.id')"
      sortable
    />
  </el-table>
</template>

<script>
import debounce from 'debounce'

export default {
  props: {
    table: {
      type: Array,
      default: () => []
    },
    infiniteScroll: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.getScrollable()?.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    this.getScrollable()?.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    selectBranch(row) {
      if (row.disabled === false) {
        this.$store.dispatch('Config/setLocationContext', {
          branches: [row],
          location: row.id
        })
        this.$nextTick(() => {
          this.$emitter.emit('refresh-requested')
          this.$emit('close')
        })
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.disabled) {
        return 'disabled'
      }

      return ''
    },
    getScrollable() {
      return this.$refs.table.$el.querySelector('.el-table__body-wrapper')
    },
    onScroll: debounce(function (event) {
      if (!this.infiniteScroll) return
      if (
        event.target.scrollTop + event.target.offsetHeight >=
        event.target.scrollHeight
      ) {
        this.$emit('paginate')
      }
    }, 100)
  }
}
</script>

<style scoped>
.el-table :deep(.disabled) {
  color: #d0d0d0;
  cursor: not-allowed !important;
}
</style>
