<template>
  <div v-click-outside="close">
    <el-button
      v-if="!isCompleted && isAdmin"
      type="warning"
      plain
      class="account-button ml-3 outline-none font-semibold hover:text-yellow text-th-info-yellow hover:bg-th-info-yellow-hover border border-solid border-th-info-yellow"
      :class="{
        'bg-th-info-yellow-selected': showList,
        'bg-th-white': !showList
      }"
      @click="showList = !showList"
    >
      {{ $t('common.interactions.buttons.account_setup') }}
    </el-button>
    <div v-show="showList" class="absolute">
      <base-card
        class="p-4 w-full bg-white z-10 mt-2"
        style="min-width: 21rem; max-width: 21rem; margin-left: -192px"
      >
        <a
          v-for="(stage, index) in optionsOrdered"
          :key="stage"
          :class="{
            'bg-th-light-blue hover:bg-th-light-blue-hover': !getStage(stage),
            'bg-th-lighter-green text-th-green hover:bg-th-lighter-green-hover': getStage(
              stage
            )
          }"
          class="mb-2 flex cursor-pointer rounded"
          @click="triggerOnboardingStep(stage)"
        >
          <div class="flex items-center mx-4">
            <div
              class="rounded-full h-12 w-12 bg-white flex items-center justify-center"
              :class="{ 'text-3xl': getStage(stage) }"
            >
              <svgicon
                v-if="getStage(stage)"
                class="complete"
                data-testid="tickIcon"
                width="23"
                height="23"
                :src="tickIcon"
              />
              <span v-else>{{ index + 1 }}</span>
            </div>
          </div>
          <div class="py-4 pr-4 flex flex-col text-sm">
            <span class="font-semibold" v-text="titleTranslations[stage]" />
            <span v-text="descriptionTranslations[stage]" />
          </div>
        </a>
      </base-card>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import BaseCard from '@/components/base-card'
import { mapGetters, mapState } from 'vuex'
import tickIcon from '@/assets/icons/th-tick.svg'
export default {
  components: {
    BaseCard
  },
  data() {
    return {
      optionsOrdered: ['account_information', 'branches', 'register', 'staff'],
      tickIcon
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin'
    }),
    ...mapState({
      showOnboardList: (state) => state.Config.showOnboardList
    }),
    showList: {
      get: function () {
        return this.showOnboardList
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setShowOnboardList', newValue || false)
      }
    },
    stages() {
      return (
        safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'onboarding.stages'
        ) || []
      )
    },
    isCompleted() {
      return (
        safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'onboarding.completed'
        ) !== false
      )
    },
    titleTranslations() {
      return {
        account_information: this.$t(
          'onboarding.card.account_information.title'
        ),
        branches: this.$t('onboarding.card.branches.title'),
        register: this.$t('onboarding.card.register.title'),
        staff: this.$t('onboarding.card.staff.title')
      }
    },
    descriptionTranslations() {
      return {
        account_information: this.$t(
          'onboarding.card.account_information.description'
        ),
        branches: this.$t('onboarding.card.branches.description'),
        register: this.$t('onboarding.card.register.description'),
        staff: this.$t('onboarding.card.staff.description')
      }
    }
  },
  methods: {
    open() {
      this.showList = true
    },
    close() {
      this.showList = false
    },
    getStage(stage) {
      return this.stages[stage] || null
    },
    triggerOnboardingStep(step) {
      // Let the page know that we are redirecting from onboarding
      this.$store.dispatch('Config/setIsOnboardingRoute', true)
      switch (step) {
        case 'branches':
          this.$router.push({ name: 'resources-branches' })
          break
        case 'staff':
          this.$router.push({ name: 'staff-list' })
          break
        case 'account_information':
          this.$router.push({ name: 'account' })
          break
        case 'register':
          this.$router.push({ name: 'resources-registers-all' })
          break
      }
      this.close()
    }
  }
}
</script>

<style>
.complete {
  fill: var(--color-complete) !important;
}
</style>
