export function getNavigation({ featureConfig } = { featureConfig: {} }) {
  const isCrmEnabled = !!featureConfig?.customers?.crm
  const isVouchersEnabled = !!featureConfig?.loyalty?.vouchers
  const isSuppliersEnabled = !!featureConfig?.suppliers
  const isFiscalizationEnabled = !!featureConfig?.misc?.fiscalisation
  const isReservationsEnabled = !!featureConfig?.reservations
  const isApiKeysEnabled = !!featureConfig?.settings?.general?.api_keys

  return [
    {
      enabled: true,
      value: '/settings/dashboard/general',
      key: 'pages.settings.general.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/api_users',
      key: 'pages.settings.api_users.title'
    },
    {
      enabled: isApiKeysEnabled,
      value: '/settings/dashboard/api_keys',
      key: 'pages.settings.api_keys.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/searches',
      key: 'pages.settings.searches.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/analytics',
      key: 'pages.settings.analytics.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/custom_dashboards',
      key: 'pages.settings.custom_dashboards.title'
    },
    {
      enabled: isCrmEnabled,
      value: '/settings/dashboard/customers',
      key: 'pages.settings.customers.title'
    },
    {
      enabled: isSuppliersEnabled,
      value: '/settings/dashboard/suppliers',
      key: 'pages.settings.suppliers.title'
    },
    {
      enabled: isVouchersEnabled,
      value: '/settings/dashboard/voucher-systems',
      key: 'pages.settings.voucher-systems.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/staff',
      key: 'pages.settings.staff.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/products',
      key: 'pages.settings.products.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/communications',
      key: 'pages.settings.communications.title'
    },
    {
      enabled: isFiscalizationEnabled,
      value: '/settings/dashboard/fiscalization',
      key: 'pages.settings.fiscalization.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/integrations',
      key: 'pages.settings.integrations.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/accounting',
      key: 'pages.settings.accounting.title'
    },
    {
      enabled: true,
      value: '/settings/dashboard/trash',
      key: 'pages.settings.trash.title'
    },
    {
      enabled: isReservationsEnabled,
      value: '/settings/dashboard/reservations',
      key: 'pages.settings.reservations.title'
    }
  ]
}
