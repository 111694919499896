import safeGet from 'just-safe-get'

export function getNavigation(
  { featureConfig, clientAccountConfiguration } = {
    featureConfig: {},
    clientAccountConfiguration: {}
  }
) {
  const isGastronomyDisabled =
    (!featureConfig.settings?.pos?.pagers ||
      featureConfig.settings?.pos?.pagers === 'false') &&
    (!featureConfig.settings?.pos?.kitchen_printers ||
      featureConfig.settings?.pos?.kitchen_printers === 'false')
  const isItalianFinancialsEnabled =
    safeGet(clientAccountConfiguration, 'tenant.address.country') === 'IT'
  const isFeatureReceiptEnabled = !!featureConfig.digital_receipts
  return [
    // Discounts
    {
      enabled: true,
      value: '/settings/pos/discounts',
      key: 'pages.settings.discounts.title'
    },

    // Change Suggestions
    {
      enabled: true,
      value: '/settings/pos/change_suggestions',
      key: 'pages.settings.change_suggestions.title'
    },

    // Security
    {
      enabled: true,
      value: '/settings/pos/security',
      key: 'pages.settings.security.title'
    },

    // Search Behaviour
    {
      enabled: true,
      value: '/settings/pos/search',
      key: 'pages.settings.search.title'
    },

    // Checkout
    {
      enabled: true,
      value: '/settings/pos/check_out',
      key: 'pages.settings.check_out.title'
    },

    // Gastronomy
    {
      enabled: !isGastronomyDisabled,
      value: '/settings/pos/gastronomy',
      key: 'pages.settings.gastronomy.title'
    },

    // Financials
    {
      enabled: true,
      value: '/settings/pos/financials',
      key: 'pages.settings.financials.title'
    },

    // Italian Financials
    {
      enabled: isItalianFinancialsEnabled,
      value: '/settings/pos/italian_financials',
      key: 'pages.settings.italian_financials.title'
    },

    // Receipts
    {
      enabled: isFeatureReceiptEnabled,
      value: '/settings/pos/receipts',
      key: 'pages.settings.receipts.title'
    },

    // Tipping
    {
      enabled: true,
      value: '/settings/pos/tipping',
      key: 'pages.settings.tipping.title'
    }
  ]
}
