import qs from 'qs'

export default {
  install: (app, options = {}) => {
    app.config.globalProperties.$makeFilteredPath = function makeFilteredPath(
      path,
      filter,
      additionalQuery = {}
    ) {
      const query = qs.stringify({
        ...additionalQuery,
        filter: {
          ...filter
        }
      })

      return `${path}?${query}`
    }
  }
}
