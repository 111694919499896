<template>
  <div class="tillhub-tab-page-wrapper" :style="style">
    <nav>
      <router-link
        v-for="header in headers"
        :key="header.value"
        class="nav-item"
        :to="`${header.value}${query ? `?${query}` : ''}`"
        :class="isActive(header.value)"
        @click="trackNavigation(header.key)"
        v-text="header.label"
      />
    </nav>
    <div class="content">
      <slot id="content" />
    </div>
  </div>
</template>

<script>
import qs from 'qs'

function stripTrailingSlash(str) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1)
  }
  return str
}

export default {
  name: 'ThTabPageWrapper',
  props: {
    headers: {
      type: Array,
      required: true
    },
    margin: {
      type: [Boolean, String],
      default: true
    },
    footer: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    },
    gutter: {
      type: [String, Number],
      required: false,
      default: 0
    },
    persistedQuery: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  computed: {
    style() {
      let padding = '0'
      if (Number.isFinite(this.gutter) && this.gutter !== 0) {
        padding = `${this.gutter}px`
      } else if (this.gutter) {
        padding = this.gutter
      }

      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
        padding
      }
    },
    route() {
      return stripTrailingSlash(this.$route.path)
    },
    query() {
      let q

      if (!this.persistedQuery) return q

      try {
        q = qs.stringify(this.persistedQuery || {})
      } catch (err) {
        this.$logException(err, { trackError: false })
      }

      return q
    }
  },
  methods: {
    isActive: function (page) {
      return this.route === page ? 'nav-active' : ''
    },
    trackNavigation(key) {
      if (!key) {
        // eslint-disable-next-line no-console
        console.error(
          'Navigation item does not have "key" property to track it'
        )
        return
      }
      this.$ampli.eventWithBaseProps('navigationSecondary', {
        navigation_to: this.$t(key, 'en')
      })
    }
  }
}
</script>

<style scoped>
.tillhub-tab-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tillhub-tab-page-wrapper > * {
  margin: 0;
}

.tillhub-tab-page-wrapper a {
  text-decoration: none;
  color: inherit;
}

.el-main {
  overflow: hidden;
}

.content {
  height: 100%;
  width: 100%;
  overflow: auto;
}

nav {
  background: var(--navigation-bg-color);
  color: var(--navigation-font-color);
  height: 45px;
  display: flex;
  overflow-x: auto;
  box-sizing: content-box;
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
  padding: 0 25px;
}

nav::-webkit-scrollbar {
  display: none;
}

a.nav-item {
  position: relative;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  word-wrap: unset;
  flex: 1 1 auto;
}

a.nav-item:hover {
  color: var(--tab-nav-color);
}

a.nav-item.nav-active {
  color: var(--tab-nav-color);
}

a.nav-active::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 5px solid var(--tab-nav-color);
  z-index: 1;
}

nav a {
  user-select: none;
  box-sizing: border-box;
}
</style>
