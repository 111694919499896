import qs from 'qs'
import mapValues from 'just-map-values'
import { checkPermissions, shouldAllowNavItem } from '../permissions'
import { handleAtLeastSome } from '@/store/helpers/app-config/index'

// Function to retrieve the children array of a path
// Every part of the path is passed in paths
// For example we can go to the children of the financial_accounting ['/reports', 'financial_accounting']
export function getChildrenFromParent(root, paths = []) {
  // Get root with all the paths
  let children = root
  // Go deep inside each node children until we hit the last path, and return the children
  for (const path of paths) {
    children = children.find((route) => route.path === path).children
  }
  return children
}

export function getChildNameByPermissions(
  pathToChildren,
  root,
  role,
  userScopes,
  featureConfig = null,
  featureLock = true
) {
  let path = 'home'
  const possibleChildren = getChildrenFromParent(root, pathToChildren)
  for (const child of possibleChildren) {
    // This child is used just to redirect
    if (child.path === '' && !child.component) {
      continue
    }
    // Check first the feature flags, including the featureLock
    if (
      featureConfig &&
      child.meta?.featurePath &&
      featureLock &&
      !handleAtLeastSome(featureConfig, child.meta?.featurePath)
    ) {
      continue
    }
    // Check second the scope permissions
    // Get scopes of the child
    const scopes =
      child.scopes || child.meta?.scopes || child.meta?.meta?.scopes || []
    // If it does not have scopes, means everybody has access
    // Or they have the exact permissions
    // Or we are not in child level, but we have permission to some child
    const isParent = !!child?.children?.length
    if (
      !scopes.length ||
      checkPermissions({ scopes }, role, userScopes) ||
      (isParent && shouldAllowNavItem(scopes, userScopes))
    ) {
      if (!isParent) {
        pathToChildren.push(child.path)
        path = pathToChildren.join('/')
        break
      } else {
        // In case it has children, search deeper till a child
        pathToChildren.push(child.path)
        path = getChildNameByPermissions(
          pathToChildren,
          root,
          role,
          userScopes,
          featureConfig,
          featureLock
        )
        break
      }
    }
  }
  return path
}

/**
 * Curried function to parse filters to make them ready to be used in the query string
 * @param {Function} parseFn
 * @param {Object} filters
 * @returns {Object}
 */
export const parseQueries = (parseFn = (k, v) => v) => (filters) =>
  Object.entries(filters).reduce((result, filter) => {
    const [key, { value }] = filter

    return {
      ...result,
      [key]: parseFn(key, value)
    }
  }, {})

/**
 * Curried function to create a path and query string from a filters object
 * @param {string} path
 * @param {object} filters
 * @returns {string} path with query string
 * @example
 * path = '/path'
 * filters = { start: '2019-01-01', date: '2019-01-31' }
 * returns '/path?filter=%5Bstart%5B=2019-01-01&filter%5Bend%5B=2019-01-31'
 */
export const makeQuery = (path) => (filters) => {
  const query = qs.stringify(
    {
      filter: mapValues(filters, (value) => value ?? undefined)
    },
    { addQueryPrefix: true }
  )

  return `${path}${query}`
}
