import safeGet from 'just-safe-get'

/**
 * Determines the sorting order depending on the column sorting type
 * a > b => 1 | a = b => 0 | a < b => -1
 * Sort by date, number, currency or string by default
 * @param {Object} a
 * @param {Object} b
 * @param {Object} info
 * @param {Object} sortType
 * @param {String} fallback
 * @returns {Number}
 */
export function compareRow(a, b, info, sortType, fallback) {
  let A = safeGet(a, info.prop)
  let B = safeGet(b, info.prop)

  if (sortType === 'date') {
    A = new Date(A)
    B = new Date(B)
  } else if (sortType === 'number') {
    A = parseFloat(A)
    B = parseFloat(B)
  } else if (sortType === 'currency') {
    A = getPriceValue(A, fallback)
    B = getPriceValue(B, fallback)
  } else if (info.column.formatter) {
    A = info.column.formatter(a).toString().toLowerCase()
    B = info.column.formatter(b).toString().toLowerCase()
  }

  // handles equal values and nulls/undefined/fallbacks
  if (A === B) {
    return 0
  } else if (isNull(A, fallback)) {
    return info.order === 'ascending' ? -1 : 1
  } else if (isNull(B, fallback)) {
    return info.order === 'ascending' ? 1 : -1
  }

  if (info.order === 'ascending') {
    return A > B ? 1 : -1
  } else {
    return A < B ? 1 : -1
  }
}

function getPriceValue(price, fallback) {
  const priceRegex = /[^\d.,-]*/g
  if (typeof price === 'string') {
    // Keep the number part of the string
    return parseFloat(price?.replace(priceRegex, '') || -Infinity)
  } else {
    return parseFloat(!isNull(price, fallback) ? price : -Infinity)
  }
}

function isNull(value, fallback) {
  return (
    value === null ||
    value === undefined ||
    value === 'NAN' ||
    value.toString() === 'NaN' ||
    value === fallback
  )
}
