import { Userpilot } from 'userpilot'

const userPilotToken = process.env.VUE_APP_USER_PILOT_TOKEN
const automatedQaUserId = process.env.VUE_APP_QA_AUTOMATED_USER_ID
let showUserPilot = !!userPilotToken

export function userPilotInitialize() {
  if (!showUserPilot) return
  Userpilot.initialize(userPilotToken, {
    // We use a proxy to avoid passing the user's IP to Userpilot
    endpoint: 'userpilot.tillhub.com'
  })
}

export function userPilotIdentify(userId, userProperties = {}) {
  if (userId === automatedQaUserId) {
    showUserPilot = false //suppress userpilot for automated QA user
  }
  if (!showUserPilot) return
  if (!userId) return Userpilot.anonymous() // anonymous user
  Userpilot.identify(userId, userProperties) // identified user
}

export function userPilotReload() {
  if (!showUserPilot) return
  Userpilot.reload()
}
