<template>
  <el-input
    v-model="input"
    class="mb-2"
    :placeholder="placeholder"
    maxlength="32"
  >
    <template #append>
      <el-checkbox v-model="checked" />
    </template>
  </el-input>
</template>

<script>
import set from 'just-safe-set'
import get from 'just-safe-get'
import typeOf from 'just-typeof'
import cloneDeep from 'clone-deep'
import { mapState } from 'vuex'
import { HEADERS_MAP } from '@/constants'

export default {
  props: {
    header: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      headers: HEADERS_MAP(this)
    }
  },
  computed: {
    placeholder() {
      if (
        this.templateType === 'invoice_v1' ||
        this.templateType === 'delivery_note_v1' ||
        this.templateType === 'full_receipt_v1'
      ) {
        return this.headers[this.header].default
      }
      return ''
    },
    input: {
      get() {
        return get(this.map, `${this.header}.custom`)
      },
      set(value) {
        let map = cloneDeep(this.map)
        if (typeOf(map) !== 'object') map = {}

        set(map, `${this.header}.custom`, value)

        this.$store.commit('Templates/setCurrentTemplateOptionsProp', { map })
      }
    },
    checked: {
      get() {
        if (typeOf(this.map) !== 'object') return false
        if (!this.map[this.header]) return false
        if (!this.map[this.header].show) return false
        return get(this.map, `${this.header}.show`)
      },
      set(value) {
        let map = cloneDeep(this.map)
        if (typeOf(map) !== 'object') map = {}

        set(map, `${this.header}.show`, value)

        this.$store.commit('Templates/setCurrentTemplateOptionsProp', { map })
      }
    },
    ...mapState({
      map(state) {
        return get(state.Templates, 'currentTemplate.options.map')
      },
      templateType(state) {
        return get(state.Templates, 'currentTemplate.type')
      }
    })
  }
}
</script>
