import { isUnifiedCommerce } from '@/constants'

const shared = {
  timetracking: false
}

const getDefaultFeatures = () => ({
  // This flag can be used in cases where a feature that is currently being
  // developed, but is deployed to staging, needs to be hidden in production.
  development: false,
  login: {
    password_forgotten: true
  },
  orders: {
    reports_orders: isUnifiedCommerce(),
    orders_transactions: isUnifiedCommerce()
  },
  reports: {
    financial_accounting: {
      transactions: true,
      balances: true,
      cash_book: false,
      returns: true,
      payment_options: true,
      payments: true,
      vat: true,
      counting_protocols: true
    },
    statistics: {
      revenues: true,
      products: true,
      product_groups: true,
      staff: true,
      customers: true,
      vouchers: true,
      discounts: true,
      stocks: true,
      sold_cart_items: true
    },
    exports: true
  },
  settings: {
    general: {
      integrations: true,
      api_keys: false
    },
    pos: {
      pagers: false,
      kitchen_printers: false
    },
    timetracking: shared.timetracking,
    users: true
  },
  products: {
    manager: true,
    label_printing: false,
    product_groups: true,
    product_templates: true,
    addon_groups: false,
    discounts: true,
    pricebooks: true,
    tags: true,
    promotions: true,
    service: {
      questions: true
    }
  },
  inventory: {
    stock_manager: {
      goods_in: true,
      goods_out: true,
      relocation: true
    },
    stock_movements: true,
    reasons: true,
    processes: false,
    stock_takings: false
  },
  accounting: {
    taxes: true,
    accounts: true,
    expense_accounts: true,
    payment_options: true,
    safe_management: true
  },
  customers: {
    crm: true,
    customer: {
      notes: true,
      contact: true,
      customer_details: true
    }
  },
  staff: true,
  reservations: false,
  suppliers: true,
  documents: true,
  loyalty: {
    vouchers: false,
    voucher_systems: false,
    voucher_systems_v2: false,
    abocard: false
  },
  timetracking: shared.timetracking,
  webhooks: false,
  utilities: {
    document_designer: true,
    custom_dashboards: true,
    audits: {
      actions: true,
      logs: true
    },
    cms: {
      contents: true,
      content_templates: true
    },
    notifications: false,
    favourites: true
  },
  resources: {
    branches: true,
    branch_groups: true,
    warehouses: true,
    registers: true,
    licenses: true,
    devices: true,
    device_groups: true
  },
  misc: {
    // individual features that are not related to a specific page/category
    datev: false,
    fiscalisation: true,
    gastro_tables: false,
    takeaway: false,
    globalSearch: true
  }
})

export default getDefaultFeatures
